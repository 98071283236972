import React from 'react';
import { IAppReportLotPublic } from '../../interfaces/auctions/IAppReportLot';
import { ContentBold, ContentDesc, ContentHead } from '../../common/typos';
import { currencyConvert } from '../../utils/price';
import { Image } from '../../common/images/Image.styles';

interface IReportLotBlockProps {
  reportLot: IAppReportLotPublic;
}

export function ReportLotBlock(props: IReportLotBlockProps) {
  const reportLot: IAppReportLotPublic = props.reportLot;
  return (
    <>
      <ContentHead>{reportLot.name}</ContentHead>
      <Image className={'L'} src={reportLot.imageURL} alt="lot" />
      <ContentDesc>
        Sold by:{' '}
        <ContentBold>
          {reportLot.Total} ($
          {currencyConvert(reportLot.Total)})
        </ContentBold>
      </ContentDesc>
      <ContentDesc>
        From: {reportLot.startPrice} ($
        {currencyConvert(reportLot.startPrice)})
      </ContentDesc>
      <ContentDesc>By {reportLot.bidCnt} bids.</ContentDesc>
    </>
  );
}
