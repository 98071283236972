export interface ISizeSet<T> {
  mobileM: T;
  mobileL: T;
  tablet: T;
  laptop: T;
}

export const sizeScreenValues: ISizeSet<number> = {
  mobileM: 360,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
};

export const sizesBorder = {
  widthS: '1px',
  widthM: '2px',
  widthL: '3px',
  radius: '5px',
  radius0: 0,
};

export const sizes = {
  half: '50%',
  full: '100%',
  widthS: '150px',
  widthM: '250px',
  widthL: '350px',
  widthXL: '500px',
};

export const margins = {
  blockX2: '30px',
  blockX1: '19px',
  block: '15px',
  item: '5px',
};

export const weights = {
  weightL: 600,
  weightM: 500,
  weightS: 400,
};

const sizeScreen = {
  mobileM: 360,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
};

export const device = {
  mobileM: `(min-width: ${sizeScreen.mobileM}px)`,
  mobileL: `(min-width: ${sizeScreen.mobileL}px)`,
  tablet: `(min-width: ${sizeScreen.tablet}px)`,
  laptop: `(min-width: ${sizeScreen.laptop}px)`,
};
