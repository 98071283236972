import { IBid, IBidData } from '../../interfaces/examples/IBid';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { EXAMPLE_BID_CREATED, EXAMPLE_BIDS_LOAD } from './typesExample';
import { AccountIDExample } from '../../config/examples/static-accounts';
import { ILotRate } from '../../interfaces/examples/ILot';
import { getRandomAccount } from './users.actions';

function generateLotBids(lotID: string, startPrice: number): IBid[] {
  const cnt: number = 3 + Math.floor(Math.random() * 5);

  const bids: IBid[] = [];
  let price: number = startPrice;
  for (let i = 0; i < cnt; i++) {
    const bid: IBid = generateNextBid(lotID, price, i);
    price = bid.price;
    bids.unshift(bid);
  }

  return bids;
}

function generateNextBid(lotID: string, price: number, ind: number): IBid {
  const rate: ILotRate = makeRate(price);
  return {
    id: `bid-${lotID}-${ind}`,
    lotID,
    lotType: 'Live',
    accountID: getRandomAccount().id,
    price: price + rate.x10,
    date: new Date().toISOString(),
  };
}

export function makeRate(price: number): ILotRate {
  if (price < 2500) {
    return { x08: 400, x10: 500, x12: 625 };
  } else if (price < 5000) {
    return { x08: 480, x10: 600, x12: 750 };
  } else if (price < 9000) {
    return { x08: 600, x10: 750, x12: 950 };
  } else if (price < 15000) {
    return { x08: 800, x10: 1000, x12: 1250 };
  } else if (price < 22000) {
    return { x08: 1000, x10: 1250, x12: 1500 };
  } else if (price < 30000) {
    return { x08: 1200, x10: 1500, x12: 1875 };
  } else if (price < 40000) {
    return { x08: 1400, x10: 1750, x12: 2200 };
  } else if (price < 50000) {
    return { x08: 1600, x10: 2000, x12: 2500 };
  } else if (price < 65000) {
    return { x08: 2000, x10: 2500, x12: 3125 };
  } else if (price < 85000) {
    return { x08: 2400, x10: 3000, x12: 3750 };
  } else {
    return { x08: 4000, x10: 5000, x12: 6250 };
  }
}

export const loadBidsByLot =
  (lotID: string, startPrice: number, cb?: (bids: IBid[]) => void) =>
  (dispatch: IDispatch<IBidData>, getState: () => IState) => {
    const bidsFromCache: IBid[] = getState().Example.bidsByLotID[lotID];
    if (bidsFromCache) {
      return;
    }

    const bids: IBid[] = generateLotBids(lotID, startPrice);
    const payload: IBidData = {
      lotID,
      isByBot: false,
      bids,
    };
    dispatch({ type: EXAMPLE_BIDS_LOAD, payload });

    if (cb) {
      cb(bids);
    }
  };

export const createBid =
  (lotID: string, price: number) =>
  (dispatch: IDispatch<IBidData>, getState: () => IState) => {
    const bidsFromCache: IBid[] = getState().Example.bidsByLotID[lotID];
    const bidsCnt: number = bidsFromCache.length;
    const payload: IBidData = {
      lotID,
      isByBot: false,
      bids: [
        {
          id: `bid-${lotID}-${bidsCnt}`,
          lotID,
          lotType: 'Live',
          accountID: AccountIDExample,
          price: price,
          date: '',
        },
      ],
    };

    dispatch({ type: EXAMPLE_BID_CREATED, payload });
    setTimeout(
      () => createBidByBot(lotID, price, bidsCnt)(dispatch, getState),
      500
    );
  };

export const createBidByBot =
  (lotID: string, price: number, bidsCnt: number) =>
  (dispatch: IDispatch<IBidData>, getState: () => IState) => {
    const cnt: number = getState().Example.botsCntByLotID[lotID];
    if (cnt > 1) {
      return;
    }

    const payload: IBidData = {
      lotID,
      isByBot: true,
      bids: [
        {
          id: `bid-${lotID}-${bidsCnt + 1}`,
          lotID,
          lotType: 'Live',
          accountID: getRandomAccount().id,
          price: price,
          date: '',
        },
      ],
    };

    dispatch({ type: EXAMPLE_BID_CREATED, payload });
  };
