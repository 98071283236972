import React, { useEffect, useState } from 'react';
import { BlockPageContent } from '../../common/styles';
import { currencyConvert } from '../../utils/price';
import { Gif, operateWithGif } from '../../common/animation/Gif';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { loadBidsByLot } from '../../actions/examples/bid.actions';
import { AccountIDExample } from '../../config/examples/static-accounts';
import {
  IAccountExamples,
  IAccountExamplesSet,
} from '../../interfaces/examples/IAccountExamples';
import { ILotStatus } from '../../interfaces/examples/ILot';
import { IBid } from '../../interfaces/examples/IBid';
import { LotBlock } from './Lot.styles';

interface IBidsProps {
  lotID: string;
  statusByLotID: Record<string, ILotStatus>;
  bidsByLotID: Record<string, IBid[]>;
  users: IAccountExamplesSet;
}

function Bids(props: IBidsProps) {
  const [bids, setBids] = useState<IBid[]>([]);
  const [gifSrc, setGifSrc] = useState<null | string>(null);
  const [status, setStatus] = useState<ILotStatus>(null);
  const [timeoutHolder, setTimeoutHolder] = useState<any>(null);

  useEffect(() => {
    const statusLocal = props.statusByLotID[props.lotID] || null;

    if (statusLocal === 'win') {
      operateWithGif('win', setGifSrc, timeoutHolder, setTimeoutHolder);
    } else if (statusLocal === 'lost' && status === 'win') {
      operateWithGif('lost', setGifSrc, timeoutHolder, setTimeoutHolder);
    }
    setStatus(statusLocal);
  }, [props.statusByLotID]);

  useEffect(() => {
    const bidsLocal: IBid[] = props.bidsByLotID[props.lotID] || [];
    setBids(bidsLocal);
  }, [props.bidsByLotID, props.lotID]);

  function renderUser(accountID: string) {
    const user: IAccountExamples = props.users[accountID];
    return (
      <ContentDescInline>
        {accountID === AccountIDExample ? 'Your name' : user.name}
      </ContentDescInline>
    );
  }

  function renderBlock(bid: IBid, index: number) {
    const isSelf = bid.accountID === AccountIDExample;

    return (
      <ContentDesc className={isSelf ? 'success' : ''} key={index}>
        {renderUser(bid.accountID)}
        <ContentDescInline> - </ContentDescInline>
        <ContentDescInline>
          {bid.price}
          <ContentDescInline>
            {' '}
            (${currencyConvert(bid.price)})
          </ContentDescInline>
        </ContentDescInline>
      </ContentDesc>
    );
  }

  return (
    <BlockPageContent>
      <LotBlock>{gifSrc && <Gif status={status} src={gifSrc} />}</LotBlock>

      {bids.slice(0, 10).map((bid: IBid, index) => renderBlock(bid, index))}
    </BlockPageContent>
  );
}

export default connect(
  (state: IState) => ({
    statusByLotID: state.Example.statusByLotID,
    bidsByLotID: state.Example.bidsByLotID,
    users: state.Example.users,
  }),
  {
    loadBidsByLot,
  }
)(Bids);
