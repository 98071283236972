import './config/locale.config';

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppBlock } from './App.styles';
import { appConfig } from './config/app.config';
import MainPage from './pages/main/MainPage';
import { ThankYouPage } from './pages/main/ThankYouPage';
import { PrivacyPage } from './pages/main/PrivacyPage';
import { TermsPage } from './pages/main/TermsPage';
import AuctionPage from './pages/auctions/AuctionPage';
import Header from './components/Header';
import { Footer } from './components/Footer';
import { Page } from './common/layout';
import AuctionListPage from './pages/auctions/AuctionListPage';
import AuctionLotsPage from './pages/auctions/AuctionLotsPage';
import LotsAllPage from './pages/auctions/LotsAllPage';
import LoginPage from './pages/account/LoginPage';
import { connect } from 'react-redux';
import { checkIsLogged } from './actions/account/user.actions';
import ModerationMain from './pages/moderation/ModerationMain';
import CommunicationMailing from './pages/moderation/communication/CommunicationMailing';
import CommunicationRecommendation from './pages/moderation/communication/CommunicationRecommendation';
import CommunicationUsers from './pages/moderation/communication/CommunicationUsers';
import PartnersAuction from './pages/moderation/partners/PartnersAuction';
import PartnersFeedbacks from './pages/moderation/partners/PartnersFeedbacks';
import PartnersLot from './pages/moderation/partners/PartnersLot';
import Errors from './components/Errors';
import PartnerAuctionListPage from './pages/partners/PartnerAuctionListPage';
import PartnerAuctionPage from './pages/partners/PartnerAuctionPage';
import CreateAuction from './pages/moderation/CreateAuction';
import CreateAuctionPage from './pages/main/CreateAuctionPage';
import { LeadFormPage } from './pages/main/LeadFormPage';
import { BlockPage } from './common/styles';
import CommunicationMailingOne from './pages/moderation/communication/CommunicationMailingOne';
import CommunicationLogs from './pages/moderation/communication/CommunicationLogs';
import CommunicationTemplateParams from './pages/moderation/communication/CommunicationTemplateParams';
import MainAnalytics from './pages/analytics/MainAnalytics';
import CacheAnalytics from './pages/analytics/CacheAnalytics';
import TechAnalytics from './pages/analytics/TechAnalytics';
import CacheAllAnalytics from './pages/analytics/CacheAllAnalytics';
import TechAllAnalytics from './pages/analytics/TechAllAnalytics';
import DescriptionAllAnalytics from './pages/analytics/DescriptionAllAnalytics';

const { routes } = appConfig;

interface IAppProps {
  checkIsLogged: () => void;
}

function App(props: IAppProps) {
  const [scrollFromButton, setScrollFromButton] = useState<null | string>(null);

  useEffect(() => {
    document.title = 'Create Auction';
  }, []);
  useEffect(() => props.checkIsLogged(), []);

  const scrollTo = (buttonName: string) => {
    setScrollFromButton(buttonName);
    setTimeout(() => setScrollFromButton(null), 1000);
  };

  return (
    <AppBlock>
      <Router>
        <Header scrollTo={scrollTo} />
        <Errors />
        <Page>
          <Routes>
            <Route path={routes.main.home} element={<MainPage />} />
            <Route path={routes.main.numbers} element={<CreateAuctionPage />} />
            <Route path={routes.main.login} element={<LoginPage />} />
            <Route path={routes.main.loginToken} element={<LoginPage />} />
            <Route path={routes.main.loginToken} element={<LoginPage />} />
            <Route path={routes.main.leadForm} element={<LeadFormPage />} />
            <Route path={routes.landing.thankYou} element={<ThankYouPage />} />
            <Route path={routes.landing.privacy} element={<PrivacyPage />} />
            <Route path={routes.landing.terms} element={<TermsPage />} />
            <Route path={routes.auctions.one} element={<AuctionPage />} />
            <Route path={routes.auctions.list} element={<AuctionListPage />} />
            <Route path={routes.auctions.lots} element={<AuctionLotsPage />} />
            <Route path={routes.auctions.lotsAll} element={<LotsAllPage />} />
            <Route
              path={routes.partners.list}
              element={<PartnerAuctionListPage />}
            />
            <Route
              path={routes.partners.one}
              element={<PartnerAuctionPage />}
            />
            <Route path={routes.Moderation.main} element={<ModerationMain />} />
            <Route
              path={routes.Moderation.CreateAuction}
              element={<CreateAuction />}
            />
            <Route
              path={routes.Moderation.communication.mailing}
              element={<CommunicationMailing />}
            />
            <Route
              path={routes.Moderation.communication.mailingOne}
              element={<CommunicationMailingOne />}
            />
            <Route
              path={routes.Moderation.communication.recommendation}
              element={<CommunicationRecommendation />}
            />
            <Route
              path={routes.Moderation.communication.templateParams}
              element={<CommunicationTemplateParams />}
            />
            <Route
              path={routes.Moderation.communication.logs}
              element={<CommunicationLogs />}
            />
            <Route
              path={routes.Moderation.communication.users}
              element={<CommunicationUsers />}
            />
            <Route
              path={routes.Moderation.partners.auction}
              element={<PartnersAuction />}
            />
            <Route
              path={routes.Moderation.partners.feedbacks}
              element={<PartnersFeedbacks />}
            />
            <Route
              path={routes.Moderation.partners.lot}
              element={<PartnersLot />}
            />
            <Route path={routes.Analytics.main} element={<MainAnalytics />} />
            <Route path={routes.Analytics.tech} element={<TechAnalytics />} />
            <Route
              path={routes.Analytics.techAll}
              element={<TechAllAnalytics />}
            />
            <Route
              path={routes.Analytics.descriptionAll}
              element={<DescriptionAllAnalytics />}
            />
            <Route path={routes.Analytics.cache} element={<CacheAnalytics />} />
            <Route
              path={routes.Analytics.cacheAll}
              element={<CacheAllAnalytics />}
            />
          </Routes>

          <BlockPage>
            <Footer />
          </BlockPage>
        </Page>
      </Router>
    </AppBlock>
  );
}

export default connect(() => ({}), {
  checkIsLogged,
})(App);
