// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { Analytics, initializeAnalytics } from '@firebase/analytics';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'create-auction.firebaseapp.com',
  projectId: 'create-auction',
  storageBucket: 'create-auction.appspot.com',
  messagingSenderId: '178776665872',
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: 'G-S17EDWZWXZ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analyticsApp: Analytics = getAnalytics(app);
initializeAnalytics(app);
// analytics.app.
