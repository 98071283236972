import styled from 'styled-components';
import { margins } from '../../common/sizes';
import { borders, shadows } from '../../common/styles';

export const BlockPreview = styled.div`
  margin: ${margins.block} 0;
  padding: ${margins.item} 0;
  border-top: ${borders.mainL};
  border-bottom: ${borders.mainL};
  box-shadow: ${shadows.main};
`;
