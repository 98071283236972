import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  AUCTION_LOAD_IDS,
  AUCTION_LOAD_SET,
  REPORT_LOT_BATCH_LOAD,
  REPORT_LOT_SEARCH_LOAD,
  REPORT_LOT_SET_LOAD,
} from '../actions/types';
import { ActionCase, IStateAuctions } from '../interfaces/system/IState';
import {
  IAppAuctionPublic,
  IAppAuctionSet,
} from '../interfaces/auctions/IAppAuction';
import {
  IAppReportLotBatchData,
  IAppReportLotSearchData,
  IAppReportLotSet,
} from '../interfaces/auctions/IAppReportLot';

const initialStateAuctions: IStateAuctions = {
  byAuctionID: {},
  auctionIDsByPlatform: {},
  auctionIDs: [],

  auctions: {
    completed: [],
    upcoming: [],
  },

  reportLots: {
    limit: 15,
    byLotID: {},
    cntByBatchKey: {},
    IDsByBatchKey: {},
    IDsBySearchKey: {},
  },
};

function sortAuctions(a: IAppAuctionPublic, b: IAppAuctionPublic): 1 | -1 {
  if (a.dateAuction && b.dateAuction) {
    return a.dateAuction > b.dateAuction ? -1 : 1;
  } else if (a.dateStart && b.dateStart) {
    return a.dateStart > b.dateStart ? -1 : 1;
  } else if (a.live && a.live.total && b.live && b.live.total) {
    return a.live.total > b.live.total ? -1 : 1;
  }
  return -1;
}

function setAuctions(state: Draft<IStateAuctions>) {
  const completed: IAppAuctionPublic[] = [];
  const upcoming: IAppAuctionPublic[] = [];
  state.auctionIDs.forEach((auctionID: string) => {
    const auction: IAppAuctionPublic = state.byAuctionID[auctionID];
    if (auction.isComplete) {
      completed.push(auction);
    } else {
      upcoming.push(auction);
    }
  });

  state.auctions.completed = completed.sort(sortAuctions);
  state.auctions.upcoming = upcoming.sort(sortAuctions);
}

export const auctionsReducer: Reducer = createReducer(
  initialStateAuctions,
  (builder: ActionReducerMapBuilder<IStateAuctions>) => {
    // auction
    builder.addCase<string, ActionCase<string[]>>(
      AUCTION_LOAD_IDS,
      (state: Draft<IStateAuctions>, action: ActionCase<string[]>) => {
        state.auctionIDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IAppAuctionSet>>(
      AUCTION_LOAD_SET,
      (state: Draft<IStateAuctions>, action: ActionCase<IAppAuctionSet>) => {
        console.log(AUCTION_LOAD_SET, action.payload);

        const set: IAppAuctionSet = action.payload;
        Object.keys(set).forEach((auctionID: string) => {
          const auction: IAppAuctionPublic = set[auctionID];
          state.byAuctionID[auctionID] = auction;
          if (!state.auctionIDsByPlatform[auction.platformType]) {
            state.auctionIDsByPlatform[auction.platformType] = [];
          }
          state.auctionIDsByPlatform[auction.platformType].push(auctionID);
        });
        setAuctions(state);
      }
    );
    // report lots
    builder.addCase<string, ActionCase<IAppReportLotSet>>(
      REPORT_LOT_SET_LOAD,
      (state: Draft<IStateAuctions>, action: ActionCase<IAppReportLotSet>) => {
        const set: IAppReportLotSet = action.payload;
        Object.keys(set).forEach((lotID: string) => {
          state.reportLots.byLotID[lotID] = set[lotID];
        });
      }
    );
    builder.addCase<string, ActionCase<IAppReportLotBatchData>>(
      REPORT_LOT_BATCH_LOAD,
      (
        state: Draft<IStateAuctions>,
        action: ActionCase<IAppReportLotBatchData>
      ) => {
        const { key, data } = action.payload;
        const { cnt, items } = data;

        if (!state.reportLots.cntByBatchKey[key]) {
          state.reportLots.cntByBatchKey[key] = cnt;
        }
        if (!state.reportLots.IDsByBatchKey[key]) {
          state.reportLots.IDsByBatchKey[key] = [];
        }
        state.reportLots.IDsByBatchKey[key] =
          state.reportLots.IDsByBatchKey[key].concat(items);
      }
    );
    builder.addCase<string, ActionCase<IAppReportLotSearchData>>(
      REPORT_LOT_SEARCH_LOAD,
      (
        state: Draft<IStateAuctions>,
        action: ActionCase<IAppReportLotSearchData>
      ) => {
        const { key, reportIDs } = action.payload;
        state.reportLots.IDsBySearchKey[key] = reportIDs;
      }
    );
  }
);
