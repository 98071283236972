import React, { useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { logEvent } from 'firebase/analytics';
import { analyticsApp } from '../../config/firebase.config';
import LeadForm from '../../components/landing/LeadForm';
import { BlockPage } from '../../common/styles';

export function LeadFormPage() {
  useEffect(() => logEvent(analyticsApp, 'LeadFormPage'), []);

  return (
    <BlockPage>
      <LeadForm />
    </BlockPage>
  );
}
