import {
  IBatchParams,
  RenderFormFunction,
  RenderItemPublicFunction,
  RenderItemViewFunction,
} from '../common';
import { RefObject } from 'react';

export interface IModerationRender<ItemPublic> {
  renderItemPublic: RenderItemPublicFunction<ItemPublic>;
  renderForm?: RenderFormFunction<ItemPublic>;
  renderFormRemove?: RenderFormFunction<ItemPublic>;
  renderItemView?: RenderItemViewFunction;
}

export interface IModerationCb {
  cbView?: (itemID: string) => void;
  cbDelete?: (itemID: string) => void;
  cbCancel?: () => void;
}

export interface IModerationData<ItemPublic> {
  set: Record<string, ItemPublic>;
  list?: {
    load?: (() => void) | ((hardReload?: boolean) => void);
    IDs: string[];
  };
  batch?: IBatchParams<ItemPublic>;
  search?: {
    search: (search: string) => void;
    IDsBySearch: Record<string, string[]>;
    placeholderTitle: string;
  };
  actions?: string[];
  errors?: string[];
}

export interface IModerationDataEdit<ItemPublic> {
  set: Record<string, ItemPublic>;
  actions?: string[];
  errors?: string[];
}

export interface IModerationAction {
  hideCreate?: boolean;
  hideSelected?: boolean;
  showInline?: boolean;
  createActionTitle?: string;
  editActionTitle?: string;
  removeActionTitle?: string;
  skipDefaultLoading?: boolean;
}

export interface IModerationSelection {
  cbSelected?: (itemID: string) => void;
  cbSelectedList?: (itemIDs: string[]) => void;
  selectedIDs?: null | string[];
}

export function scrollToForm(ref: RefObject<HTMLDivElement>) {
  setTimeout(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, 300);
}
