import styled from 'styled-components';
import { colors } from '../colors';
import { margins, sizes, sizesBorder } from '../sizes';
import { fonts } from '../typos';
import { borders } from '../styles';

export const ImagesLoaderBlock = styled.div`
  display: block;
  max-width: ${sizes.widthXL};
  min-width: ${sizes.widthS};
  width: ${sizes.full};
`;
export const ImagesLoaderItem = styled.div`
  display: block;

  &.draggable {
    cursor: grab;
  }

  & a {
    text-decoration: underline;
    margin-bottom: ${margins.item};
    display: block;
    color: ${colors.main};
    font-weight: ${fonts.weightM};
  }
`;
export const ImageBlock = styled.div``;
export const Image = styled.img`
  width: ${sizes.full};
  max-width: ${sizes.widthL};
  margin-right: ${margins.item};

  &.S {
    max-width: ${sizes.widthS};
  }

  &.M {
    max-width: ${sizes.widthM};
  }

  &.L {
    max-width: ${sizes.widthL};
  }

  &.XL {
    max-width: ${sizes.widthXL};
  }

  &.half {
    width: ${sizes.half};
  }

  &.block {
    display: block;
    box-sizing: border-box;
  }

  &.win {
    ${borders.win}
  }

  &.lost {
    ${borders.lost}
  }

  &.inline {
    float: left;
    display: inline-block;
  }

  &.radius {
    border-radius: ${sizesBorder.radius};
  }
`;

export const ImageIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: ${margins.item};

  &.small {
    width: 15px;
    height: 15px;
  }

  &.widthL {
    width: 25px;
  }

  &.big {
    width: 50px;
    height: 50px;
  }

  &.clickable {
    cursor: pointer;
  }

  &.inline {
    margin-left: ${margins.item};
  }

  &.win {
    ${borders.win}
  }

  &.lost {
    ${borders.lost}
  }
`;

export const ButtonBlock = styled.div`
  margin: ${margins.block} 0;
`;
export const GalleryBlock = styled.div`
  display: block;
  width: ${sizes.full};
  max-width: ${sizes.widthXL};
  overflow: auto;
  padding: ${margins.block} 0;
`;
export const GalleryBlockSmall = styled.div`
  display: block;
  width: ${sizes.full};
  max-width: ${sizes.widthM};
`;
