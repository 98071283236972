import React, { useEffect } from 'react';
import { ContentDesc, ContentHead } from '../../common/typos';
import { ICreateAuctionPublic } from '../../interfaces/CreateAuction/ICreateAuction';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { loadCreateAuction } from '../../actions/CreateAuction/CreateAuction.actions';
import { Image } from '../../common/images/Image.styles';
import { Block } from '../../common/layout';
import { L } from '../../common/locale';

interface ICreateAuctionPublicProps {
  loadCreateAuction: () => void;
  data: undefined | null | ICreateAuctionPublic;
  showImage?: boolean;
}

function CreateAuctionPublic(props: ICreateAuctionPublicProps) {
  useEffect(() => props.loadCreateAuction(), []);
  const data: undefined | null | ICreateAuctionPublic = props.data;

  if (!data) {
    return <></>;
  }

  return (
    <Block>
      <ContentHead>{data.name}</ContentHead>
      <ContentDesc>
        <L k={'LandingMainDescription'} />
      </ContentDesc>
      <ContentHead>
        <L k={'AnalyticsTitleCreate'} />
      </ContentHead>
      <ContentDesc>
        ${data.GMV} <L k={'AnalyticsGMV'} />
      </ContentDesc>
      <ContentDesc>
        <L k={'ProductByShort'} />
      </ContentDesc>
      <ContentDesc>
        {data.auctionCnt} <L k={'AnalyticsAuctions'} />
      </ContentDesc>
      <ContentDesc>
        {data.lotCnt} <L k={'AnalyticsLots'} />
      </ContentDesc>
      <ContentDesc>
        {data.bids} <L k={'AnalyticsBids'} />
      </ContentDesc>
      {/*<ContentDesc>{data.followers}</ContentDesc>*/}
      {props.showImage && (
        <Image className={'L'} src={data.imageURL} alt={data.name} />
      )}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    data: state.CreateAuction.data,
  }),
  {
    loadCreateAuction,
  }
)(CreateAuctionPublic);
