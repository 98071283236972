import React, { useEffect, useState } from 'react';
import MakeBid from './MakeBid';
import { BidBlock, BidStatus, LotBlock, LotSellerText } from './Lot.styles';
import { currencyConvert } from '../../utils/price';
import { BlockPageContent } from '../../common/styles';
import {
  ContentDesc,
  ContentDescInline,
  ContentHead,
} from '../../common/typos';
import {
  IAccountExamples,
  IAccountExamplesSet,
} from '../../interfaces/examples/IAccountExamples';
import {
  ILotShort,
  ILotStatus,
  ILotType,
} from '../../interfaces/examples/ILot';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { AdminAction, AdminActionBlock } from './AdminActions.styles';
import { adminLotWin } from '../../actions/examples/lot.actions';
import { Image } from '../../common/images/Image.styles';
import { Block } from '../../common/layout';

interface ILotProps {
  adminLotWin: (lotType: ILotType) => void;
  lot: ILotShort;
  users: IAccountExamplesSet;
  statusByLotID: Record<string, ILotStatus>;
}

export function Lot(props: ILotProps) {
  const [sellerMail, setSellerMail] = useState<null | string>();
  const [status, setStatus] = useState<ILotStatus>(null);

  useEffect(() => {
    const statusLocal = props.statusByLotID[props.lot.id] || null;
    setStatus(statusLocal);
  }, [props.statusByLotID]);
  useEffect(() => {
    const { users, lot } = props;
    const seller: null | IAccountExamples = users[lot.sellerID] || null;
    setSellerMail(seller ? seller.mail : null);
  }, [props.users, props.lot]);

  function winLot() {
    props.adminLotWin('Live');
  }

  return (
    <>
      <BlockPageContent>
        <Block>
          <Image
            src={props.lot.imageURL}
            alt={props.lot.name}
            className={'M block ' + (status ? status : '')}
          />

          {sellerMail && (
            <LotSellerText className={status || ''}>
              by {sellerMail}
            </LotSellerText>
          )}
        </Block>

        <LotBlock>
          <ContentHead>{props.lot.name}</ContentHead>
          <ContentDesc>{props.lot.description}</ContentDesc>
          {props.lot.winCnt !== 1 && (
            <ContentDesc className="success">
              * {props.lot.winCnt} donators will win this lot
            </ContentDesc>
          )}
          <BidBlock>
            <ContentDesc>Current Bid:</ContentDesc>
            <ContentHead>
              {props.lot.price}{' '}
              <ContentDescInline>
                (${currencyConvert(props.lot.price)})
              </ContentDescInline>
              {status === 'win' && (
                <BidStatus className="win">Current winner</BidStatus>
              )}
              {status === 'lost' && (
                <BidStatus className="lost">You lost bid</BidStatus>
              )}
            </ContentHead>
          </BidBlock>
          <MakeBid />
        </LotBlock>

        <AdminActionBlock>
          <AdminAction onClick={winLot}>
            Win <br />
            (will show only for moderator)
          </AdminAction>
        </AdminActionBlock>
      </BlockPageContent>
    </>
  );
}

export default connect(
  (state: IState) => ({
    users: state.Example.users,
    statusByLotID: state.Example.statusByLotID,
  }),
  {
    adminLotWin,
  }
)(Lot);
