import styled from 'styled-components';
import { borders } from '../styles';
import { sizes } from '../sizes';

export const VideoBlock = styled.div`
  float: right;

  &.left {
    float: unset;
    display: block;
    margin: auto;
    width: inherit;
  }
`;
export const Video = styled.video`
  float: right;
  position: absolute;
  transform: translateX(-100%);
  width: ${sizes.widthM};
  height: auto;

  &.win {
    ${borders.win}
  }

  &.lost {
    ${borders.lost}
  }
`;
