import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../utils/analytics';
import { IState } from '../../interfaces/system/IState';
import { FormBlock } from '../../common/forms/Form.styles';
import { updateCreateAuction } from '../../actions/CreateAuction/CreateAuction.actions';
import { ICreateAuctionPublic } from '../../interfaces/CreateAuction/ICreateAuction';
import { Form, IFormItem } from '../../common/forms/Form';
import { FormsFields } from '../../common/forms/forms';

interface ICreateAuctionFormProps {
  data: undefined | null | ICreateAuctionPublic;
  isDisabled?: boolean;
  updateCreateAuction: (
    name: string,
    description: string,
    GMV: number,
    bids: number,
    lotCnt: number,
    followers: number,
    auctionCnt: number,
    imageURL: string
  ) => void;
  cbCancel?: () => void;
  actions: string[];
  errors: string[];
}

function CreateAuctionForm(props: ICreateAuctionFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);

  const fields: Partial<
    Record<keyof ICreateAuctionPublic, undefined | IFormItem>
  > = {
    name: {
      ...FormsFields.name,
      $value: props.data?.name,
    },
    description: {
      ...FormsFields.description,
      $value: props.data?.description,
    },
    GMV: {
      ...FormsFields.cnt,
      $value: props.data?.GMV.toString(),
    },
    bids: {
      ...FormsFields.cnt,
      title: 'bid count',
      $value: props.data?.bids.toString(),
    },
    lotCnt: {
      ...FormsFields.cnt,
      title: 'lot count',
      $value: props.data?.lotCnt.toString(),
    },
    followers: {
      ...FormsFields.cnt,
      title: 'followers count',
      $value: props.data?.followers.toString(),
    },
    auctionCnt: {
      ...FormsFields.cnt,
      title: 'auction count',
      $value: props.data?.auctionCnt.toString(),
    },
    imageURL: {
      ...FormsFields.imageURL,
      title: 'Company image',
      $valueList: (props.data && [props.data.imageURL]) || [],
    },
  };

  useEffect(() => sendEvent('Open-CreateAuctionForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    if (props.cbCancel) {
      props.cbCancel();
    }
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const handleUpdate = () => {
    setIsSending(true);
    props.updateCreateAuction(
      (fields.name && fields.name.$value) || '',
      (fields.description && fields.description.$value) || '',
      Number(fields.GMV && fields.GMV.$value),
      Number(fields.bids && fields.bids.$value),
      Number(fields.lotCnt && fields.lotCnt.$value),
      Number(fields.followers && fields.followers.$value),
      Number(fields.auctionCnt && fields.auctionCnt.$value),
      (fields.imageURL &&
        fields.imageURL.$valueList &&
        fields.imageURL.$valueList[0]) ||
        ''
    );
  };

  return (
    <FormBlock>
      <Form
        isUpdate={true}
        isDisabled={props.isDisabled}
        placeholderTitle={'create auction'}
        fields={fields}
        isSending={isSending}
        cbCancel={props.cbCancel}
        cbUpdate={handleUpdate}
        updateAll={true}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    data: state.CreateAuction.data,
    actions: state.CreateAuction.actions,
    errors: state.System.errors,
  }),
  {
    updateCreateAuction,
  }
)(CreateAuctionForm);
