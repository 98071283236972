import React, { useEffect, useState } from 'react';
import { Switch } from '../forms/Switch';
import { ActionLink } from '../styles';
import { Block } from '../layout';
import { ContentHead } from '../typos';

interface IBlockViewProps {
  default?: boolean;
  title: string;
  element: JSX.Element;
}

export function BlockView(props: IBlockViewProps) {
  const [isShow, setIsShow] = useState<boolean>(
    props.default !== undefined ? props.default : true
  );

  useEffect(() => {
    if (props.default === undefined) {
      return;
    }

    setIsShow(props.default);
  }, [props.default]);

  return (
    <>
      <ContentHead className={'clickable'} onClick={() => setIsShow(!isShow)}>
        {props.title}
      </ContentHead>
      {isShow && props.element}
    </>
  );
}
