import React, { useState } from 'react';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
} from '../../../common/typos';
import {
  IEmailLogPublic,
  IEmailLogView,
} from '../../../interfaces/communication/IEmailLog';
import { IParamsItemView } from '../../../common/common';
import { prepareAuctionDate } from '../../../utils/renderParts';
import { ActionLink } from '../../../common/styles';
import { appConfig } from '../../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { Block } from '../../../common/layout';

interface IEmailLogModerationProps {
  emailLog: IEmailLogPublic;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
  ParamsItemView?: IParamsItemView;
}

export function EmailLogModeration(props: IEmailLogModerationProps) {
  const navigate = useNavigate();
  const [isEventValueShow, setIsEventValueShow] = useState<
    Record<string, boolean>
  >({});
  const emailLog: IEmailLogPublic = props.emailLog;
  const view: null | IEmailLogView = props.ParamsItemView
    ? (props.ParamsItemView as IEmailLogView)
    : null;
  const toggleEventValue = (key: string): void => {
    const isEventValueShowLocal: Record<string, boolean> = Object.assign(
      {},
      isEventValueShow
    );
    isEventValueShowLocal[key] = !isEventValueShowLocal[key];
    setIsEventValueShow(isEventValueShowLocal);
  };

  const goToMailing = (): void => {
    const itemID: null | string = emailLog.mailingID;
    if (!itemID) {
      return;
    }
    const path: string =
      appConfig.routes.Moderation.communication.mailingOne.replace(
        appConfig.params.itemID,
        itemID
      );
    navigate(path);
  };

  const renderEventsInline = (): JSX.Element => {
    const keys: string[] = Object.keys(emailLog.events);
    return (
      <>
        {keys.map((key: string) => (
          <ContentDescInline>
            <>
              <ActionLink onClick={() => toggleEventValue(key)}>
                {isEventValueShow[key] && (
                  <ContentBold>
                    {key} ({Object.keys(emailLog.events[key]).length})
                  </ContentBold>
                )}
                {!isEventValueShow[key] && (
                  <ContentDescInline>
                    {key} ({Object.keys(emailLog.events[key]).length})
                  </ContentDescInline>
                )}
              </ActionLink>
              {'; '}
            </>

            {isEventValueShow[key] && (
              <Block onClick={() => toggleEventValue(key)}>
                <ContentDesc>
                  <ContentBold>{key} at: </ContentBold>
                </ContentDesc>

                {emailLog.events[key].map((date: string) => (
                  <ContentDesc key={date}>
                    {'  '}
                    {prepareAuctionDate(date)}
                    {'; '}
                  </ContentDesc>
                ))}
              </Block>
            )}
          </ContentDescInline>
        ))}
      </>
    );
  };

  return (
    <>
      {props.showInline && (
        <>
          {!view && (
            <ContentDesc>
              <ContentBold>{emailLog.mode}</ContentBold>
              {props.inlineBlock}
            </ContentDesc>
          )}
          {view && (
            <ContentDesc>
              {view.mode && <ContentBold>{emailLog.mode} - </ContentBold>}
              {view.accountID && (
                <ContentDescInline>{emailLog.accountID}. </ContentDescInline>
              )}
              {view.action && (
                <ContentDescInline>{emailLog.action}. </ContentDescInline>
              )}
              {view.isAllowed && (
                <ContentDescInline>
                  {emailLog.isAllowed ? 'allowed' : 'not allowed'}.{' '}
                </ContentDescInline>
              )}
              {view.messageID && (
                <ContentDescInline>{emailLog.messageID}. </ContentDescInline>
              )}
              {view.mailingID && emailLog.mailingID && (
                <ContentDescInline>
                  <ActionLink onClick={goToMailing}>open mailing</ActionLink>.{' '}
                </ContentDescInline>
              )}
              {view.date && (
                <ContentDescInline>
                  {prepareAuctionDate(emailLog.date)}.{' '}
                </ContentDescInline>
              )}
              {view.events && renderEventsInline()}
              {props.inlineBlock}
            </ContentDesc>
          )}
        </>
      )}
      {!props.showInline && <></>}
    </>
  );
}
