import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { ActionButton, BlockActions } from '../../common/styles';
import {
  loadReportLotBatchNext,
  prepareBatchKeyReportLot,
} from '../../actions/auctions/reportLot.actions';
import {
  IAppReportLotOrder,
  IAppReportLotPublic,
} from '../../interfaces/auctions/IAppReportLot';
import { IPlatformType } from '../../config/platform.config';
import { OrderBlock } from '../../common/navigation';
import { Loading } from '../../common/animation/Loading';
import { Block, CarouselBlock } from '../../common/layout';
import { ContentDesc, ContentHead } from '../../common/typos';
import { Carousel } from 'react-responsive-carousel';
import { ILotType } from '../../interfaces/examples/ILot';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { makePathFromName } from '../../utils/routes';
import { IAppAuctionPublic } from '../../interfaces/auctions/IAppAuction';
import { ReportLotInline } from './ReportLotInline';
import { ReportLotBlock } from './ReportLotBlock';
import ReportLotSearch from './ReportLotSearch';
import { L } from '../../common/locale';

interface IReportLotsProps {
  mode: 'carousel' | 'rows';
  auction: null | IAppAuctionPublic;
  lotType: null | ILotType;
  platformType: null | IPlatformType;
  platformAuctionID: null | string;
  loadReportLotBatchNext: (
    lotType: null | string,
    platformType: null | IPlatformType,
    platformAuctionID: null | string,
    order: null | IAppReportLotOrder
  ) => void;
  byLotID: Record<string, IAppReportLotPublic>;
  cntByBatchKey: Record<string, number>;
  IDsByBatchKey: Record<string, string[]>;
  onlyList?: boolean;
}

function ReportLots(props: IReportLotsProps) {
  const navigate = useNavigate();
  const [reportIDs, setReportIDs] = useState<string[]>([]);
  const [reports, setReports] = useState<IAppReportLotPublic[]>([]);
  const [reportCnt, setReportCnt] = useState<null | number>(null);
  const [lotType, setLotType] = useState<null | string>(props.lotType);
  const [platformType, setPlatformType] = useState<null | IPlatformType>(
    props.platformType
  );
  const [platformAuctionID, setPlatformAuctionID] = useState<null | string>(
    props.platformAuctionID
  );
  const [order, setOrder] = useState<null | IAppReportLotOrder>('Total');
  const [batchKey, setBatchKey] = useState<string>(
    prepareBatchKeyReportLot(lotType, platformType, platformAuctionID, order)
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  useEffect(() => {
    setBatchKey(
      prepareBatchKeyReportLot(lotType, platformType, platformAuctionID, order)
    );
  }, [lotType, platformType, platformAuctionID, order]);
  useEffect(() => {
    if (!batchKey) {
      return;
    }

    if (!props.IDsByBatchKey[batchKey]) {
      loadNext();
      return;
    }

    const IDs: string[] = props.IDsByBatchKey[batchKey] || [];
    const cnt: number = props.cntByBatchKey[batchKey] || 0;
    const loaded: number = IDs.length;
    const isMore: boolean = cnt > loaded;

    if (!isLoading && !loaded && isMore) {
      loadNext();
    }

    setIsLoading(false);
    setReportIDs(IDs);
    setReportCnt(cnt);
    setIsShowMore(isMore);
  }, [props.IDsByBatchKey, batchKey]);
  useEffect(() => {
    const reportsLocal: IAppReportLotPublic[] = [];
    reportIDs.forEach((reportID: string) => {
      const reportLocal: IAppReportLotPublic = props.byLotID[reportID];
      if (reportLocal) {
        reportsLocal.push(reportLocal);
      }
    });

    setIsLoading(false);
    setReports(reportsLocal);
  }, [reportIDs, props.byLotID]);

  const loadNext = () => {
    setIsLoading(true);
    setIsShowMore(false);
    props.loadReportLotBatchNext(
      lotType,
      platformType,
      platformAuctionID,
      order
    );
  };
  const goToReports = () => {
    if (!props.auction || !props.lotType) {
      return;
    }
    const auctionPath = makePathFromName(props.auction.name);
    const path: string = appConfig.routes.auctions.lots
      .replace(appConfig.params.auctionPath, auctionPath)
      .replace(appConfig.params.platformPath, props.auction.platformType)
      .replace(appConfig.params.lotTypeParam, props.lotType);

    navigate(path);
  };

  return (
    <>
      {!props.onlyList && (
        <>
          <ReportLotSearch platformType={platformType} />

          <OrderBlock>
            <ContentHead
              onClick={() => setOrder('Total')}
              className={order === 'Total' ? 'active item' : 'item'}
            >
              <L k={'ReportsLotOrderTotal'} />
            </ContentHead>

            <ContentHead
              onClick={() => setOrder('bidCnt')}
              className={order === 'bidCnt' ? 'active item' : 'item'}
            >
              <L k={'ReportsLotOrderBidCnt'} />
            </ContentHead>
          </OrderBlock>

          {reportCnt && (
            <ContentDesc>
              {reportCnt} <L k={'AnalyticsLots'} />
            </ContentDesc>
          )}
        </>
      )}

      {props.mode === 'carousel' && (
        <CarouselBlock>
          <Carousel
            autoPlay={true}
            interval={5000}
            transitionTime={3000}
            swipeable={true}
            infiniteLoop={true}
            showArrows={true}
            showThumbs={false}
          >
            {reports.slice(0, 10).map((reportLot: IAppReportLotPublic) => (
              <Block key={reportLot.id} className={'left'}>
                <ReportLotBlock reportLot={reportLot} />
              </Block>
            ))}
          </Carousel>
        </CarouselBlock>
      )}

      {props.mode === 'rows' &&
        reports.map((reportLot: IAppReportLotPublic) => (
          <ReportLotInline reportLot={reportLot} key={reportLot.id} />
        ))}

      <BlockActions>
        {isLoading && <Loading size={'big'} />}
        {props.mode !== 'carousel' && isShowMore && (
          <ActionButton onClick={() => loadNext()}>
            <L k={'ButtonLoadMore'} />
          </ActionButton>
        )}
        {props.mode === 'carousel' && props.auction && (
          <ActionButton onClick={() => goToReports()}>
            <L k={'AuctionLotsAll'} />
          </ActionButton>
        )}
      </BlockActions>
    </>
  );
}

export default connect(
  (state: IState) => ({
    byLotID: state.Auctions.reportLots.byLotID,
    cntByBatchKey: state.Auctions.reportLots.cntByBatchKey,
    IDsByBatchKey: state.Auctions.reportLots.IDsByBatchKey,
  }),
  {
    loadReportLotBatchNext,
  }
)(ReportLots);
