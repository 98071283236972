import React from 'react';
import { ImageIcon } from '../images/Image.styles';

interface ILoadingProps {
  size?: 'small' | 'big';
}

export function Loading(props: ILoadingProps) {
  return (
    <ImageIcon
      src="/icons/loading.gif"
      alt="loading"
      className={props.size || ''}
    />
  );
}
