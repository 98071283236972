import React from 'react';
import { ContentDesc, ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAppPartnerFeedbacksPublic } from '../../interfaces/partners/IAppPartnerFeedbacks';
import { Image } from '../../common/images/Image.styles';

interface IPartnersFeedbacksItemModerationProps {
  partner: IAppPartnerFeedbacksPublic;
}

// accountID: null | string;

export function PartnersFeedbacksItemModeration(
  props: IPartnersFeedbacksItemModerationProps
) {
  const partner: IAppPartnerFeedbacksPublic = props.partner;

  return (
    <Block>
      <Image className={'L'} src={partner.imageURL} alt={partner.name} />
      <ContentDesc>{partner.platformType}</ContentDesc>
      <ContentHead>{partner.name}</ContentHead>
      <ContentHead>{partner.position}</ContentHead>
      <ContentDesc>{partner.description}</ContentDesc>
      <ContentDesc>{partner.feedback}</ContentDesc>
    </Block>
  );
}
