import React from 'react';
import { ContentDescInline } from '../typos';
import { Block } from '../layout';
import { Label } from './Form.styles';

interface ISwitchMultipleProps {
  checked: string;
  options: string[];
  cb: (checked: string) => void;
}

export function SwitchMultiple(props: ISwitchMultipleProps) {
  return (
    <Block className="flex">
      {props.options.map((option: string) => (
        <Label
          onChange={() => props.cb(option)}
          className={props.checked === option ? 'selected' : ''}
          style={{ display: 'inline-block' }}
        >
          <input type="radio" checked={props.checked === option} />
          <ContentDescInline>{option}</ContentDescInline>
        </Label>
      ))}
    </Block>
  );
}
