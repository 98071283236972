import React from 'react';
import { connect } from 'react-redux';
import TopDonators from './TopDonators';
import { IReportAuction } from '../../interfaces/examples/IReportAuction';
import { BlockPageContent } from '../../common/styles';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { currencyConvert } from '../../utils/price';

interface IResultFinalProps {
  reportAuction: null | IReportAuction;
}

function ResultFinal(props: IResultFinalProps) {
  return (
    <BlockPageContent>
      <PageHead>Auction Result:</PageHead>

      {props.reportAuction && (
        <>
          <ContentDesc>
            Total donated:{' '}
            <ContentBold>
              {props.reportAuction.total} ($
              {currencyConvert(props.reportAuction.total)}){' '}
            </ContentBold>
          </ContentDesc>

          <ContentHead>
            Thank you for active participation and for you donations!
          </ContentHead>

          <br />
          <ContentHead>
            Bank details for donation (and lots) are able in your profile block.
          </ContentHead>

          <TopDonators
            topDonators={props.reportAuction.topDonators}
          ></TopDonators>
          <br />
          <br />
          <BlockPageContent>
            <ContentHead>
              Support sharing <ContentBold>create.auction</ContentBold> platform
            </ContentHead>
          </BlockPageContent>
        </>
      )}
    </BlockPageContent>
  );
}

export default connect(
  (state: IState) => ({
    reportAuction: state.Example.reportAuction,
  }),
  {}
)(ResultFinal);
