import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import {
  IAccountExamples,
  IAccountExamplesSet,
} from '../../interfaces/examples/IAccountExamples';
import { IReportLot } from '../../interfaces/examples/IReportLot';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../common/typos';
import { BlockPageContent } from '../../common/styles';
import { currencyConvert } from '../../utils/price';
import { ILotBuyer } from '../../interfaces/examples/ILot';
import { AdminAction, AdminActionBlock } from './AdminActions.styles';
import { getGifSrc, Gif } from '../../common/animation/Gif';
import { sendReportAuction } from '../../actions/examples/reports.actions';
import { LotBlock } from './Lot.styles';
import { Image } from '../../common/images/Image.styles';

interface ILotResultProps {
  sendReportAuction: () => void;
  users: IAccountExamplesSet;
  reportLots: IReportLot[];
}

function ResultLot(props: ILotResultProps) {
  const [report, setReport] = useState<null | IReportLot>(null);
  const [seller, setSeller] = useState<null | IAccountExamples>(null);
  const [buyers, setBuyers] = useState<null | IAccountExamples[]>([]);
  const [gifSrc, setGifSrc] = useState<null | string>(null);

  useEffect(() => {
    const src = getGifSrc('resultLot');
    setGifSrc(src);
    setTimeout(() => setGifSrc(null), 3000);
  }, []);
  useEffect(() => {
    const reportLocal = props.reportLots[0];
    setReport(reportLocal);
    if (!reportLocal) {
      return;
    }
    setBuyers(
      reportLocal.buyerList
        ? reportLocal.buyerList.map(
            (buyer: ILotBuyer) => props.users[buyer.buyerID]
          )
        : null
    );
    setSeller(props.users[reportLocal.sellerID]);
  }, [props.reportLots, props.users]);

  return (
    <BlockPageContent>
      <PageHead>Lot' Results</PageHead>

      <LotBlock>
        {report && (
          <>
            <Image className={'L'} src={report.imageURL} alt={report.name} />
            <ContentHead>{report.name}</ContentHead>

            <ContentDesc>
              Sold for{' '}
              <ContentBold>
                {report.Total || report.price} ($
                {currencyConvert(report.Total || report.price || 0)})
              </ContentBold>
            </ContentDesc>

            <ContentDesc>{report.bidCnt} bids</ContentDesc>

            {buyers &&
              buyers.map((buyer: IAccountExamples, index: number) => (
                <ContentDesc key={index}>
                  Thanks to <ContentBold>{buyer.name}</ContentBold> for
                  donation! ({buyer.mail})
                </ContentDesc>
              ))}
          </>
        )}

        {seller && (
          <ContentDesc>Thanks to {seller.name} for a lot!</ContentDesc>
        )}

        {gifSrc && <Gif src={gifSrc} />}
      </LotBlock>

      <BlockPageContent>
        <AdminActionBlock>
          <AdminAction onClick={props.sendReportAuction}>
            Send Report Auction <br />
            (will show only for moderator)
          </AdminAction>
        </AdminActionBlock>
      </BlockPageContent>
    </BlockPageContent>
  );
}

export default connect(
  (state: IState) => ({
    reportLots: state.Example.reportLots,
    users: state.Example.users,
  }),
  {
    sendReportAuction,
  }
)(ResultLot);
