import React from 'react';
import { connect } from 'react-redux';
import TopAccount from './TopAccount';
import { IReportTopSeller } from '../../interfaces/examples/IReportAuction';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { Icon } from '../landing/Button.styles';

interface IAuctionResultProps {
  topSellers: IReportTopSeller[];
}

function TopSellers(props: IAuctionResultProps) {
  return (
    <>
      {props.topSellers.map((seller: IReportTopSeller, index: number) => (
        <ContentDesc key={index}>
          <TopAccount accountID={seller.accountID} />
          <ContentDescInline>{seller.total}</ContentDescInline>
          <Icon src={'/icons/medal.png'} />
        </ContentDesc>
      ))}
    </>
  );
}

export default connect(() => ({}), {})(TopSellers);
