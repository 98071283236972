import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../../utils/analytics';
import { ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import { Form, IFormItem } from '../../../common/forms/Form';
import {
  ITemplateParamsGroup,
  ITemplateParamsGroupList,
  ITemplateParamsPublic,
  ITemplateParamsSet,
} from '../../../interfaces/communication/ITemplateParams';
import {
  createTemplateParams,
  updateTemplateParams,
} from '../../../actions/communication/templateParams.actions';
import {
  FormsFields,
  transformRecordToSave,
  transformToRecord,
} from '../../../common/forms/forms';
import {
  IEmailMode,
  IEmailModeMailingList,
} from '../../../interfaces/communication/IEmailLog';
import {
  IAppRecommendationType,
  IAppRecommendationTypeList,
} from '../../../interfaces/communication/IAppRecommendation';

interface ITemplateParamsFormProps {
  templateParams?: null | ITemplateParamsPublic;
  isDisabled?: boolean;
  createTemplateParams: (
    group: ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    value: string,
    params: null | Record<string, string>
  ) => void;
  updateTemplateParams: (
    itemID: string,
    group: null | ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    value: null | string,
    params: null | Record<string, string>
  ) => void;
  cbCancel: () => void;
  cbDelete?: (templateParamsID: string) => void;
  set: ITemplateParamsSet;
  actions: string[];
  errors: string[];
}

const fields: Partial<
  Record<keyof ITemplateParamsPublic, undefined | IFormItem>
> = {
  group: {
    mode: 'select',
    isRequired: true,
    defaultSelect: ITemplateParamsGroupList,
    $valueSelect: [ITemplateParamsGroupList[0]],
  },
  mode: {
    mode: 'select',
    isRequired: true,
    defaultSelect: IEmailModeMailingList,
    nullNameBeforeSelect: 'any',
    $valueSelect: ['any'],
  },
  type: {
    mode: 'select',
    isRequired: true,
    nullNameBeforeSelect: 'any',
    defaultSelect: IAppRecommendationTypeList,
    $valueSelect: ['any'],
  },
  value: {
    ...FormsFields.description,
    max: 5000,
  },
  params: {
    ...FormsFields.params,
    isRequired: false,
    $valueRecord: transformToRecord(null),
  },
};

function TemplateParamsForm(props: ITemplateParamsFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => sendEvent('Open-TemplateParamsForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.templateParams) {
      return;
    }

    if (fields.mode) {
      fields.mode.$valueSelect = props.templateParams.mode
        ? [props.templateParams.mode]
        : null;
    }
    if (fields.type) {
      fields.type.$valueSelect = props.templateParams.type
        ? [props.templateParams.type]
        : null;
    }
    if (fields.value) {
      fields.value.$value = props.templateParams.value;
    }
    if (fields.params) {
      fields.params.$valueRecord = transformToRecord(
        props.templateParams.params
      );
    }
  }, [props.templateParams]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const getGroup = (): null | ITemplateParamsGroup => {
    const groupField: null | IFormItem = fields.group || null;
    if (!groupField) {
      return null;
    }

    return (
      (groupField.$valueSelect &&
        (groupField.$valueSelect[0] as ITemplateParamsGroup)) ||
      null
    );
  };
  const getMode = (): null | IEmailMode => {
    const modeField: null | IFormItem = fields.mode || null;
    if (!modeField) {
      return null;
    }

    const mode: null | string =
      (modeField.$valueSelect && modeField.$valueSelect[0]) || null;

    return mode === modeField.nullNameBeforeSelect
      ? null
      : (mode as IEmailMode);
  };
  const getType = (): null | IAppRecommendationType => {
    const typeField: null | IFormItem = fields.type || null;
    if (!typeField) {
      return null;
    }

    const mode: null | string =
      (typeField.$valueSelect && typeField.$valueSelect[0]) || null;

    return mode === typeField.nullNameBeforeSelect
      ? null
      : (mode as IAppRecommendationType);
  };

  const handleCreate = () => {
    setIsSending(true);
    props.createTemplateParams(
      getGroup() || ITemplateParamsGroupList[0],
      getMode(),
      getType(),
      (fields.value && fields.value.$value) || '',
      transformRecordToSave(fields.params?.$valueRecord)
    );
  };
  const handleUpdate = () => {
    if (!props.templateParams) {
      return;
    }

    setIsSending(true);
    props.updateTemplateParams(
      props.templateParams.id,
      getGroup(),
      getMode(),
      getType(),
      (fields.value && fields.value.$value) || null,
      transformRecordToSave(fields.params?.$valueRecord)
    );
  };
  const handleDelete = () => {
    if (!props.templateParams || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.templateParams.id);
  };

  return (
    <FormBlock>
      {!props.templateParams && (
        <ContentHead>New TemplateParams creation</ContentHead>
      )}
      {props.templateParams && <ContentHead>TemplateParams update</ContentHead>}

      <Form
        isDisabled={props.isDisabled}
        placeholderTitle={'auction templateParams'}
        fields={fields}
        isUpdate={!!props.templateParams}
        isSending={isSending}
        cbCancel={props.cbCancel}
        cbCreate={handleCreate}
        cbUpdate={handleUpdate}
        cbDelete={handleDelete}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Communication.TemplateParams.set,
    actions: state.Communication.TemplateParams.actions,
    errors: state.System.errors,
  }),
  {
    createTemplateParams,
    updateTemplateParams,
  }
)(TemplateParamsForm);
