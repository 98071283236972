export type IMailingStatus = 'planned' | 'sent' | 'removed';
export const IMailingStatusList: IMailingStatus[] = [
  'planned',
  'sent',
  'removed',
];

export type IMailingAction = 'init' | 'send' | 'update' | 'remove';

export interface IMailingLogPublic extends IMailingLog {
  id: string;
}

export interface IMailingLog {
  adminAccountID: string;
  mailingID: string;
  action: IMailingAction;
  changes?: Record<string, string>;
  date: string;
}
