import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../utils/analytics';
import { IState } from '../../interfaces/system/IState';
import PartnersAuctionPublicList from '../../components/partners/PartnersAuctionPublicList';
import { BlockPage } from '../../common/styles';

interface IPartnerAuctionListPageProps {}

function PartnerAuctionListPage(props: IPartnerAuctionListPageProps) {
  useEffect(() => sendPageEvent('Partner Auction'), []);

  return (
    <BlockPage>
      <PartnersAuctionPublicList mode={'rows'} />
    </BlockPage>
  );
}

export default connect((state: IState) => ({}), {})(PartnerAuctionListPage);
