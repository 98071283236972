export class CacheRequests {
  private set: Record<string, true> = {};

  constructor() {}

  getToLoad(IDs: string[]): string[] {
    const toLoad: string[] = [];
    IDs.forEach((id: string) => {
      if (!this.set[id]) {
        this.set[id] = true;
        toLoad.push(id);
      }
    });

    return toLoad;
  }

  checkOneIsLoaded(id: string): boolean {
    if (!this.set[id]) {
      this.set[id] = true;
      return false;
    }

    return true;
  }

  clean(): void {
    this.set = {};
  }
}
