import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { colors } from '../../common/colors';
import { ChartsBlock } from './analytics.styles';
import { ContentDesc } from '../../common/typos';
import { IBaseAnalyticsAggregatedModelCreate } from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';

interface IAnalyticsChartSelectedProps {
  items: IBaseAnalyticsAggregatedModelCreate<
    string | undefined,
    string | undefined
  >[];
  selectedParam: string;
  chartSize: null | number;
}

interface IChartItem {
  date: string;

  [value: string]: string | number;
}

export function AnalyticsChartSelected(props: IAnalyticsChartSelectedProps) {
  const [data, setData] = useState<IChartItem[]>([]);

  useEffect(() => {
    const listLocal: IChartItem[] = [];
    props.items.forEach(({ date, items }) => {
      const set: IChartItem = {
        date: date.slice(0, 5),
      };
      items.forEach(({ value, param, min, max, avg, cnt }) => {
        if (
          value === props.selectedParam ||
          value + '-' + param === props.selectedParam
        ) {
          set.min = min;
          set.max = max;
          set.avg = avg;
          set.cnt = cnt;
        }
      });
      listLocal.push(set);
    });

    setData(listLocal);
  }, [props.items, props.selectedParam]);

  const getColorFromPalette = (index: number): string => {
    return colors.palette[index % colors.palette.length];
  };

  return (
    <ChartsBlock>
      <ContentDesc>{props.selectedParam}:</ContentDesc>
      <LineChart width={props.chartSize || 400} height={300} data={data}>
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />

        <CartesianGrid stroke="#f5f5f5" />
        <Line type="monotone" dataKey="min" stroke={getColorFromPalette(0)} />
        <Line type="monotone" dataKey="max" stroke={getColorFromPalette(1)} />
        <Line type="monotone" dataKey="avg" stroke={getColorFromPalette(2)} />
        <Line type="monotone" dataKey="cnt" stroke={getColorFromPalette(3)} />
      </LineChart>
    </ChartsBlock>
  );
}
