import { ISizeSet, sizeScreenValues } from '../common/sizes';

export function getWidthSize(size: number): null | keyof ISizeSet<any> {
  if (size >= sizeScreenValues.laptop) {
    return 'laptop';
  } else if (size >= sizeScreenValues.tablet) {
    return 'tablet';
  } else if (size >= sizeScreenValues.mobileL) {
    return 'mobileL';
  } else if (size >= sizeScreenValues.mobileM) {
    return 'mobileM';
  } else {
    return 'mobileM';
  }
}

export function getCatalogItemsCnt(
  clientWidthSize: null | keyof ISizeSet<any>,
): number {
  if (!clientWidthSize) {
    return 1;
  }

  let cnt: number = 1;
  if (clientWidthSize === 'laptop') {
    cnt = 5;
  } else if (clientWidthSize === 'tablet') {
    cnt = 4;
  } else if (clientWidthSize === 'mobileL') {
    cnt = 3;
  } else if (clientWidthSize === 'mobileM') {
    cnt = 2;
  }

  return cnt;
}

export function makeBatches<Item>(cnt: number, items: Item[]): Item[][] {
  const batches: Item[][] = [];
  for (let i: number = 0, len: number = items.length; i < len; i += cnt) {
    const batch: Item[] = items.slice(i, i + cnt);
    batches.push(batch);
  }
  return batches;
}
