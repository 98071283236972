const params = {
  itemID: `:itemID`,
  auctionPath: `:auctionPath`,
  reportPath: `:reportPath`,
  accountPath: `:accountPath`,
  productDraftID: `:productDraftID`,
  productPath: `:productPath`,
  platformPath: `:platformPath`,
  token: `:token`,
  lotTypeParam: `:lotTypeParam`,
};

export const appConfig = Object.freeze({
  isDev: process.env.NODE_ENV === `development`,
  devPlatform: process.env.REACT_APP_PLATFORM || null,
  params,
  routes: {
    main: {
      home: `/`,
      login: `/login`,
      loginToken: `/login/${params.token}`,
      numbers: `/numbers`,
      leadForm: `/partnership`,
    },
    landing: {
      thankYou: `/thank-you`,
      privacy: `/privacy`,
      terms: `/terms`,
    },
    auctions: {
      list: `/auctions`,
      one: `/auctions/${params.platformPath}/${params.auctionPath}`,
      lots: `/auctions/${params.platformPath}/${params.auctionPath}/lots/${params.lotTypeParam}`,
      lotsAll: `/lots`,
    },
    partners: {
      list: `/partners`,
      one: `/partners/${params.platformPath}`,
    },
    account: {
      lots: `/account/lots`,
      settings: `/account/settings`,
      donations: '/account/donations',
      subscribe: `/account/subscribe`,
    },
    Moderation: {
      main: '/moderation/main',
      CreateAuction: '/moderation/CreateAuction',
      communication: {
        mailing: '/moderation/communication/mailing',
        mailingOne: `/moderation/communication/mailing/${params.itemID}`,
        users: '/moderation/communication/users',
        recommendation: '/moderation/communication/recommendation',
        templateParams: '/moderation/communication/template/params',
        logs: '/moderation/communication/logs',
      },
      partners: {
        auction: '/moderation/partners/auction',
        feedbacks: '/moderation/partners/feedbacks',
        lot: '/moderation/partners/lot',
      },
    },
    Analytics: {
      main: '/analytics/main',
      cache: '/analytics/cache',
      cacheAll: '/analytics/cache/all',
      tech: '/analytics/tech',
      techAll: '/analytics/tech/all',
      descriptionAll: '/analytics/description/all',
    },
  },
});
