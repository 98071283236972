import React, { useEffect, useState } from 'react';
import { Block } from '../../common/layout';
import { ContentBold, ContentDesc } from '../../common/typos';
import { IBaseAnalyticsAggregatedModelCreate } from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  AnalyticsDescriptionItemsElement,
  IElementOrder,
  IItemsOrder,
} from './AnalyticsDescriptionItemsElement';

interface IAnalyticsDescriptionItemsProps {
  items: IBaseAnalyticsAggregatedModelCreate<
    undefined | string,
    undefined | string
  >[];
  itemOrder: IItemsOrder;
  elementOrder: IElementOrder;
}

export function AnalyticsDescriptionItems(
  props: IAnalyticsDescriptionItemsProps
) {
  const [items, setItems] = useState<
    IBaseAnalyticsAggregatedModelCreate<
      undefined | string,
      undefined | string
    >[]
  >([]);

  useEffect(() => {
    setItems(
      Object.assign([], props.items).sort(
        (
          a: IBaseAnalyticsAggregatedModelCreate<
            undefined | string,
            undefined | string
          >,
          b: IBaseAnalyticsAggregatedModelCreate<
            undefined | string,
            undefined | string
          >
        ): number => {
          return (a[props.itemOrder] || '') > (b[props.itemOrder] || '')
            ? -1
            : 1;
        }
      )
    );
  }, [props.items, props.itemOrder]);

  return (
    <Block>
      {items.map(
        (
          item: IBaseAnalyticsAggregatedModelCreate<
            undefined | string,
            undefined | string
          >
        ) => (
          <Block>
            <ContentDesc>
              <ContentBold>
                {item.date} - {item.value} - {item.param}
              </ContentBold>
            </ContentDesc>
            <AnalyticsDescriptionItemsElement
              items={item.items}
              itemOrder={props.itemOrder}
              elementOrder={props.elementOrder}
            />
          </Block>
        )
      )}
    </Block>
  );
}
