import React, { useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { logEvent } from 'firebase/analytics';
import { analyticsApp } from '../../config/firebase.config';
import { IState } from '../../interfaces/system/IState';
import { connect } from 'react-redux';
import { IAuctionMode } from '../../interfaces/examples/IAuction';
import { loadUsers } from '../../actions/examples/users.actions';
import AuctionLive from './AuctionLive';
import ResultLot from './ResultLot';
import ResultAuction from './ResultAuction';
import ResultFinal from './ResultFinal';
import { Block } from '../../common/layout';

interface IAuctionWrapperProps {
  loadUsers: () => void;
  auctionMode: IAuctionMode;
}

function AuctionWrapper(props: IAuctionWrapperProps) {
  useEffect(() => logEvent(analyticsApp, 'Init example - Auction Live'), []);
  useEffect(() => props.loadUsers(), []);

  return (
    <Block>
      {props.auctionMode === 'Lot' && <AuctionLive />}
      {props.auctionMode === 'ReportLot' && <ResultLot />}
      {props.auctionMode === 'ReportAuction' && <ResultAuction />}
      {props.auctionMode === 'FinalReport' && <ResultFinal />}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    auctionMode: state.Example.auctionMode,
  }),
  {
    loadUsers,
  }
)(AuctionWrapper);
