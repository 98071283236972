import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState, IStateAnalyticsGroup } from '../../interfaces/system/IState';
import { sendPageEvent } from '../../utils/analytics';
import { Loading } from '../../common/animation/Loading';
import { ActionLink, BlockPage, BlockPageContent } from '../../common/styles';
import AnalyticsMainHead from '../../common/moderation/AnalyticsMainHead';
import { IPlatformType, PlatformTypeList } from '../../config/platform.config';
import { SelectItemsList } from '../../common/moderation/SelectItemsList';
import { loadAnalyticsMainByPlatform } from '../../actions/analytics/analyticsAll.actions';
import { ChartCalendar } from '../../components/analytics/ChartCalendar';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { BlockView } from '../../common/moderation/BlockView';
import AnalyticsChart from '../../components/analytics/AnalyticsChart';

interface ITechAllAnalyticsProps {
  loadAnalyticsMainByPlatform: (
    platformType: IPlatformType,
    from: string,
    to: string
  ) => void;
  retentionByPlatform: Partial<Record<IPlatformType, IStateAnalyticsGroup>>;
  cronByPlatform: Partial<Record<IPlatformType, IStateAnalyticsGroup>>;
  dbByPlatform: Partial<Record<IPlatformType, IStateAnalyticsGroup>>;
  routeByPlatform: Partial<Record<IPlatformType, IStateAnalyticsGroup>>;
}

function TechAllAnalytics(props: ITechAllAnalyticsProps) {
  const [platformType, setPlatformType] = useState<IPlatformType>('Main');
  const [isShowAll, setIsShowAll] = useState<boolean>();
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();

  useEffect(() => sendPageEvent('TechAllAnalytics'), []);
  useEffect(() => {
    if (!from || !to) {
      return;
    }

    props.loadAnalyticsMainByPlatform(platformType, from, to);
  }, [platformType, from, to]);

  const saveCalendar = (fromLocal: string, toLocal: string): void => {
    setFrom(fromLocal);
    setTo(toLocal);
  };

  const hideAll = (): void => {
    setIsShowAll(false);
  };
  const showAll = (): void => {
    setIsShowAll(true);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <AnalyticsMainHead />

        <ChartCalendar cb={saveCalendar} />

        {(!props.routeByPlatform[platformType] ||
          !props.dbByPlatform[platformType] ||
          !props.cronByPlatform[platformType] ||
          !props.retentionByPlatform[platformType]) && <Loading size={'big'} />}

        <ContentDesc>
          {isShowAll === false ? (
            <ContentDescInline>hide all</ContentDescInline>
          ) : (
            <ActionLink onClick={hideAll}>hide all</ActionLink>
          )}{' '}
          {isShowAll === true || isShowAll === undefined ? (
            <ContentDescInline>show all</ContentDescInline>
          ) : (
            <ActionLink onClick={showAll}>show all</ActionLink>
          )}
        </ContentDesc>

        <SelectItemsList
          cbSelected={setPlatformType}
          selected={platformType}
          items={PlatformTypeList}
        />

        {props.retentionByPlatform[platformType] && (
          <BlockView
            title={'Counts retention'}
            default={isShowAll}
            element={
              <AnalyticsChart
                items={props.retentionByPlatform[platformType]?.items || []}
              />
            }
          />
        )}
        {props.routeByPlatform[platformType] && (
          <BlockView
            title={'Time by route'}
            default={isShowAll}
            element={
              <AnalyticsChart
                items={props.routeByPlatform[platformType]?.items || []}
              />
            }
          />
        )}
        {props.cronByPlatform[platformType] && (
          <BlockView
            title={'Time by cron'}
            default={isShowAll}
            element={
              <AnalyticsChart
                items={props.cronByPlatform[platformType]?.items || []}
              />
            }
          />
        )}
        {props.dbByPlatform[platformType] && (
          <BlockView
            title={'Time by db'}
            default={isShowAll}
            element={
              <AnalyticsChart
                items={props.dbByPlatform[platformType]?.items || []}
              />
            }
          />
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    retentionByPlatform: state.Analytics.retentionGroupByPlatform.main,
    cronByPlatform: state.Analytics.cronGroupByPlatform.main,
    dbByPlatform: state.Analytics.dbGroupByPlatform.main,
    routeByPlatform: state.Analytics.routeGroupByPlatform.main,
  }),
  {
    loadAnalyticsMainByPlatform,
  }
)(TechAllAnalytics);
