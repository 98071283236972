import React, { useEffect, useState } from 'react';
import { ContentDesc } from '../../../common/typos';
import { Block } from '../../../common/layout';
import {
  IEmailLogBatchFiltersParams,
  IEmailMode,
  IEmailModeMailingList,
} from '../../../interfaces/communication/IEmailLog';
import { FormFieldSelect } from '../../../common/forms/FormFieldSelect';
import { prepareBatchKeyEmailLog } from '../../../actions/communication/emailLog.actions';

interface IEmailLogFiltersProps {
  cb: (batchKey: string, params: IEmailLogBatchFiltersParams) => void;
}

export function EmailLogFilters(props: IEmailLogFiltersProps) {
  const [mailingID, setMailingID] = useState<null | string>(null);
  const [accountID, setAccountID] = useState<null | string>(null);
  const [mode, setMode] = useState<null | IEmailMode>(null);

  useEffect(() => {
    props.cb(prepareBatchKeyEmailLog(mailingID, accountID, mode), {
      mailingID,
      accountID,
      mode,
    });
  }, [mailingID, accountID, mode]);

  return (
    <Block className="article">
      <Block>
        <ContentDesc>Filter on mailing:</ContentDesc>
        {/*<FormFieldSelect
          isDisabled={false}
          valueError={null}
          saveValue={(values: string[]) =>
            setStatus(values[0] as IMailingStatus)
          }
          // isValidForm={true}
          // title={item.title || field[0].toUpperCase() + field.slice(1)}
          defaultValue={status ? [status] : null}
          values={IMailingStatusList}
        />*/}
      </Block>

      <Block>
        <ContentDesc>Filter on account:</ContentDesc>
        {/*<FormFieldSelect
          isDisabled={false}
          valueError={null}
          saveValue={(values: string[]) => setMode(values[0] as IEmailMode)}
          // isValidForm={isValid}
          // title={item.title || field[0].toUpperCase() + field.slice(1)}
          defaultValue={mode ? [mode] : null}
          values={IEmailModeMailingList}
        />*/}
      </Block>

      <Block>
        <ContentDesc>Filter on mode:</ContentDesc>
        <FormFieldSelect
          isDisabled={false}
          valueError={null}
          saveValue={(values: string[]) => setMode(values[0] as IEmailMode)}
          // isValidForm={isValid}
          // title={item.title || field[0].toUpperCase() + field.slice(1)}
          defaultValue={mode ? [mode] : null}
          values={IEmailModeMailingList}
        />
      </Block>
    </Block>
  );
}
