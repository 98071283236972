import styled, { css } from 'styled-components';
import { colors } from './colors';
import { margins } from './sizes';

export const fonts = {
  weightXS: 300,
  weightS: 400,
  weightM: 500,
  weightL: 600,
  main: css`
    font-family: Montserrat, -apple-system, sans-serif;
  `,
  sizeS: '12px',
  sizeM: '16px',
  sizeLess: '20px',
  sizeL: '22px',
};

export const typos = {
  name: css`
    font-size: ${fonts.sizeL};
    font-weight: ${fonts.weightS};
    letter-spacing: 0;
    ${fonts.main};
  `,
  desc: css`
    font-size: ${fonts.sizeM};
    font-weight: ${fonts.weightS};
    letter-spacing: 0;
    ${fonts.main};
  `,
};

export const PageHead = styled.h1`
  ${typos.name};
  font-weight: ${fonts.weightL};
  padding-top: 10px;
  line-height: 2rem;

  &.clickable {
    cursor: pointer;
  }
`;
export const PageDesc = styled.h2`
  ${typos.name};
  padding-top: 5px;
  line-height: 2rem;

  &.clickable {
    cursor: pointer;
  }

  & b {
    font-weight: ${fonts.weightM};
  }

  &.item {
    display: inline-block;
    float: left;
    margin-right: ${margins.block};
  }

  &.active {
    color: ${colors.main};
    font-weight: ${fonts.weightS};
    text-decoration: underline;
  }

  &.fail {
    color: ${colors.lost};
  }

  &.success {
    color: ${colors.win};
  }
`;
export const ContentHead = styled.p`
  ${typos.name};
  //padding-top: 10px;
  line-height: 2rem;
  margin: 0;

  &.clickable {
    cursor: pointer;
  }

  &.success {
    color: ${colors.win};
  }

  &.fail {
    color: ${colors.lost};
  }

  &.item {
    display: inline-block;
    float: left;
    margin-right: ${margins.block};
  }

  &.active {
    color: ${colors.main};
    font-weight: ${fonts.weightS};
    text-decoration: underline;
  }
`;
export const ContentDesc = styled.p`
  ${typos.desc};
  line-height: 1.375rem;
  margin: 0;
  white-space: pre-wrap;

  & a {
    text-decoration: underline;
    display: inline-block !important;
    margin: 0 !important;
    font-weight: ${fonts.weightM};

    :hover {
      color: ${colors.main};
      font-weight: ${fonts.weightL};
      transition: all 1s ease-out;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.success {
    color: ${colors.win};
  }

  &.fail {
    color: ${colors.lost};
  }
`;
export const ContentDescInline = styled.span`
  ${typos.desc};
  line-height: 1.375rem;
  margin: 0;
  white-space: pre-wrap;

  &.bold {
    font-weight: ${fonts.weightL};
  }

  &.clickable {
    cursor: pointer;
  }

  &.win {
    color: ${colors.win};
  }

  &.lost {
    color: ${colors.lost};
  }

  &.right {
    float: right;
  }

  &.bigger {
    ${typos.name};
    font-size: ${fonts.sizeLess};
    font-weight: ${fonts.weightM};
    line-height: unset;
  }
`;
export const ContentBold = styled.b`
  &,
  & span {
    font-weight: ${fonts.weightL};
  }
`;
export const ContentUl = styled.ul`
  width: 100%;
  display: block;
  float: left;

  &.noMargin {
    margin: 0;
    padding: 0;
  }
`;
export const ContentLi = styled.li`
  width: 100%;
  display: block;
`;
