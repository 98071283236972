import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { CacheRequests } from '../../service/cacheRequests';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAppPartnerAuctionCreateBody,
  IAppPartnerAuctionDeleteQuery,
  IAppPartnerAuctionIDsResponse,
  IAppPartnerAuctionSet,
  IAppPartnerAuctionSetQuery,
  IAppPartnerAuctionSetResponse,
  IAppPartnerAuctionUpdateBody,
} from '../../interfaces/partners/IAppPartnerAuction';
import { dispatchInfo } from '../../service/error';
import {
  ITEM_CREATED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  PARTNER_AUCTION_ACTION,
  PARTNER_AUCTION_LOAD_IDS,
  PARTNER_AUCTION_LOAD_SET,
} from '../types';
import { IPlatformType } from '../../config/platform.config';

const { partnerAuction } = apiConfig.endpoints.partners;
const cacheByID: CacheRequests = new CacheRequests();

export const loadPartnerAuctionIDs =
  (hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      string | string[] | IAppPartnerAuctionSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    const { Partners } = getState();
    if (!hardReload && Partners.Auction.IDs[0]) {
      return;
    }

    callApi<IAppPartnerAuctionIDsResponse>(partnerAuction.ids)
      .then((data: IAppPartnerAuctionIDsResponse) => {
        loadPartnerAuctionSet(data.partnerIDs)(dispatch, getState);

        dispatch({
          type: PARTNER_AUCTION_LOAD_IDS,
          payload: data.partnerIDs,
        });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadPartnerAuctionSet =
  (partnerAuctionIDs: string[], hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      string | IAppPartnerAuctionSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!partnerAuctionIDs || !partnerAuctionIDs[0]) {
      return;
    }
    const set: IAppPartnerAuctionSet = getState().Partners.Auction.set;
    let IDsToLoad: string[] = [];
    if (!hardReload) {
      const toLoad: string[] = [];
      partnerAuctionIDs.forEach((partnerAuctionID: string) => {
        if (!set[partnerAuctionID]) {
          toLoad.push(partnerAuctionID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      IDsToLoad = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }
    }

    const query: IAppPartnerAuctionSetQuery = {
      partnerIDs: hardReload
        ? partnerAuctionIDs.join(',')
        : IDsToLoad.join(','),
    };
    callApi<IAppPartnerAuctionSetResponse>(partnerAuction.main, query)
      .then((data: IAppPartnerAuctionSetResponse) => {
        dispatch({ type: PARTNER_AUCTION_LOAD_SET, payload: data.set });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const updatePartnerAuction =
  (
    partnerID: string,
    name: null | string,
    description: null | string,
    hostAuction: null | string,
    hostAuctionSub: undefined | null | string,
    hostCompany: undefined | null | string,
    imageURL: null | string
  ) =>
  (
    dispatch: IDispatch<
      string | IAppPartnerAuctionSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IAppPartnerAuctionUpdateBody = {
      partnerID,
    };
    if (name) {
      body.name = name;
    }
    if (description) {
      body.description = description;
    }
    if (hostAuction) {
      body.hostAuction = hostAuction;
    }
    if (imageURL) {
      body.imageURL = imageURL;
    }
    if (hostAuctionSub !== undefined) {
      body.hostAuctionSub = hostAuctionSub;
    }
    if (hostCompany !== undefined) {
      body.hostCompany = hostCompany;
    }

    callApi(partnerAuction.main, {}, body, 'POST')
      .then(() =>
        dispatch({ type: PARTNER_AUCTION_ACTION, payload: ITEM_UPDATED })
      )
      .then(() => loadPartnerAuctionSet([partnerID], true)(dispatch, getState))
      .then(() => dispatchInfo(dispatch, 'Partner is updated'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

// export const updatePartnerAuctionSelf =

export const createPartnerAuction =
  (
    platformType: IPlatformType,
    name: string,
    description: string,
    hostAuction: string,
    hostAuctionSub: null | string,
    hostCompany: null | string,
    imageURL: string
  ) =>
  (
    dispatch: IDispatch<
      string | string[] | IAppPartnerAuctionSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IAppPartnerAuctionCreateBody = {
      platformType,
      name,
      description,
      hostAuction,
      hostAuctionSub,
      hostCompany,
      imageURL,
    };
    callApi(partnerAuction.main, {}, body)
      .then(() =>
        dispatch({ type: PARTNER_AUCTION_ACTION, payload: ITEM_CREATED })
      )
      .then(() => loadPartnerAuctionIDs(true)(dispatch, getState))
      .then(() => dispatchInfo(dispatch, 'Partner is created'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const deletePartnerAuction =
  (partnerID: string) =>
  (
    dispatch: IDispatch<
      string | string[] | IAppPartnerAuctionSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const query: IAppPartnerAuctionDeleteQuery = {
      partnerID: partnerID,
    };
    callApi(partnerAuction.main, query, undefined, 'DELETE')
      .then(() =>
        dispatch({ type: PARTNER_AUCTION_ACTION, payload: ITEM_REMOVED })
      )
      .then(() => loadPartnerAuctionIDs(true)(dispatch, getState))
      .then(() => dispatchInfo(dispatch, 'Partner is removed'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
