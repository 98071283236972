import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { DATA_LOADED } from '../actions/types';
import { ActionCase, IStateLead } from '../interfaces/system/IState';

const initialStateAccount: IStateLead = {
  data: '',
};

export const leadReducer: Reducer = createReducer(
  initialStateAccount,
  (builder: ActionReducerMapBuilder<IStateLead>) => {
    builder.addCase<string, ActionCase<string>>(
      DATA_LOADED,
      (state: Draft<IStateLead>, action: ActionCase<string>) => {
        state.data = action.payload;
      }
    );
  }
);
