import React from 'react';
import { ContentBold, ContentDesc, ContentDescInline } from '../common/typos';

export const prepareAuctionDate = (date: null | string): string => {
  let dateValue: null | string;
  if (!date) {
    dateValue = 'TBD';
  } else {
    if (!isNaN(Date.parse(date))) {
      dateValue = new Date(date).toLocaleString().slice(0, -3);
    } else {
      dateValue = date;
    }
  }

  return dateValue;
};

export const renderAuctionDate = (
  date: null | string,
  opts?: {
    label?: string;
    hideLabel?: boolean;
    isBold?: boolean;
    inline?: boolean;
  }
) => {
  const dateValue: null | string = prepareAuctionDate(date);
  let label: string = '';
  if (opts && !opts.hideLabel) {
    label = opts.label || 'Auction date';
  }

  return (
    <>
      {opts && opts.inline ? (
        <ContentDescInline>{dateValue}</ContentDescInline>
      ) : (
        <ContentDesc>
          {label ? label + ': ' : ''}
          {opts && opts.isBold ? (
            <ContentBold>{dateValue}</ContentBold>
          ) : (
            <ContentDescInline>{dateValue}</ContentDescInline>
          )}
        </ContentDesc>
      )}
    </>
  );
};
