import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import {
  ITEM_CREATED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  MAILING_ACTION,
  MAILING_LOAD_BATCH,
  MAILING_LOAD_IDS,
  MAILING_LOAD_PREVIEW,
  MAILING_LOAD_SET,
  MAILING_LOAD_SETTINGS,
} from '../types';
import {
  IMailingBatchBody,
  IMailingCreateBody,
  IMailingIDsResponse,
  IMailingOneResponse,
  IMailingPreviewBody,
  IMailingPreviewResponse,
  IMailingSet,
  IMailingSetResponse,
  IMailingSettingsResponse,
  IMailingUpdateBody,
} from '../../interfaces/communication/IMailing';
import { CacheRequests } from '../../service/cacheRequests';
import { IEmailMode } from '../../interfaces/communication/IEmailLog';
import { IMailingStatus } from '../../interfaces/communication/IMailingLog';
import { dispatchError } from '../../service/error';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { DataBatch, DataBatchKey } from '../../interfaces/system/data';

const { main, ids, one, settings, batch, preview } =
  apiConfig.endpoints.communication.mailing;

const cacheByID: CacheRequests = new CacheRequests();

export function prepareMailingBatchKey(
  status: null | IMailingStatus,
  mode: null | IEmailMode
): string {
  return JSON.stringify({
    status,
    mode,
  });
}

export const loadMailingOne =
  (mailingID: string) =>
  (
    dispatch: IDispatch<string | IMailingSet | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IMailingOneResponse>(one, { mailingID })
      .then((data: IMailingOneResponse) => {
        if (!data.mailing) {
          return;
        }
        const payload: IMailingSet = {};
        payload[mailingID] = data.mailing;
        dispatch({ type: MAILING_LOAD_SET, payload });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadMailingSet =
  (mailingIDs: string[]) =>
  (
    dispatch: IDispatch<string | IMailingSet | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    if (!mailingIDs || !mailingIDs[0]) {
      return;
    }
    const set: IMailingSet = getState().Communication.Mailing.set;
    const toLoad: string[] = [];
    mailingIDs.forEach((mailingID: string) => {
      if (!set[mailingID]) {
        toLoad.push(mailingID);
      }
    });
    if (!toLoad[0]) {
      return;
    }

    const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
    if (!IDsToLoad[0]) {
      return;
    }

    callApi<IMailingSetResponse>(main, { mailingIDs: IDsToLoad.join(',') })
      .then((data: IMailingSetResponse) => {
        dispatch({ type: MAILING_LOAD_SET, payload: data.set });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadMailingIDs =
  () =>
  (
    dispatch: IDispatch<string | string[] | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi<IMailingIDsResponse>(ids)
      .then((data: IMailingIDsResponse) => {
        dispatch({ type: MAILING_LOAD_IDS, payload: data.mailingIDs });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadMailingSettings =
  () =>
  (
    dispatch: IDispatch<
      IMailingSettingsResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }
    if (getState().Communication.Mailing.settings) {
      return;
    }

    callApi<IMailingSettingsResponse>(settings)
      .then((data: IMailingSettingsResponse) =>
        dispatch({
          type: MAILING_LOAD_SETTINGS,
          payload: data,
        })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadMailingBatch =
  (status: null | IMailingStatus, mode: null | IEmailMode) =>
  (
    dispatch: IDispatch<
      string | IMailingSet | DataBatchKey<string> | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const batchKey: string = prepareMailingBatchKey(status, mode);
    const { limit, byBatchKey } = getState().Communication.Mailing;
    const body: IMailingBatchBody = {
      limit,
      skip: (byBatchKey[batchKey] && byBatchKey[batchKey].loaded) || 0,
      status,
      mode,
    };

    callApi<DataBatch<string>>(batch, {}, body, 'POST')
      .then((data: DataBatch<string>) => {
        const payload: DataBatchKey<string> = {
          ...data,
          batchKey,
        };

        loadMailingSet(data.items)(dispatch, getState);
        dispatch({
          type: MAILING_LOAD_BATCH,
          payload,
        });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadMailingPreview =
  (
    mode: IEmailMode,
    params: Record<string, string>,
    accountID: null | string
  ) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IMailingPreviewBody = {
      mode,
      params,
      accountID,
    };

    callApi<IMailingPreviewResponse>(preview, {}, body, 'POST')
      .then((data: IMailingPreviewResponse) =>
        dispatch({
          type: MAILING_LOAD_PREVIEW,
          payload: data.html,
        })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const createMailing =
  (
    mode: IEmailMode,
    params: Record<string, string>,
    sendAt: null | string,
    accountIDs: null | string[]
  ) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IMailingCreateBody = {
      mode,
      params,
      sendAt,
      accountIDs,
    };
    callApi(main, {}, body)
      .then(() => dispatch({ type: MAILING_ACTION, payload: ITEM_CREATED }))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Mailing is created',
          params: {},
          type: 'Info',
        })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const updateMailing =
  (
    mailingID: string,
    params: null | Record<string, string>,
    sendAt: null | string,
    accountIDs?: null | string[]
  ) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IMailingUpdateBody = {
      mailingID,
    };
    if (params) {
      body.params = params;
    }
    if (sendAt) {
      body.sendAt = sendAt;
    }
    if (accountIDs !== undefined) {
      body.accountIDs = accountIDs;
    }

    callApi(main, {}, body, 'POST')
      .then(() => dispatch({ type: MAILING_ACTION, payload: ITEM_UPDATED }))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Mailing is updated',
          params: {},
          type: 'Info',
        })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const deleteMailing =
  (mailingID: string) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    callApi(main, { mailingID }, undefined, 'DELETE')
      .then(() => dispatch({ type: MAILING_ACTION, payload: ITEM_REMOVED }))
      .then(() =>
        dispatchError(dispatch, {
          message: 'Mailing is removed',
          params: {},
          type: 'Info',
        })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
