import React from 'react';
import { connect } from 'react-redux';
import { ContentDesc, ContentHead } from '../common/typos';
import { IState } from '../interfaces/system/IState';
import { ErrorBlock, ErrorItem } from './Errors.styles';
import { IServiceInfo } from '../interfaces/system/IError';

interface IErrorsProps {
  infos: IServiceInfo[];
}

function Errors(props: IErrorsProps) {
  return (
    <ErrorBlock>
      {props.infos.map((item: IServiceInfo, index: number) => (
        <ErrorItem key={index} className={item.isError ? 'error' : ''}>
          <ContentHead>{item.message}</ContentHead>
          {item.params &&
            item.params.map(({ key, value }) => (
              <ContentDesc key={index}>
                {key !== '' ? key + ' - ' : ''}
                {value}
              </ContentDesc>
            ))}
        </ErrorItem>
      ))}
    </ErrorBlock>
  );
}

export default connect(
  (state: IState) => ({
    infos: state.System.infos,
  }),
  {}
)(Errors);
