import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { loadPartnerAuctionIDs } from '../../actions/partners/partnerAuction.actions';
import {
  IAppPartnerAuctionPublic,
  IAppPartnerAuctionSet,
} from '../../interfaces/partners/IAppPartnerAuction';
import { PartnersAuctionPublic } from './PartnersAuctionPublic';
import { Block, CarouselBlock } from '../../common/layout';
import { Carousel } from 'react-responsive-carousel';
import { L } from '../../common/locale';

interface IPartnersAuctionPublicListProps {
  mode: 'carousel' | 'rows';
  loadPartnerAuctionIDs: (hardReload?: boolean) => void;
  IDs: string[];
  set: IAppPartnerAuctionSet;
  hideTitle?: boolean;
  allowNavigation?: boolean;
}

function PartnersAuctionPublicList(props: IPartnersAuctionPublicListProps) {
  const [partners, setPartners] = useState<IAppPartnerAuctionPublic[]>([]);

  useEffect(() => props.loadPartnerAuctionIDs(), []);
  useEffect(() => {
    const partnersLocal: IAppPartnerAuctionPublic[] = [];

    props.IDs.map((partnerID: string) => {
      const partner: null | IAppPartnerAuctionPublic =
        props.set[partnerID] || null;
      partnersLocal.push(partner);
    });

    setPartners(partnersLocal);
  }, [props.IDs, props.set]);

  return (
    <Block>
      {!props.hideTitle && (
        <PageHead>
          <L k={'ShopTitlePartnersShort'} />
        </PageHead>
      )}

      {props.mode === 'carousel' && (
        <CarouselBlock>
          <Carousel
            autoPlay={true}
            interval={5000}
            transitionTime={3000}
            swipeable={true}
            infiniteLoop={true}
            showArrows={true}
            showThumbs={false}
          >
            {partners[0] &&
              partners.slice(0, 10).map((partner: IAppPartnerAuctionPublic) => (
                <Block key={partner.id} className={'left'}>
                  <PartnersAuctionPublic
                    partner={partner}
                    hideDescription={true}
                    hideButtons={true}
                    allowNavigation={props.allowNavigation}
                  />
                </Block>
              ))}
          </Carousel>
        </CarouselBlock>
      )}

      {props.mode === 'rows' &&
        partners[0] &&
        partners.map((partner: IAppPartnerAuctionPublic) => (
          <PartnersAuctionPublic partner={partner} key={partner.id} />
        ))}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    IDs: state.Partners.Auction.IDs,
    set: state.Partners.Auction.set,
  }),
  {
    loadPartnerAuctionIDs,
  }
)(PartnersAuctionPublicList);
