import React from 'react';
import { ActionButton, BlockActions } from '../styles';
import { Loading } from '../animation/Loading';
import { IParamsItemView, RenderItemPublicFunction } from '../common';
import { IModerationAction } from './Moderation';
import { ImageIcon } from '../images/Image.styles';

interface IModerationItemProps<ItemPublic> {
  itemID: string;
  set: Record<string, ItemPublic>;
  isDisabled?: boolean;
  cbView?: (itemID: string) => void;
  cbEdit?: (itemID: string) => void;
  cbDelete?: (itemID: string) => void;
  renderItemPublic: RenderItemPublicFunction<ItemPublic>;
  ParamsItemView?: IParamsItemView;
  action?: IModerationAction;
}

export function ModerationItem<ItemPublic>(
  props: IModerationItemProps<ItemPublic>
) {
  const edit = (itemID: string) => {
    if (props.cbEdit) {
      props.cbEdit(itemID);
    }
  };

  const renderBlockActions = (itemID: string): JSX.Element => {
    if (props.action?.showInline) {
      return (
        <>
          {(props.cbEdit || props.cbDelete) && (
            <>
              {props.cbView && (
                <ImageIcon
                  src="/icons/view.png"
                  alt="view"
                  onClick={() => (props.cbView && props.cbView(itemID)) || null}
                  className="clickable lost inline"
                />
              )}
              {props.cbEdit && (
                <ImageIcon
                  src="/icons/edit.png"
                  alt="edit"
                  onClick={() => edit(itemID)}
                  className="clickable lost small inline"
                />
              )}
              {props.cbDelete && (
                <ImageIcon
                  src="/icons/delete.png"
                  alt="delete"
                  onClick={() =>
                    (props.cbDelete && props.cbDelete(itemID)) || null
                  }
                  className="clickable lost small inline"
                />
              )}
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {(props.cbEdit || props.cbDelete) && (
            <BlockActions className={props.action?.showInline ? ' inline' : ''}>
              {props.cbEdit && (
                <ActionButton
                  onClick={() => (props.cbEdit && props.cbEdit(itemID)) || null}
                  className="fail"
                  disabled={props.isDisabled}
                >
                  {props.action?.editActionTitle || 'Edit'}
                </ActionButton>
              )}
              {props.cbDelete && (
                <ActionButton
                  onClick={() =>
                    (props.cbDelete && props.cbDelete(itemID)) || null
                  }
                  className="fail"
                  disabled={props.isDisabled}
                >
                  {props.action?.removeActionTitle || 'Remove'}
                </ActionButton>
              )}
            </BlockActions>
          )}
        </>
      );
    }
  };

  return (
    <>
      {props.set[props.itemID] &&
        props.renderItemPublic(
          props.set[props.itemID],
          (props.action?.showInline && renderBlockActions(props.itemID)) ||
            undefined,
          props.ParamsItemView,
          props.action?.showInline
        )}
      {!props.set[props.itemID] && <Loading />}

      {!props.action?.showInline && renderBlockActions(props.itemID)}
    </>
  );
}
