import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { colors } from '../../common/colors';
import { ChartsBlock, ChartsItem, LabelsBlock } from './analytics.styles';
import { ActionLink } from '../../common/styles';
import { ContentDesc } from '../../common/typos';
import { IBaseAnalyticsAggregatedModelCreate } from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import { Block } from '../../common/layout';
import { AnalyticsChartSelected } from './AnalyticsChartSelected';
import { SwitchMultiple } from '../../common/forms/SwitchMultiple';

interface IAnalyticsChartSimpleProps {
  items: IBaseAnalyticsAggregatedModelCreate<
    string | undefined,
    string | undefined
  >[];
  clientWidth: null | number;
}

interface IChartItem {
  date: string;

  [value: string]: string | number;
}

type IMode = 'min' | 'max' | 'avg';
const modeList: IMode[] = ['min', 'max', 'avg'];

export function AnalyticsChartSimple(props: IAnalyticsChartSimpleProps) {
  const [mode, setMode] = useState<IMode>('max');
  const [chartList, setChartList] = useState<IChartItem[]>([]);
  const [countList, setCountList] = useState<IChartItem[]>([]);
  const [valueMaxSet, setValueMaxSet] = useState<Record<string, number>>({});
  const [valueCntSet, setValueCntSet] = useState<Record<string, number>>({});
  const [valueList, setValueList] = useState<string[]>([]);
  const [valueShowSet, setValueShowSet] = useState<Record<string, boolean>>({});
  const [chartSize, setChartSize] = useState<null | number>(null);
  const [selectedParam, setSelectedParam] = useState<null | string>(null);
  const [sortOrder, setSortOrder] = useState<
    'valueMin' | 'valueMax' | 'cntMin' | 'cntMax'
  >('valueMax');

  useEffect(() => {
    const chartListLocal: IChartItem[] = [];
    const countListLocal: IChartItem[] = [];

    const valueMaxSetLocal: Record<string, number> = {};
    const valueCntSetLocal: Record<string, number> = {};

    if (!props.items) {
      return;
    }

    props.items.forEach(({ date, items }) => {
      const set: Record<string, string | number> = {};
      const setCount: Record<string, string | number> = {};

      items.forEach(({ value, param, min, max, avg, cnt }) => {
        if (!value) {
          return;
        }

        const title: string = value + (param ? '-' + param : '');
        const merit: number = mode === 'min' ? min : mode === 'max' ? max : avg;

        valueMaxSetLocal[title] = Math.max(merit, valueMaxSetLocal[title] || 0);
        valueCntSetLocal[title] = Math.max(cnt, valueCntSetLocal[title] || 0);

        set[title] = merit;
        setCount[title] = cnt;
      });
      chartListLocal.push({
        date,
        ...set,
      });
      countListLocal.push({
        date,
        ...setCount,
      });
    });

    setChartList(chartListLocal);
    setCountList(countListLocal);
    setValueMaxSet(valueMaxSetLocal);
    setValueCntSet(valueCntSetLocal);

    const valueListLocal: string[] = [];
    const tempList: { value: string; max: number }[] = [];
    Object.keys(valueMaxSetLocal).forEach((value: string) => {
      tempList.push({ value, max: valueMaxSetLocal[value] });
    });
    tempList
      .sort((a, b) => (a.max > b.max ? -1 : 1))
      .forEach(({ value }) => {
        valueListLocal.push(value);
      });
    const valueShowSetLocal: Record<string, boolean> = {};
    for (let i = 0, len = Math.min(3, valueListLocal.length); i < len; i++) {
      valueShowSetLocal[valueListLocal[i]] = true;
    }
    setValueList(valueListLocal);
    setValueShowSet(valueShowSetLocal);
  }, [props.items, mode]);
  useEffect(() => {
    let chartSizeLocal: null | number = props.clientWidth;
    if (!chartSizeLocal) {
      return;
    }

    chartSizeLocal = Math.floor(chartSizeLocal / 2);
    chartSizeLocal = Math.max(chartSizeLocal, 200);
    chartSizeLocal = Math.min(chartSizeLocal, 500);
    setChartSize(chartSizeLocal);
  }, [props.clientWidth]);

  const showValue = (value: string): void => {
    const valueShowSetLocal: Record<string, boolean> = Object.assign(
      {},
      valueShowSet
    );
    valueShowSetLocal[value] = !valueShowSetLocal[value];
    setValueShowSet(valueShowSetLocal);
  };
  const getColorFromPalette = (index: number): string => {
    return colors.palette[index % colors.palette.length];
  };
  const renderChartBlock = (chartData: IChartItem[], title: string) => {
    return (
      <ChartsItem className="half">
        <ContentDesc>{title}</ContentDesc>
        <LineChart width={chartSize || 400} height={300} data={chartData}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />

          <CartesianGrid stroke="#f5f5f5" />
          {valueList.map((value: string, index: number) => (
            <>
              {valueShowSet[value] && (
                <Line
                  type="monotone"
                  dataKey={value}
                  stroke={getColorFromPalette(index)}
                  key={value}
                  style={{ cursor: 'pointer' }}
                  // @ts-ignore
                  activeDot={{ cursor: 'pointer', onClick: selectValue }}
                />
              )}
            </>
          ))}
        </LineChart>
      </ChartsItem>
    );
  };

  const selectValue = (
    f: Function,
    data: { dataKey: string; index: number }
  ): void => {
    setSelectedParam(data.dataKey);
  };

  const uncheck = () => {
    setValueShowSet({});
  };
  const sort = (isMin: boolean, isValues: boolean) => {
    const valueListLocal: string[] = [];
    const tempList: { value: string; num: number }[] = [];
    Object.keys(valueMaxSet).forEach((value: string) => {
      tempList.push({
        value,
        num: isValues ? valueMaxSet[value] : valueCntSet[value],
      });
    });
    tempList
      .sort((a, b) => {
        const order: number = a.num > b.num ? -1 : 1;
        return isMin ? -1 * order : order;
      })
      .forEach(({ value }) => {
        valueListLocal.push(value);
      });
    setValueList(valueListLocal);
    setSortOrder(
      isValues ? (isMin ? 'valueMin' : 'valueMax') : isMin ? 'cntMin' : 'cntMax'
    );
  };

  const renderLabel = (isValue: boolean): JSX.Element => {
    return (
      <LabelsBlock>
        <ActionLink
          onClick={() => sort(false, isValue)}
          className={sortOrder === 'valueMax' ? 'active' : ''}
        >
          desc
        </ActionLink>
        <ActionLink
          onClick={() => sort(true, isValue)}
          className={sortOrder === 'valueMin' ? 'active' : ''}
        >
          asc
        </ActionLink>

        {valueList.map((value: string) => (
          <ContentDesc key={value}>
            <ActionLink onClick={() => showValue(value)}>
              <input
                type="checkbox"
                checked={valueShowSet[value] || false}
                onChange={() => undefined}
              />
              {value} ({isValue ? valueMaxSet[value] : valueCntSet[value]})
            </ActionLink>
          </ContentDesc>
        ))}
      </LabelsBlock>
    );
  };

  return (
    <ChartsBlock>
      <SwitchMultiple
        checked={mode}
        options={modeList}
        cb={(value: string) => setMode(value as IMode)}
      />

      {selectedParam && (
        <AnalyticsChartSelected
          items={props.items}
          selectedParam={selectedParam}
          chartSize={chartSize}
        />
      )}

      {renderChartBlock(chartList, `${mode} time`)}
      {renderChartBlock(countList, 'Count')}

      <Block>
        <ActionLink onClick={uncheck}>Uncheck all</ActionLink>
      </Block>

      {renderLabel(true)}
      {renderLabel(false)}
    </ChartsBlock>
  );
}
