import React from 'react';
import { ContentDescInline } from '../typos';
import { Block } from '../layout';
import { Label } from './Form.styles';

interface ISwitchTripleProps {
  isChecked: null | boolean;
  option: string;
  cb: (isChecked: null | boolean) => void;
}

export function SwitchTriple(props: ISwitchTripleProps) {
  return (
    <Block className='flex'>
      <Label
        onChange={() => props.cb(true)}
        className={props.isChecked === true ? 'selected' : ''}
        style={{ display: 'inline-block' }}
      >
        <input type='radio' checked={props.isChecked === true} />
        <ContentDescInline>{props.option}</ContentDescInline>
      </Label>
      <Label
        onChange={() => props.cb(false)}
        className={props.isChecked === false ? 'selected' : ''}
        style={{ display: 'inline-block' }}
      >
        <input type='radio' checked={props.isChecked === false} />
        <ContentDescInline>Not {props.option}</ContentDescInline>
      </Label>
      <Label
        onChange={() => props.cb(null)}
        className={props.isChecked === null ? 'selected' : ''}
        style={{ display: 'inline-block' }}
      >
        <input type='radio' checked={props.isChecked === null} />
        <ContentDescInline>Any</ContentDescInline>
      </Label>
    </Block>
  );
}
