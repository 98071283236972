export type IEmailMode =
  | 'first'
  | 'moderation'
  | 'moderationStarted'
  | 'admin'
  | 'newProducts'
  | 'weekLostLot'
  | 'weekAuction'
  | 'donationDetails'
  | 'PartnerAuction'
  | 'EmptyEmail'
  | 'ToPartners'
  | 'ToRecommendation';

export const IEmailModeList: IEmailMode[] = [
  'first',
  'moderation',
  'admin',
  'newProducts',
  'weekLostLot',
  'weekAuction',
  'donationDetails',
  'PartnerAuction',
  'EmptyEmail',
  'ToPartners',
  'ToRecommendation',
];

export const IEmailModeMailingList: IEmailMode[] = [
  // 'first',
  // 'moderation',
  // 'admin',
  'newProducts',
  // 'weekLostLot',
  'weekAuction',
  // 'donationDetails',
  'PartnerAuction',
  'EmptyEmail',
  'ToPartners',
  'ToRecommendation',
];

export interface IEmailLogBatchFiltersParams {
  mailingID: null | string;
  accountID: null | string;
  mode: null | IEmailMode;
}

export interface IEmailLogView extends Record<string, boolean> {
  accountID: boolean;
  mode: boolean;
  action: boolean;
  isAllowed: boolean;
  messageID: boolean;
  mailingID: boolean;
  events: boolean;
  date: boolean;
}

export interface IEmailLog {
  accountID: string;
  mode: IEmailMode;
  action: 'init' | 'send';
  isAllowed: boolean;
  messageID: string;
  mailingID: string | null;
  events: Record<string, string[]>;
  date: string;
}

export interface IEmailLogPublic extends IEmailLog {
  id: string;
}

export type IEmailLogSet = Record<string, IEmailLogPublic>;

export interface IEmailLogIDsResponse {
  logIDs: string[];
}

export interface IEmailLogSetResponse {
  set: IEmailLogSet;
}

export interface IEmailLogBatchBody
  extends Record<string, string | null | number> {
  limit: number;
  skip: number;
  mailingID: null | string;
  accountID: null | string;
  mode: null | IEmailMode;
}

// export interface IEmailLogBatchData extends DataBatch<string> {
//   batchKey: string;
// }
