import React from 'react';
import { ContentDesc, ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAppPartnerLotPublic } from '../../interfaces/partners/IAppPartnerLot';
import { Image } from '../../common/images/Image.styles';

interface IPartnersLotItemModerationProps {
  partner: IAppPartnerLotPublic;
}

export function PartnersLotItemModeration(
  props: IPartnersLotItemModerationProps
) {
  const partner: IAppPartnerLotPublic = props.partner;

  return (
    <Block>
      <Image className={'L'} src={partner.imageURL} alt={partner.name} />
      <ContentHead>{partner.name}</ContentHead>
      <ContentDesc>{partner.description}</ContentDesc>
    </Block>
  );
}
