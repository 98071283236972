import React, { ChangeEvent, useEffect, useState } from 'react';
import { Block } from '../layout';
import { ContentBold, ContentHead } from '../typos';
import { RenderItemPublicFunction } from '../common';
import { ModerationItemsList } from './ModerationItemsList';
import { ModerationItemsListMultiple } from './ModerationItemsListMultiple';
import { IModerationAction, IModerationSelection } from './Moderation';
import { InputSearch } from '../forms/Form.styles';

interface ISearchProps<ItemPublic> {
  searchPrefix?: string;
  searchKey?: string;
  search: (search: string) => void;
  set: Record<string, ItemPublic>;
  IDsBySearch: Record<string, string[]>;
  renderItemPublic: RenderItemPublicFunction<ItemPublic>;
  placeholderTitle: string;
  selection?: IModerationSelection;
  action?: IModerationAction;
}

export function Search<ItemPublic>(props: ISearchProps<ItemPublic>) {
  const [itemIDs, setItemIDs] = useState<string[]>([]);
  const [search, setSearch] = useState<null | string>();

  useEffect(() => {
    const key: string =
      (props.searchPrefix || '') + (props.searchKey || search || '');
    setItemIDs(props.IDsBySearch[key] || []);
  }, [props.searchKey, props.IDsBySearch]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const localSearch = event.target.value;
    setSearch(localSearch);
    props.search(localSearch);
  };

  return (
    <Block className="marginBlock">
      <InputSearch
        type="text"
        onChange={handleOnChange}
        placeholder={'Search by ' + props.placeholderTitle}
      />
      {search && (
        <>
          <ContentHead>
            Items by <ContentBold>{search}:</ContentBold>
          </ContentHead>

          {!props.selection?.cbSelectedList && (
            <ModerationItemsList
              isDisabled={false}
              load={() => undefined}
              renderItemPublic={props.renderItemPublic}
              IDs={itemIDs || []}
              set={props.set}
              action={props.action}
              selection={props.selection}
            />
          )}
          {props.selection?.cbSelectedList && (
            <ModerationItemsListMultiple
              isDisabled={false}
              load={() => undefined}
              renderItemPublic={props.renderItemPublic}
              IDs={itemIDs || []}
              set={props.set}
              action={props.action}
              selection={props.selection}
            />
          )}
        </>
      )}
    </Block>
  );
}
