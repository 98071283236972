import React, { useEffect, useState } from 'react';
import { Block } from '../layout';
import { IParamsItemView, RenderItemPublicFunction } from '../common';
import { ModerationItem } from './ModerationItem';
import { IModerationAction, IModerationSelection } from './Moderation';

interface IModerationItemsListMultipleProps<ItemPublic> {
  isDisabled?: boolean;
  cbView?: (itemID: string) => void;
  cbEdit?: (itemID: string) => void;
  cbDelete?: (itemID: string) => void;
  load: () => void;
  renderItemPublic: RenderItemPublicFunction<ItemPublic>;
  ParamsItemView?: IParamsItemView;
  IDs: string[];
  set: Record<string, ItemPublic>;
  action?: IModerationAction;
  selection?: IModerationSelection;
}

export function ModerationItemsListMultiple<ItemPublic>(
  props: IModerationItemsListMultipleProps<ItemPublic>
) {
  const [selectedSet, setSelectedSet] = useState<Record<string, boolean>>({});
  useEffect(() => props.load(), []);
  useEffect(() => {
    if (props.selection?.selectedIDs) {
      const selectedSetLocal: Record<string, boolean> = {};
      props.selection.selectedIDs.forEach((selectedID: string) => {
        selectedSetLocal[selectedID] = true;
      });
      setSelectedSet(selectedSetLocal);
    } else {
      setSelectedSet({});
    }
  }, [props.selection?.selectedIDs]);
  const [isSelectable] = useState<boolean>(!!props.selection?.cbSelectedList);

  const handleOnSelect = (itemID: string): void => {
    if (!props.selection?.cbSelectedList) {
      return;
    }
    const selectedSetLocal = Object.assign({}, selectedSet);
    selectedSetLocal[itemID] = !selectedSetLocal[itemID];
    setSelectedSet(selectedSetLocal);
    props.selection.cbSelectedList(
      props.IDs.filter((id: string) => selectedSetLocal[id])
    );
  };

  return (
    <Block className="article">
      {props.IDs.map((itemID: string) => (
        <Block
          key={itemID}
          onClick={() => handleOnSelect(itemID)}
          className={
            'flex' +
            (selectedSet[itemID] ? ' selected' : '') +
            (props.action?.showInline ? ' inline' : '') +
            (isSelectable ? ' clickable' : '')
          }
        >
          {isSelectable && (
            <input
              type="checkbox"
              checked={selectedSet[itemID] || false}
              onChange={() => undefined}
            />
          )}

          <ModerationItem
            itemID={itemID}
            set={props.set}
            renderItemPublic={props.renderItemPublic}
            ParamsItemView={props.ParamsItemView}
            isDisabled={props.isDisabled}
            cbView={props.cbView}
            cbEdit={props.cbEdit}
            cbDelete={props.cbDelete}
            action={props.action}
          />
        </Block>
      ))}
    </Block>
  );
}
