import { ILotConst } from '../../interfaces/examples/ILot';

export const LotConstList: ILotConst[] = [
  {
    name: 'Stolen Spring',
    description: '',
    imageURL:
      'https://auction-space.fra1.cdn.digitaloceanspaces.com/zoola/products/auction-2/Olga_Myronenko/product-1/1.png',
    startPrice: 5000,
  },
  {
    name: 'Shells from Snake Island',
    description: '',
    imageURL:
      'https://auction-space.fra1.cdn.digitaloceanspaces.com/zoola/products/auction-2/Kseniya_Draganuk/product-1/1.png',
    startPrice: 2500,
  },
  {
    name: 'National flag from Bakhmut',
    description: '',
    imageURL:
      'https://auction-space.fra1.cdn.digitaloceanspaces.com/zoola/lots/auction-1/lot-flag.png',
    startPrice: 5000,
  },
];
