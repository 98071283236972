import styled from 'styled-components';

export const NotificationBlock = styled.div`
  max-height: 50vh;
  position: relative;
  overflow: auto;
`;
export const Item = styled.div`
  display: block;
`;

export const IconUnread = styled.img`
  width: auto;
  height: 15px;
  cursor: pointer;
`;
