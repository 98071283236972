import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  IState,
  IStateAnalyticsGroupExtended,
} from '../../interfaces/system/IState';
import { ChartsBlock } from './analytics.styles';
import { IBaseAnalyticsAggregatedModelCreate } from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import { AnalyticsChartExtended } from './AnalyticsChartExtended';
import { AnalyticsChartSimple } from './AnalyticsChartSimple';

interface IAnalyticsChartProps {
  items: IBaseAnalyticsAggregatedModelCreate<
    string | undefined,
    string | undefined
  >[];
  Extended?: IStateAnalyticsGroupExtended;
  cbExtendedLoad?: (value: null | string, param: null | string) => void;
  clientWidth: null | number;
}

function AnalyticsChart(props: IAnalyticsChartProps) {
  const [extendedView, setExtendedView] = useState<boolean>(false);

  return (
    <ChartsBlock>
      <AnalyticsChartExtended
        Extended={props.Extended}
        cbExtendedLoad={props.cbExtendedLoad}
        cbIsShow={setExtendedView}
      />

      {!extendedView && (
        <AnalyticsChartSimple
          items={props.items}
          clientWidth={props.clientWidth}
        />
      )}
    </ChartsBlock>
  );
}

export default connect(
  (state: IState) => ({
    clientWidth: state.System.clientWidth,
  }),
  {}
)(AnalyticsChart);
