import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import {
  IParamsItemView,
  RenderBatchFilterFunction,
  RenderItemPublicFunction,
  RenderItemViewFunction,
} from '../../../common/common';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import { DataBatchWide } from '../../../interfaces/system/data';
import {
  IEmailLogBatchFiltersParams,
  IEmailLogPublic,
  IEmailLogSet,
  IEmailLogView,
  IEmailMode,
} from '../../../interfaces/communication/IEmailLog';
import {
  loadEmailLogBatch,
  prepareBatchKeyEmailLog,
} from '../../../actions/communication/emailLog.actions';
import { EmailLogFilters } from '../../../components/communication/EmailLog/EmailLogFilters';
import { EmailLogModeration } from '../../../components/communication/EmailLog/EmailLogModeration';
import { ItemView } from '../../../common/moderation/ItemView';

interface ICommunicationLogsProps {
  loadEmailLogBatch: (
    mailingID: null | string,
    accountID: null | string,
    mode: null | IEmailMode
  ) => void;
  isAdmin: boolean;
  set: IEmailLogSet;
  byBatchKey: Record<string, DataBatchWide>;
  errors: string[];
}

function CommunicationLogs(props: ICommunicationLogsProps) {
  const [batchKey, setBatchKey] = useState<string>(
    prepareBatchKeyEmailLog(null, null, null)
  );
  const [filters, setFilters] = useState<IEmailLogBatchFiltersParams>({
    mailingID: null,
    accountID: null,
    mode: null,
  });

  useEffect(() => sendPageEvent('CommunicationLogs'), []);

  const loadBatch = (extraLimit?: number) => {
    if (!filters) {
      return;
    }

    props.loadEmailLogBatch(filters.mailingID, filters.accountID, filters.mode);
  };

  const renderBatchFilter: RenderBatchFilterFunction = () => {
    return (
      <EmailLogFilters
        cb={(batchKey, params) => {
          setBatchKey(batchKey);
          setFilters(params);
        }}
      />
    );
  };

  const renderItemPublic: RenderItemPublicFunction<IEmailLogPublic> = (
    item: IEmailLogPublic,
    inlineBlock?: JSX.Element,
    ParamsItemView?: IParamsItemView
  ) => {
    return (
      <EmailLogModeration
        emailLog={item}
        showInline={true}
        inlineBlock={inlineBlock}
        ParamsItemView={ParamsItemView}
      />
    );
  };

  const renderItemView: RenderItemViewFunction = (
    cb: (params: IEmailLogView) => void
  ) => {
    const defaultParams: IEmailLogView = {
      accountID: false,
      mode: true,
      action: true,
      isAllowed: true,
      messageID: false,
      mailingID: false,
      events: true,
      date: true,
    };

    return (
      <ItemView
        cb={(params: Record<string, boolean>) => cb(params as IEmailLogView)}
        defaultParam={defaultParams}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'Logs moderation'}
      itemName={'this mailing'}
      render={{
        renderItemPublic,
        renderItemView,
      }}
      data={{
        set: props.set,
        errors: props.errors,
        batch: {
          batchKey,
          byBatchKey: props.byBatchKey,
          loadBatch,
          renderBatchFilter,
        },
      }}
      action={{
        showInline: true,
      }}
      isAdmin={props.isAdmin}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    set: state.Communication.Log.set,
    byBatchKey: state.Communication.Log.byBatchKey,
    errors: state.System.errors,
  }),
  {
    loadEmailLogBatch,
  }
)(CommunicationLogs);
