import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ActionButton, BlockPage, BlockPageContent } from '../../common/styles';
import { ContentHead } from '../../common/typos';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { sendPageEvent } from '../../utils/analytics';
import { IState } from '../../interfaces/system/IState';
import { Block } from '../../common/layout';
import ModerationMainHead from '../../common/moderation/ModerationMainHead';

interface IModerationMainProps {
  // loadAdminIDs: () => void;
  // loadAccountsBatch: (loadOnce?: boolean) => void;
  // loadRecommendationIDs: () => void;
  // loadMailingIDs: () => void;
  isAdmin: boolean;
  // adminIDs: null | string[];
  // accountCnt: null | number;
  // mailingIDs: string[];
  // recommendationIDs: string[];
}

function ModerationMain(props: IModerationMainProps) {
  const navigation = useNavigate();

  useEffect(() => sendPageEvent('MainModeration'), []);
  // useEffect(() => {
  //   props.loadAdminIDs();
  //   props.loadAccountsBatch(true);
  //   props.loadMailingIDs();
  //   props.loadRecommendationIDs();
  // }, [props.isAdmin]);
  const goToCreateAuction = () => {
    navigation(appConfig.routes.Moderation.CreateAuction);
  };
  const goToCommunicationMailing = () => {
    navigation(appConfig.routes.Moderation.communication.mailing);
  };
  const goToCommunicationUsers = () => {
    navigation(appConfig.routes.Moderation.communication.users);
  };
  const goToCommunicationRecommendations = () => {
    navigation(appConfig.routes.Moderation.communication.recommendation);
  };
  const goToCommunicationLogs = () => {
    navigation(appConfig.routes.Moderation.communication.logs);
  };
  const goToCommunicationTemplateParams = () => {
    navigation(appConfig.routes.Moderation.communication.templateParams);
  };
  const goToPartnersAuction = () => {
    navigation(appConfig.routes.Moderation.partners.auction);
  };
  const goToPartnersFeedbacks = () => {
    navigation(appConfig.routes.Moderation.partners.feedbacks);
  };
  const goToPartnersLot = () => {
    navigation(appConfig.routes.Moderation.partners.lot);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />
        <Block>
          <ActionButton onClick={goToCreateAuction}>CreateAuction</ActionButton>
        </Block>
        <Block>
          <ContentHead>Partners:</ContentHead>
          {/*<ActionButton onClick={goToAdminsModeration}>
            Admins
            {props.adminIDs ? '(' + props.adminIDs.length + ')' : ''}
          </ActionButton>*/}

          <ActionButton onClick={goToPartnersAuction}>
            Auction{' '}
            {/*{props.accountCnt !== null ? '(' + props.accountCnt + ')' : ''}*/}
          </ActionButton>
          <ActionButton onClick={goToPartnersLot}>
            Lot {/*{props.mailingIDs && props.mailingIDs[0]*/}
            {/*  ? '(' + props.mailingIDs.length + ')'*/}
            {/*  : ''}*/}
          </ActionButton>
          <ActionButton onClick={goToPartnersFeedbacks}>
            Feedbacks{' '}
            {/*{props.recommendationIDs && props.recommendationIDs[0]*/}
            {/*  ? '(' + props.recommendationIDs.length + ')'*/}
            {/*  : ''}*/}
          </ActionButton>
        </Block>
        <Block>
          <ContentHead>Communication:</ContentHead>
          <ActionButton onClick={goToCommunicationMailing}>
            Send emails
          </ActionButton>
          <ActionButton onClick={goToCommunicationUsers}>
            Communicate with users
          </ActionButton>
          <ActionButton onClick={goToCommunicationRecommendations}>
            Make recommendations
          </ActionButton>
          <ActionButton onClick={goToCommunicationLogs}>
            See email logs
          </ActionButton>
          <ActionButton onClick={goToCommunicationTemplateParams}>
            Prepare Email Templates
          </ActionButton>
        </Block>
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
  }),
  {}
)(ModerationMain);
