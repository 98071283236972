import { css } from 'styled-components';

export const colors = {
  background: '#EFECEC',
  backgroundDark: '#D5D2D2FF',

  win: 'darkgreen',
  lost: 'darkred',
  danger: 'darkred',

  main: '#00253e',
  greyBorder: '#ccc',

  action: '#FFEC3D',
  error: '#8B0000',

  palette: ['#00253e', '#99181a', '#ce10b5', '#89adf9', '#16c95d'],
};

export const shadow = css`
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 0, rgba(0, 0, 0, 0.1) 0 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
`;
export const shadowS = css`
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 0, rgba(0, 0, 0, 0.1) 0 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
`;
