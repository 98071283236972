import React from 'react';
import { connect } from 'react-redux';
import TopAccount from './TopAccount';
import { IReportTopBidder } from '../../interfaces/examples/IReportAuction';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { Icon } from '../landing/Button.styles';

interface IAuctionResultProps {
  topBidders: IReportTopBidder[];
}

function TopBidders(props: IAuctionResultProps) {
  return (
    <>
      {props.topBidders.map((bidder: IReportTopBidder, index: number) => (
        <ContentDesc key={index}>
          <TopAccount accountID={bidder.accountID} />
          <ContentDescInline>{bidder.count}</ContentDescInline>
          {index < 3 && <Icon src={'/icons/medal-scratch.png'} />}
        </ContentDesc>
      ))}
    </>
  );
}

export default connect(() => ({}), {})(TopBidders);
