import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { ImageLoader } from './ImageLoader';
import { Image, ImagesLoaderBlock, ImagesLoaderItem } from './Image.styles';

interface ImagesLoaderProps {
  isDisabled?: boolean;
  limit: number;
  cbImages: (imageBase64: string[]) => void;
  imagesToEdit: null | string[];
  isDraggable: boolean;
  hardReset?: boolean;
  autoSelect?: boolean;
  noCrop?: boolean;
}

export function ImagesLoader(props: ImagesLoaderProps) {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>(['']);

  useEffect(() => {
    if (props.imagesToEdit && props.imagesToEdit[0]) {
      resetImages(props.imagesToEdit);
    } else if (props.hardReset) {
      resetImages(['']);
    }
  }, [props.imagesToEdit, props.hardReset]);

  const handleImage = (imagesBase64: null | string, index: number = 0) => {
    let imagesLocal: string[] = [...images];
    if (imagesBase64) {
      imagesLocal[index] = imagesBase64;
    } else {
      imagesLocal.splice(index, 1);
    }

    props.cbImages(imagesLocal);
    resetImages(imagesLocal);
  };

  const resetImages = (imageList?: string[]) => {
    let imagesLocal: string[] = imageList ? [...imageList] : [...images];
    imagesLocal = imagesLocal.filter((img: string) => img !== '');
    if (imagesLocal.length < props.limit) {
      imagesLocal.push('');
    }

    resetIsDraggable(imagesLocal);
    setImages(imagesLocal);
  };

  const reSort = (fromIndex: number, toIndex: number) => {
    const imagesLocal: string[] = [...images];
    const item = imagesLocal.splice(fromIndex, 1)[0];
    imagesLocal.splice(toIndex, 0, item);

    props.cbImages(imagesLocal);
    resetImages(imagesLocal);
  };

  const resetIsDraggable = (imagesLocal: string[]) => {
    if (!props.isDraggable) {
      return;
    }

    setIsDraggable(imagesLocal.filter((img: string) => img !== '').length > 1);
  };
  return (
    <ImagesLoaderBlock>
      {(images && (
        <ReactDragListView
          onDragEnd={reSort}
          nodeSelector={'.handle'}
          handleSelector={'a'}
        >
          {images.map((image, index) => (
            <ImagesLoaderItem key={index} className="handle">
              {isDraggable && image !== '' && (
                <a href="#">Drag to change sort</a>
              )}

              {image !== '' && (
                <Image src={image} className="XL cropped-img" alt="" />
              )}
              <ImageLoader
                isDisabled={props.isDisabled}
                image={image}
                cbImage={(img) => handleImage(img, index)}
                allowRemove={true}
                key={index}
                autoSelect={props.autoSelect}
                noCrop={props.noCrop}
              />
            </ImagesLoaderItem>
          ))}
        </ReactDragListView>
      )) || (
        <ImageLoader
          cbImage={(img) => handleImage(img)}
          allowRemove={true}
          autoSelect={props.autoSelect}
          noCrop={props.noCrop}
        />
      )}
    </ImagesLoaderBlock>
  );
}
