import React from 'react';
import { BlockActions, ButtonMain } from '../styles';
import { ContentBold, ContentDesc } from '../typos';

interface IFormRemoveReAskProps {
  title?: string;
  cbCancel: () => void;
  cbDelete?: () => void;
}

export function FormRemoveReAsk(props: IFormRemoveReAskProps) {
  return (
    <BlockActions>
      <ContentDesc>
        Are you sure want to remove <ContentBold>{props.title}</ContentBold>?
      </ContentDesc>
      <ButtonMain onClick={props.cbDelete} className='fail'>
        Remove
      </ButtonMain>
      <ButtonMain onClick={props.cbCancel} className='success'>
        Cancel
      </ButtonMain>
    </BlockActions>
  );
}
