import { apiConfig } from '../../config/api.config';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import {
  ANALYTICS_DB_LOAD,
  ANALYTICS_DB_LOAD_BY_PLATFORM,
  ANALYTICS_DB_LOAD_CACHE,
  ANALYTICS_DB_LOAD_CACHE_ALL,
  ANALYTICS_DB_LOAD_EXTENDED,
  ANALYTICS_DB_LOAD_OPTIONS,
} from '../types';
import {
  IBaseAnalyticsAggregatedModelGetByPlatformResponse,
  IBaseAnalyticsAggregatedModelGetCacheAllResponse,
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetExtendedData,
  IBaseAnalyticsAggregatedModelGetOptionsResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  loadAnalyticsCacheAllWrapper,
  loadAnalyticsCacheWrapper,
  loadAnalyticsExtendedWrapper,
  loadAnalyticsMainByPlatformWrapper,
  loadAnalyticsMainWrapper,
  loadAnalyticsOptionsWrapper,
  saveAnalyticsCacheByPlatformWrapper,
  saveAnalyticsCacheWrapper,
} from './analyticsWrapper.actions';
import { IPlatformType } from '../../config/platform.config';

const { main, platform, extended, options, cache, cacheAll } =
  apiConfig.endpoints.analytics.techDb;

export const loadAnalyticsDbMain =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      IBaseAnalyticsAggregatedModelGetResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainWrapper(
      main,
      ANALYTICS_DB_LOAD,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsDbMainByPlatform =
  (platformType: IPlatformType, from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetByPlatformResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainByPlatformWrapper(
      platform,
      ANALYTICS_DB_LOAD_BY_PLATFORM,
      platformType,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsDbExtended =
  (from: string, to: string, value: null | string, param: null | string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetExtendedData
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsExtendedWrapper(
      extended,
      ANALYTICS_DB_LOAD_EXTENDED,
      from,
      to,
      value,
      param
    )(dispatch, getState);
  };

export const loadAnalyticsDbOptions =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetOptionsResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsOptionsWrapper(
      options,
      ANALYTICS_DB_LOAD_OPTIONS,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsDbCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheWrapper(
      cache,
      ANALYTICS_DB_LOAD_CACHE,
      hardReload
    )(dispatch, getState);
  };

export const loadAnalyticsDbCacheAll =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheAllResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheAllWrapper(
      cacheAll,
      ANALYTICS_DB_LOAD_CACHE_ALL,
      hardReload
    )(dispatch, getState);
  };

export const saveTechDbCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheWrapper(cache)(dispatch, getState);
  };

export const saveTechDbCacheByPlatform =
  (platformType: IPlatformType) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheByPlatformWrapper(cacheAll, platformType)(
      dispatch,
      getState
    );
  };
