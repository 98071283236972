import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IState } from '../../interfaces/system/IState';
import { Block, CarouselBlock } from '../../common/layout';
import Preview from './Preview';
import {
  IProductPreview,
  IProductPreviewSet,
} from '../../interfaces/shop/IProduct';
import { IPlatforms, IPlatformType } from '../../config/platform.config';

interface IPreviewCarouselProps {
  platformType: IPlatformType;
  noView: boolean;
  hideAnalytics?: boolean;
  previewIDs: string[];

  setByPlatform: IPlatforms<IProductPreviewSet>;
  // byPreviewID: Record<string, IProductPreview>;
}

function PreviewCarousel(props: IPreviewCarouselProps) {
  const [previews, setPreviews] = useState<IProductPreview[]>([]);

  useEffect(() => {
    const previewsLocal: IProductPreview[] = [];
    const set: IProductPreviewSet = props.setByPlatform[props.platformType];
    props.previewIDs.forEach((previewID: string) => {
      const preview: null | IProductPreview = set[previewID] || null;
      if (preview) {
        previewsLocal.push(preview);
      }
      setPreviews(previewsLocal);
    });
  }, [props.platformType, props.previewIDs, props.setByPlatform]);

  return (
    <CarouselBlock>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        infiniteLoop={true}
        showArrows={true}
      >
        {previews[0] &&
          previews.slice(0, 10).map((preview: IProductPreview) => (
            <Block key={preview.id} className={'left'}>
              <Preview
                noView={props.noView}
                hideAnalytics={props.hideAnalytics}
                fullWidth={true}
                preview={preview}
                cnt={1}
              />
            </Block>
          ))}
      </Carousel>
    </CarouselBlock>
  );
}

export default connect(
  (state: IState) => ({
    setByPlatform: state.Shop.preview.setByPlatform,
  }),
  {}
)(PreviewCarousel);
