import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ActionLink, BlockPage, BlockPageContent } from '../../common/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { sendPageEvent } from '../../utils/analytics';
import { IState } from '../../interfaces/system/IState';
import { Loading } from '../../common/animation/Loading';
import Auction from '../../components/auctions/Auction';
import { IAppAuctionPublic } from '../../interfaces/auctions/IAppAuction';
import ReportLots from '../../components/auctions/ReportLots';
import { loadPartnerAuctionIDs } from '../../actions/partners/partnerAuction.actions';
import {
  IAppPartnerAuctionPublic,
  IAppPartnerAuctionSet,
} from '../../interfaces/partners/IAppPartnerAuction';
import { appConfig } from '../../config/app.config';
import { PartnersAuctionPublic } from '../../components/partners/PartnersAuctionPublic';
import { loadAuctionIDs } from '../../actions/auctions/auction.actions';
import { ContentDescInline, ContentHead } from '../../common/typos';
import { Block, BlockHead } from '../../common/layout';
import { IPlatformType, validatePlatform } from '../../config/platform.config';
import { L } from '../../common/locale';

interface IPartnerAuctionPageProps {
  loadPartnerAuctionIDs: (hardReload?: boolean) => void;
  loadAuctionIDs: () => void;
  IDs: string[];
  set: IAppPartnerAuctionSet;
  completed: IAppAuctionPublic[];
  upcoming: IAppAuctionPublic[];
}

function PartnerAuctionPage(props: IPartnerAuctionPageProps) {
  const navigate = useNavigate();
  const { platformPath } = useParams();

  const [partner, setPartner] = useState<null | IAppPartnerAuctionPublic>(null);
  const [auctionsUpcoming, setAuctionsUpcoming] = useState<IAppAuctionPublic[]>(
    []
  );
  const [auctionsCompleted, setAuctionsCompleted] = useState<
    IAppAuctionPublic[]
  >([]);
  const [platformType, setPlatformType] = useState<null | IPlatformType>(null);

  useEffect(() => sendPageEvent('Partner Auction'), []);
  useEffect(() => props.loadPartnerAuctionIDs(), []);
  useEffect(() => props.loadAuctionIDs(), []);
  useEffect(
    () => setPlatformType(validatePlatform(platformPath || null)),
    [platformPath]
  );

  useEffect(() => {
    if (!platformPath) {
      navigate(appConfig.routes.partners.list);
      return;
    }

    let partnerLocal: null | IAppPartnerAuctionPublic = null;
    props.IDs.forEach((partnerID: string) => {
      const i: null | IAppPartnerAuctionPublic = props.set[partnerID];
      if (i && i.platformType === platformPath) {
        partnerLocal = i;
      }
    });

    if (!partnerLocal) {
      return;
    }

    setPartner(partnerLocal);
  }, [props.IDs, props.set]);
  useEffect(() => {
    if (!platformPath) {
      navigate(appConfig.routes.partners.list);
      return;
    }

    const auctionsUpcomingLocal: IAppAuctionPublic[] = [];
    props.upcoming.forEach((auction: IAppAuctionPublic) => {
      if (auction.platformType === platformPath) {
        auctionsUpcomingLocal.push(auction);
      }
    });
    const auctionsCompletedLocal: IAppAuctionPublic[] = [];
    props.completed.forEach((auction: IAppAuctionPublic) => {
      if (auction.platformType === platformPath) {
        auctionsCompletedLocal.push(auction);
      }
    });

    setAuctionsUpcoming(auctionsUpcomingLocal);
    setAuctionsCompleted(auctionsCompletedLocal);
  }, [props.completed, props.upcoming]);

  const navigateToPartnerList = () => {
    navigate(appConfig.routes.partners.list);
  };
  return (
    <BlockPage>
      <ActionLink onClick={navigateToPartnerList}>
        <L k={'ActionLandingPartners'} />
      </ActionLink>

      {partner && (
        <BlockPageContent>
          <PartnersAuctionPublic partner={partner} hideButtons={true} />

          {auctionsUpcoming[0] && (
            <>
              <ContentHead className="success">
                <ContentDescInline style={{ fontSize: '2rem' }}>
                  •
                </ContentDescInline>
                <L k={'AuctionsTitleUpcomingList'} />
              </ContentHead>
              {auctionsUpcoming.map((auction: IAppAuctionPublic) => (
                <Auction
                  auction={auction}
                  key={auction.id}
                  hidePartnerNavigation={true}
                />
              ))}
            </>
          )}

          {auctionsCompleted[0] && (
            <>
              <ContentHead>
                <L k={'AuctionsTitleCompleted'} />
              </ContentHead>
              {auctionsCompleted.map((auction: IAppAuctionPublic) => (
                <Auction
                  auction={auction}
                  key={auction.id}
                  hidePartnerNavigation={true}
                />
              ))}
            </>
          )}

          {platformType && (
            <Block>
              <BlockHead>
                <L k={'ReportsTopLotTotal'} />
              </BlockHead>
              <ReportLots
                auction={null}
                platformType={platformType}
                platformAuctionID={null}
                lotType={null}
                mode="carousel"
                onlyList={true}
              />
            </Block>
          )}
        </BlockPageContent>
      )}

      {!partner && <Loading size={'big'} />}
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    IDs: state.Partners.Auction.IDs,
    set: state.Partners.Auction.set,
    upcoming: state.Auctions.auctions.upcoming,
    completed: state.Auctions.auctions.completed,
  }),
  {
    loadAuctionIDs,
    loadPartnerAuctionIDs,
  }
)(PartnerAuctionPage);
