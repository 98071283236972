import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IState,
  IStateAnalyticsGroup,
  IStateAnalyticsGroupExtended,
} from '../../interfaces/system/IState';
import { sendPageEvent } from '../../utils/analytics';
import AnalyticsChart from '../../components/analytics/AnalyticsChart';
import { Loading } from '../../common/animation/Loading';
import { ActionLink, BlockPage, BlockPageContent } from '../../common/styles';
import AnalyticsMainHead from '../../common/moderation/AnalyticsMainHead';
import {
  loadAnalyticsMain,
  loadAnalyticsOptions,
} from '../../actions/analytics/analyticsAll.actions';
import { ChartCalendar } from '../../components/analytics/ChartCalendar';
import { BlockView } from '../../common/moderation/BlockView';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { loadAnalyticsDbExtended } from '../../actions/analytics/techDb.actions';
import { loadAnalyticsRouteExtended } from '../../actions/analytics/techRoute.actions';

interface ITechAnalyticsProps {
  loadAnalyticsMain: (from: string, to: string) => void;
  loadAnalyticsDbExtended: (
    from: string,
    to: string,
    value: null | string,
    param: null | string
  ) => void;
  loadAnalyticsRouteExtended: (
    from: string,
    to: string,
    value: null | string,
    param: null | string
  ) => void;
  loadAnalyticsOptions: (from: string, to: string) => void;
  retention: null | IStateAnalyticsGroup;
  cron: null | IStateAnalyticsGroup;
  db: null | IStateAnalyticsGroup;
  dbExtended: IStateAnalyticsGroupExtended;
  route: null | IStateAnalyticsGroup;
  routeExtended: IStateAnalyticsGroupExtended;
}

function TechAnalytics(props: ITechAnalyticsProps) {
  const [isShowAll, setIsShowAll] = useState<boolean>();
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();

  useEffect(() => sendPageEvent('TechAnalytics'), []);
  useEffect(() => {
    if (!from || !to) {
      return;
    }

    props.loadAnalyticsOptions(from, to);
    props.loadAnalyticsMain(from, to);
  }, [from, to]);

  const loadAnalyticsDbExtended = (
    value: null | string,
    param: null | string
  ) => {
    if (!from || !to) {
      return;
    }

    props.loadAnalyticsDbExtended(from, to, value, param);
  };
  const loadAnalyticsRouteExtended = (
    value: null | string,
    param: null | string
  ) => {
    if (!from || !to) {
      return;
    }

    props.loadAnalyticsRouteExtended(from, to, value, param);
  };

  const saveCalendar = (fromLocal: string, toLocal: string): void => {
    setFrom(fromLocal);
    setTo(toLocal);
  };

  const hideAll = (): void => {
    setIsShowAll(false);
  };
  const showAll = (): void => {
    setIsShowAll(true);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <AnalyticsMainHead />

        <ChartCalendar cb={saveCalendar} />

        {(!props.route || !props.db || !props.cron || !props.retention) && (
          <Loading size={'big'} />
        )}

        <ContentDesc>
          {isShowAll === false ? (
            <ContentDescInline>hide all</ContentDescInline>
          ) : (
            <ActionLink onClick={hideAll}>hide all</ActionLink>
          )}{' '}
          {isShowAll === true || isShowAll === undefined ? (
            <ContentDescInline>show all</ContentDescInline>
          ) : (
            <ActionLink onClick={showAll}>show all</ActionLink>
          )}
        </ContentDesc>

        {props.retention && (
          <BlockView
            title={'Counts retention'}
            default={isShowAll}
            element={<AnalyticsChart items={props.retention.items} />}
          />
        )}
        {props.route && (
          <BlockView
            title={'Time by route'}
            default={isShowAll}
            element={
              <AnalyticsChart
                items={props.route.items}
                Extended={props.routeExtended}
                cbExtendedLoad={loadAnalyticsRouteExtended}
              />
            }
          />
        )}
        {props.cron && (
          <BlockView
            title={'Time by cron'}
            default={isShowAll}
            element={<AnalyticsChart items={props.cron.items} />}
          />
        )}
        {props.db && (
          <BlockView
            title={'Time by db'}
            default={isShowAll}
            element={
              <AnalyticsChart
                items={props.db.items}
                Extended={props.dbExtended}
                cbExtendedLoad={loadAnalyticsDbExtended}
              />
            }
          />
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    retention: state.Analytics.retentionGroup.main,
    cron: state.Analytics.cronGroup.main,
    db: state.Analytics.dbGroup.main,
    route: state.Analytics.routeGroup.main,
    dbExtended: state.Analytics.dbGroup.extended,
    routeExtended: state.Analytics.routeGroup.extended,
  }),
  {
    loadAnalyticsMain,
    loadAnalyticsDbExtended,
    loadAnalyticsRouteExtended,
    loadAnalyticsOptions,
  }
)(TechAnalytics);
