import styled from 'styled-components';
import { ContentDesc, fonts, typos } from '../../common/typos';
import { colors } from '../../common/colors';
import { transitions } from '../../common/styles';
import { margins, sizes, sizesBorder } from '../../common/sizes';

export const LotSellerText = styled(ContentDesc)`
  background-color: ${colors.background};
  color: black;
  border-radius: ${sizesBorder.radius};
  padding: 2px !important;
  display: inline-block;
  text-align: right;
  margin-top: -20px !important;
  margin-left: -3px;
  position: absolute;
`;
export const LotBlock = styled.div`
  display: block;
  width: ${sizes.full};
  max-width: ${sizes.widthXL};
  float: left;
`;

export const BidBlock = styled.div`
  margin: ${margins.block} 0;
`;

export const BidStatus = styled.span`
  ${typos.name};
  float: right;
  font-weight: ${fonts.weightM};

  &.win {
    color: ${colors.win};
    transition: ${transitions.easy};
  }

  &.lost {
    color: ${colors.lost};
    transition: ${transitions.easy};
  }
`;

export const BidPriceUSD = styled.span`
  ${typos.name};
  font-weight: ${fonts.weightXS};
`;
