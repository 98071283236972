import styled from 'styled-components';
import { margins } from '../common/sizes';

export const FooterBlock = styled.div`
  display: block;
  float: left;
  margin-top: ${margins.blockX2};
  width: 100%;
`;
export const FooterBlockItem = styled.div`
  display: inline-block;
  float: left;
  margin-right: ${margins.item};
`;
