import React, { useEffect, useState } from 'react';
import { IStateAnalyticsGroupExtended } from '../../interfaces/system/IState';
import { ContentDesc } from '../../common/typos';
import {
  IBaseAnalyticsAggregatedModelCreate,
  IBaseAnalyticsAggregatedModelCreateByDate,
  IBaseAnalyticsAggregatedModelCreateItem,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import { Switch } from '../../common/forms/Switch';
import { FormFieldSelect } from '../../common/forms/FormFieldSelect';
import { Block, BlockHalf } from '../../common/layout';
import { Loading } from '../../common/animation/Loading';
import AnalyticsChart from './AnalyticsChart';
import { AnalyticsDescriptionItems } from './AnalyticsDescriptionItems';
import { IElementOrder, IItemsOrder } from './AnalyticsDescriptionItemsElement';

interface IAnalyticsDescriptionExtendedProps {
  Extended?: IStateAnalyticsGroupExtended;
  cbExtendedLoad?: (value: null | string, param: null | string) => void;
  cbIsShow: (isShowLocal: boolean) => void;
  itemOrder: IItemsOrder;
  elementOrder: IElementOrder;
}

export function AnalyticsDescriptionExtended(
  props: IAnalyticsDescriptionExtendedProps
) {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [value, setValue] = useState<null | string>(null);
  const [param, setParam] = useState<null | string>(null);
  const [items, setItems] = useState<
    | null
    | IBaseAnalyticsAggregatedModelCreate<
        string | undefined,
        string | undefined
      >[]
  >(null);

  useEffect(() => {
    if (!props.cbExtendedLoad || (!value && !param)) {
      return;
    }

    props.cbExtendedLoad(value, param);
  }, [value, param]);
  useEffect(() => {
    if (!props.Extended) {
      return;
    }

    const composite = `${value}-${param}`;
    const itemsSource:
      | null
      | IBaseAnalyticsAggregatedModelCreate<
          string | undefined,
          string | undefined
        >[] =
      (props.Extended.byValueByParam[composite] &&
        props.Extended.byValueByParam[composite].items) ||
      null;

    const itemsLocal: IBaseAnalyticsAggregatedModelCreate<
      string | undefined,
      string | undefined
    >[] = [];

    const setByDate: IBaseAnalyticsAggregatedModelCreateByDate<
      string | undefined,
      string | undefined
    > = {};
    if (itemsSource) {
      itemsSource.forEach(({ date, items, param, value }) => {
        if (!setByDate[date]) {
          setByDate[date] = [];
        }
        items.forEach(
          (
            item: IBaseAnalyticsAggregatedModelCreateItem<
              string | undefined,
              string | undefined
            >
          ) => {
            setByDate[date].push({ ...item, value, param });
          }
        );
      });
    }

    if (itemsSource) {
      Object.keys(setByDate).forEach((date: string) => {
        itemsLocal.push({
          date,
          items: setByDate[date],
        });
      });
    }

    setItems(itemsLocal[0] ? itemsLocal : null);
  }, [value, param, props.Extended]);

  const selectExtendedValue = (values: string[]) => {
    const value: string = values[0];
    setValue(value === 'all' ? null : value);
  };

  const selectExtendedParam = (params: string[]) => {
    const param: string = params[0];
    setParam(param === 'all' ? null : param);
  };

  const saveIsShow = (isShowLocal: boolean) => {
    setIsShow(isShowLocal);
    props.cbIsShow(isShowLocal);
  };

  return (
    <Block>
      {props.Extended && props.Extended.options && (
        <>
          <Switch isChecked={isShow} option={'extended'} cb={saveIsShow} />
          {isShow && (
            <>
              <BlockHalf>
                <>
                  <ContentDesc>Select value:</ContentDesc>
                  {props.Extended.options.values && (
                    <FormFieldSelect
                      isMultiple={false}
                      defaultValue={value ? [value] : null}
                      nullNameBeforeSelect={'all'}
                      saveValue={selectExtendedValue}
                      values={props.Extended.options.values}
                      valueError={null}
                    />
                  )}
                </>
              </BlockHalf>
              <BlockHalf>
                <>
                  <ContentDesc>Select param:</ContentDesc>
                  {props.Extended.options.params && (
                    <FormFieldSelect
                      isMultiple={false}
                      defaultValue={param ? [param] : null}
                      nullNameBeforeSelect={'all'}
                      saveValue={selectExtendedParam}
                      values={props.Extended.options.params}
                      valueError={null}
                    />
                  )}
                </>
              </BlockHalf>

              {(value || param) && !items && <Loading />}
              {items && (
                <AnalyticsDescriptionItems
                  items={items}
                  itemOrder={props.itemOrder}
                  elementOrder={props.elementOrder}
                />
              )}
            </>
          )}
        </>
      )}
    </Block>
  );
}
