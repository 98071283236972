import { LotConstList } from '../../config/examples/static-lots';
import { loadBidsByLot, makeRate } from './bid.actions';
import { IBid, IBidData } from '../../interfaces/examples/IBid';
import {
  ILotConst,
  ILotConstExtended,
  ILotShort,
  ILotType,
} from '../../interfaces/examples/ILot';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { EXAMPLE_LOT_LOAD, EXAMPLE_REPORT_LOT_LOAD } from './typesExample';
import { getRandomAccount } from './users.actions';
import { IReportLot } from '../../interfaces/examples/IReportLot';

let LotInd: number = 0;

function getLotNext(): null | ILotConstExtended {
  const lot: ILotConst = LotConstList[LotInd];

  LotInd++;
  return lot
    ? {
        id: `lot-${LotInd}`,
        ...lot,
      }
    : null;
}

export const loadLot =
  () =>
  (
    dispatch: IDispatch<null | IBidData | ILotShort>,
    getState: () => IState
  ) => {
    const lotConst: null | ILotConstExtended = getLotNext();
    if (!lotConst) {
      dispatch({ type: EXAMPLE_LOT_LOAD, payload: null });
      return;
    }

    loadBidsByLot(lotConst.id, lotConst.startPrice, (bids: IBid[]) => {
      const lastBid: IBid = bids[0];

      const lot: ILotShort = {
        id: lotConst.id,
        lotType: 'Live',
        name: lotConst.name,
        description: lotConst.description,
        imageURL: lotConst.imageURL,
        sellerID: getRandomAccount().id,
        price: lastBid.price,
        startPrice: lotConst.startPrice,
        rate: makeRate(lastBid.price),
        winCnt: 1,
      };

      dispatch({ type: EXAMPLE_LOT_LOAD, payload: lot });
    })(dispatch, getState);
  };

export const adminLotWin =
  (lotType: ILotType) =>
  (dispatch: IDispatch<IReportLot>, getState: () => IState) => {
    const { currentLotID, lotByID, bidsByLotID } = getState().Example;
    const lotID: string = currentLotID || '';
    const bids: IBid[] = bidsByLotID[lotID];
    const bidLast: IBid = bids[0];
    const lot: ILotShort = lotByID[lotID || ''];
    const sellerID = lot.sellerID;
    const buyerID = bidLast.accountID;
    const price: number = lot.price;
    const report: IReportLot = {
      id: '',
      lotID,
      lotType,
      name: lot.name,
      imageURL: lot.imageURL,
      Total: price,
      price,
      startPrice: lot.startPrice,
      winCnt: 1,
      bidCnt: bids.length,
      sellerID,
      buyerList: [
        {
          buyerID,
          price,
        },
      ],
    };

    dispatch({ type: EXAMPLE_REPORT_LOT_LOAD, payload: report });
  };
