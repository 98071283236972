import { IBid } from '../../interfaces/examples/IBid';
import { ILotBuyer } from '../../interfaces/examples/ILot';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { EXAMPLE_REPORT_AUCTION_LOAD } from './typesExample';
import { IReportLot } from '../../interfaces/examples/IReportLot';
import { IReportAuction } from '../../interfaces/examples/IReportAuction';

export const sendReportAuction =
  () => (dispatch: IDispatch<IReportAuction>, getState: () => IState) => {
    const { bidsByLotID, reportLots } = getState().Example;

    const reportAuction: IReportAuction = {
      total: 0,
      bidCnt: 0,
      lotCnt: reportLots.length,
      followers: 0,
      topSellers: [],
      topDonators: [],
      topBidders: [],
      // week
      topValuable: null,
      topPopular: null,
      updatedAt: '',
    };

    const sellerSet: Record<string, number> = {};
    const donatorSet: Record<string, number> = {};
    const bidderSet: Record<string, number> = {};
    reportLots.forEach((reportLot: IReportLot) => {
      const { sellerID, Total, buyerList } = reportLot;
      reportAuction.total += Total;
      sellerSet[sellerID] = (sellerSet[sellerID] || 0) + Total;
      if (buyerList) {
        buyerList.forEach((buyer: ILotBuyer) => {
          donatorSet[buyer.buyerID] = buyer.price;
        });
      }
    });

    Object.keys(bidsByLotID).forEach((lotID: string) => {
      bidsByLotID[lotID].forEach((bid: IBid) => {
        const { accountID } = bid;
        bidderSet[accountID] = (bidderSet[accountID] || 0) + 1;
        reportAuction.bidCnt++;
      });
    });

    reportAuction.topSellers = Object.keys(sellerSet).map(
      (accountID: string) => ({
        accountID,
        total: sellerSet[accountID],
      })
    );
    reportAuction.topDonators = Object.keys(donatorSet).map(
      (accountID: string) => ({
        accountID,
        donated: donatorSet[accountID],
      })
    );
    reportAuction.topBidders = Object.keys(bidderSet).map(
      (accountID: string) => ({
        accountID,
        count: bidderSet[accountID],
      })
    );

    reportAuction.topSellers = reportAuction.topSellers.sort((a, b) =>
      a.total > b.total ? -1 : 1
    );
    reportAuction.topDonators = reportAuction.topDonators.sort((a, b) =>
      a.donated > b.donated ? -1 : 1
    );
    reportAuction.topBidders = reportAuction.topBidders
      .sort((a, b) => (a.count > b.count ? -1 : 1))
      .slice(0, 5);

    dispatch({ type: EXAMPLE_REPORT_AUCTION_LOAD, payload: reportAuction });
  };

export const loadNextLot =
  () => (dispatch: IDispatch<null>, getState: () => IState) => {
    dispatch({ type: EXAMPLE_REPORT_AUCTION_LOAD, payload: null });
  };
