import React from 'react';
import { ContentDesc, ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAppPartnerAuctionPublic } from '../../interfaces/partners/IAppPartnerAuction';
import { Image } from '../../common/images/Image.styles';

interface IPartnersAuctionItemModerationProps {
  partner: IAppPartnerAuctionPublic;
}

export function PartnersAuctionItemModeration(
  props: IPartnersAuctionItemModerationProps
) {
  const partner: IAppPartnerAuctionPublic = props.partner;

  return (
    <Block>
      <Image className={'L'} src={partner.imageURL} alt={partner.name} />
      <ContentHead>{partner.name}</ContentHead>
      <ContentDesc>{partner.platformType}</ContentDesc>
      <ContentDesc>{partner.description}</ContentDesc>
      <ContentDesc>{partner.hostAuction}</ContentDesc>
      <ContentDesc>{partner.hostAuctionSub}</ContentDesc>
      <ContentDesc>{partner.hostCompany}</ContentDesc>
    </Block>
  );
}
