import { appConfig } from './app.config';

const hostName: string = appConfig.isDev
  ? 'http://localhost:3002'
  : 'https://auction-api.create.auction';

export const apiConfig = Object.freeze({
  hostName,
  host: `${hostName}/v1/`,
  socketHost: `${hostName}`,
  endpoints: {
    CreateAuction: {
      main: 'app/CreateAuction/main',
    },
    leads: {
      main: 'app/leads/main',
    },
    auctions: {
      ids: 'app/auctions/ids',
      set: 'app/auctions/set',
    },
    analytics: {
      retention: {
        main: 'analytics/retention/main',
      },
      techRoute: {
        main: 'analytics/tech/route/main',
        platform: 'analytics/tech/route/platform',
        extended: 'analytics/tech/route/extended',
        options: 'analytics/tech/route/options',
        cache: 'analytics/tech/route/cache',
        cacheAll: 'analytics/tech/route/cache/all',
      },
      techCron: {
        main: 'analytics/tech/cron/main',
        platform: 'analytics/tech/cron/platform',
        cache: 'analytics/tech/cron/cache',
        cacheAll: 'analytics/tech/cron/cache/all',
      },
      techDb: {
        main: 'analytics/tech/db/main',
        platform: 'analytics/tech/db/platform',
        extended: 'analytics/tech/db/extended',
        options: 'analytics/tech/db/options',
        cache: 'analytics/tech/db/cache',
        cacheAll: 'analytics/tech/db/cache/all',
      },
      behavioralRetention: {
        main: 'analytics/behavioral/retention/main',
        platform: 'analytics/behavioral/retention/platform',
        cache: 'analytics/behavioral/retention/cache',
        cacheAll: 'analytics/behavioral/retention/cache/all',
        online: 'analytics/behavioral/retention/online',
        onlinePlatform: 'analytics/behavioral/retention/online/platform',
      },
    },
    reportLot: {
      batch: 'app/report/lot/batch',
      set: 'app/report/lot/set',
      search: 'app/report/lot/search',
    },
    account: {
      main: 'account/main',
      auth: {
        token: 'account/auth',
        tokenGoogle: 'account/auth/google/token',
        google: 'account/auth/google',
      },
      notifications: {
        ids: 'account/notification/ids',
        one: 'account/notification/one',
        main: 'account/notification',
        moderation: 'account/notification/moderation',
        moderationList: 'account/notification/moderation/get',
      },
      set: 'account/users',
      search: 'account/search',
      batch: 'account/batch',
    },
    partners: {
      partnerAuction: {
        main: 'app/partner/auction',
        ids: 'app/partner/auction/ids',
      },
      partnerFeedbacks: {
        main: 'app/partner/feedbacks',
        ids: 'app/partner/feedbacks/ids',
      },
      partnerLot: {
        main: 'app/partner/lot',
        ids: 'app/partner/lot/ids',
      },
    },
    shop: {
      productMain: 'app/shop/product/main',
      productBatch: 'app/shop/product/batch',
      productDetails: 'app/shop/product/details',
      productAnalytics: 'app/shop/product/analytics',
    },
    communication: {
      recommendation: {
        main: 'app/communication/recommendation/main',
        bulk: 'app/communication/recommendation/bulk',
        ids: 'app/communication/recommendation/ids',
        one: 'app/communication/recommendation/one',
        set: 'app/communication/recommendation/set',
        batch: 'app/communication/recommendation/batch',
        search: 'app/communication/recommendation/search',
      },
      mailing: {
        main: 'communication/mailing',
        ids: 'communication/mailing/ids',
        one: 'communication/mailing/one',
        settings: 'communication/mailing/settings',
        batch: 'communication/mailing/batch',
        preview: 'communication/mailing/preview',
      },
      log: {
        ids: 'communication/log/ids',
        set: 'communication/log/set',
        batch: 'communication/log/batch',
      },
      templateParams: {
        main: 'communication/template/params/main',
        ids: 'communication/template/params/ids',
        one: 'communication/template/params/one',
        set: 'communication/template/params/set',
        batch: 'communication/template/params/batch',
        search: 'communication/template/params/search',
      },
    },
  },
});
