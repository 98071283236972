import React, { useEffect, useState } from 'react';
import {
  ContentBold,
  ContentDesc,
  ContentDescInline,
} from '../../common/typos';
import {
  IBaseAnalyticsAggregatedModelCreateItem,
  IBaseAnalyticsModelAggregatedItem,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';

interface IAnalyticsDescriptionItemsProps {
  items: IBaseAnalyticsModelAggregatedItem[];
  itemOrder: IItemsOrder;
  elementOrder: IElementOrder;
}

export type IItemsOrder = 'value' | 'param' | 'date';

export const itemsOrderList: IItemsOrder[] = ['value', 'param', 'date'];

export type IElementOrder = 'min' | 'max' | 'avg' | 'cnt';

export const elementOrderList: IElementOrder[] = ['min', 'max', 'avg', 'cnt'];

export function AnalyticsDescriptionItemsElement(
  props: IAnalyticsDescriptionItemsProps
) {
  const [items, setItems] = useState<
    IBaseAnalyticsAggregatedModelCreateItem<
      undefined | string,
      undefined | string
    >[]
  >([]);

  useEffect(() => {
    setItems(
      Object.assign([], props.items).sort(
        (
          a: IBaseAnalyticsModelAggregatedItem,
          b: IBaseAnalyticsModelAggregatedItem
        ): number => {
          return a[props.elementOrder] > b[props.elementOrder] ? -1 : 1;
        }
      )
    );
  }, [props.items, props.itemOrder]);

  return (
    <>
      {items.map(
        (
          item: IBaseAnalyticsAggregatedModelCreateItem<
            undefined | string,
            undefined | string
          >
        ) => (
          <ContentDesc>
            <ContentDescInline>
              {item.value} - {item.param} - {''}
            </ContentDescInline>
            {item.min}...
            {item.max}
            {' / '}
            {item.cnt} ({item.avg} avg)
          </ContentDesc>
        )
      )}
    </>
  );
}
