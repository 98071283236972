import React from 'react';
import { ContentDesc, ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAppPartnerAuctionPublic } from '../../interfaces/partners/IAppPartnerAuction';
import { Image } from '../../common/images/Image.styles';
import { BlockActions, ButtonMain } from '../../common/styles';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { L } from '../../common/locale';

interface IPartnersAuctionPublicProps {
  partner: IAppPartnerAuctionPublic;
  hideDescription?: boolean;
  hideButtons?: boolean;
  allowNavigation?: boolean;
}

export function PartnersAuctionPublic(props: IPartnersAuctionPublicProps) {
  const navigate = useNavigate();
  const partner: IAppPartnerAuctionPublic = props.partner;

  const navigateToPartner = () => {
    navigate(
      appConfig.routes.partners.one.replace(
        appConfig.params.platformPath,
        partner.platformType
      )
    );
  };
  const goToPartnerSite = () => {
    const url: string | null = partner.hostAuction || partner.hostAuctionSub;
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  };
  const handleClick = () => {
    if (!props.allowNavigation) {
      return;
    }

    navigate(
      appConfig.routes.partners.one.replace(
        appConfig.params.platformPath,
        partner.platformType
      )
    );
  };
  return (
    <Block
      className={props.allowNavigation ? 'clickable' : ''}
      onClick={handleClick}
    >
      <Image className={'L'} src={partner.imageURL} alt={partner.name} />
      <ContentHead>{partner.name}</ContentHead>
      {/*<ContentDesc>{partner.platformType}</ContentDesc>*/}
      {!props.hideDescription && (
        <ContentDesc>{partner.description}</ContentDesc>
      )}

      {!props.hideButtons && (
        <BlockActions>
          <ButtonMain onClick={navigateToPartner} className={'success'}>
            <L k={'ActionLandingAuctions'} />
          </ButtonMain>
          <ButtonMain onClick={goToPartnerSite} className={'fail'}>
            <L k={'ActionLandingAuction'} />
          </ButtonMain>
        </BlockActions>
      )}
      {/*<ContentDesc>{partner.hostAuctionSub}</ContentDesc>*/}
      {/*<ContentDesc>{partner.hostCompany}</ContentDesc>*/}
    </Block>
  );
}
