import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import {
  loadAccountsBatch,
  searchAccounts,
} from '../../../actions/account/accounts.actions';
import { DataBatchWide } from '../../../interfaces/system/data';
import { IAccount, IAccountSet } from '../../../interfaces/account/IAccount';
import { RenderItemPublicFunction } from '../../../common/common';
import { AccountItemModeration } from '../../../components/account/AccountItemModeration';
import {
  renderTemplateParamsForm,
  renderTemplateParamsItemPublic,
  renderTemplateParamsItemView,
} from '../../../components/communication/TemplateParams/TemplateParams';

interface ICommunicationUsersProps {
  // loadEmailLogBatch: (
  //   mailingID: null | string,
  //   accountID: null | string,
  //   mode: null | IEmailMode
  // ) => void;
  // userSet: IAccountSet;
  // emailLogByBatchKey: Record<string, DataBatchWide>;
  // emailLogByLogID: IEmailLogSet;
  searchAccounts: (search: string) => void;
  loadAccountsBatch: () => void;
  isAdmin: boolean;
  set: IAccountSet;
  IDsBySearchKey: Record<string, string[]>;
  byBatchKey: Record<string, DataBatchWide>;

  errors: string[];
}

function CommunicationUsers(props: ICommunicationUsersProps) {
  // const [logs, setLogs] = useState<null | IEmailLogPublic[]>(null);

  useEffect(() => sendPageEvent('CommunicationUsers'), []);
  /*useEffect(() => {
    if (!emailLogIDs) {
      setLogs(null);
      return;
    }

    const logsLocal: IEmailLogPublic[] = [];
    emailLogIDs.forEach((logID: string) => {
      logsLocal.push(props.emailLogByLogID[logID]);
    });
    setLogs(logsLocal);
  }, [emailLogIDs, props.emailLogByLogID]);*/

  // const renderLog = (log: IEmailLogPublic) => {
  //   if (!log) {
  //     return;
  //   }
  //   const eventsList: {
  //     name: string;
  //     dates: string[];
  //   }[] = [];
  //   const eventsSet: Record<string, string[]> = log.events || {};
  //   Object.keys(eventsSet).forEach((name: string) => {
  //     const dates: string[] = [];
  //     eventsSet[name].forEach((date: string) => {
  //       dates.push(prepareAuctionDate(date));
  //     });
  //     eventsList.push({ name, dates });
  //   });
  //
  //   return (
  //     <Block key={log.id} className="item">
  //       <ContentDesc>
  //         <ContentBold>{log.mode}</ContentBold>
  //       </ContentDesc>
  //
  //       {!log.isAllowed && <ContentDesc>Unsubscribed</ContentDesc>}
  //       {log.mailingID && (
  //         <ContentDesc className="clickable">Was mailing</ContentDesc>
  //       )}
  //
  //       {eventsList.map(
  //         (event: { name: string; dates: string[] }, index: number) => (
  //           <div key={index}>
  //             <ContentDesc>{event.name}:</ContentDesc>
  //             {event.dates.map((date: string) => (
  //               <ContentDesc>{date}</ContentDesc>
  //             ))}
  //           </div>
  //         )
  //       )}
  //
  //       {renderAuctionDate(log.date)}
  //     </Block>
  //   );
  // };

  const loadBatch = (extraLimit?: number) => {
    // if (!filters) {
    //   return;
    // }

    props
      .loadAccountsBatch
      // filters.emails,
      // extraLimit
      ();
  };

  const renderItemPublic: RenderItemPublicFunction<IAccount> = (
    item: IAccount,
    inlineBlock?: JSX.Element
  ) => {
    return (
      <AccountItemModeration
        account={item}
        showInline={true}
        inlineBlock={inlineBlock}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'Users moderation'}
      itemName={'this user'}
      render={{
        renderItemPublic,
      }}
      data={{
        set: props.set,
        batch: {
          batchKey: '',
          byBatchKey: props.byBatchKey,
          loadBatch,
        },
        search: {
          search: props.searchAccounts,
          IDsBySearch: props.IDsBySearchKey,
          placeholderTitle: 'email or name',
        },
        errors: props.errors,
      }}
      action={{
        showInline: true,
      }}
      isAdmin={props.isAdmin}
    />
  );

  // return (
  //   <BlockPage>
  //     <BlockPageContent>
  //       <PageHead>Communication with users</PageHead>
  //
  //       {/*{accountID &&
  //         logs &&
  //         logs.map((log: IEmailLogPublic) => renderLog(log))}*/}
  //     </BlockPageContent>
  //   </BlockPage>
  // );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    set: state.Account.accounts.set,
    IDsBySearchKey: state.Account.accounts.IDsBySearchKey,
    byBatchKey: state.Account.accounts.byBatchKey,
    errors: state.System.errors,
  }),
  {
    searchAccounts,
    loadAccountsBatch,
  }
)(CommunicationUsers);
