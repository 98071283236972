import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import PartnersAuctionForm from '../../../components/partners/PartnersAuctionForm';
import {
  IAppPartnerAuctionPublic,
  IAppPartnerAuctionSet,
} from '../../../interfaces/partners/IAppPartnerAuction';
import {
  deletePartnerAuction,
  loadPartnerAuctionIDs,
} from '../../../actions/partners/partnerAuction.actions';
import { PartnersAuctionItemModeration } from '../../../components/partners/PartnersAuctionItemModeration';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import {
  RenderFormFunction,
  RenderItemPublicFunction,
} from '../../../common/common';
import {
  renderTemplateParamsForm,
  renderTemplateParamsItemPublic,
  renderTemplateParamsItemView,
} from '../../../components/communication/TemplateParams/TemplateParams';

interface IPartnersAuctionProps {
  loadPartnerAuctionIDs: (hardReload?: boolean) => void;
  deletePartnerAuction: (partnerID: string) => void;
  IDs: string[];
  set: IAppPartnerAuctionSet;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function PartnersAuction(props: IPartnersAuctionProps) {
  const renderItemPublic: RenderItemPublicFunction<IAppPartnerAuctionPublic> = (
    item: IAppPartnerAuctionPublic
  ) => {
    return <PartnersAuctionItemModeration partner={item} />;
  };

  const renderForm: RenderFormFunction<IAppPartnerAuctionPublic> = (
    isDisabled: undefined | boolean,
    item: undefined | IAppPartnerAuctionPublic,
    cbCancel: () => void,
    cbDelete?: (partnerID: string) => void
  ) => {
    return (
      <PartnersAuctionForm
        isDisabled={isDisabled}
        partner={item}
        cbCancel={cbCancel}
        cbDelete={cbDelete}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'Partner auction moderation'}
      itemName={'this auction partner'}
      itemNameCreate={'Create new Partner'}
      render={{
        renderItemPublic,
        renderForm,
      }}
      data={{
        set: props.set,
        list: { load: props.loadPartnerAuctionIDs, IDs: props.IDs },
        actions: props.actions,
        errors: props.errors,
      }}
      cb={{
        cbDelete: (removingID: string) =>
          props.deletePartnerAuction(removingID),
      }}
      action={{
        showInline: true,
      }}
      isAdmin={props.isAdmin}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    IDs: state.Partners.Auction.IDs,
    set: state.Partners.Auction.set,
    actions: state.Partners.Auction.actions,
    errors: state.System.errors,
  }),
  {
    loadPartnerAuctionIDs,
    deletePartnerAuction,
  }
)(PartnersAuction);
