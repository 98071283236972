import React, { useEffect } from 'react';
import { ContentHead } from '../../common/typos';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { IAppAuctionPublic } from '../../interfaces/auctions/IAppAuction';
import Auction from './Auction';
import { Carousel } from 'react-responsive-carousel';
import { Block, CarouselBlock } from '../../common/layout';
import { IPlatformType } from '../../config/platform.config';
import { loadProductBatchFirst } from '../../actions/shop/product.actions';
import PreviewList from '../shop/PreviewList';
import { useTranslation } from 'react-i18next';

interface IAuctionListProps {
  loadProductBatchFirst: (platformType: IPlatformType) => void;
  completed: IAppAuctionPublic[];
  upcoming: IAppAuctionPublic[];
}

function AuctionList(props: IAuctionListProps) {
  const { t } = useTranslation();

  useEffect(() => {
    const platformTypeList: IPlatformType[] = [];
    props.upcoming.forEach((upcoming: IAppAuctionPublic) => {
      const platformType: IPlatformType = upcoming.platformType;
      if (!platformTypeList.includes(platformType)) {
        platformTypeList.push(platformType);
      }
    });

    if (!platformTypeList[0]) {
      return;
    }

    platformTypeList.forEach((platformType: IPlatformType) => {
      props.loadProductBatchFirst(platformType);
    });
  }, [props.upcoming]);

  const renderAuctions = (auctions: IAppAuctionPublic[], title: string) => {
    return (
      <>
        <ContentHead>{title}</ContentHead>
        <CarouselBlock>
          <Carousel
            autoPlay={true}
            interval={5000}
            transitionTime={3000}
            swipeable={false}
            infiniteLoop={true}
            showArrows={true}
          >
            {auctions.map((auction: IAppAuctionPublic) => (
              <Block key={auction.id} className={'left'}>
                <Auction auction={auction} />
              </Block>
            ))}
          </Carousel>
        </CarouselBlock>
      </>
    );
  };

  return (
    <Block>
      {props.upcoming[0] &&
        renderAuctions(props.upcoming, t('AuctionsTitleUpcomingList'))}

      <Block>
        <PreviewList />
      </Block>

      {props.completed[0] &&
        renderAuctions(props.completed, t('AuctionsTitleCompleted'))}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    completed: state.Auctions.auctions.completed,
    upcoming: state.Auctions.auctions.upcoming,
  }),
  {
    loadProductBatchFirst,
  }
)(AuctionList);
