import React, { useEffect, useState } from 'react';
import { Block } from '../layout';
import { IParamsItemView, RenderItemPublicFunction } from '../common';
import { ModerationItem } from './ModerationItem';
import { IModerationAction, IModerationSelection } from './Moderation';

interface IModerationItemsListProps<ItemPublic> {
  isDisabled?: boolean;
  cbView?: (itemID: string) => void;
  cbEdit?: (itemID: string) => void;
  cbDelete?: (itemID: string) => void;
  load?: () => void;
  renderItemPublic: RenderItemPublicFunction<ItemPublic>;
  ParamsItemView?: IParamsItemView;
  IDs: string[];
  set: Record<string, ItemPublic>;
  action?: IModerationAction;
  selection?: IModerationSelection;
}

export function ModerationItemsList<ItemPublic>(
  props: IModerationItemsListProps<ItemPublic>
) {
  const [selected, setSelected] = useState<null | string>(null);
  useEffect(() => {
    if (props.load) {
      props.load();
    }
  }, []);
  useEffect(() => {
    setSelected(
      (props.selection?.selectedIDs && props.selection.selectedIDs[0]) || null
    );
  }, [props.selection?.selectedIDs]);
  const [isSelectable] = useState<boolean>(!!props.selection?.cbSelected);

  const handleOnSelect = (itemID: string): void => {
    if (isSelectable) {
      setSelected(itemID);
    }
    if (props.selection?.cbSelected) {
      props.selection.cbSelected(itemID);
    }
  };

  return (
    <Block className="article">
      {props.IDs.map((itemID: string) => (
        <Block
          key={itemID}
          onClick={() => handleOnSelect(itemID)}
          className={
            'flex' +
            (isSelectable && itemID === selected ? ' selected' : '') +
            (props.action?.showInline ? ' inline' : '') +
            (isSelectable ? ' clickable' : '')
          }
        >
          {isSelectable && (
            <input
              type="radio"
              checked={itemID === selected}
              onChange={() => undefined}
            />
          )}

          <ModerationItem
            itemID={itemID}
            set={props.set}
            renderItemPublic={props.renderItemPublic}
            ParamsItemView={props.ParamsItemView}
            isDisabled={props.isDisabled}
            cbView={props.cbView}
            cbEdit={props.cbEdit}
            cbDelete={props.cbDelete}
            action={props.action}
          />
        </Block>
      ))}
    </Block>
  );
}
