import { appConfig } from '../config/app.config';
import { logEvent } from 'firebase/analytics';
import { analyticsApp } from '../config/firebase.config';
import { IPlatformType } from '../config/platform.config';
import { getPlatformType } from './platform';

export function sendEvent(eventName: string): void {
  if (appConfig.isDev) {
    return;
  }
  logEvent(analyticsApp, eventName);
}

export function sendPageEvent(pageName: string): void {
  const platformType: IPlatformType = getPlatformType();
  sendEvent(platformType + '-open-' + pageName + '-page');
  document.title =
    platformType.slice(0, 1).toUpperCase() +
    platformType.slice(1) +
    ' Auction - ' +
    pageName;
}
