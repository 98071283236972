import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../utils/analytics';
import { ContentHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { FormBlock } from '../../common/forms/Form.styles';
import {
  createPartnerFeedbacks,
  updatePartnerFeedbacks,
} from '../../actions/partners/partnerFeedbacks.actions';
import {
  IAppPartnerFeedbacksPublic,
  IAppPartnerFeedbacksSet,
} from '../../interfaces/partners/IAppPartnerFeedbacks';
import { IPlatformType, validatePlatform } from '../../config/platform.config';
import { Form, IFormItem } from '../../common/forms/Form';
import { FormsFields } from '../../common/forms/forms';

interface IPartnersFeedbacksFormProps {
  partner?: null | IAppPartnerFeedbacksPublic;
  isDisabled?: boolean;
  createPartnerFeedbacks: (
    platformType: IPlatformType,
    accountID: null | string,
    name: string,
    position: string,
    description: null | string,
    imageURL: string,
    feedback: string
  ) => void;
  updatePartnerFeedbacks: (
    partnerID: string,
    accountID: undefined | null | string,
    name: null | string,
    position: null | string,
    description: undefined | null | string,
    imageURL: null | string,
    feedback: null | string
  ) => void;
  cbCancel: () => void;
  cbDelete?: (partnerID: string) => void;
  set: IAppPartnerFeedbacksSet;
  actions: string[];
  errors: string[];
}

function PartnersFeedbacksForm(props: IPartnersFeedbacksFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);

  const fields: Partial<
    Record<keyof IAppPartnerFeedbacksPublic, undefined | IFormItem>
  > = {
    platformType:
      (!props.partner && {
        ...FormsFields.platformType,
        $valueSelect: [],
      }) ||
      undefined,
    name: {
      ...FormsFields.name,
      $value: props.partner?.name,
    },
    description: {
      ...FormsFields.description,
      $value: props.partner?.description,
    },
    position: {
      ...FormsFields.description,
      max: 50,
      $value: props.partner?.position,
    },
    feedback: {
      ...FormsFields.description,
      max: 750,
      $value: props.partner?.feedback,
    },
    imageURL: {
      ...FormsFields.imageURL,
      title: 'Person image',
      $valueList: (props.partner && [props.partner.imageURL]) || [],
    },
  };

  useEffect(() => sendEvent('Open-PartnersFeedbacksForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const handleCreate = () => {
    const platformType: null | IPlatformType = validatePlatform(
      (fields.platformType &&
        fields.platformType.$valueList &&
        fields.platformType.$valueList[0]) ||
        ''
    );
    if (!platformType) {
      return;
    }

    if (fields.platformType) {
      fields.platformType.$valueList = [platformType];
    }

    setIsSending(true);
    props.createPartnerFeedbacks(
      platformType,
      (fields.accountID && fields.accountID.$value) || null,
      (fields.name && fields.name.$value) || '',
      (fields.position && fields.position.$value) || '',
      (fields.description && fields.description.$value) || '',
      (fields.imageURL &&
        fields.imageURL.$valueList &&
        fields.imageURL.$valueList[0]) ||
        '',
      (fields.feedback && fields.feedback.$value) || ''
    );
  };
  const handleUpdate = () => {
    if (!props.partner) {
      return;
    }
    setIsSending(true);
    props.updatePartnerFeedbacks(
      props.partner.id,
      (fields.accountID && fields.accountID.$value) || null,
      (fields.name && fields.name.$value) || null,
      (fields.position && fields.position.$value) || null,
      (fields.description && fields.description.$value) || null,
      (fields.imageURL &&
        fields.imageURL.$valueList &&
        fields.imageURL.$valueList[0]) ||
        null,
      (fields.feedback && fields.feedback.$value) || null
    );
  };
  const handleDelete = () => {
    if (!props.partner || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.partner.id);
  };

  return (
    <FormBlock>
      {!props.partner && <ContentHead>New Feedbacks creation</ContentHead>}
      {props.partner && <ContentHead>Feedbacks update</ContentHead>}

      <Form
        isUpdate={!!props.partner}
        isDisabled={props.isDisabled}
        placeholderTitle={'partner feedbacks'}
        fields={fields}
        isSending={isSending}
        cbCancel={props.cbCancel}
        cbCreate={handleCreate}
        cbUpdate={handleUpdate}
        cbDelete={handleDelete}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Partners.Feedbacks.set,
    actions: state.Partners.Feedbacks.actions,
    errors: state.System.errors,
  }),
  {
    createPartnerFeedbacks,
    updatePartnerFeedbacks,
  }
)(PartnersFeedbacksForm);
