import React, { ChangeEvent, useEffect, useRef } from 'react';
import { ActionButton } from '../styles';

interface IImageInputProps {
  isDisabled?: boolean;
  onImageSelected: (file: string) => void;
  autoSelect?: boolean;
}

export function ImageInput(props: IImageInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () =>
        props.onImageSelected(
          (reader.result && reader.result.toString()) || ''
        );
    }
  };

  const onChooseImg = () => {
    inputRef.current && inputRef.current.click();
  };

  useEffect(() => {
    if (props.autoSelect) {
      onChooseImg();
    }
  }, [props.autoSelect]);

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: 'none' }}
        disabled={props.isDisabled}
      />

      <ActionButton className="btn success" onClick={onChooseImg}>
        Add Image
      </ActionButton>
    </>
  );
}
