import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { ActionCase, IStateSystem } from '../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../interfaces/system/IError';
import {
  API_ERROR,
  API_ERROR_POP,
  API_INFO_POP,
  API_INFO_PUSH,
  SYSTEM_SET_WIDTH,
} from '../actions/types';
import { getWidthSize } from '../utils/catalog';

const initialStateSystem: IStateSystem = {
  errors: [],
  infos: [],
  clientWidth: null,
  clientWidthSize: null,
};

export const systemReducer: Reducer = createReducer(
  initialStateSystem,
  (builder: ActionReducerMapBuilder<IStateSystem>) => {
    builder.addCase<string, ActionCase<IServiceError>>(
      API_ERROR,
      (state: Draft<IStateSystem>, action: ActionCase<IServiceError>) => {
        state.errors.unshift(action.payload.message);
      }
    );
    builder.addCase<string, ActionCase<void>>(
      API_ERROR_POP,
      (state: Draft<IStateSystem>) => {
        state.errors.pop();
      }
    );
    builder.addCase<string, ActionCase<IServiceInfo>>(
      API_INFO_PUSH,
      (state: Draft<IStateSystem>, action: ActionCase<IServiceInfo>) => {
        const { message, params, isError } = action.payload;

        let isExists: boolean = false;
        state.infos.forEach((err: IServiceInfo) => {
          if (err.message === message) {
            isExists = true;
          }
        });
        if (isExists) {
          return;
        }

        state.infos.unshift({ message, params, isError });
      }
    );
    builder.addCase<string, ActionCase<void>>(
      API_INFO_POP,
      (state: Draft<IStateSystem>) => {
        state.infos.pop();
      }
    );
    builder.addCase<string, ActionCase<number>>(
      SYSTEM_SET_WIDTH,
      (state: Draft<IStateSystem>, action: ActionCase<number>) => {
        const size: number = action.payload;
        state.clientWidth = size;
        state.clientWidthSize = getWidthSize(size);
      }
    );
  }
);
