import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IAccount } from '../../interfaces/account/IAccount';
import { LoginBlock, LoginBlockButton } from './LoginPage.styles';
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import { googleConfig } from '../../config/google.config';
import {
  checkToken,
  checkTokenGoogle,
  getLoginAction,
  getPathBeforeLogin,
} from '../../actions/account/user.actions';
import { appConfig } from '../../config/app.config';
import { useNavigate, useParams } from 'react-router-dom';
import { sendEvent, sendPageEvent } from '../../utils/analytics';
import { apiConfig } from '../../config/api.config';
import { ContentBold, ContentHead } from '../../common/typos';
import { BlockPage, BlockPageContent } from '../../common/styles';
import { IState } from '../../interfaces/system/IState';
import { setError } from '../../actions/system/system.actions';
import { L } from '../../common/locale';

interface ILoginPageProps {
  checkToken: (token: string) => void;
  checkTokenGoogle: (token: string) => void;
  setError: (message: string) => void;
  account: null | IAccount;
}

function LoginPage(props: ILoginPageProps) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [tokenParam, setTokenParam] = useState<string>();
  const [title, setTitle] = useState<null | string>(null);

  useEffect(() => sendPageEvent('Login'), []);
  useEffect(() => {
    switch (getPathBeforeLogin()) {
      default:
        setTitle(null);
        break;
    }
  }, []);
  useEffect(() => {
    if (token) {
      setTokenParam(token);
    }
  }, [token]);
  useEffect(() => {
    if (!props.account && (token || tokenParam)) {
      props.checkToken(token || tokenParam || '');
      return;
    }

    if (props.account && props.account.id) {
      sendEvent('Login-complete');

      const addToCalendar: null | string = getLoginAction();
      if (addToCalendar) {
        window.open(addToCalendar, '_blank');
        navigate(appConfig.routes.main.home);
        return;
      }
      const pathBeforeLogin: null | string = getPathBeforeLogin();
      if (pathBeforeLogin && !pathBeforeLogin.startsWith('/login')) {
        window.location.pathname = pathBeforeLogin;
      } else {
        navigate(appConfig.routes.main.home);
      }
    }
  }, [props.account]);

  const onSuccess = (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      props.checkTokenGoogle(credentialResponse.credential);
    }
  };

  const onError = () => {
    props.setError('Login is failed');
  };

  return (
    <BlockPage>
      <LoginBlock>
        <BlockPageContent className="relative">
          {!title && (
            <ContentHead style={{ margin: 'auto' }}>
              <L k={'AccountLoginCall'} />
            </ContentHead>
          )}
          {title && (
            <ContentHead style={{ margin: 'auto' }}>
              <L k={'AccountLoginCallAction'} />
              {title}
            </ContentHead>
          )}
        </BlockPageContent>

        <GoogleOAuthProvider clientId={googleConfig.clientId}>
          <LoginBlockButton>
            <GoogleLogin
              onSuccess={onSuccess}
              onError={onError}
              size={'large'}
              ux_mode={'redirect'}
              useOneTap={true}
              theme={'filled_blue'}
              shape={'rectangular'}
              login_uri={
                apiConfig.host +
                apiConfig.endpoints.account.auth.google +
                '?platform=Main'
              }
            />
          </LoginBlockButton>
        </GoogleOAuthProvider>
      </LoginBlock>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
  }),
  {
    checkToken,
    checkTokenGoogle,
    setError,
  }
)(LoginPage);
