import React, { ChangeEvent, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  ErrorInfo,
  FormBlock,
  FormGroup,
  Input,
  Label,
} from '../../common/forms/Form.styles';

import { setLead } from '../../actions/landing/lead.actions';
import { IState } from '../../interfaces/system/IState';
import { MainButton } from './Button';
import { ContentDesc } from '../../common/typos';
import { BlockDelimiter, BlockHead } from '../../common/layout';
import { useLocation } from 'react-router-dom';
import { BlockPageContent } from '../../common/styles';

interface ILeadFormProps {
  // scrollFromButton: null | string;
  setLead: (name: string, email: string, company: string) => void;
}

function LeadForm(props: ILeadFormProps) {
  const location = useLocation();
  const formRef = useRef<HTMLDivElement | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');

  const [nameError, setNameError] = useState<null | string>();
  const [emailError, setEmailError] = useState<null | string>();
  const [companyError, setCompanyError] = useState<null | string>();
  // const [isShown, setIsShown] = useState<boolean>(true);

  // todo implement auto-submit

  // useEffect(() => {
  //   if (!formRef || !formRef.current || !props.scrollFromButton) {
  //     return;
  //   }
  //   logEvent(analyticsApp, 'ClickTo' + props.scrollFromButton);
  //   formRef.current.scrollIntoView();
  // }, [props.scrollFromButton]);
  // useEffect(() => {
  //   setIsShown(location.pathname === appConfig.routes.main.home);
  // }, [location.pathname]);

  const handleSubmit = () => {
    window.scrollTo(0, 0);
    if (validateAll()) {
      // todo wait for errors
      setIsSending(true);
      props.setLead(name, email, company);
    }
  };

  const handleChanges = (
    type: 'name' | 'email' | 'company',
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value: string = event.target.value;
    if (type === 'name') {
      setNameError(validateName(value));
      setName(value);
      validateAll(value);
    } else if (type === 'email') {
      setEmailError(validateEmail(value));
      setEmail(value);
      validateAll(undefined, value);
    } else if (type === 'company') {
      setCompanyError(validateCompany(value));
      setCompany(value);
      validateAll(undefined, undefined, value);
    }
  };

  const validateAll = (
    nameLocal?: string,
    emailLocal?: string,
    companyLocal?: string
  ): boolean => {
    const isValid: boolean =
      !validateName(nameLocal) &&
      !validateEmail(emailLocal) &&
      !validateCompany(companyLocal);

    setIsDisabled(!isValid);
    return isValid;
  };

  const validateName = (nameLocal?: string): null | string => {
    const value: string = nameLocal || name;
    let error: null | string = null;
    if (value.length <= 3) {
      error = 'Name is too short';
    } else if (value.length > 50) {
      error = 'Name is too long';
    }
    return error;
  };

  const validateEmail = (emailLocal?: string): null | string => {
    const value: string = emailLocal || email;
    let error: null | string = null;
    if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      error = 'Email is not correct';
    }

    return error;
  };

  const validateCompany = (companyLocal?: string): null | string => {
    const value: string = companyLocal || company;
    let error: null | string = null;
    if (value.length < 5) {
      error = 'Company is too short';
    } else if (value.length > 140) {
      error = 'Company is too long';
    }
    return error;
  };

  return (
    <>
      {/*{isShown && (*/}
      {/*  <>*/}
      <BlockDelimiter />
      <BlockPageContent>
        <BlockHead>Start your auction journey:</BlockHead>
        <ContentDesc>
          Only the first lot is needed - everything else is up to me
        </ContentDesc>
        <FormBlock
          ref={formRef}
          className={isSending ? 'sending shadow' : 'shadow'}
        >
          <FormGroup>
            <Label>Your name</Label>
            {nameError && <ErrorInfo>{nameError}</ErrorInfo>}
            <Input
              value={name}
              type={'text'}
              onChange={(e) => handleChanges('name', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label>Your email</Label>
            {emailError && <ErrorInfo>{emailError}</ErrorInfo>}
            <Input
              value={email}
              type={'email'}
              onChange={(e) => handleChanges('email', e)}
            />
          </FormGroup>

          <FormGroup>
            <Label>Your company link or name</Label>
            {companyError && <ErrorInfo>{companyError}</ErrorInfo>}
            <Input
              value={company}
              type={'text'}
              onChange={(e) => handleChanges('company', e)}
            />
          </FormGroup>

          <MainButton
            label="Submit"
            isDisabled={isDisabled || isSending}
            onClick={handleSubmit}
          />
        </FormBlock>
      </BlockPageContent>
      <BlockDelimiter />
      {/*  </>*/}
      {/*)}*/}
    </>
  );
}

export default connect((state: IState) => ({}), {
  setLead,
})(LeadForm);
