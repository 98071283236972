import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  ExampleBlock,
  ExampleIconBlock,
  ExampleIconDown,
  ExampleWrap,
  LiveColumn,
  LiveRow,
  LiveVideo,
  SupportBlockIcon,
  SupportBlockItem,
  SupportBlockItemFirst,
} from './MainPage.styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IState } from '../../interfaces/system/IState';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app.config';
import { analyticsApp } from '../../config/firebase.config';
import { logEvent } from 'firebase/analytics';
import AuctionWrapper from '../../components/examples/AuctionWrapper';
import { IAuctionMode } from '../../interfaces/examples/IAuction';
import { loadAuctionIDs } from '../../actions/auctions/auction.actions';
import { IAppAuctionSet } from '../../interfaces/auctions/IAppAuction';
import AuctionList from '../../components/auctions/AuctionList';
import { Article, Block, BlockHead } from '../../common/layout';
import { PageHead } from '../../common/typos';
import ReportLots from '../../components/auctions/ReportLots';
import PartnersAuctionPublicList from '../../components/partners/PartnersAuctionPublicList';
import CreateAuctionPublic from '../../components/CreateAuction/CreateAuctionPublic';
import { BlockPage, BlockPageContent } from '../../common/styles';
import { L, LArray } from '../../common/locale';
import { useTranslation } from 'react-i18next';

interface IMainPageProps {
  loadAuctionIDs: (hardReload?: boolean) => void;
  result: string;
  auctionMode: IAuctionMode;
  byAuctionID: IAppAuctionSet;
  auctionIDs: string[];
}

type IHidableBlock = 'ForWhom' | 'Why' | 'Formats' | 'Support' | 'Try';

// todo current lots

function MainPage(props: IMainPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const blockRef = useRef<HTMLDivElement | null>(null);
  const [blockViewSet, setBlockViewSet] = useState<
    Partial<Record<IHidableBlock, boolean>>
  >({});

  useEffect(() => logEvent(analyticsApp, 'OpenMainPage'), []);
  useEffect(() => {
    if (props.result === 'success') {
      navigate(appConfig.routes.landing.thankYou);
    }
  }, [props.result]);
  useEffect(() => props.loadAuctionIDs(), []);

  useEffect(() => {
    if (!blockRef || !blockRef.current) {
      return;
    }

    blockRef.current.scrollIntoView();
  }, [props.auctionMode]);

  const toggleBlockView = (name: IHidableBlock) => {
    const blockViewSetLocal: Partial<Record<IHidableBlock, boolean>> =
      Object.assign({}, blockViewSet);
    blockViewSetLocal[name] = !blockViewSetLocal[name];
    setBlockViewSet(blockViewSetLocal);
  };

  return (
    <>
      <BlockPage>
        <BlockPageContent>
          <CreateAuctionPublic />
          <Block>
            <AuctionList />
          </Block>
          <Block>
            <BlockHead>
              <L k={'ReportsTopLotTotal'} />
            </BlockHead>
            <ReportLots
              auction={null}
              platformType={null}
              platformAuctionID={null}
              lotType={null}
              mode="carousel"
              onlyList={true}
            />
          </Block>
          <Block>
            <BlockHead>
              <L k={'ShopTitlePartnersShort'} />
            </BlockHead>
            <PartnersAuctionPublicList
              mode={'carousel'}
              hideTitle={true}
              allowNavigation={true}
            />
          </Block>
          <Block>
            <BlockHead
              className={
                blockViewSet['ForWhom'] ? 'clicked clickable' : 'clickable'
              }
              onClick={() => toggleBlockView('ForWhom')}
            >
              <L k={'LandingMainQuestionWhom'} />
            </BlockHead>
            <Article className={blockViewSet['ForWhom'] ? '' : 'hidden'}>
              {LArray('LandingMainTargetGroups').map(
                (option: string, index: number) => (
                  <SupportBlockItem key={index}>
                    <SupportBlockIcon src="/icons/arrow.png" alt="" />
                    {option}
                  </SupportBlockItem>
                )
              )}
            </Article>
          </Block>
          <Block>
            <BlockHead
              className={
                blockViewSet['Why'] ? 'clicked clickable' : 'clickable'
              }
              onClick={() => toggleBlockView('Why')}
            >
              <L k={'LandingMainQuestionWhy'} />
            </BlockHead>
            <Article className={blockViewSet['Why'] ? '' : 'hidden'}>
              {LArray('LandingMainMainFeatures').map(
                (option: string, index: number) => (
                  <SupportBlockItem key={index}>{option}</SupportBlockItem>
                )
              )}
            </Article>
          </Block>
          <Block>
            <BlockHead
              className={
                blockViewSet['Formats'] ? 'clicked clickable' : 'clickable'
              }
              onClick={() => toggleBlockView('Formats')}
            >
              <L k={'LandingMainQuestionFormats'} />
            </BlockHead>
            <Article className={blockViewSet['Formats'] ? '' : 'hidden'}>
              <LiveRow>
                <LiveColumn>
                  <PageHead>
                    <L k={'MenuAuctionLive'} />
                  </PageHead>
                  <LiveVideo
                    autoPlay
                    loop={true}
                    controls={true}
                    muted
                    playsInline
                    src="https://auction-space.fra1.cdn.digitaloceanspaces.com/zoola/videos/auction-live.mp4"
                    id="example-auction-live"
                    preload="auto"
                    width="100%"
                    height="auto"
                  />
                </LiveColumn>
                <LiveColumn>
                  <PageHead>
                    <L k={'MenuAuctionWeek'} />
                  </PageHead>
                  <LiveVideo
                    // autoPlay
                    loop={true}
                    controls={true}
                    muted
                    playsInline
                    src="https://auction-space.fra1.cdn.digitaloceanspaces.com/zoola/videos/auction-week.mp4"
                    id="example-auction-week"
                    preload="auto"
                    width="100%"
                    height="auto"
                  />
                </LiveColumn>
              </LiveRow>
              <LiveRow>
                <LiveColumn>
                  {LArray('LandingMainLiveOptions').map(
                    (option: string, index: number) => (
                      <SupportBlockItem key={index}>
                        <SupportBlockIcon src="/icons/arrow.png" alt="" />
                        {option}
                      </SupportBlockItem>
                    )
                  )}
                </LiveColumn>
                <LiveColumn>
                  {LArray('LandingMainWeekOptions').map(
                    (option: string, index: number) => (
                      <SupportBlockItem key={index}>
                        <SupportBlockIcon src="/icons/arrow.png" alt="" />
                        {option}
                      </SupportBlockItem>
                    )
                  )}
                </LiveColumn>
              </LiveRow>
            </Article>
          </Block>
          <Block>
            <BlockHead
              className={
                blockViewSet['Support'] ? 'clicked clickable' : 'clickable'
              }
              onClick={() => toggleBlockView('Support')}
            >
              <L k={'LandingMainQuestionSupport'} />
            </BlockHead>
            <Article className={blockViewSet['Support'] ? '' : 'hidden'}>
              <SupportBlockItemFirst>
                <L k={'LandingMainQuestionSupportAnswer'} />
              </SupportBlockItemFirst>
              {LArray('LandingMainSupportOptions').map(
                (option: string, index: number) => (
                  <SupportBlockItem key={index}>
                    <SupportBlockIcon src="/icons/arrow.png" alt="" />
                    {option}
                  </SupportBlockItem>
                )
              )}
            </Article>
          </Block>

          {/*<Block>*/}
          {/*  <BlockName>All you need is register lot</BlockName>*/}
          {/*</Block>*/}

          <Block>
            <BlockHead
              className={
                blockViewSet['Try'] ? 'clicked clickable' : 'clickable'
              }
              onClick={() => toggleBlockView('Try')}
            >
              <L k={'LandingMainQuestionLive'} />
            </BlockHead>
          </Block>
        </BlockPageContent>
      </BlockPage>

      <Article className={blockViewSet['Try'] ? '' : 'hidden'}>
        <ExampleWrap>
          <BlockPage>
            <Block className="noOverflow">
              <ExampleBlock className={blockViewSet['Try'] ? 'active' : ''}>
                <AuctionWrapper />
              </ExampleBlock>
            </Block>
          </BlockPage>
        </ExampleWrap>
        <ExampleIconBlock onClick={() => toggleBlockView('Try')}>
          {!blockViewSet['Try'] && (
            <ExampleIconDown
              width="30px"
              src="/icons/unfold-down.png"
              alt="expand"
            />
          )}
          {blockViewSet['Try'] && (
            <ExampleIconDown
              width="30px"
              src="/icons/unfold-up.png"
              alt="expand"
            />
          )}
        </ExampleIconBlock>
      </Article>

      {/*<AppContent>
        <Block>
          <BlockName>How the Auction works</BlockName>
          <ImageFull src="images/how-it-works.png" alt="How it works" />
        </Block>
        <Block>
          <MainButton
            label="Let's get started!"
            isDisabled={isDisabled}
            onClick={() => scrollToForm('LetsGetStarted')}
          />

          <BlockName>Try your self at Example Auction</BlockName>

          <LiveRow>
           <LiveColumn>
              <LiveVideo
                autoPlay
                loop={true}
                muted
                playsInline
                src="https://auction-space.fra1.cdn.digitaloceanspaces.com/zoola/videos/example-action.mp4"
                id="auction-bids"
                preload="auto"
                width="100%"
                height="100%"
              />
            </LiveColumn>
          <LiveColumn>
          {ExampleSteps.map((option: string, index: number) => (
            <SupportBlockItem key={index}>
              <SupportBlockIcon src="/icons/feature.png" alt="" />
              {option}
            </SupportBlockItem>
          ))}
          </LiveColumn>
          </LiveRow>

          <CarouselLinkMain
            href="https://example.create.auction"
            target="_blank"
          >
            example.create.auction
          </CarouselLinkMain>
        </Block>
      </AppContent>*/}
      {/*<VictoryWrap>
        {[1, 2, 3, 4].map((i, index: number) => (
          <VictoryBlock key={index}>
            <VictoryBlockName>Support the Ukraine army!</VictoryBlockName>
          </VictoryBlock>
        ))}
      </VictoryWrap>*/}
    </>
  );
}

export default connect(
  (state: IState) => ({
    result: state.Lead.data,
    auctionMode: state.Example.auctionMode,
    byAuctionID: state.Auctions.byAuctionID,
    auctionIDs: state.Auctions.auctionIDs,
  }),
  {
    loadAuctionIDs,
  }
)(MainPage);
