import React, { HTMLInputTypeAttribute, useState } from 'react';
import { FormGroup, Label } from './Form.styles';
import { ActionButton, BlockActions, BlockDates, ButtonMain } from '../styles';
import { FormField } from './FormField';
import { FormFieldImage } from './FormFieldImages';
import { Loading } from '../animation/Loading';
import { FormFieldSelect } from './FormFieldSelect';
import { FormFieldRecord } from './FormFieldRecord';
import { IValueRecord, IValueRecordItem } from './forms';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ContentDesc } from '../typos';
import { ButtonBlock } from '../images/Image.styles';

export interface IFormItem {
  mode: 'short' | 'long' | 'input' | 'images' | 'select' | 'date';
  modeInput?: HTMLInputTypeAttribute;
  isRequired: boolean;
  min?: number;
  max?: number;
  title?: string;
  customValidation?: () => null | string;
  valueMode?: 'object' | 'array' | 'record';
  $value?: null | string;
  $valueList?: null | string[];
  $valueObject?: Record<string, undefined | string>;
  $valueRecord?: IValueRecord;
  $valueSelect?: null | string[];
  $valueDate?: null | Date;
  defaultObject?: Record<string, undefined | string>;
  nullNameBeforeSelect?: string;
  defaultSelect?: string[];
}

interface IFormProps {
  isUpdate: boolean;
  isDisabled?: boolean;
  placeholderTitle: string;
  fields: Record<string, undefined | IFormItem>;
  isSending: boolean;
  cbCancel?: () => void;
  cbCreate?: () => void;
  cbUpdate: () => void;
  cbDelete?: () => void;
  cbFieldUpdates?: () => void;
  updateAll?: boolean;
  renderElements?: () => JSX.Element;
}

export function Form(props: IFormProps) {
  const [errors, setErrors] = useState<Record<string, null | string>>({});
  const [isValid, setIsValid] = useState<boolean>(true);

  const validateImages = (
    skipError: boolean,
    $valueList: undefined | null | string[],
    field: string,
    isRequired: boolean
  ): null | string => {
    const error: null | string =
      isRequired && (!$valueList || !$valueList[0])
        ? 'Image is required'
        : null;

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const validateSelect = (
    skipError: boolean,
    $valueSelect: undefined | null | string[],
    field: string,
    isRequired: boolean
  ): null | string => {
    const error: null | string =
      isRequired && (!$valueSelect || !$valueSelect[0])
        ? 'Value is required'
        : null;

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const validateValue = (
    skipError: boolean,
    field: string,
    isRequired: boolean,
    value: null | string | Record<string, null | string>,
    min?: number,
    max?: number
  ): null | string => {
    let error: null | string = null;

    if (!isRequired && !value) {
      return error;
    }

    if (isRequired && !value) {
      error = 'Is required';
    } else if (min && (!value || value.toString().length < min)) {
      error = `Should be longer than ${min} symbols`;
    } else if (max && value && value.toString().length > max) {
      error = `Should be shorter than ${max} symbols`;
    }

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const validateValueList = (
    skipError: boolean,
    field: string,
    isRequired: boolean,
    valueList: string[],
    min?: number,
    max?: number
  ): null | string => {
    let error: null | string = null;

    if (!isRequired && (!valueList || !valueList[0])) {
      return error;
    }

    for (let i = 0, len = valueList.length; i < len; i++) {
      const value: string = valueList[i];
      if (isRequired && !value) {
        error = 'Is required';
      } else if (min && (!value || value.toString().length < min)) {
        error = `Should be longer than ${min} symbols`;
      } else if (max && value && value.toString().length > max) {
        error = `Should be shorter than ${max} symbols`;
      }
    }

    if (!skipError) {
      errors[field] = error;
      setErrors(errors);
    }

    return error;
  };

  const prepareValue = (field: string): void => {
    const errorsLocal: Record<string, null | string> = Object.assign(
      {},
      errors
    );
    errorsLocal[field] = null;

    setErrors(errorsLocal);
    setIsValid(true);
  };

  const saveValue = (field: string, value: string, item: IFormItem) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    if (item.valueMode === 'array') {
      $fieldGroup.$valueList = value.split(', ');
    } else {
      $fieldGroup.$value = value;
    }
    props.cbFieldUpdates && props.cbFieldUpdates();
  };
  const saveValueList = (field: string, values: string[]) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    $fieldGroup.$valueList = values;
    props.cbFieldUpdates && props.cbFieldUpdates();
  };
  const saveValueSelect = (field: string, values: string[]) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    $fieldGroup.$valueSelect = values;
    props.cbFieldUpdates && props.cbFieldUpdates();
  };
  const saveValueComplex = (field: string, key: string, value: string) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    if (!$fieldGroup.$valueObject) {
      $fieldGroup.$valueObject = {};
    }
    if ($fieldGroup.$valueObject[key]) {
      $fieldGroup.$valueObject[key] = value;
    }
    props.cbFieldUpdates && props.cbFieldUpdates();
  };
  const saveValueDate = (field: string, value: Date) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    $fieldGroup.$valueDate = value;
    props.cbFieldUpdates && props.cbFieldUpdates();
  };

  const saveValueRecord = (
    field: string,
    index: null | number,
    key: string,
    value: string
  ) => {
    prepareValue(field);
    const $fieldGroup: undefined | IFormItem = props.fields[field];
    if (!$fieldGroup) {
      return;
    }

    if (!$fieldGroup.$valueRecord) {
      $fieldGroup.$valueRecord = [];
    }

    $fieldGroup.$valueRecord[
      index === null ? $fieldGroup.$valueRecord.length : index
    ] = {
      key,
      value,
    };
    props.cbFieldUpdates && props.cbFieldUpdates();
  };

  const handleCreate = () => {
    if (!validateAll(false, false)) {
      return;
    }

    if (!props.cbCreate) {
      return;
    }
    props.cbCreate();
  };
  const handleUpdate = () => {
    if (!validateAll(true, false)) {
      return;
    }

    props.cbUpdate();
  };

  const validateAll = (isUpdate: boolean, skipError: boolean): boolean => {
    let isValid: boolean = true;
    Object.keys(props.fields).forEach((field: string) => {
      const item: undefined | IFormItem = props.fields[field];
      if (!item) {
        return;
      }

      const isRequired: boolean = isUpdate ? false : item.isRequired;
      if (item.mode === 'images') {
        if (validateImages(skipError, item.$valueList, field, isRequired)) {
          isValid = false;
        }
        return;
      }
      if (item.mode === 'select') {
        if (validateSelect(skipError, item.$valueSelect, field, isRequired)) {
          isValid = false;
        }
        return;
      }

      if (item.valueMode === 'array') {
        const value: string[] = item.$valueList || [];
        if (
          validateValueList(
            skipError,
            field,
            isRequired,
            value,
            item.min,
            item.max
          )
        ) {
          isValid = false;
        }
        return;
      }

      if (item.valueMode !== 'object') {
        const value: string | null = item.$value || null;
        if (
          validateValue(skipError, field, isRequired, value, item.min, item.max)
        ) {
          isValid = false;
        }
        return;
      }

      if (!item.defaultObject) {
        if (
          validateValue(skipError, field, isRequired, null, item.min, item.max)
        ) {
          isValid = false;
        }
        return;
      }

      Object.keys(item.defaultObject).forEach((name) => {
        if (
          validateValue(
            skipError,
            field,
            isRequired,
            (item.$valueObject && item.$valueObject[name]) || null,
            item.min,
            item.max
          )
        ) {
          isValid = false;
        }
      });
    });

    setIsValid(isValid);
    return isValid;
  };

  const renderItem = (field: string): JSX.Element => {
    const item: undefined | IFormItem = props.fields[field];
    if (!item) {
      return <></>;
    }

    if (item.mode === 'images') {
      return (
        <FormFieldImage
          isDisabled={props.isDisabled}
          defaultValue={item.$valueList || null}
          valueError={errors[field] || null}
          saveValue={(images: string[]) => saveValueList(field, images)}
          isValidForm={isValid}
          title={item.title || field[0].toUpperCase() + field.slice(1)}
          limit={item.max || 1}
        />
      );
    }

    if (item.mode === 'select') {
      return (
        <FormFieldSelect
          isDisabled={props.isDisabled}
          valueError={errors[field] || null}
          saveValue={(selects: string[]) => saveValueSelect(field, selects)}
          title={item.title || field[0].toUpperCase() + field.slice(1)}
          nullNameBeforeSelect={item.nullNameBeforeSelect}
          defaultValue={item.$valueSelect || null}
          values={item.defaultSelect || []}
        />
      );
    }

    if (item.mode === 'date') {
      return (
        <BlockDates>
          <Label>
            <ContentDesc>
              {item.title || 'Select time or send immediately:'}
            </ContentDesc>
            <DatePicker
              selected={item.$valueDate}
              showTimeSelect
              dateFormat="Pp"
              onChange={(date: Date) => saveValueDate(field, date)}
            />
          </Label>
        </BlockDates>
      );
    }

    const mode: 'short' | 'long' | 'input' = item.mode;
    if (item.valueMode === 'object') {
      return (
        <>
          {Object.keys(item.defaultObject || item.$valueObject || {}).map(
            (name: string) => (
              <FormField
                isDisabled={props.isDisabled}
                mode={mode}
                modeInput={item.modeInput}
                saveValue={(value: string) =>
                  saveValueComplex(field, name, value)
                }
                defaultValue={
                  (item.$valueObject && item.$valueObject[name]) ||
                  (item.defaultObject && item.defaultObject[name]) ||
                  null
                }
                valueError={errors[field] || null}
                title={`${item.title || field} - ${name}`}
                placeholder={`Set ${props.placeholderTitle} ${
                  item.title || field
                } - ${name}`}
              />
            )
          )}
        </>
      );
    }

    if (item.valueMode === 'record') {
      return (
        <>
          {(item.$valueRecord || []).map(
            (record: IValueRecordItem, index: number) => (
              <FormFieldRecord
                isDisabled={props.isDisabled}
                saveValue={(key: string, value: string) =>
                  saveValueRecord(field, index, key, value)
                }
                record={record}
                valueError={errors[field] || null}
                title={`${item.title || field} - ${record.key}`}
                placeholder={`Set ${props.placeholderTitle} ${
                  item.title || field
                } - ${record.key}`}
              />
            )
          )}

          <ButtonBlock>
            <ActionButton onClick={() => saveValueRecord(field, null, '', '')}>
              Add one more {field}
            </ActionButton>
          </ButtonBlock>
        </>
      );
    }

    return (
      <FormField
        isDisabled={props.isDisabled}
        mode={mode}
        modeInput={item.modeInput}
        saveValue={(value: string) => saveValue(field, value, item)}
        defaultValue={
          item.valueMode === 'array'
            ? (item.$valueList && item.$valueList.join(', ')) || null
            : item.$value || null
        }
        valueError={errors[field] || null}
        title={item.title || field[0].toUpperCase() + field.slice(1)}
        placeholder={`Set ${props.placeholderTitle} ${item.title}`}
      />
    );
  };

  return (
    <FormGroup>
      {Object.keys(props.fields).map((field: string) => (
        <FormGroup className={props.isDisabled ? 'disabled' : ''} key={field}>
          {renderItem(field)}
        </FormGroup>
      ))}

      {props.renderElements && props.renderElements()}

      {!props.isDisabled && (
        <BlockActions>
          {props.isUpdate ? (
            <>
              <ButtonMain
                onClick={handleUpdate}
                className="success"
                disabled={props.isSending}
              >
                {props.isSending && <Loading size={'small'} />}
                Update
              </ButtonMain>
              {props.cbDelete && (
                <ButtonMain
                  onClick={props.cbDelete}
                  className="fail"
                  disabled={props.isSending}
                >
                  {props.isSending && <Loading size={'small'} />}
                  Remove
                </ButtonMain>
              )}
              {props.cbCancel && (
                <ButtonMain
                  onClick={props.cbCancel}
                  className="success"
                  disabled={props.isSending}
                >
                  {props.isSending && <Loading size={'small'} />}
                  Cancel
                </ButtonMain>
              )}
            </>
          ) : (
            <>
              <ButtonMain
                onClick={handleCreate}
                className="success"
                disabled={props.isSending}
              >
                {props.isSending && <Loading size={'small'} />}
                Create
              </ButtonMain>
              <ButtonMain
                onClick={props.cbCancel}
                className="fail"
                disabled={props.isSending}
              >
                {props.isSending && <Loading size={'small'} />}
                Cancel
              </ButtonMain>
            </>
          )}
        </BlockActions>
      )}
    </FormGroup>
  );
}
