import React, { useState } from 'react';
import { Switch } from '../forms/Switch';
import { ActionLink } from '../styles';
import { Block } from '../layout';

interface IItemViewProps {
  defaultParam: Record<string, boolean>;
  cb: (params: Record<string, boolean>) => void;
}

export function ItemView(props: IItemViewProps) {
  const keys: string[] = Object.keys(props.defaultParam);
  const [Views, setViews] = useState<Record<string, boolean>>(
    Object.assign({}, props.defaultParam),
  );

  const setValue = (key: string, value: boolean): void => {
    const ViewsLocal: Record<string, boolean> = Object.assign({}, Views);
    ViewsLocal[key] = value;
    setViews(ViewsLocal);
    props.cb(ViewsLocal);
  };

  const setForAll = (isShow: boolean) => {
    const ViewsLocal: Record<string, boolean> = Object.assign({}, Views);
    keys.forEach((key: string) => (ViewsLocal[key] = isShow));
    setViews(ViewsLocal);
    props.cb(ViewsLocal);
  };

  return (
    <>
      <Block>
        <ActionLink onClick={() => setForAll(true)}>Show all</ActionLink>
        <ActionLink onClick={() => setForAll(false)}>Hide all</ActionLink>
      </Block>

      <Block>
        {keys.map((key: string) => (
          <Switch
            isChecked={Views[key]}
            option={key}
            cb={(isChecked: boolean) => setValue(key, isChecked)}
            key={key}
          />
        ))}
      </Block>
    </>
  );
}
