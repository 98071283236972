import styled from 'styled-components';
import { colors } from '../colors';
import { fonts, typos } from '../typos';
import { margins, sizes } from '../sizes';
import { blocks } from '../layout';

export const OrderRow = styled.div`
  ${blocks.full};
`;

export const OrderBy = styled.h2`
  display: inline-block;
  float: left;
  ${typos.name};
  padding-top: ${margins.item};
  line-height: 2rem;
  color: ${colors.main};
  cursor: pointer;
  margin: ${margins.block} 0;
  margin-right: ${margins.block};
  min-width: ${sizes.widthS};

  &.active {
    color: ${colors.main};
    font-weight: ${fonts.weightS};
    text-decoration: underline;
  }

  &.fail {
    color: ${colors.lost};
  }

  &.success {
    color: ${colors.win};
  }

  &.small {
    font-size: ${fonts.sizeM};
    padding: 0;
    line-height: unset;
    margin: 0;
  }
`;
