import React, { useEffect, useState } from 'react';
import { ContentDescInline } from '../typos';
import { FormField } from './FormField';
import { IValueRecordItem } from './forms';

interface IFormFieldRecordProps {
  isDisabled?: boolean;
  record: IValueRecordItem;
  saveValue: (key: string, value: string) => void;
  valueError: null | string;
  title: string;
  placeholder: string;
  validationShouldExists?: number;
  validationMin?: number;
  validationMax?: number;
  customValidation?: () => null | string;
}

export function FormFieldRecord(props: IFormFieldRecordProps) {
  const [recordKey, setRecordKey] = useState<string>(props.record.key);
  const [recordValue, setRecordValue] = useState<string>(props.record.value);

  useEffect(() => {
    setRecordKey(props.record.key);
    setRecordValue(props.record.value);
  }, [props.record]);
  const saveValue = (key: null | string, value: null | string) => {
    if (key) {
      setRecordKey(key);
    }
    if (value) {
      setRecordValue(value);
    }
    props.saveValue(
      key !== null ? key : recordKey,
      value !== null ? value : recordValue,
    );
  };

  return (
    <>
      <ContentDescInline>
        {props.title !== '' ? props.title : 'add new param'}:{' '}
      </ContentDescInline>

      <FormField
        isDisabled={props.isDisabled}
        mode={'input'}
        saveValue={(key: string) => saveValue(key, null)}
        defaultValue={recordKey}
        valueError={props.valueError}
        title={props.record.keyName || 'key'}
        placeholder={props.placeholder}
      />

      <FormField
        isDisabled={props.isDisabled}
        mode={'short'}
        saveValue={(value: string) => saveValue(null, value)}
        defaultValue={recordValue}
        valueError={props.valueError}
        title={props.record.valueName || 'value'}
        placeholder={props.placeholder}
      />
    </>
  );
}
