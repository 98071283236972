import styled, { css } from 'styled-components';
import { device, margins, sizes, weights } from './sizes';
import { borders, transitions } from './styles';
import { ContentHead } from './typos';
import { colors } from './colors';

export const blocks = {
  full: css`
    display: block;
    float: left;
    width: ${sizes.full};
  `,
  half: css`
    display: block;
    float: left;
    width: ${sizes.half};
  `,
};
export const Page = styled.div`
  margin-top: 125px !important;

  @media ${device.mobileL} {
    margin-top: 75px !important;
  }
`;
export const Block = styled.div`
  margin: ${margins.item} 0;
  position: relative;
  float: left;
  display: block;
  width: ${sizes.full};

  &.hide {
    opacity: 50%;
  }

  &.noOverflow {
    overflow: hidden;
  }

  &.noMargin {
    margin: 0;
  }

  &.noWidth {
    width: unset;
  }

  &.sending {
    filter: opacity(0.5);
  }

  &.selected {
    color: ${colors.win};
  }

  &.clickable {
    cursor: pointer;
  }

  &.flex {
    display: flex;
    align-items: center;
    //justify-content: space-between;
  }

  &.flexTop {
    display: flex;
    align-items: start;
    //justify-content: space-between;
  }

  &.flexBetween {
    justify-content: space-between;
  }

  &.inline {
    margin: 0;
  }

  &.heightHalf {
    max-height: 50vh;
    position: relative;
    overflow: auto;
  }

  &.heightL {
    max-height: ${sizes.widthL};
    position: relative;
    overflow: auto;
  }

  &.heightM {
    max-height: ${sizes.widthM};
    position: relative;
    overflow: auto;
  }

  &.heightS {
    max-height: ${sizes.widthS};
    position: relative;
    overflow: auto;
  }

  &.widthXL {
    max-width: ${sizes.widthXL};
    float: none;
  }

  &.carousel {
    & .carousel-root {
      max-width: ${sizes.widthL};
      //width: ${sizes.full};
    }

    & .slide {
      padding-bottom: ${margins.blockX2};
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.moderation {
    padding-bottom: ${margins.item};
    border-bottom: ${borders.mainS};
  }

  &.hide {
    visibility: hidden;
  }

  & a.drag {
    display: flex;
    width: ${sizes.full};
  }

  &.left {
    text-align: left;
  }

  &.win {
    ${borders.win}
  }

  &.lost {
    ${borders.lost}
  }
`;
export const BlockHalf = styled.div`
  ${blocks.half};
`;
export const BlockDelimiter = styled.div`
  ${borders.main_bottom}
  margin: ${margins.block} 0
`;
export const BlockHead = styled(ContentHead)`
  // font-weight: ${weights.weightS};
  // border: none;
  // ${transitions.easy}

  &.clickable:hover {
    font-weight: ${weights.weightM};
    cursor: pointer;
  }

  &.clicked {
    font-weight: ${weights.weightL} !important;
    ${borders.main_bottom}
    ${transitions.easy}
  }
`;
export const Article = styled.div`
  height: auto;
  overflow: auto;

  &.hidden {
    height: 0;
    overflow: hidden;
  }
`;
export const CarouselBlock = styled.div`
  width: ${sizes.full};
  max-width: ${sizes.widthXL};

  & li.dot {
    width: 15px !important;
    height: 15px !important;
  }
`;
export const ItemInline = styled.div`
  display: flex;
  margin-bottom: ${margins.item};
  align-items: start;
  cursor: pointer;
`;
export const ItemInlineImageBlock = styled.div`
  width: 100px;
  margin-right: ${margins.item};

  &.win {
    ${borders.win}
  }
`;
export const ItemInlineContent = styled.div``;
