import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { ActionCase, IStateExample } from '../interfaces/system/IState';
import {
  EXAMPLE_BID_CREATED,
  EXAMPLE_BIDS_LOAD,
  EXAMPLE_LOT_LOAD,
  EXAMPLE_REPORT_AUCTION_LOAD,
  EXAMPLE_REPORT_LOT_LOAD,
  EXAMPLE_USERS_LOAD,
} from '../actions/examples/typesExample';
import { ILotShort, ILotStatus } from '../interfaces/examples/ILot';
import { IBid, IBidData } from '../interfaces/examples/IBid';
import { AccountIDExample } from '../config/examples/static-accounts';
import { IAccountExamplesSet } from '../interfaces/examples/IAccountExamples';
import { IReportLot } from '../interfaces/examples/IReportLot';
import { IReportAuction } from '../interfaces/examples/IReportAuction';

const initialStateAccount: IStateExample = {
  auctionMode: 'Lot',
  statusByLotID: {},
  currentLotID: null,
  lotByID: {},
  bidsByLotID: {},
  botsCntByLotID: {},
  users: {},
  reportLots: [],
  reportAuction: null,
};

function getStatus(
  bids: IBid[],
  accountID: null | string,
  lotWinCnt: number
): ILotStatus {
  if (!accountID) {
    return null;
  }

  for (let i = 0, len = lotWinCnt + 3; i < len; i++) {
    const bid: undefined | IBid = bids[i];
    if (!bid) {
      return null;
    }

    if (bid.accountID === accountID) {
      return i < lotWinCnt ? 'win' : 'lost';
    }
  }

  return null;
}

export const exampleReducer: Reducer = createReducer(
  initialStateAccount,
  (builder: ActionReducerMapBuilder<IStateExample>) => {
    builder.addCase<string, ActionCase<null | ILotShort>>(
      EXAMPLE_LOT_LOAD,
      (state: Draft<IStateExample>, action: ActionCase<null | ILotShort>) => {
        const lot: null | ILotShort = action.payload;
        if (lot) {
          const lotID: string = lot.id;
          state.currentLotID = lotID;
          state.lotByID[lotID] = lot;
        } else {
          state.currentLotID = null;
          state.auctionMode = 'FinalReport';
        }
      }
    );
    builder.addCase<string, ActionCase<IBidData>>(
      EXAMPLE_BIDS_LOAD,
      (state: Draft<IStateExample>, action: ActionCase<IBidData>) => {
        const { bids, lotID } = action.payload;
        state.bidsByLotID[lotID] = bids;
        state.statusByLotID[lotID] = getStatus(bids, AccountIDExample, 1);
      }
    );
    builder.addCase<string, ActionCase<IBidData>>(
      EXAMPLE_BID_CREATED,
      (state: Draft<IStateExample>, action: ActionCase<IBidData>) => {
        const { bids, lotID, isByBot } = action.payload;
        const bid: IBid = bids[0];
        state.bidsByLotID[lotID].unshift(bids[0]);
        state.statusByLotID[lotID] = getStatus(
          state.bidsByLotID[lotID],
          AccountIDExample,
          1
        );

        if (isByBot) {
          state.botsCntByLotID[lotID] = (state.botsCntByLotID[lotID] || 0) + 1;
        }
        state.lotByID[lotID].price = bid.price;
      }
    );
    builder.addCase<string, ActionCase<IAccountExamplesSet>>(
      EXAMPLE_USERS_LOAD,
      (
        state: Draft<IStateExample>,
        action: ActionCase<IAccountExamplesSet>
      ) => {
        state.users = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IReportLot>>(
      EXAMPLE_REPORT_LOT_LOAD,
      (state: Draft<IStateExample>, action: ActionCase<IReportLot>) => {
        state.currentLotID = null;
        state.reportLots.unshift(action.payload);
        state.auctionMode = 'ReportLot';
      }
    );
    builder.addCase<string, ActionCase<IReportAuction>>(
      EXAMPLE_REPORT_AUCTION_LOAD,
      (state: Draft<IStateExample>, action: ActionCase<IReportAuction>) => {
        if (action.payload) {
          state.reportAuction = action.payload;
          state.auctionMode = 'ReportAuction';
        } else {
          state.auctionMode = 'Lot';
        }
      }
    );
  }
);
