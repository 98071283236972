import React, { useEffect, useState } from 'react';
import { prepareTemplateParamsBatchKey } from '../../../actions/communication/templateParams.actions';
import { ContentDesc } from '../../../common/typos';
import { Block } from '../../../common/layout';
import {
  IEmailMode,
  IEmailModeMailingList,
} from '../../../interfaces/communication/IEmailLog';
import {
  IAppRecommendationType,
  IAppRecommendationTypeList,
} from '../../../interfaces/communication/IAppRecommendation';
import { FormFieldSelect } from '../../../common/forms/FormFieldSelect';
import {
  ITemplateParamsGroup,
  ITemplateParamsGroupList,
} from '../../../interfaces/communication/ITemplateParams';

export interface ITemplateParamsBatchFiltersParams {
  group: null | ITemplateParamsGroup;
  mode: null | IEmailMode;
  type: null | IAppRecommendationType;
  params: null | Record<string, string>;
}

interface ITemplateParamsBatchFiltersProps {
  cb: (batchKey: string, params: ITemplateParamsBatchFiltersParams) => void;
}

export function TemplateParamsBatchFilters(
  props: ITemplateParamsBatchFiltersProps
) {
  const [group, setGroup] = useState<null | ITemplateParamsGroup>(null);
  const [mode, setMode] = useState<null | IEmailMode>(null);
  const [type, setType] = useState<null | IAppRecommendationType>(null);
  const [params, setParams] = useState<null | Record<string, string>>(null);

  useEffect(() => {
    props.cb(prepareTemplateParamsBatchKey(group, mode, type, params), {
      group,
      mode,
      type,
      params,
    });
  }, [group, mode, type, params]);

  return (
    <Block className="article">
      <Block>
        <ContentDesc>Filter on group:</ContentDesc>
        <FormFieldSelect
          isDisabled={false}
          valueError={null}
          saveValue={(values: string[]) =>
            setGroup(values[0] as ITemplateParamsGroup)
          }
          // isValidForm={isValid}
          // title={item.title || field[0].toUpperCase() + field.slice(1)}
          defaultValue={mode ? [mode] : null}
          values={ITemplateParamsGroupList}
        />
      </Block>
      <Block>
        <ContentDesc>Filter on mode:</ContentDesc>
        <FormFieldSelect
          isDisabled={false}
          valueError={null}
          saveValue={(values: string[]) => setMode(values[0] as IEmailMode)}
          // isValidForm={isValid}
          // title={item.title || field[0].toUpperCase() + field.slice(1)}
          defaultValue={mode ? [mode] : null}
          values={IEmailModeMailingList}
        />
      </Block>

      <Block>
        <ContentDesc>Filter on type:</ContentDesc>
        <FormFieldSelect
          isDisabled={false}
          valueError={null}
          saveValue={(values: string[]) =>
            setType(values[0] as IAppRecommendationType)
          }
          // isValidForm={isValid}
          // title={item.title || field[0].toUpperCase() + field.slice(1)}
          defaultValue={mode ? [mode] : null}
          values={IAppRecommendationTypeList}
        />
      </Block>
    </Block>
  );
}
