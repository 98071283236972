import { callApi, catchError } from '../../service/api';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IApiError } from '../../interfaces/system/IApi';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { CacheRequests } from '../../service/cacheRequests';
import {
  IBaseAnalyticsAggregatedModelGetByPlatformQuery,
  IBaseAnalyticsAggregatedModelGetByPlatformResponse,
  IBaseAnalyticsAggregatedModelGetCacheAllResponse,
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetExtendedData,
  IBaseAnalyticsAggregatedModelGetExtendedQuery,
  IBaseAnalyticsAggregatedModelGetOptionsQuery,
  IBaseAnalyticsAggregatedModelGetOptionsResponse,
  IBaseAnalyticsAggregatedModelGetQuery,
  IBaseAnalyticsAggregatedModelGetResponse,
  IBaseAnalyticsAggregatedModelPutCacheByPlatformBody,
  IBaseAnalyticsAggregatedModelPutCacheByPlatformResonnse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import { IPlatformType } from '../../config/platform.config';
import { setError } from '../system/system.actions';

const cacheByID: CacheRequests = new CacheRequests();

export const loadAnalyticsMainWrapper =
  (url: string, dispatchType: string, from: string, to: string) =>
  (
    dispatch: IDispatch<
      IBaseAnalyticsAggregatedModelGetResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    const params: IBaseAnalyticsAggregatedModelGetQuery = { from, to };
    if (cacheByID.checkOneIsLoaded(`${dispatchType}-${from}-${to}`)) {
      return;
    }

    callApi<IBaseAnalyticsAggregatedModelGetResponse>(url, params)
      .then((data: IBaseAnalyticsAggregatedModelGetResponse) =>
        dispatch({ type: dispatchType, payload: data })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadAnalyticsMainByPlatformWrapper =
  (
    url: string,
    dispatchType: string,
    platformType: IPlatformType,
    from: string,
    to: string
  ) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetByPlatformResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const params: IBaseAnalyticsAggregatedModelGetByPlatformQuery = {
      platformType,
      from,
      to,
    };
    if (
      cacheByID.checkOneIsLoaded(
        `${dispatchType}=${platformType}-${from}-${to}`
      )
    ) {
      return;
    }

    callApi<IBaseAnalyticsAggregatedModelGetResponse>(url, params)
      .then((data: IBaseAnalyticsAggregatedModelGetResponse) => {
        const payload: IBaseAnalyticsAggregatedModelGetByPlatformResponse = {
          data,
          platformType,
        };
        dispatch({ type: dispatchType, payload });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadAnalyticsExtendedWrapper =
  (
    url: string,
    dispatchType: string,
    from: string,
    to: string,
    value: null | string,
    param: null | string
  ) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetExtendedData
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const params: IBaseAnalyticsAggregatedModelGetExtendedQuery = {
      from,
      to,
    };
    if (value) {
      params.value = value;
    }
    if (param) {
      params.param = param;
    }
    if (
      cacheByID.checkOneIsLoaded(
        `${dispatchType}-${from}-${to}-${value}-${param}`
      )
    ) {
      return;
    }

    callApi<IBaseAnalyticsAggregatedModelGetResponse>(url, params)
      .then((data: IBaseAnalyticsAggregatedModelGetResponse) => {
        const payload: IBaseAnalyticsAggregatedModelGetExtendedData = {
          ...data,
          value,
          param,
        };
        dispatch({ type: dispatchType, payload });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadAnalyticsOptionsWrapper =
  (url: string, dispatchType: string, from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetOptionsResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (cacheByID.checkOneIsLoaded(dispatchType)) {
      return;
    }

    const params: IBaseAnalyticsAggregatedModelGetOptionsQuery = {
      from,
      to,
    };

    callApi<IBaseAnalyticsAggregatedModelGetOptionsResponse>(url, params)
      .then((data: IBaseAnalyticsAggregatedModelGetOptionsResponse) =>
        dispatch({ type: dispatchType, payload: data })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadAnalyticsCacheWrapper =
  (url: string, dispatchType: string, hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!hardReload && cacheByID.checkOneIsLoaded(dispatchType)) {
      return;
    }

    callApi<IBaseAnalyticsAggregatedModelGetCacheResponse>(url)
      .then((data: IBaseAnalyticsAggregatedModelGetCacheResponse) =>
        dispatch({ type: dispatchType, payload: data })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadAnalyticsCacheAllWrapper =
  (url: string, dispatchType: string, hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheAllResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!hardReload && cacheByID.checkOneIsLoaded(dispatchType)) {
      return;
    }

    callApi<IBaseAnalyticsAggregatedModelGetCacheAllResponse>(url)
      .then((data: IBaseAnalyticsAggregatedModelGetCacheAllResponse) =>
        dispatch({ type: dispatchType, payload: data })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const saveAnalyticsCacheWrapper =
  (url: string) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    callApi<IBaseAnalyticsAggregatedModelPutCacheByPlatformResonnse>(
      url,
      undefined,
      undefined,
      'POST'
    )
      .then((data: IBaseAnalyticsAggregatedModelPutCacheByPlatformResonnse) =>
        setError('Cache is saved ' + data.cnt)(dispatch)
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const saveAnalyticsCacheByPlatformWrapper =
  (url: string, platformType: IPlatformType) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    const body: IBaseAnalyticsAggregatedModelPutCacheByPlatformBody = {
      platformType,
    };

    callApi<IBaseAnalyticsAggregatedModelPutCacheByPlatformResonnse>(
      url,
      undefined,
      body,
      'POST'
    )
      .then((data: IBaseAnalyticsAggregatedModelPutCacheByPlatformResonnse) =>
        setError('Cache is saved ' + data.cnt)(dispatch)
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
