import React from 'react';
import { ContentDescInline } from '../typos';
import { Block } from '../layout';
import { InputSwitch, LabelSwitch, SliderSwitch } from './Form.styles';

interface ISwitchProps {
  isChecked: boolean;
  options?: [string, string];
  option?: string;
  cb: (isChecked: boolean) => void;
}

export function Switch(props: ISwitchProps) {
  const failedOption: string = props.options
    ? props.options[0]
    : props.option
    ? 'Hide ' + props.option
    : 'Hide';
  const succeedOption: string = props.options
    ? props.options[1]
    : props.option
    ? 'Show ' + props.option
    : 'Show';
  const setChecked = (isChecked?: boolean): void => {
    props.cb(isChecked !== undefined ? isChecked : !props.isChecked);
  };

  return (
    <Block className="flex">
      <ContentDescInline
        className={!props.isChecked ? 'bold clickable' : 'clickable'}
        onClick={() => setChecked(false)}
      >
        {failedOption}
      </ContentDescInline>
      <ContentDescInline> </ContentDescInline>
      <LabelSwitch>
        <InputSwitch
          type="checkbox"
          checked={props.isChecked}
          onChange={() => setChecked()}
        />
        <SliderSwitch className="slider" />
      </LabelSwitch>
      <ContentDescInline> </ContentDescInline>
      <ContentDescInline
        className={props.isChecked ? 'bold clickable' : 'clickable'}
        onClick={() => setChecked(true)}
      >
        {succeedOption}
      </ContentDescInline>
    </Block>
  );
}
