import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentBold, ContentDesc, ContentHead } from '../../common/typos';
import { ActionLink } from '../../common/styles';
import { Block } from '../../common/layout';
import { AuctionItem } from './auctions.style';
import { renderAuctionDate } from '../../utils/renderParts';
import { makePathFromName } from '../../utils/routes';
import { AuctionTotals } from './AuctionTotals';
import { appConfig } from '../../config/app.config';
import {
  IAppAuctionPublic,
  IAppAuctionSet,
} from '../../interfaces/auctions/IAppAuction';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { Image } from '../../common/images/Image.styles';
import {
  IAppPartnerAuctionPublic,
  IAppPartnerAuctionSet,
} from '../../interfaces/partners/IAppPartnerAuction';
import { loadPartnerAuctionIDs } from '../../actions/partners/partnerAuction.actions';
import { L } from '../../common/locale';

interface IAuctionUpcomingProps {
  loadPartnerAuctionIDs: (hardReload?: boolean) => void;
  auction: IAppAuctionPublic;
  byAuctionID: IAppAuctionSet;
  auctionIDsByPlatform: Record<string, string[]>;
  IDs: string[];
  set: IAppPartnerAuctionSet;
  hidePartnerNavigation?: boolean;
}

function Auction(props: IAuctionUpcomingProps) {
  const navigate = useNavigate();
  const [partner, setPartner] = useState<null | IAppPartnerAuctionPublic>(null);
  useEffect(() => props.loadPartnerAuctionIDs(), []);
  useEffect(() => {
    props.IDs.map((partnerID: string) => {
      const i: null | IAppPartnerAuctionPublic = props.set[partnerID] || null;
      if (i && i.platformType === props.auction.platformType) {
        setPartner(i);
      }
    });
  }, [props.IDs, props.set]);

  const addToCalendar = () => {
    if (!props.auction.linkCalendar) {
      return;
    }

    window.open(props.auction.linkCalendar, '_blank');
  };

  const navigateToAuction = (auction: IAppAuctionPublic) => {
    if (!auction) {
      return;
    }
    const auctionPath = makePathFromName(auction.name);
    const path: string = appConfig.routes.auctions.one
      .replace(appConfig.params.auctionPath, auctionPath)
      .replace(appConfig.params.platformPath, auction.platformType);
    // const auctionIDs: string[] =
    //   props.auctionIDsByPlatform[auction.platformType] || [];
    // const list: string[] = [];
    // auctionIDs.forEach((auctionID: string) => {
    //   const i: IAppAuctionPublic = props.byAuctionID[auctionID];
    //   if (auction.name === i.name) {
    //     list.push(i.id);
    //   }
    // });
    // list.sort();
    // navigate(path + '-' + list.indexOf(auction.id));
    navigate(path);
  };
  const navigateToPartner = () => {
    if (!partner) {
      return;
    }

    navigate(
      appConfig.routes.partners.one.replace(
        appConfig.params.platformPath,
        partner.platformType
      )
    );
  };

  return (
    <AuctionItem>
      <Block
        onClick={() => navigateToAuction(props.auction)}
        className="clickable"
      >
        {props.auction.imgURL && (
          <Image
            className={'L'}
            src={props.auction.imgURL}
            alt={props.auction.name}
          />
        )}
      </Block>
      {!props.hidePartnerNavigation && partner && (
        <Block>
          <ContentHead>
            <ActionLink onClick={navigateToPartner}>
              <L k={'ActionMoreAbout'} /> {partner.name}
            </ActionLink>
          </ContentHead>
        </Block>
      )}
      <Block
        onClick={() => navigateToAuction(props.auction)}
        className="clickable"
      >
        <ContentDesc>
          <ContentBold>{props.auction.name}</ContentBold> -{' '}
          {props.auction.description}
        </ContentDesc>
        {props.auction.dateAuction &&
          renderAuctionDate(props.auction.dateAuction, { label: 'Live' })}
        {props.auction.dateWeek
          ? renderAuctionDate(props.auction.dateWeek, {
              label: 'Week',
            })
          : ''}
      </Block>

      {props.auction.linkCalendar && (
        <ContentDesc>
          <ActionLink onClick={addToCalendar}>
            <L k={'ActionAddCalendar'} />
          </ActionLink>
        </ContentDesc>
      )}

      {(props.auction.live || props.auction.week) && (
        <Block
          onClick={() => navigateToAuction(props.auction)}
          className="clickable"
        >
          <AuctionTotals auction={props.auction} isBig={false} />
        </Block>
      )}
    </AuctionItem>
  );
}

export default connect(
  (state: IState) => ({
    byAuctionID: state.Auctions.byAuctionID,
    auctionIDsByPlatform: state.Auctions.auctionIDsByPlatform,
    IDs: state.Partners.Auction.IDs,
    set: state.Partners.Auction.set,
  }),
  {
    loadPartnerAuctionIDs,
  }
)(Auction);
