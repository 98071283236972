import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IState,
  IStateAnalyticsGroupCache,
} from '../../interfaces/system/IState';
import { sendPageEvent } from '../../utils/analytics';
import {
  ActionButton,
  ActionLink,
  BlockPage,
  BlockPageContent,
} from '../../common/styles';
import AnalyticsMainHead from '../../common/moderation/AnalyticsMainHead';
import { Loading } from '../../common/animation/Loading';
import { AnalyticsCacheItems } from '../../components/analytics/AnalyticsCacheItems';
import {
  loadAnalyticsCache,
  saveAnalyticsCache,
} from '../../actions/analytics/analyticsAll.actions';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { loadAnalyticsRetentionOnlineNow } from '../../actions/analytics/behavioralRetention.actions';
import { BlockView } from '../../common/moderation/BlockView';

interface ICacheAnalyticsProps {
  loadAnalyticsCache: (hardReload: boolean) => void;
  loadAnalyticsRetentionOnlineNow: () => void;
  saveAnalyticsCache: () => void;
  retentionCache: null | IStateAnalyticsGroupCache;
  cronCache: null | IStateAnalyticsGroupCache;
  dbCache: null | IStateAnalyticsGroupCache;
  routeCache: null | IStateAnalyticsGroupCache;
  onlineNow: null | number;
}

function CacheAnalytics(props: ICacheAnalyticsProps) {
  const [isShowAll, setIsShowAll] = useState<boolean>();

  useEffect(() => sendPageEvent('CacheAnalytics'), []);
  useEffect(() => reload(), []);

  const reload = () => {
    props.loadAnalyticsCache(true);
    props.loadAnalyticsRetentionOnlineNow();
  };

  const save = () => {
    props.saveAnalyticsCache();
  };

  const hideAll = (): void => {
    setIsShowAll(false);
  };
  const showAll = (): void => {
    setIsShowAll(true);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <AnalyticsMainHead />

        {(!props.routeCache ||
          !props.dbCache ||
          !props.cronCache ||
          !props.retentionCache) && <Loading size={'big'} />}

        <ActionButton onClick={reload}>Reload</ActionButton>
        <ActionButton onClick={save}>Save Cache</ActionButton>

        <ContentDesc>
          {isShowAll === false ? (
            <ContentDescInline>hide all</ContentDescInline>
          ) : (
            <ActionLink onClick={hideAll}>hide all</ActionLink>
          )}{' '}
          {isShowAll === true || isShowAll === undefined ? (
            <ContentDescInline>show all</ContentDescInline>
          ) : (
            <ActionLink onClick={showAll}>show all</ActionLink>
          )}
        </ContentDesc>

        {props.onlineNow != null && (
          <ContentDesc>
            Now online:
            {props.onlineNow}
          </ContentDesc>
        )}
        {props.retentionCache && (
          <BlockView
            title={'Retention Cache'}
            default={isShowAll}
            element={<AnalyticsCacheItems items={props.retentionCache.items} />}
          />
        )}
        {props.routeCache && (
          <BlockView
            title={'Route Analytics Cache'}
            default={isShowAll}
            element={<AnalyticsCacheItems items={props.routeCache.items} />}
          />
        )}
        {props.dbCache && (
          <BlockView
            title={'DB Analytics Cache'}
            default={isShowAll}
            element={<AnalyticsCacheItems items={props.dbCache.items} />}
          />
        )}
        {props.cronCache && (
          <BlockView
            title={'Cron Analytics Cache'}
            default={isShowAll}
            element={<AnalyticsCacheItems items={props.cronCache.items} />}
          />
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    onlineNow: state.Analytics.retentionGroup.onlineNow,
    retentionCache: state.Analytics.retentionGroup.cache,
    cronCache: state.Analytics.cronGroup.cache,
    dbCache: state.Analytics.dbGroup.cache,
    routeCache: state.Analytics.routeGroup.cache,
  }),
  {
    loadAnalyticsCache,
    loadAnalyticsRetentionOnlineNow,
    saveAnalyticsCache,
  }
)(CacheAnalytics);
