import { dispatchInfo } from '../../service/error';
import { IDispatch } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { SYSTEM_SET_WIDTH } from '../types';

export const setError =
  (message: string) => (dispatch: IDispatch<IServiceInfo | IServiceError>) =>
    dispatchInfo(dispatch, message);

export const setContainerWidth =
  (width: number) => (dispatch: IDispatch<number>) => {
    dispatch({ type: SYSTEM_SET_WIDTH, payload: width });
  };
