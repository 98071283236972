import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { ActionCase, IStateCreateAuction } from '../interfaces/system/IState';
import { CREATE_AUCTION_ACTION, CREATE_AUCTION_LOAD } from '../actions/types';
import { ICreateAuctionResponse } from '../interfaces/CreateAuction/ICreateAuction';

const initialStateCreateAuction: IStateCreateAuction = {
  data: undefined,
  actions: [],
};

export const CreateAuctionReducer: Reducer = createReducer(
  initialStateCreateAuction,
  (builder: ActionReducerMapBuilder<IStateCreateAuction>) => {
    builder.addCase<string, ActionCase<ICreateAuctionResponse>>(
      CREATE_AUCTION_LOAD,
      (
        state: Draft<IStateCreateAuction>,
        action: ActionCase<ICreateAuctionResponse>
      ) => {
        state.data = action.payload.data;
      }
    );
    builder.addCase<string, ActionCase<string>>(
      CREATE_AUCTION_ACTION,
      (state: Draft<IStateCreateAuction>, action: ActionCase<string>) => {
        state.actions.unshift(action.payload);
      }
    );
  }
);
