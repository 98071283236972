import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { sendPageEvent } from '../../utils/analytics';
import {
  ActionButton,
  ActionLink,
  BlockPage,
  BlockPageContent,
} from '../../common/styles';
import AnalyticsMainHead from '../../common/moderation/AnalyticsMainHead';
import { Loading } from '../../common/animation/Loading';
import { IPlatformType, PlatformTypeList } from '../../config/platform.config';
import { SelectItemsList } from '../../common/moderation/SelectItemsList';
import {
  loadAnalyticsCacheAll,
  saveAnalyticsCacheByPlatform,
} from '../../actions/analytics/analyticsAll.actions';
import { IBaseAnalyticsModelCreate } from '../../interfaces/analytics/IBaseAnalyticsModel';
import { loadAnalyticsRetentionOnlineNowByPlatform } from '../../actions/analytics/behavioralRetention.actions';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { BlockView } from '../../common/moderation/BlockView';
import { AnalyticsCacheItems } from '../../components/analytics/AnalyticsCacheItems';

interface ICacheAllAnalyticsProps {
  loadAnalyticsCacheAll: (hardReload: boolean) => void;
  loadAnalyticsRetentionOnlineNowByPlatform: (
    platformType: IPlatformType
  ) => void;
  saveAnalyticsCacheByPlatform: (platformType: IPlatformType) => void;
  onlineNowByPlatform: Partial<Record<IPlatformType, null | number>>;
  retentionCacheAll: Partial<
    Record<
      IPlatformType,
      IBaseAnalyticsModelCreate<undefined | string, undefined | string>[]
    >
  >;
  cronCacheAll: Partial<
    Record<
      IPlatformType,
      IBaseAnalyticsModelCreate<undefined | string, undefined | string>[]
    >
  >;
  dbCacheAll: Partial<
    Record<
      IPlatformType,
      IBaseAnalyticsModelCreate<undefined | string, undefined | string>[]
    >
  >;
  routeCacheAll: Partial<
    Record<
      IPlatformType,
      IBaseAnalyticsModelCreate<undefined | string, undefined | string>[]
    >
  >;
}

function CacheAllAnalytics(props: ICacheAllAnalyticsProps) {
  const [platformType, setPlatformType] = useState<IPlatformType>('Main');
  const [isShowAll, setIsShowAll] = useState<boolean>();
  const [platformSet, setPlatformSet] = useState<Map<IPlatformType, string>>();

  useEffect(() => sendPageEvent('CacheAllAnalytics'), []);
  useEffect(() => reload(), []);
  useEffect(
    () => props.loadAnalyticsRetentionOnlineNowByPlatform(platformType),
    [platformType]
  );

  useEffect(() => {
    const set: Map<IPlatformType, string> = new Map<IPlatformType, string>();

    PlatformTypeList.forEach((platformType: IPlatformType) => {
      set.set(
        platformType,
        ' - ' +
          ((props.cronCacheAll[platformType]?.length || 0) +
            (props.dbCacheAll[platformType]?.length || 0) +
            (props.routeCacheAll[platformType]?.length || 0))
      );
    });

    setPlatformSet(set);
  }, [props.cronCacheAll, props.dbCacheAll, props.routeCacheAll]);

  const reload = () => {
    props.loadAnalyticsCacheAll(true);
    props.loadAnalyticsRetentionOnlineNowByPlatform(platformType);
  };

  const save = () => {
    props.saveAnalyticsCacheByPlatform(platformType);
  };

  const hideAll = (): void => {
    setIsShowAll(false);
  };
  const showAll = (): void => {
    setIsShowAll(true);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <AnalyticsMainHead />
        {(!props.routeCacheAll ||
          !props.dbCacheAll ||
          !props.cronCacheAll ||
          !props.retentionCacheAll) && <Loading size={'big'} />}
        <SelectItemsList
          cbSelected={setPlatformType}
          selected={platformType}
          items={PlatformTypeList}
          setAdditional={platformSet}
        />
        <ActionButton onClick={reload}>Reload</ActionButton>
        <ActionButton onClick={save}>Save Cache</ActionButton>

        <ContentDesc>
          {isShowAll === false ? (
            <ContentDescInline>hide all</ContentDescInline>
          ) : (
            <ActionLink onClick={hideAll}>hide all</ActionLink>
          )}{' '}
          {isShowAll === true || isShowAll === undefined ? (
            <ContentDescInline>show all</ContentDescInline>
          ) : (
            <ActionLink onClick={showAll}>show all</ActionLink>
          )}
        </ContentDesc>

        <ContentDesc>
          Now online:
          {props.onlineNowByPlatform[platformType]}
          {props.onlineNowByPlatform[platformType] === null && (
            <Loading size={'small'} />
          )}
        </ContentDesc>
        {props.retentionCacheAll && props.retentionCacheAll[platformType] && (
          <BlockView
            title={'Retention Cache'}
            default={isShowAll}
            element={
              <AnalyticsCacheItems
                items={props.retentionCacheAll[platformType] || []}
              />
            }
          />
        )}
        {props.routeCacheAll && props.routeCacheAll[platformType] && (
          <BlockView
            title={'Route Analytics Cache'}
            default={isShowAll}
            element={
              <AnalyticsCacheItems
                items={props.routeCacheAll[platformType] || []}
              />
            }
          />
        )}
        {props.dbCacheAll && props.dbCacheAll[platformType] && (
          <BlockView
            title={'DB Analytics Cache'}
            default={isShowAll}
            element={
              <AnalyticsCacheItems
                items={props.dbCacheAll[platformType] || []}
              />
            }
          />
        )}
        {props.cronCacheAll && props.cronCacheAll[platformType] && (
          <BlockView
            title={'Cron Analytics Cache'}
            default={isShowAll}
            element={
              <AnalyticsCacheItems
                items={props.cronCacheAll[platformType] || []}
              />
            }
          />
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    onlineNowByPlatform: state.Analytics.retentionGroup.onlineNowByPlatform,
    retentionCacheAll: state.Analytics.retentionGroupByPlatform.cache,
    cronCacheAll: state.Analytics.cronGroupByPlatform.cache,
    dbCacheAll: state.Analytics.dbGroupByPlatform.cache,
    routeCacheAll: state.Analytics.routeGroupByPlatform.cache,
  }),
  {
    loadAnalyticsCacheAll,
    loadAnalyticsRetentionOnlineNowByPlatform,
    saveAnalyticsCacheByPlatform,
  }
)(CacheAllAnalytics);
