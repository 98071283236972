import { appConfig } from './app.config';

export type IPlatformType = keyof IPlatforms<any>;

export const PlatformTypeList: IPlatformType[] = [
  'Main',
  'aimprosoft',
  'artukraine',
  'keenethics',
  'zoola',
  'example',
  'tochka',
  'local',
];
export const PlatformTypeProdList: IPlatformType[] = [
  'aimprosoft',
  'artukraine',
  'keenethics',
  'tochka',
  'zoola',
];

export interface IPlatforms<T> {
  Main: T;
  aimprosoft: T;
  artukraine: T;
  keenethics: T;
  zoola: T;
  example: T;
  tochka: T;
  shukLive: T;
  shukWeek: T;
  local: T;
}

export const platformConfigs: IPlatforms<string> = {
  Main: 'https://create.auction',
  aimprosoft: 'https://aimprosoft.charity',
  artukraine: 'https://artukraine.auction',
  keenethics: 'https://keenethics.charity',
  zoola: 'https://zoola-charity.com',
  example: 'https://example.create.auction',
  tochka: 'https://tochka.create.auction',
  shukLive: 'https://shuk-live.create.auction',
  shukWeek: 'https://shuk-week.create.auction',
  local: 'https://create-auction-app.web.app',
};

export const platformPageTitle: IPlatforms<string> = {
  Main: 'Create Auction',
  aimprosoft: 'Aimpro.Soft Charity Auction',
  artukraine: 'ART Ukraine Auction',
  keenethics: 'Keenethics Charity Auction',
  zoola: 'Zoola Charity Auction',
  example: 'Example Auction',
  tochka: 'Аукціон Точки Збору',
  shukLive: '[тестовий] Аукціон Подільского Шуку (лайв)',
  shukWeek: '[тестовий] Аукціон Подільского Шуку (тижневий)',
  local: 'Local Auction',
  // local: 'ART Ukraine Auction',
};

export function getPlatformType(): IPlatformType {
  const { isDev, devPlatform } = appConfig;
  if (isDev && devPlatform) {
    if (devPlatform === 'artukraine') {
      return 'artukraine';
    } else if (devPlatform === 'aimprosoft') {
      return 'aimprosoft';
    } else if (devPlatform === 'keenethics') {
      return 'keenethics';
    } else if (devPlatform === 'zoola') {
      return 'zoola';
    } else if (devPlatform === 'example') {
      return 'example';
    } else if (devPlatform === 'tochka') {
      return 'tochka';
    } else if (devPlatform === 'shukLive') {
      return 'shukLive';
    } else if (devPlatform === 'shukWeek') {
      return 'shukWeek';
    } else {
      return 'local';
    }
  }

  return getRealPlatformType();
}

export function getRealPlatformType(): IPlatformType {
  const origin: string = window.location.origin;
  if (origin === platformConfigs.artukraine) {
    return 'artukraine';
  } else if (origin === platformConfigs.aimprosoft) {
    return 'aimprosoft';
  } else if (origin === platformConfigs.keenethics) {
    return 'keenethics';
  } else if (origin === platformConfigs.zoola) {
    return 'zoola';
  } else if (origin === platformConfigs.example) {
    return 'example';
  } else if (origin === platformConfigs.tochka) {
    return 'tochka';
  } else if (origin === platformConfigs.shukLive) {
    return 'shukLive';
  } else if (origin === platformConfigs.shukWeek) {
    return 'shukWeek';
  } else {
    // return 'temp';
    return 'local';
  }
}

export function getPlatformHeader(): string {
  const platformType: IPlatformType = getPlatformType();
  if (platformType === 'artukraine') {
    return platformPageTitle.artukraine;
  } else if (platformType === 'aimprosoft') {
    return platformPageTitle.aimprosoft;
  } else if (platformType === 'keenethics') {
    return platformPageTitle.keenethics;
  } else if (platformType === 'zoola') {
    return platformPageTitle.zoola;
  } else if (platformType === 'example') {
    return platformPageTitle.example;
  } else if (platformType === 'tochka') {
    return platformPageTitle.tochka;
  } else if (platformType === 'shukLive') {
    return platformPageTitle.shukLive;
  } else if (platformType === 'shukWeek') {
    return platformPageTitle.shukWeek;
  } else {
    return platformPageTitle.local;
  }
}

export function checkIsExample(): boolean {
  return getPlatformType() === 'example';
}

export function validatePlatform(value: null | string): null | IPlatformType {
  if (!value) {
    return null;
  }

  if (value === 'Main') {
    return 'Main';
  } else if (value === 'keenethics') {
    return 'keenethics';
  } else if (value === 'aimprosoft') {
    return 'aimprosoft';
  } else if (value === 'zoola') {
    return 'zoola';
  } else if (value === 'example') {
    return 'example';
  } else if (value === 'artukraine') {
    return 'artukraine';
  } else {
    return null;
  }
}

export function initPlatformSet<T>(value: T): IPlatforms<T> {
  return {
    shukLive: value,
    shukWeek: value,
    tochka: value,
    Main: value,
    aimprosoft: value,
    artukraine: value,
    keenethics: value,
    zoola: value,
    example: value,
    local: value,
  };
}

export function initPlatformSetConstructor<T>(init: () => T): IPlatforms<T> {
  return {
    shukLive: init(),
    shukWeek: init(),
    tochka: init(),
    Main: init(),
    aimprosoft: init(),
    artukraine: init(),
    keenethics: init(),
    zoola: init(),
    example: init(),
    local: init(),
  };
}
