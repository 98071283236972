import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import CreateAuctionForm from '../../components/CreateAuction/CreateAuctionForm';
import { ICreateAuctionPublic } from '../../interfaces/CreateAuction/ICreateAuction';
import { Loading } from '../../common/animation/Loading';
import { ContentBold, ContentHead } from '../../common/typos';
import ModerationMainHead from '../../common/moderation/ModerationMainHead';
import { BlockPage, BlockPageContent } from '../../common/styles';
import { loadCreateAuction } from '../../actions/CreateAuction/CreateAuction.actions';

interface ICreateAuctionProps {
  loadCreateAuction: () => void;
  data: undefined | null | ICreateAuctionPublic;
}

function CreateAuction(props: ICreateAuctionProps) {
  useEffect(() => props.loadCreateAuction(), []);
  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />
        <ContentHead>Create Auction moderation</ContentHead>

        {props.data && <CreateAuctionForm isDisabled={false} />}
        {props.data === undefined && <Loading size={'big'} />}
        {props.data === null && (
          <ContentHead>
            <ContentBold>No service information</ContentBold>
          </ContentHead>
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    data: state.CreateAuction.data,
  }),
  { loadCreateAuction }
)(CreateAuction);
