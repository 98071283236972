import React from 'react';
import { Block } from '../layout';
import { ContentDesc } from '../typos';

interface ISelectItemsListProps<Item> {
  isDisabled?: boolean;
  cbSelected: (item: Item) => void;
  selected: null | Item;
  items: Item[];
  setAdditional?: Map<Item, string>;
}

export function SelectItemsList<Item>(props: ISelectItemsListProps<Item>) {
  return (
    <Block className="article">
      {props.items.map((item: Item) => (
        <Block
          key={String(item)}
          onClick={() => props.cbSelected(item)}
          className={
            'clickable flex ' + (item === props.selected ? 'selected' : '')
          }
        >
          <input
            type="radio"
            checked={item === props.selected}
            onChange={() => undefined}
          />

          <ContentDesc>
            {String(item)}
            {props.setAdditional && props.setAdditional.get(item)}
          </ContentDesc>
        </Block>
      ))}
    </Block>
  );
}
