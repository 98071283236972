import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../../utils/analytics';
import { ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { FormBlock } from '../../../common/forms/Form.styles';
import { Form, IFormItem } from '../../../common/forms/Form';
import {
  IAppRecommendationPublic,
  IAppRecommendationSet,
  IAppRecommendationSocial,
  IAppRecommendationType,
} from '../../../interfaces/communication/IAppRecommendation';
import {
  createRecommendation,
  updateRecommendation,
} from '../../../actions/communication/recommendation.actions';
import {
  FormsFields,
  transformRecordToSave,
  transformToRecord,
} from '../../../common/forms/forms';

interface IRecommendationFormProps {
  recommendation?: null | IAppRecommendationPublic;
  isDisabled?: boolean;
  createRecommendation: (
    name: string,
    type: IAppRecommendationType,
    emails: string[],
    url: string,
    social: IAppRecommendationSocial,
    geo: string,
    parsedID: undefined | string,
    categories: undefined | string[],
    params?: null | Record<string, string>
  ) => void;
  updateRecommendation: (
    recommendationID: string,
    name: null | string,
    type: null | IAppRecommendationType,
    emails: null | string[],
    url: null | string,
    social: null | IAppRecommendationSocial,
    geo: null | string,
    parsedID: undefined | null | string,
    categories: undefined | null | string[],
    params: undefined | null | Record<string, string>
  ) => void;
  cbCancel: () => void;
  cbDelete?: (recommendationID: string) => void;
  set: IAppRecommendationSet;
  actions: string[];
  errors: string[];
}

const fields: Partial<
  Record<keyof IAppRecommendationPublic, undefined | IFormItem>
> = {
  name: {
    ...FormsFields.name,
  },
  geo: {
    ...FormsFields.geo,
  },
  url: {
    ...FormsFields.url,
  },
  social: {
    ...FormsFields.social,
    $valueObject: {},
  },
  emails: {
    ...FormsFields.emails,
    $valueList: [],
  },
  params: {
    ...FormsFields.params,
    isRequired: false,
    $valueRecord: transformToRecord(null),
  },
};

function RecommendationForm(props: IRecommendationFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => sendEvent('Open-RecommendationForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.recommendation) {
      return;
    }

    if (fields.name) {
      fields.name.$value = props.recommendation.name;
    }
    if (fields.geo) {
      fields.geo.$value = props.recommendation.geo;
    }
    if (fields.url) {
      fields.url.$value = props.recommendation.url;
    }
    if (fields.social) {
      fields.social.$valueObject = Object.assign(
        {},
        props.recommendation.social
      );
    }
    if (fields.emails) {
      fields.emails.$valueList = props.recommendation.emails;
    }
    if (fields.params) {
      fields.params.$valueRecord = transformToRecord(
        props.recommendation.params
      );
    }
  }, [props.recommendation]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const handleCreate = () => {
    setIsSending(true);
    props.createRecommendation(
      (fields.name && fields.name.$value) || '',
      'it-company',
      (fields.emails && fields.emails.$valueList) || [],
      (fields.url && fields.url.$value) || '',
      (fields.social && fields.social.$valueObject) || {},
      (fields.geo && fields.geo.$value) || '',
      (fields.parsedID && fields.parsedID.$value) || '',
      [],
      transformRecordToSave(fields.params?.$valueRecord)
    );
  };
  const handleUpdate = () => {
    if (!props.recommendation) {
      return;
    }

    setIsSending(true);
    props.updateRecommendation(
      props.recommendation.id,
      (fields.name && fields.name.$value) || null,
      'it-company',
      (fields.emails && fields.emails.$valueList) || null,
      (fields.url && fields.url.$value) || null,
      (fields.social && fields.social.$valueObject) || null,
      (fields.geo && fields.geo.$value) || null,
      (fields.parsedID && fields.parsedID.$value) || null,
      null,
      transformRecordToSave(fields.params?.$valueRecord)
    );
  };
  const handleDelete = () => {
    if (!props.recommendation || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.recommendation.id);
  };

  return (
    <FormBlock>
      {!props.recommendation && (
        <ContentHead>New Recommendation creation</ContentHead>
      )}
      {props.recommendation && <ContentHead>Recommendation update</ContentHead>}

      <Form
        isDisabled={props.isDisabled}
        placeholderTitle={'auction recommendation'}
        fields={fields}
        isUpdate={!!props.recommendation}
        isSending={isSending}
        cbCancel={props.cbCancel}
        cbCreate={handleCreate}
        cbUpdate={handleUpdate}
        cbDelete={handleDelete}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Communication.Recommendation.set,
    actions: state.Communication.Recommendation.actions,
    errors: state.System.errors,
  }),
  {
    createRecommendation,
    updateRecommendation,
  }
)(RecommendationForm);
