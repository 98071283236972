import styled from 'styled-components';
import { colors } from '../../common/colors';
import { blocks } from '../../common/layout';
import { fonts } from '../../common/typos';
import { margins } from '../../common/sizes';

export const SearchInput = styled.input`
  //text-align: center;
  font-size: ${fonts.sizeM};
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${colors.main};
  background-color: transparent;
  outline: none;
  //width: calc(100% - 25px);
  width: 100%;
  box-sizing: border-box;

  :focus {
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }
`;

export const AuctionItem = styled.div`
  ${blocks.full};
  margin-bottom: ${margins.blockX2};

  &.clickable {
    cursor: pointer;
  }
`;
