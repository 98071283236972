import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ContentDesc, ContentHead } from '../../../common/typos';
import { IState } from '../../../interfaces/system/IState';
import { IEmailMode } from '../../../interfaces/communication/IEmailLog';
import {
  loadTemplateParamsBatch,
  prepareTemplateParamsBatchKey,
} from '../../../actions/communication/templateParams.actions';
import {
  ITemplateParamsGroup,
  ITemplateParamsPublic,
  ITemplateParamsSet,
} from '../../../interfaces/communication/ITemplateParams';
import { IAppRecommendationType } from '../../../interfaces/communication/IAppRecommendation';
import { DataBatchWide } from '../../../interfaces/system/data';
import { Block } from '../../../common/layout';
import { ActionButton, ActionLink } from '../../../common/styles';

interface ITemplateParamsSelectProps {
  group: ITemplateParamsGroup;
  mode: null | IEmailMode;
  loadTemplateParamsBatch: (
    group: null | ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    params: null | Record<string, string>,
    extraLimit?: number
  ) => void;
  cbValue: (value: string) => void;
  byBatchKey: Record<string, DataBatchWide>;
  set: ITemplateParamsSet;
}

function TemplateParamsSelect(props: ITemplateParamsSelectProps) {
  const [batchKey, setBatchKey] = useState<string>(
    prepareTemplateParamsBatchKey(null, props.mode, null, null)
  );
  const [templates, setTemplates] = useState<ITemplateParamsPublic[]>([]);
  const [isShow, setIsShow] = useState<boolean>(true);
  const [valueShowMore, setValueShowMore] = useState<Record<string, boolean>>(
    {}
  );

  useEffect(
    () =>
      setBatchKey(prepareTemplateParamsBatchKey(null, props.mode, null, null)),
    [props.mode]
  );
  useEffect(
    () => props.loadTemplateParamsBatch(null, props.mode, null, null),
    [batchKey]
  );

  useEffect(() => {
    if (!batchKey) {
      return;
    }

    if (!props.byBatchKey[batchKey]) {
      return;
    }

    const templatesLocal: ITemplateParamsPublic[] = [];

    const templateIDs: string[] = props.byBatchKey[batchKey].IDs;
    templateIDs.forEach((templateID: string) => {
      const template: ITemplateParamsPublic = props.set[templateID];
      if (!template) {
        return;
      }

      if (template.group === props.group) {
        templatesLocal.push(template);
      }
    });

    setTemplates(templatesLocal);
  }, [batchKey, props.byBatchKey, props.set]);

  const toggleShowMore = (templateID: string) => {
    const valueShowMoreLocal = { ...valueShowMore };
    valueShowMoreLocal[templateID] = !valueShowMoreLocal[templateID];
    setValueShowMore(valueShowMoreLocal);
  };

  return (
    <>
      {templates[0] && (
        <ContentHead className={'clickable'} onClick={() => setIsShow(!isShow)}>
          Select {props.group} from templates ({templates.length}):
        </ContentHead>
      )}

      {isShow &&
        templates.map((template: ITemplateParamsPublic) => (
          <Block key={template.id}>
            <ActionButton onClick={() => props.cbValue(template.value)}>
              select
            </ActionButton>
            {template.value.length > 50 ? (
              <>
                {valueShowMore[template.id] && (
                  <>
                    <ContentDesc>{template.value.slice(0, 50)}...</ContentDesc>
                    <ActionLink onClick={() => toggleShowMore(template.id)}>
                      show more
                    </ActionLink>
                  </>
                )}

                {!valueShowMore[template.id] && (
                  <>
                    <ActionLink onClick={() => toggleShowMore(template.id)}>
                      show less
                    </ActionLink>
                    <ContentDesc>{template.value}</ContentDesc>
                  </>
                )}
              </>
            ) : (
              <>
                <ContentDesc>{template.value}</ContentDesc>
              </>
            )}
          </Block>
        ))}
    </>
  );
}

export default connect(
  (state: IState) => ({
    byBatchKey: state.Communication.TemplateParams.byBatchKey,
    set: state.Communication.TemplateParams.set,
  }),
  {
    loadTemplateParamsBatch,
  }
)(TemplateParamsSelect);
