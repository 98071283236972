import { ITranslationAllTypes } from './_.types';
import { ContentDesc } from '../../common/typos';
import React from 'react';

export const translationEN: ITranslationAllTypes = {
  // auctions
  AuctionsTitle: 'Auctions',
  AuctionsTitleEmpty: 'Here will be Auctions information',
  AuctionsTitleUpcoming: 'Upcoming Auction',
  AuctionsTitleUpcomingList: 'Upcoming Auctions:',
  AuctionsTitleUpcomingShort: 'Upcoming:',
  AuctionsTitleCompleted: 'Completed Auctions:',
  AuctionsTitleCompletedShort: 'Completed:',
  AuctionsDonations: 'Donations:',
  AuctionsDonationsPlace: `Auction Donations would be here after auction will be complete and information would be updated`,
  AuctionLotsAll: 'Show all lots',
  AuctionLotsLive: 'Live Auction lots',
  AuctionLotsLiveAll: 'Show all Live Auction lot reports',
  AuctionLotsWeek: 'Week Auction lots',
  AuctionLotsWeekAll: 'Show all Week Auction lot reports',
  AuctionLotsEmpty:
    'Auction results would be here after auction will be complete',
  AuctionDonatorsAll: 'Show all donators',
  // auction
  AuctionBid: 'bid',
  AuctionBidCurrent: 'Current Bid:',
  AuctionBidCustom: 'Make your offer:',
  AuctionBidCustomPlaceholder: 'or enter you offer',
  AuctionBiddersMultiple: 'donators will win this lot',
  AuctionStatusWin: 'Current winner',
  AuctionStatusLost: 'You lost bid',
  AuctionLotCurrent: 'Current Lot:',
  AuctionAdminNoLots: 'No lots to start auction',
  AuctionTitle: 'You win the auction -<line />Ukraine win the war!',
  // account
  AccountDonationTitle: 'Your donation by each Auctions',
  AccountDonationTitleDetails: 'Donation details:',
  AccountDonationItem: 'Donation:',
  AccountDonationLink: 'Link to make payment:',
  AccountDonationScreenshot: 'Payment screenshot:',
  AccountDonationScreenshotMake: 'Add payment screenshot',
  AccountLotCreateTitle: `* Product will be sent to moderation after submit.`,
  AccountLotCreateDescription: `Description would be extended and standardize according to general auction style.<line />You can add just short description and main information.`,
  AccountLotCreateAction: `Create a Lot`,
  AccountLots: 'Your lots',
  AccountLotsLiveTitle: 'Your lots from Live Auction:',
  AccountLotsWeekTitle: 'Your lots from Week Auction:',
  AccountLotsOrderLiked: 'Liked Lots',
  AccountLotsOrderCreated: 'Created Lots',
  AccountLotsOrderCreatedEmpty: 'You have not added any lot yet',
  AccountLotsOrderDeclined: 'Declined Lots',
  AccountLotsOrderDeclinedReason: 'Reason:',
  AccountLotsOrderDeclinedEmpty: 'No declined Lots',
  AccountLotsOrderSold: 'Sold lots',
  AccountLotsOrderSoldEmpty: 'You have not sold any lot yet',
  AccountLotsOrderDonated: 'Donated for',
  AccountLotsOrderDonatedEmpty: 'You have not donated for any lot yet',
  AccountSettings: `Setting up your profile`,
  AccountSettingsPartner: `Setting up your partner profile`,
  AccountSettingsSubscription: `Your subscription preferences`,
  AccountSettingsAuction: 'Get Auction Updates',
  AccountSettingsLot: 'Get Your Lot Updates',
  AccountSettingsBid: 'Your Bid Updates',
  AccountSettingsUnsubscribeTitle: "We're sorry to see you go!",
  AccountSettingsUnsubscribeDesc:
    "If you'd like to unsubscribe from our emails and notifications," +
    'please update your preferences or use "Unsubscribe" button. You' +
    "won't receive any further updates from us.",
  AccountLoginCall: `Please <bold>Log in</bold> to have access to the auction<line />and be a part of something special!`,
  AccountLoginCallAuction: `The auction has started.<line/> You need to <bold>Log in</bold> to enter!`,
  AccountLoginCallAction: `Please <bold>Log in</bold> to `,
  // shop
  ShopTitleMain: 'Discover the lots',
  ShopTitleCatalog: 'Catalog of possible Lots for Auction',
  ShopTitlePartners: 'Discover our partners',
  ShopTitlePartnersShort: 'Our partners:',
  ShopTitleSellers: 'Discover our sellers',
  LotTitleNoLots: 'No lots at shop yet',
  // lot
  LotTitleFresh: 'The <bold>newest</bold> lots:',
  LotTitleLiked: 'Most <bold>liked</bold> lots:',
  LotTitlePopular: 'Most <bold>popular</bold> lots:',
  LotTitlePartners: 'Lots from our <bold>partners</bold>:',
  // products
  ProductBy: 'Product by',
  ProductByShort: 'by',
  ProductByPartners: 'Products by partners:',
  // items
  ItemsEmpty: 'No items created yet',
  // analytics
  AnalyticsTitleCreate: 'We in numbers:',
  AnalyticsLots: 'lots',
  AnalyticsViews: 'views',
  AnalyticsViewAdmin: '* only one view from the seller and admin',
  AnalyticsLikes: 'likes',
  AnalyticsBids: 'bids',
  AnalyticsFollowers: 'followers',
  AnalyticsAuctions: 'auctions',
  AnalyticsGMV: 'raised already',
  AnalyticsTotalProducts: 'Total for all products:',
  // reports
  ReportsCurrentWeek: 'Current Week Auction reports:',
  ReportsCurrent: 'Current Total:',
  ReportsTotal: 'Total results:',
  ReportsTotalShop: 'Shop results:',
  ReportsLots: 'Lots:',
  ReportsBids: 'Bids:',
  ReportsLikes: 'Likes:',
  ReportsAttenders: 'Attenders:',
  ReportsSellers: 'Sellers:',
  ReportsFollowers: 'Followers:',
  ReportsViewsAll: 'Collected views:',
  ReportsViewsUnique: 'Unique lot views:',
  ReportsTopLotPrice: 'Top valuable lot:',
  ReportsTopLotTotal: 'Top expensive lots:',
  ReportsTopLotPopular: 'Top popular lot:',
  ReportsDonatorsCurrent: 'Top (current) donators:',
  ReportsTopBidders: 'Top bidders:',
  ReportsTopSellers: 'Top sellers:',
  ReportsCurrencyUAH: 'UAH',
  HallTitle: 'Hall of Fame',
  HallSortDonated: 'Donated Total',
  HallSortSold: 'Sold Total',
  // orders
  OrderNew: 'Freshest',
  OrderPopular: 'Top popular',
  OrderLiked: 'Top liked',
  // archive
  ReportsUserTitle: 'Account Reports',
  ReportsLotTitleLive: 'Lot Reports from Live Auction',
  ReportsLotTitleWeek: 'Lot Reports from Week Auction',
  ReportsLotOrderAuction: 'By auction order',
  ReportsLotOrderTotal: 'Top expensive',
  ReportsLotOrderBidCnt: 'Top popular',
  ReportsLotOrderBuyTotal: 'Top donators',
  ReportsLotOrderSellTotal: 'Top sellers',
  ReportsLotAll: "Show all account' reports",
  // moderation

  // buttons
  ButtonLotsAll: 'See all lots',
  ButtonLotsFresh: 'All lots',
  ButtonLotsPopular: 'All popular lots',
  ButtonLotAdd: 'Add your lot',
  ButtonLotAddFirst: 'Add first lot',
  ButtonLotEdit: 'Edit lot',
  ButtonLoadMore: 'Load more',
  ButtonSave: 'Save',
  // actions
  ActionLogIn: 'Log in',
  ActionLogInToLike: 'to like',
  ActionYes: 'yes',
  ActionNo: 'no',
  ActionUnsubscribe: 'Unsubscribe all',
  ActionSubscribe: 'Subscribe all',
  ActionGoToShop: 'Go to shop',
  ActionStart: 'Start',
  ActionReport: 'Report',
  ActionAuctionEnter: 'Enter the auction:',
  ActionLandingAuctions: 'See lots and auctions',
  ActionLandingAuction: 'Visit auction site',
  ActionLandingPartners: 'See all partners',
  ActionMoreAbout: 'More about',
  ActionAddCalendar: 'Add to calendar',
  // menu
  MenuAuctionLive: 'Live Auction',
  MenuAuctionWeek: 'Week Auction',
  MenuHall: 'Hall of Fame',
  MenuAuctions: 'Auctions',
  MenuShop: 'Shop',
  MenuModeration: 'Moderation',
  MenuAnalytics: 'Analytics',
  MenuMyLot: 'My Lots',
  MenuMyDonations: 'My Donations',
  MenuSettings: 'Settings',
  MenuPartnerSettings: 'Partner Settings',
  MenuCreate: 'We in numbers',
  MenuPartners: 'Partners',
  MenuLogIn: 'Log in',
  MenuLogout: 'Logout',
  // fields
  FieldsName: 'name',
  // landing
  LandingMainDescription:
    'Create Auction is a platform for creating and hosting charity auctions. ' +
    'With just one lot to start, you can create your own charity story and raise money for the causes you care about.',
  LandingMainQuestionWhom: 'For Whom?',
  LandingMainQuestionWhy: 'Why Create Auction?',
  LandingMainQuestionFormats: 'What Auction format to choose?',
  LandingMainQuestionSupport: 'How can we support your auction?',
  LandingMainQuestionSupportAnswer:
    "It's not just about technology.<line />We are here to help you organize successful charity auction:",
  LandingMainQuestionLive:
    'Can you win lot right now and here (on live auction)?',
  LandingMainSupportOptions: [
    `Help with organizing a charity evening`,
    `Lot moderation process`,
    `Generate first lots and examples of next`,
    `Add magic to your event`,
  ],
  LandingMainMainFeatures: [
    `🎯 Versatile Auction Formats`,
    `🛠️ Easy Setup and Customization`,
    `📈 Real-Time Analytics`,
    `💌 Donor Engagement`,
    `💼 White Label Solution`,
  ],
  LandingMainTargetGroups: [
    `💚Business with Social Responsibility`,
    `🎊Event Organizers and Fundraising Professionals`,
    `🦋Nonprofit Organizations`,
  ],
  LandingMainLiveOptions: [
    `🎙️ Real-Time Interaction`,
    `🕒 Timely Lot Closures`,
    `📢 Competitive Bidding`,
  ],
  LandingMainWeekOptions: [
    `🗓️ Flexible Bidding Period`,
    `🔄 Rolling Lot Closure`,
    `💻📱 Seamless Online Experience`,
  ],
  LandingLotsPopular: 'Most popular lots from upcoming auctions:',
};
