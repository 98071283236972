import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { ActionButton, BlockActions } from '../styles';

interface ImageCropperProps {
  image: string;
  onCropDone: (area: null | Area) => void;
  onCropCancel: () => void;
}

export function ImageCropper(props: ImageCropperProps) {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedArea, setCroppedArea] = useState<null | Area>(null);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  };

  return (
    <>
      <Cropper
        image={props.image}
        aspect={1} // 1 / 1 || 16 / 9
        crop={crop}
        zoom={zoom}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        style={{
          containerStyle: {
            position: 'relative',
            width: '100%',
            minHeight: '400px',
            backgroundColor: '#fff',
          },
        }}
      />

      <BlockActions>
        <ActionButton onClick={props.onCropCancel} className='fail'>
          Not this image
        </ActionButton>

        <ActionButton
          onClick={() => props.onCropDone(croppedArea)}
          className='success'
        >
          Image is good
        </ActionButton>
      </BlockActions>
    </>
  );
}
