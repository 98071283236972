import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import {
  ActionCase,
  IStateShop,
  IStateShopProductByOrder,
} from '../interfaces/system/IState';
import {
  SHOP_PRODUCT_ANALYTICS_LOAD,
  SHOP_PRODUCT_BATCH_LOAD,
  SHOP_PRODUCT_LIST_LOAD,
} from '../actions/types';
import {
  IAppShopLotBatchData,
  IAppShopLotListData,
  IAppShopLotProductAnalyticsData,
  IAppShopLotProductAnalyticsSet,
} from '../interfaces/shop/IAppShopLot';
import { initPlatformSet } from '../config/platform.config';
import {
  IProductPreview,
  IProductPreviewOrder,
  IProductPreviewSet,
} from '../interfaces/shop/IProduct';

const initialStatePartners: IStateShop = {
  preview: {
    limit: 10,
    platforms: [],
    productAnalyticsByPlatform: initPlatformSet<IAppShopLotProductAnalyticsSet>(
      {}
    ),
    setByPlatform: initPlatformSet<IProductPreviewSet>({}),
    orderByPlatform: initPlatformSet<
      Record<IProductPreviewOrder, IStateShopProductByOrder>
    >({
      new: {
        cnt: null,
        loaded: 0,
        previewIDs: [],
      },
      popular: {
        cnt: null,
        loaded: 0,
        previewIDs: [],
      },
      liked: {
        cnt: null,
        loaded: 0,
        previewIDs: [],
      },
    }),
  },
};

export const shopReducer: Reducer = createReducer(
  initialStatePartners,
  (builder: ActionReducerMapBuilder<IStateShop>) => {
    builder.addCase<string, ActionCase<IAppShopLotBatchData>>(
      SHOP_PRODUCT_BATCH_LOAD,
      (state: Draft<IStateShop>, action: ActionCase<IAppShopLotBatchData>) => {
        const { order, cnt, items, platformType } = action.payload;
        const { orderByPlatform, platforms } = state.preview;
        if (!platforms.includes(platformType)) {
          platforms.push(platformType);
        }

        const $stateOrder: IStateShopProductByOrder =
          orderByPlatform[platformType][order];
        $stateOrder.cnt = cnt;
        $stateOrder.previewIDs = $stateOrder.previewIDs.concat(
          items.filter((i) => $stateOrder.previewIDs.indexOf(i) === -1)
        );
        $stateOrder.loaded = $stateOrder.previewIDs.length;
      }
    );
    builder.addCase<string, ActionCase<IAppShopLotListData>>(
      SHOP_PRODUCT_LIST_LOAD,
      (state: Draft<IStateShop>, action: ActionCase<IAppShopLotListData>) => {
        const { platformType, products } = action.payload;
        const { setByPlatform } = state.preview;
        products.forEach((product: IProductPreview) => {
          const { id } = product;
          setByPlatform[platformType][id] = product;
        });
      }
    );
    builder.addCase<string, ActionCase<IAppShopLotProductAnalyticsData>>(
      SHOP_PRODUCT_ANALYTICS_LOAD,
      (
        state: Draft<IStateShop>,
        action: ActionCase<IAppShopLotProductAnalyticsData>
      ) => {
        const { platformType, analytics, productID } = action.payload;
        const { productAnalyticsByPlatform } = state.preview;
        productAnalyticsByPlatform[platformType][productID] = analytics;
      }
    );
  }
);
