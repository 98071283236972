import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IReportAuction } from '../../interfaces/examples/IReportAuction';
import { IState } from '../../interfaces/system/IState';
import { BlockPageContent } from '../../common/styles';
import {
  ContentBold,
  ContentDesc,
  ContentHead,
  PageHead,
} from '../../common/typos';
import { currencyConvert } from '../../utils/price';
import TopDonators from './TopDonators';
import TopBidders from './TopBidders';
import TopSellers from './TopSellers';
import { getGifSrc, Gif } from '../../common/animation/Gif';
import { AdminAction, AdminActionBlock } from './AdminActions.styles';
import { loadNextLot } from '../../actions/examples/reports.actions';
import { LotBlock } from './Lot.styles';

interface IAuctionResultProps {
  loadNextLot: () => void;
  reportAuction: null | IReportAuction;
}

function ResultAuction(props: IAuctionResultProps) {
  const [gifSrc, setGifSrc] = useState<null | string>(null);

  useEffect(() => {
    const src = getGifSrc('resultAuction');
    setGifSrc(src);
    setTimeout(() => setGifSrc(null), 3000);
  }, []);

  return (
    <BlockPageContent>
      <PageHead>Auction' current Results:</PageHead>

      <LotBlock>
        {props.reportAuction && (
          <>
            <ContentDesc>
              Total donated:{' '}
              <ContentBold>
                {props.reportAuction.total} ($
                {currencyConvert(props.reportAuction.total)}){' '}
              </ContentBold>
            </ContentDesc>
            <ContentDesc>{props.reportAuction.bidCnt} bids made</ContentDesc>

            <ContentHead>Top donators:</ContentHead>
            <TopDonators
              topDonators={props.reportAuction.topDonators}
            ></TopDonators>

            <ContentHead>Top bidders:</ContentHead>
            <TopBidders
              topBidders={props.reportAuction.topBidders}
            ></TopBidders>

            <ContentHead>Top sellers:</ContentHead>
            <TopSellers
              topSellers={props.reportAuction.topSellers}
            ></TopSellers>
          </>
        )}

        {gifSrc && <Gif src={gifSrc} />}
      </LotBlock>

      <BlockPageContent>
        <AdminActionBlock>
          <AdminAction onClick={props.loadNextLot}>
            Send Lot
            <br />
            (will show only for moderator)
          </AdminAction>
        </AdminActionBlock>
      </BlockPageContent>
    </BlockPageContent>
  );
}

export default connect(
  (state: IState) => ({
    reportAuction: state.Example.reportAuction,
  }),
  {
    loadNextLot,
  }
)(ResultAuction);
