import React, { Dispatch, SetStateAction } from 'react';
import { Video, VideoBlock } from './Gif.styles';
import { ILotStatus } from '../../interfaces/auctions/ILotPublic';

interface IGifProps {
  src: string;
  status?: ILotStatus;
  blockClass?: 'left';
}

type GifType = 'bid' | 'win' | 'lost' | 'resultAuction' | 'resultLot';

type GifIndexes = Record<GifType, number>;
type GifSourceBase = Record<GifType, string>;

const currentIndexes: GifIndexes = {
  bid: 1,
  win: 1,
  lost: 1,
  resultAuction: 1,
  resultLot: 1,
};
const maxIndexes: GifIndexes = {
  bid: 3,
  win: 2,
  lost: 5,
  resultAuction: 4,
  resultLot: 3,
};
const sourceIndexes: GifSourceBase = {
  bid: 'bid-',
  win: 'win-',
  lost: 'lost-',
  resultAuction: 'result-auction-',
  resultLot: 'result-lot-',
};

export function getGifSrc(type: GifType): string {
  const current: number = currentIndexes[type];
  let next: number = current + 1;
  if (maxIndexes[type] < next) {
    next = 1;
  }
  currentIndexes[type] = next;
  return '/gifs/' + sourceIndexes[type] + current + '.mp4';
}

export function operateWithGif(
  type: GifType,
  $setGifSrc: Dispatch<SetStateAction<null | string>>,
  $timeoutHolder: any,
  $setTimeoutHolder: Dispatch<SetStateAction<any>>,
) {
  $setGifSrc(getGifSrc(type));
  if ($timeoutHolder) {
    clearTimeout($timeoutHolder);
  }
  $setTimeoutHolder(
    setTimeout(() => {
      $setGifSrc(null);
      $setTimeoutHolder(null);
    }, 3000),
  );
}

export function Gif(props: IGifProps) {
  return (
    <VideoBlock className={props.blockClass || ''}>
      <Video
        className={props.status || ''}
        autoPlay
        loop={true}
        muted
        playsInline
        src={props.src}
        id='gif'
        preload='auto'
      />
    </VideoBlock>
  );
}
