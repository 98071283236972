import { API_ERROR, API_INFO_POP, API_INFO_PUSH } from '../actions/types';
import { IDispatch } from '../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../interfaces/system/IError';

export function dispatchError(
  dispatch: IDispatch<IServiceInfo | IServiceInfo | IServiceError>,
  payload: IServiceError
) {
  const params: {
    key: string;
    value: string;
  }[] = [];
  if (payload.params) {
    Object.keys(payload.params).forEach((key: string) => {
      params.push({ key, value: payload.params[key] });
    });
  }

  dispatch({ type: API_ERROR, payload });

  const payloadInfo: IServiceInfo = {
    message: payload.message,
    params: params[0] ? params : undefined,
    isError: true,
  };
  dispatch({ type: API_INFO_PUSH, payload: payloadInfo });
  setTimeout(
    () => dispatch({ type: API_INFO_POP, payload: payloadInfo }),
    3000
  );
}

export function dispatchInfo(
  dispatch: IDispatch<IServiceInfo>,
  message: string,
  params?: {
    key: string;
    value: string;
  }[]
) {
  const payload: IServiceInfo = {
    message,
    params,
  };
  dispatch({ type: API_INFO_PUSH, payload });
  setTimeout(() => dispatch({ type: API_INFO_POP, payload }), 3000);
}
