import styled from 'styled-components';
import { colors, shadow } from '../common/colors';
import { device, margins, sizes, sizesBorder } from '../common/sizes';
import { fonts } from '../common/typos';
import { borders, shadows, transitions } from '../common/styles';

export const MenuIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: ${margins.item};
  cursor: pointer;
`;
export const MenuIconBlock = styled.div`
  align-items: center;

  &.hide {
    opacity: 50%;
  }
`;
export const MenuBlock = styled.div`
  z-index: 999;
  box-shadow: ${shadows.main};
  min-width: ${sizes.widthS};
  max-width: ${sizes.widthM};
  //min-height: 50px;
  position: absolute;
  background-color: ${colors.background};
  border-radius: ${sizesBorder.radius};
  padding: ${margins.item};
  visibility: hidden;
  opacity: 0;
  transition: ${transitions.visibility};

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.notifications {
    width: ${sizes.widthM};
  }

  & a {
    text-decoration: none;
    margin-bottom: ${margins.item};
    display: block;
    color: ${colors.main};

    &.active {
      color: ${colors.main};
      font-weight: ${fonts.weightM};
    }

    &.main {
      font-weight: ${fonts.weightL};
      text-decoration: underline;
    }

    &.disabled {
      color: ${colors.greyBorder};
      cursor: inherit;
    }
  }
`;
export const Head = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: ${colors.background};
  top: 0;
  z-index: 9;
  ${shadow}
`;
export const HeadBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: ${fonts.sizeM} !important;
`;
export const HeadLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    margin-right: ${margins.item};
  }

  button {
    min-width: fit-content;
    display: flex;
    align-items: center;
  }

  &.admin {
    ${borders.lost};
    & a {
      color: ${colors.lost};
    }
  }
`;
