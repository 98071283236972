import styled, { keyframes } from 'styled-components';
import { margins } from '../../common/sizes';
import { PageHead } from '../../common/typos';

export const ScreenBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  float: left;
  width: 100%;
  background-image: url('/images/screen-main.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 200px);
`;
export const loop = keyframes`
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
`;
export const VictoryWrap = styled.section`
  display: flex;
  white-space: nowrap;
  width: 100%;
  position: relative;
  overflow: hidden;
`;
export const VictoryBlock = styled.div`
  display: flex;
  align-items: start;
  -webkit-animation: ${loop} 20s infinite linear;
  animation: ${loop} 20s infinite linear;
  margin-right: ${margins.block};
`;
export const VictoryBlockName = styled(PageHead)`
  text-transform: uppercase;
  margin: ${margins.block} 0 !important;
`;
