import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAppAuctionIDsResponse,
  IAppAuctionSet,
  IAppAuctionSetResponse,
} from '../../interfaces/auctions/IAppAuction';
import { AUCTION_LOAD_IDS, AUCTION_LOAD_SET } from '../types';
import { CacheRequests } from '../../service/cacheRequests';

const { ids, set } = apiConfig.endpoints.auctions;

const cacheByID: CacheRequests = new CacheRequests();
const cacheAll: CacheRequests = new CacheRequests();

export const loadAuctionSet =
  (auctionIDs: string[]) =>
  (
    dispatch: IDispatch<IAppAuctionSet | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!auctionIDs || !auctionIDs[0]) {
      return;
    }
    const byAuctionID: IAppAuctionSet = getState().Auctions.byAuctionID;
    const toLoad: string[] = [];
    auctionIDs.forEach((auctionID: string) => {
      if (!byAuctionID[auctionID]) {
        toLoad.push(auctionID);
      }
    });
    if (!toLoad[0]) {
      return;
    }

    const IDsToLoad: string[] = cacheByID.getToLoad(toLoad);
    if (!IDsToLoad[0]) {
      return;
    }

    callApi<IAppAuctionSetResponse>(set, {
      auctionIDs: IDsToLoad.join(','),
    })
      .then((data: IAppAuctionSetResponse) =>
        dispatch({ type: AUCTION_LOAD_SET, payload: data.set })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadAuctionIDs =
  (hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      string[] | IAppAuctionSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!hardReload) {
      if (cacheAll.checkOneIsLoaded('All')) {
        return;
      }
    }

    callApi<IAppAuctionIDsResponse>(ids)
      .then((data: IAppAuctionIDsResponse) => {
        dispatch({
          type: AUCTION_LOAD_IDS,
          payload: data.auctionIDs,
        });
        loadAuctionSet(data.auctionIDs)(dispatch, getState);
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
