import React from 'react';
import { IAppReportLotPublic } from '../../interfaces/auctions/IAppReportLot';
import {
  ItemInline,
  ItemInlineContent,
  ItemInlineImageBlock,
} from '../../common/layout';
import { ContentBold, ContentDesc, ContentHead } from '../../common/typos';
import { currencyConvert } from '../../utils/price';
import { Image } from '../../common/images/Image.styles';

interface IReportLotInlineProps {
  reportLot: IAppReportLotPublic;
}

export function ReportLotInline(props: IReportLotInlineProps) {
  const reportLot: IAppReportLotPublic = props.reportLot;
  return (
    <>
      <ContentHead>{reportLot.name}</ContentHead>

      <ItemInline>
        <ItemInlineImageBlock className="win">
          <Image className={'L'} src={reportLot.imageURL} alt="lot" />
        </ItemInlineImageBlock>

        <ItemInlineContent>
          <ContentDesc>
            Sold by:{' '}
            <ContentBold>
              {reportLot.Total} ($
              {currencyConvert(reportLot.Total)})
            </ContentBold>
          </ContentDesc>
          <ContentDesc>
            From: {reportLot.startPrice} ($
            {currencyConvert(reportLot.startPrice)})
          </ContentDesc>
          <ContentDesc>By {reportLot.bidCnt} bids.</ContentDesc>
        </ItemInlineContent>
      </ItemInline>
    </>
  );
}
