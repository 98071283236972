import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import {
  deleteTemplateParams,
  loadTemplateParamsBatch,
  prepareTemplateParamsBatchKey,
  searchTemplateParams,
} from '../../../actions/communication/templateParams.actions';
import {
  ITemplateParamsGroup,
  ITemplateParamsSet,
} from '../../../interfaces/communication/ITemplateParams';
import { DataBatchWide } from '../../../interfaces/system/data';
import {
  ITemplateParamsBatchFiltersParams,
  TemplateParamsBatchFilters,
} from '../../../components/communication/TemplateParams/TemplateParamsBatchFilters';
import { RenderBatchFilterFunction } from '../../../common/common';
import {
  renderTemplateParamsForm,
  renderTemplateParamsItemPublic,
  renderTemplateParamsItemView,
} from '../../../components/communication/TemplateParams/TemplateParams';
import { IEmailMode } from '../../../interfaces/communication/IEmailLog';
import { IAppRecommendationType } from '../../../interfaces/communication/IAppRecommendation';

interface ICommunicationTemplateParamsProps {
  loadTemplateParamsBatch: (
    group: null | ITemplateParamsGroup,
    mode: null | IEmailMode,
    type: null | IAppRecommendationType,
    params: null | Record<string, string>,
    extraLimit?: number
  ) => void;
  deleteTemplateParams: (itemID: string) => void;
  searchTemplateParams: (search: string) => void;
  IDs: string[];
  IDsBySearch: Record<string, string[]>;
  set: ITemplateParamsSet;
  byBatchKey: Record<string, DataBatchWide>;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function CommunicationTemplateParams(props: ICommunicationTemplateParamsProps) {
  const [batchKey, setBatchKey] = useState<string>(
    prepareTemplateParamsBatchKey(null, null, null, null)
  );
  const [filters, setFilters] = useState<ITemplateParamsBatchFiltersParams>({
    group: null,
    mode: null,
    type: null,
    params: null,
  });

  useEffect(() => sendPageEvent('moderation communication templateParams'), []);

  const loadBatch = (extraLimit?: number): boolean => {
    if (!filters) {
      return false;
    }

    props.loadTemplateParamsBatch(
      filters.group,
      filters.mode,
      filters.type,
      filters.params,
      extraLimit
    );

    return true;
  };

  const renderBatchFilter: RenderBatchFilterFunction = (): JSX.Element => {
    return (
      <TemplateParamsBatchFilters
        cb={(batchKey, params) => {
          setBatchKey(batchKey);
          setFilters(params);
        }}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'TemplateParams moderation'}
      itemName={'this templateParams'}
      itemNameCreate={'Create new templateParams'}
      render={{
        renderItemPublic: renderTemplateParamsItemPublic,
        renderForm: renderTemplateParamsForm,
        renderItemView: renderTemplateParamsItemView,
      }}
      data={{
        set: props.set,
        batch: {
          batchKey,
          byBatchKey: props.byBatchKey,
          loadBatch,
          renderBatchFilter,
          showExtraLoading: true,
        },
        search: {
          search: props.searchTemplateParams,
          IDsBySearch: props.IDsBySearch,
          placeholderTitle: 'params',
        },
        actions: props.actions,
        errors: props.errors,
      }}
      cb={{
        cbDelete: (removingID: string) =>
          props.deleteTemplateParams(removingID),
      }}
      action={{
        showInline: true,
        skipDefaultLoading: true,
      }}
      isAdmin={props.isAdmin}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    IDs: state.Communication.TemplateParams.IDs,
    IDsBySearch: state.Communication.TemplateParams.IDsBySearch,
    set: state.Communication.TemplateParams.set,
    byBatchKey: state.Communication.TemplateParams.byBatchKey,
    actions: state.Communication.TemplateParams.actions,
    errors: state.System.errors,
  }),
  {
    loadTemplateParamsBatch,
    deleteTemplateParams,
    searchTemplateParams,
  }
)(CommunicationTemplateParams);
