import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Lot from './Lot';
import Bids from './Bids';
import { loadLot } from '../../actions/examples/lot.actions';
import { ILotShort } from '../../interfaces/examples/ILot';
import { IState } from '../../interfaces/system/IState';
import { connect } from 'react-redux';

interface IAuctionLiveProps {
  loadLot: () => void;
  currentLotID: null | string;
  lotByID: Record<string, ILotShort>;
}

function AuctionLive(props: IAuctionLiveProps) {
  const [lot, setLot] = useState<null | ILotShort>(null);

  useEffect(() => props.loadLot(), []);
  useEffect(() => {
    setLot((props.currentLotID && props.lotByID[props.currentLotID]) || null);
  }, [props.currentLotID, props.lotByID]);

  return (
    <>
      {lot && (
        <>
          <Lot lot={lot} />
          <Bids lotID={lot.id} />
        </>
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    currentLotID: state.Example.currentLotID,
    lotByID: state.Example.lotByID,
  }),
  {
    loadLot,
  }
)(AuctionLive);
