import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { CacheRequests } from '../../service/cacheRequests';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import '../../interfaces/CreateAuction/ICreateAuction';
import { dispatchInfo } from '../../service/error';
import {
  CREATE_AUCTION_ACTION,
  CREATE_AUCTION_LOAD,
  ITEM_UPDATED,
} from '../types';
import {
  ICreateAuctionResponse,
  ICreateAuctionUpdateBody,
} from '../../interfaces/CreateAuction/ICreateAuction';

const { CreateAuction } = apiConfig.endpoints;
const cacheAll: CacheRequests = new CacheRequests();

export const loadCreateAuction =
  () =>
  (
    dispatch: IDispatch<ICreateAuctionResponse | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (cacheAll.checkOneIsLoaded('All')) {
      return;
    }

    callApi<ICreateAuctionResponse>(CreateAuction.main, {})
      .then((data: ICreateAuctionResponse) =>
        dispatch({ type: CREATE_AUCTION_LOAD, payload: data })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const updateCreateAuction =
  (
    name: string,
    description: string,
    GMV: number,
    bids: number,
    lotCnt: number,
    followers: number,
    auctionCnt: number,
    imageURL: string
  ) =>
  (
    dispatch: IDispatch<
      string | ICreateAuctionResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: ICreateAuctionUpdateBody = {
      name,
      description,
      GMV,
      bids,
      lotCnt,
      followers,
      auctionCnt,
      imageURL,
    };

    callApi(CreateAuction.main, {}, body, 'POST')
      .then(() =>
        dispatch({ type: CREATE_AUCTION_ACTION, payload: ITEM_UPDATED })
      )
      .then(() => loadCreateAuction()(dispatch, getState))
      .then(() => dispatchInfo(dispatch, 'CreateAuction is updated'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
