import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import { ContentDesc } from '../../common/typos';
import { Image } from '../../common/images/Image.styles';
import { IProductPreview } from '../../interfaces/shop/IProduct';
import { PreviewBlock } from './Shop.styles';

interface IPreviewProps {
  preview: IProductPreview;

  noView?: boolean;
  fullWidth?: boolean;
  hideAnalytics?: boolean;
  cnt: number;

  // analyticsByProductID: Record<string, null | IProductAnalytics>;
  // analyticsByProductIDSelf: Record<string, null | IProductAnalyticsByAccount>;
  // foundations: string[];
  // sellerByProduct: Record<string, string>;
  // byPreviewID: Record<string, IProductPreview>;
  // userSet: IAccountSet;
  // setView: (productID: string, mode: 'catalog' | 'details') => void;
  // setLike: (productID: string) => void;
}

function Preview(props: IPreviewProps) {
  const navigate = useNavigate();
  // const [product, setProduct] = useState<null | IProductPreview>();

  // const [analyticsProduct, setAnalyticsProduct] =
  //   useState<null | IProductAnalytics>(null);
  // const [analyticsSelf, setAnalyticsSelf] =
  //   useState<null | IProductAnalyticsByAccount>(null);
  // const [classNames, setClassNames] = useState<string>('');

  // useEffect(() => {
  //   if (!props.noView) {
  //     props.setView(props.productID, 'catalog');
  //   }
  //   resetClassnames(false);
  // }, []);

  // useEffect(
  //   () => resetAnalytics(),
  //   [props.analyticsByProductID, props.analyticsByProductIDSelf]
  // );

  // useEffect(() => {
  //   setProduct(props.byPreviewID[props.productID] || null);
  // }, [props.productID, props.byPreviewID]);

  // todo uncomment without seller as a foundation
  // useEffect(() => {
  //   if (isFoundation) {
  //     return;
  //   }
  //   if (props.foundations.indexOf(props.productID) !== -1) {
  //     resetClassnames(true);
  //     setIsFoundation(true);
  //   }
  //   setFoundation(props.sellerByProduct[props.productID]);
  // }, [props.foundations]);
  //
  // useEffect(() => {
  //   if (foundation) {
  //     return;
  //   }
  //   const { sellerByProduct, productID, userSet } = props;
  //   const sellerID: string = sellerByProduct[productID];
  //   const seller: IAccount = userSet[sellerID];
  //   if (!seller) {
  //     return;
  //   }
  //   setFoundation(seller.name);
  // }, [props.foundations, props.userSet]);

  // const resetClassnames = (localIsFoundation: boolean) => {
  //   setClassNames(
  //     'cnt-' + props.cnt
  //     // + (!props.noView && (isFoundation || localIsFoundation)
  //     //   ? ' foundation'
  //     //   : '')
  //   );
  // };

  // const resetAnalytics = () => {
  //   const id: string = props.productID;
  //   setAnalyticsProduct(props.analyticsByProductID[id] || null);
  //   setAnalyticsSelf(props.analyticsByProductIDSelf[id] || null);
  // };

  const goToProduct = () => {
    // if (!product) {
    //   return;
    // }
    // navigate(
    //   appConfig.routes.shopProducts.one.replace(
    //     `${appConfig.params.accountPath}/${appConfig.params.productPath}`,
    //     product.route
    //   )
    // );
  };

  return (
    <PreviewBlock
      onClick={goToProduct}
      style={{
        width: props.fullWidth
          ? '100%'
          : 'calc(' + Math.round(100 / props.cnt) + '% - 5px)',
      }}
      className={'cnt-' + props.cnt}
    >
      {/*{!props.hideAnalytics && (
        <AnalyticsIconBlock>
          <AnalyticsIconItem>
            {(analyticsSelf && analyticsSelf.isLiked && (
              <AnalyticsIcon src="/icons/like-full.png" alt="view" />
            )) || <AnalyticsIcon src="/icons/like.png" alt="view" />}
          </AnalyticsIconItem>
        </AnalyticsIconBlock>
      )}*/}

      <Image
        className={'M'}
        src={props.preview.imageURL}
        alt={props.preview.name}
      />
      <ContentDesc>{props.preview.name}</ContentDesc>
    </PreviewBlock>
  );
}

// todo check for deprecate

export default connect(
  (state: IState) => ({
    // analyticsByProductID: state.ShopProductAnalytics.byProductID,
    // analyticsByProductIDSelf: state.ShopProductAnalytics.byProductIDSelf,
    // foundations: state.ShopProduct.foundation.productIDs,
    // sellerByProduct: state.ShopProduct.foundation.sellerByProduct,
    // userSet: state.Account.userSet,
    // byPreviewID: state.ShopProduct.batchPreview.byPreviewID,
  }),
  {
    // setView,
    // setLike,
  }
)(Preview);
