import styled from 'styled-components';
import { sizes } from '../../common/sizes';
import { blocks } from '../../common/layout';

export const ChartsBlock = styled.div`
  display: block;
  width: ${sizes.full};
  float: left;
`;

export const ChartsItem = styled.div`
  display: inline-block;
  float: left;

  &.half {
    width: ${sizes.half};
  }
`;

export const LabelsBlock = styled.div`
  max-height: ${sizes.widthM};
  overflow-y: auto;
  ${blocks.half}
`;
