import styled from 'styled-components';
import { colors } from '../../common/colors';
import { fonts } from '../../common/typos';

export const Main = styled.button`
  font-size: ${fonts.sizeM};
  color: ${colors.main};
  border: 3px solid ${colors.main};
  border-radius: 5px;
  background-color: ${colors.action};
  min-height: 40px;
  padding: 2px 10px;
  cursor: pointer;
  margin: 5px 0;
  text-transform: uppercase;
  font-weight: 500;

  &.sending {
    filter: opacity(0.5);
  }

  &[disabled] {
    border-color: darkgray;
    color: darkgray;
    cursor: not-allowed;

    img {
      filter: opacity(0.3);
    }
  }

  :hover {
    box-shadow: darkgray 5px 5px 10px;
    padding: 12px 24px;
    transition-duration: 1s;

    &.noShadow {
      box-shadow: none;
    }
  }
`;
export const Icon = styled.img`
  margin-left: 10px;
  width: 20px;
`;
