import React from 'react';
import { Icon, Main } from './Button.styles';

interface IButtonProps {
  label: string;
  isDisabled: boolean;
  noShadow?: boolean;
  onClick: () => void;
}

export function MainButton(props: IButtonProps) {
  return (
    <Main
      disabled={props.isDisabled}
      onClick={props.onClick}
      className={props.noShadow ? 'noShadow' : ''}
    >
      {props.label} <Icon src="/icons/arrow.png" alt="" />
    </Main>
  );
}
