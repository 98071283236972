import React from 'react';
import { ContentDesc, ContentHead } from '../../common/typos';
import { Block } from '../../common/layout';
import { IAccount } from '../../interfaces/account/IAccount';
import { Image } from '../../common/images/Image.styles';

interface IAccountItemModerationProps {
  account: IAccount;
  inlineBlock?: JSX.Element;
  showInline?: boolean;
}

export function AccountItemModeration(props: IAccountItemModerationProps) {
  const account: IAccount = props.account;

  return (
    <Block>
      {account.imgURL && (
        <Image className={'M'} src={account.imgURL} alt={account.name} />
      )}
      <ContentHead>{account.name}</ContentHead>
      <ContentDesc>{account.mail}</ContentDesc>
      {account.isAdmin && <ContentDesc className={'fail'}>Admin</ContentDesc>}
    </Block>
  );
}
