export function makePathFromName(name: string): string {
  return name.replace(/ /g, '_');
}

// export function makeNameFromPath(path: string): string {
//   return path.replace(/_/g, ' ');
// }

// export function makeReportPathFromOrder(order: number): string {
//   const path = 'report-';
//   if (order < 10) {
//     return path + '00' + order;
//   } else if (order < 100) {
//     return path + '0' + order;
//   } else {
//     return path + order;
//   }
// }
