import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../utils/analytics';
import { ContentHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { FormBlock } from '../../common/forms/Form.styles';
import {
  createPartnerAuction,
  updatePartnerAuction,
} from '../../actions/partners/partnerAuction.actions';
import {
  IAppPartnerAuctionPublic,
  IAppPartnerAuctionSet,
} from '../../interfaces/partners/IAppPartnerAuction';
import { IPlatformType, validatePlatform } from '../../config/platform.config';
import { Form, IFormItem } from '../../common/forms/Form';
import { FormsFields } from '../../common/forms/forms';

interface IPartnersAuctionFormProps {
  partner?: null | IAppPartnerAuctionPublic;
  isDisabled?: boolean;
  createPartnerAuction: (
    platformType: IPlatformType,
    name: string,
    description: string,
    hostAuction: string,
    hostAuctionSub: null | string,
    hostCompany: null | string,
    imageURL: string
  ) => void;
  updatePartnerAuction: (
    partnerID: string,
    name: null | string,
    description: null | string,
    hostAuction: null | string,
    hostAuctionSub: undefined | null | string,
    hostCompany: undefined | null | string,
    imageURL: null | string
  ) => void;
  cbCancel: () => void;
  cbDelete?: (partnerID: string) => void;
  set: IAppPartnerAuctionSet;
  actions: string[];
  errors: string[];
}

function PartnersAuctionForm(props: IPartnersAuctionFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);

  const fields: Partial<
    Record<keyof IAppPartnerAuctionPublic, undefined | IFormItem>
  > = {
    platformType:
      (!props.partner && {
        ...FormsFields.platformType,
        $valueSelect: [],
      }) ||
      undefined,
    name: {
      ...FormsFields.name,
      $value: props.partner?.name,
    },
    description: {
      ...FormsFields.description,
      $value: props.partner?.description,
    },
    hostAuction: {
      ...FormsFields.url,
      isRequired: true,
      title: 'Auction host',
      $value: props.partner?.hostAuction,
    },
    hostAuctionSub: {
      ...FormsFields.url,
      max: 20,
      title: 'Auction host sub domain',
      $value: props.partner?.hostAuctionSub,
    },
    hostCompany: {
      ...FormsFields.url,
      title: 'Company domain',
      $value: props.partner?.hostCompany,
    },
    imageURL: {
      ...FormsFields.imageURL,
      title: 'Company image',
      $valueList: (props.partner && [props.partner.imageURL]) || [],
    },
  };

  useEffect(() => sendEvent('Open-PartnersAuctionForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const handleCreate = () => {
    const platformType: null | IPlatformType = validatePlatform(
      (fields.platformType &&
        fields.platformType.$valueList &&
        fields.platformType.$valueList[0]) ||
        ''
    );
    if (!platformType) {
      return;
    }

    setIsSending(true);
    props.createPartnerAuction(
      platformType,
      (fields.name && fields.name.$value) || '',
      (fields.description && fields.description.$value) || '',
      (fields.hostAuction && fields.hostAuction.$value) || '',
      (fields.hostAuctionSub && fields.hostAuctionSub.$value) || '',
      (fields.hostCompany && fields.hostCompany.$value) || '',
      (fields.imageURL &&
        fields.imageURL.$valueList &&
        fields.imageURL.$valueList[0]) ||
        ''
    );
  };
  const handleUpdate = () => {
    if (!props.partner) {
      return;
    }
    setIsSending(true);
    props.updatePartnerAuction(
      props.partner.id,
      (fields.name && fields.name.$value) || null,
      (fields.description && fields.description.$value) || null,
      (fields.hostAuction && fields.hostAuction.$value) || null,
      (fields.hostAuctionSub && fields.hostAuctionSub.$value) || null,
      (fields.hostCompany && fields.hostCompany.$value) || null,
      (fields.imageURL &&
        fields.imageURL.$valueList &&
        fields.imageURL.$valueList[0]) ||
        null
    );
  };
  const handleDelete = () => {
    if (!props.partner || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.partner.id);
  };

  return (
    <FormBlock>
      {!props.partner && (
        <ContentHead>New Auction Partner creation</ContentHead>
      )}
      {props.partner && <ContentHead>Auction Partner update</ContentHead>}

      <Form
        isUpdate={!!props.partner}
        isDisabled={props.isDisabled}
        placeholderTitle={'auction partner'}
        fields={fields}
        isSending={isSending}
        cbCancel={props.cbCancel}
        cbCreate={handleCreate}
        cbUpdate={handleUpdate}
        cbDelete={handleDelete}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Partners.Auction.set,
    actions: state.Partners.Auction.actions,
    errors: state.System.errors,
  }),
  {
    createPartnerAuction,
    updatePartnerAuction,
  }
)(PartnersAuctionForm);
