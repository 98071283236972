import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import {
  IBaseAnalyticsAggregatedModelGetByPlatformResponse,
  IBaseAnalyticsAggregatedModelGetCacheAllResponse,
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetExtendedData,
  IBaseAnalyticsAggregatedModelGetOptionsResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  loadAnalyticsCronCache,
  loadAnalyticsCronCacheAll,
  loadAnalyticsCronMain,
  loadAnalyticsCronMainByPlatform,
  saveTechCronCache,
  saveTechCronCacheByPlatform,
} from './techCron.actions';
import {
  loadAnalyticsDbCache,
  loadAnalyticsDbCacheAll,
  loadAnalyticsDbExtended,
  loadAnalyticsDbMain,
  loadAnalyticsDbMainByPlatform,
  loadAnalyticsDbOptions,
  saveTechDbCache,
  saveTechDbCacheByPlatform,
} from './techDb.actions';
import {
  loadAnalyticsRouteCache,
  loadAnalyticsRouteCacheAll,
  loadAnalyticsRouteExtended,
  loadAnalyticsRouteMain,
  loadAnalyticsRouteMainByPlatform,
  loadAnalyticsRouteOptions,
  saveTechRouteCache,
  saveTechRouteCacheByPlatform,
} from './techRoute.actions';
import { IPlatformType } from '../../config/platform.config';
import {
  loadAnalyticsRetentionCache,
  loadAnalyticsRetentionCacheAll,
  loadAnalyticsRetentionMain,
  loadAnalyticsRetentionMainByPlatform,
  saveAnalyticsRetentionCache,
  saveAnalyticsRetentionCacheByPlatform,
} from './behavioralRetention.actions';

export const loadAnalyticsMain =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      IBaseAnalyticsAggregatedModelGetResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsRetentionMain(from, to)(dispatch, getState);
    loadAnalyticsCronMain(from, to)(dispatch, getState);
    loadAnalyticsDbMain(from, to)(dispatch, getState);
    loadAnalyticsRouteMain(from, to)(dispatch, getState);
  };

export const loadAnalyticsMainByPlatform =
  (platformType: IPlatformType, from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetByPlatformResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsRetentionMainByPlatform(
      platformType,
      from,
      to
    )(dispatch, getState);
    loadAnalyticsCronMainByPlatform(platformType, from, to)(dispatch, getState);
    loadAnalyticsDbMainByPlatform(platformType, from, to)(dispatch, getState);
    loadAnalyticsRouteMainByPlatform(
      platformType,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsExtended =
  (from: string, to: string, value: null | string, param: null | string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetExtendedData
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsDbExtended(from, to, value, param)(dispatch, getState);
    loadAnalyticsRouteExtended(from, to, value, param)(dispatch, getState);
  };

export const loadAnalyticsOptions =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetOptionsResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsDbOptions(from, to)(dispatch, getState);
    loadAnalyticsRouteOptions(from, to)(dispatch, getState);
  };

export const loadAnalyticsCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsRetentionCache(hardReload)(dispatch, getState);
    loadAnalyticsCronCache(hardReload)(dispatch, getState);
    loadAnalyticsDbCache(hardReload)(dispatch, getState);
    loadAnalyticsRouteCache(hardReload)(dispatch, getState);
  };

export const loadAnalyticsCacheAll =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheAllResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsRetentionCacheAll(hardReload)(dispatch, getState);
    loadAnalyticsCronCacheAll(hardReload)(dispatch, getState);
    loadAnalyticsDbCacheAll(hardReload)(dispatch, getState);
    loadAnalyticsRouteCacheAll(hardReload)(dispatch, getState);
  };

export const saveAnalyticsCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsRetentionCache()(dispatch, getState);
    saveTechCronCache()(dispatch, getState);
    saveTechDbCache()(dispatch, getState);
    saveTechRouteCache()(dispatch, getState);
  };

export const saveAnalyticsCacheByPlatform =
  (platformType: IPlatformType) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsRetentionCacheByPlatform(platformType)(dispatch, getState);
    saveTechCronCacheByPlatform(platformType)(dispatch, getState);
    saveTechDbCacheByPlatform(platformType)(dispatch, getState);
    saveTechRouteCacheByPlatform(platformType)(dispatch, getState);
  };
