import React from 'react';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IState } from '../../interfaces/system/IState';
import CreateAuctionPublic from '../../components/CreateAuction/CreateAuctionPublic';
import { BlockPage } from '../../common/styles';

interface ICreateAuctionPageProps {}

function CreateAuctionPage(props: ICreateAuctionPageProps) {
  return (
    <BlockPage>
      <CreateAuctionPublic showImage={true} />
    </BlockPage>
  );
}

export default connect((state: IState) => ({}), {})(CreateAuctionPage);
