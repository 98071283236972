import React, { useState } from 'react';
import { ContentDescInline } from '../typos';
import { Block } from '../layout';
import { ActionLink } from '../styles';

interface IParamsWrapperProps {
  title: string;
  renderParams: () => JSX.Element;
  initial?: boolean;
}

export function ParamsWrapper(props: IParamsWrapperProps) {
  const [showAll, setShowAll] = useState<boolean>(props.initial || false);

  return (
    <Block>
      <Block>
        <ActionLink>
          <ContentDescInline
            className={showAll ? 'bold' : ''}
            onClick={() => setShowAll(true)}
          >
            Show {props.title}
          </ContentDescInline>
        </ActionLink>
        <ActionLink>
          <ContentDescInline
            className={!showAll ? 'bold' : ''}
            onClick={() => setShowAll(false)}
          >
            Hide {props.title}
          </ContentDescInline>
        </ActionLink>
      </Block>

      {showAll && props.renderParams()}
    </Block>
  );
}
