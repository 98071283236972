import React from 'react';
import { Block } from '../../../common/layout';
import { IAppRecommendationPublic } from '../../../interfaces/communication/IAppRecommendation';
import { ActionButton, BlockActions } from '../../../common/styles';

interface IRecommendationBatchActionsProps {
  IDs: string[];
  set: Record<string, IAppRecommendationPublic>;
}

export function RecommendationBatchActions(
  props: IRecommendationBatchActionsProps
) {
  const openAll = () => {
    props.IDs.forEach((id: string) => {
      openItemUrl(props.set[id]);
      openItemSocial(props.set[id]);
    });
  };

  const openUrl = () => {
    props.IDs.forEach((id: string) => openItemUrl(props.set[id]));
  };
  const openSocial = () => {
    props.IDs.forEach((id: string) => openItemSocial(props.set[id]));
  };
  const openInst = () => {
    props.IDs.forEach((id: string) => openItemInst(props.set[id]));
  };
  const openItemUrl = (item: IAppRecommendationPublic) => {
    goTo(item.url);
  };
  const openItemSocial = (item: IAppRecommendationPublic) => {
    if (!item.social) {
      return;
    }

    if (item.social.linkedin) {
      goTo(item.social.linkedin);
    }
    if (item.social.fb) {
      goTo(item.social.fb);
    }
    if (item.social.inst) {
      goTo(item.social.inst);
    }
    if (item.social.t) {
      goTo(item.social.t);
    }
    if (item.social.x) {
      goTo(item.social.x);
    }
  };
  const openItemInst = (item: IAppRecommendationPublic) => {
    if (!item.social) {
      return;
    }

    if (item.social.inst) {
      goTo(item.social.inst);
    }
  };

  const goTo = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Block>
      <BlockActions>
        <ActionButton className="main" onClick={openAll}>
          open all links
        </ActionButton>
        <ActionButton onClick={openUrl}>open url</ActionButton>
        <ActionButton onClick={openSocial}>open social</ActionButton>
        <ActionButton onClick={openInst}>open inst</ActionButton>
      </BlockActions>
    </Block>
  );
}
