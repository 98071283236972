import {
  IPlatformType,
  platformConfigs,
  platformPageTitle,
} from '../config/platform.config';
import { appConfig } from '../config/app.config';

export function getPlatformType(): IPlatformType {
  const { isDev, devPlatform } = appConfig;
  if (isDev && devPlatform) {
    if (devPlatform === 'artukraine') {
      return 'artukraine';
    } else if (devPlatform === 'aimprosoft') {
      return 'aimprosoft';
    } else if (devPlatform === 'keenethics') {
      return 'keenethics';
    } else if (devPlatform === 'zoola') {
      return 'zoola';
    } else if (devPlatform === 'example') {
      return 'example';
    } else {
      return 'local';
    }
  }

  return getRealPlatformType();
}

export function getRealPlatformType(): IPlatformType {
  const origin: string = window.location.origin;
  if (origin === platformConfigs.artukraine) {
    return 'artukraine';
  } else if (origin === platformConfigs.aimprosoft) {
    return 'aimprosoft';
  } else if (origin === platformConfigs.keenethics) {
    return 'keenethics';
  } else if (origin === platformConfigs.zoola) {
    return 'zoola';
  } else if (origin === platformConfigs.example) {
    return 'example';
  } else {
    // return 'temp';
    return 'local';
  }
}

export function getPlatformHeader(): string {
  const platformType: IPlatformType = getPlatformType();
  if (platformType === 'artukraine') {
    return platformPageTitle.artukraine;
  } else if (platformType === 'aimprosoft') {
    return platformPageTitle.aimprosoft;
  } else if (platformType === 'keenethics') {
    return platformPageTitle.keenethics;
  } else if (platformType === 'zoola') {
    return platformPageTitle.zoola;
  } else if (platformType === 'example') {
    return platformPageTitle.example;
  } else {
    return platformPageTitle.local;
  }
}

export function checkIsExample(): boolean {
  return getPlatformType() === 'example';
}
