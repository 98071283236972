import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BlockPage, BlockPageContent } from '../../common/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { sendPageEvent } from '../../utils/analytics';
import { IState } from '../../interfaces/system/IState';
import { Loading } from '../../common/animation/Loading';
import Auction from '../../components/auctions/Auction';
import {
  IAppAuctionPublic,
  IAppAuctionSet,
} from '../../interfaces/auctions/IAppAuction';
import { loadAuctionIDs } from '../../actions/auctions/auction.actions';
import { appConfig } from '../../config/app.config';
import { makePathFromName } from '../../utils/routes';
import ReportLots from '../../components/auctions/ReportLots';

interface IAuctionPageProps {
  loadAuctionIDs: (hardReload?: boolean) => void;
  byAuctionID: IAppAuctionSet;
  auctionIDsByPlatform: Record<string, string[]>;
}

function AuctionPage(props: IAuctionPageProps) {
  const navigate = useNavigate();
  const { platformPath, auctionPath } = useParams();

  const [auction, setAuction] = useState<null | IAppAuctionPublic>(null);

  useEffect(() => sendPageEvent('Auction'), []);
  useEffect(() => props.loadAuctionIDs(), []);
  useEffect(() => {
    if (!platformPath || !auctionPath) {
      navigate(appConfig.routes.auctions.list);
      return;
    }
    if (!props.auctionIDsByPlatform[platformPath]) {
      return;
    }

    let auctionIDLocal: null | string = null;
    let auctionLocal: null | IAppAuctionPublic = null;

    const auctionIDs: string[] = props.auctionIDsByPlatform[platformPath];
    auctionIDs.forEach((auctionID: string) => {
      const i: null | IAppAuctionPublic = props.byAuctionID[auctionID] || null;
      if (!i) {
        return;
      }
      const iPath = makePathFromName(i.name);
      if (iPath === auctionPath) {
        auctionLocal = i;
        auctionIDLocal = i.id;
      }

      if (auctionLocal) {
        setAuction(auctionLocal);
      }
    });
  }, [props.auctionIDsByPlatform, props.byAuctionID]);

  return (
    <BlockPage>
      {auction && (
        <BlockPageContent>
          <Auction auction={auction} />

          {auction.live && (
            <ReportLots
              auction={auction}
              platformType={auction.platformType}
              platformAuctionID={auction.platformAuctionID}
              lotType="Live"
              mode="carousel"
            />
          )}

          {auction.week && (
            <ReportLots
              auction={auction}
              platformType={auction.platformType}
              platformAuctionID={auction.platformAuctionID}
              lotType="Week"
              mode="carousel"
            />
          )}
        </BlockPageContent>
      )}

      {!auction && <Loading size={'big'} />}
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    byAuctionID: state.Auctions.byAuctionID,
    auctionIDsByPlatform: state.Auctions.auctionIDsByPlatform,
  }),
  {
    loadAuctionIDs,
  }
)(AuctionPage);
