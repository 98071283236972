import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { ActionCase, IStateAccount } from '../interfaces/system/IState';
import {
  IAccount,
  IAccountSearchData,
  IAccountSet,
} from '../interfaces/account/IAccount';
import {
  ACCOUNT_LOADED,
  ACCOUNT_LOGOUT,
  ACCOUNTS_LOAD_BATCH,
  ACCOUNTS_LOAD_SET,
  ACCOUNTS_SEARCH_LOAD,
  NOTIFICATION_VIEW,
  NOTIFICATIONS_FOR_MODERATION_LOAD,
  NOTIFICATIONS_IDS_LOAD,
  NOTIFICATIONS_ONE_LOAD,
  NOTIFICATIONS_SET_LOAD,
} from '../actions/types';
import {
  IAccountNotification,
  INotificationSet,
} from '../interfaces/account/IAccountNotification';
import { DataBatchKey } from '../interfaces/system/data';

const initialStateAccount: IStateAccount = {
  account: null,
  isAdmin: false,
  notifications: {
    isViewIncremented: false,
    IDs: [],
    set: {},
    cntAll: 0,
    cntNew: 0,
    moderation: [],
  },
  accounts: {
    limit: 15,
    set: {},
    IDsBySearchKey: {},
    byBatchKey: {},
  },
};

function reCount(state: Draft<IStateAccount>) {
  let cnt: number = 0;
  state.notifications.IDs.forEach((id: string) => {
    const notification: null | IAccountNotification =
      state.notifications.set[id] || null;
    if (notification && !notification.isRead) {
      cnt++;
    }
  });
  state.notifications.cntNew = cnt;
  if (cnt) {
    state.notifications.cntAll = state.notifications.IDs.length;
    state.notifications.isViewIncremented = false;
  }
}

export const accountReducer: Reducer = createReducer(
  initialStateAccount,
  (builder: ActionReducerMapBuilder<IStateAccount>) => {
    // account
    builder.addCase<string, ActionCase<IAccount>>(
      ACCOUNT_LOADED,
      (state: Draft<IStateAccount>, action: ActionCase<IAccount>) => {
        const account: IAccount = action.payload;
        state.account = account;
        state.isAdmin = account.isAdmin || false;
      }
    );
    builder.addCase<string, ActionCase<null>>(
      ACCOUNT_LOGOUT,
      (state: Draft<IStateAccount>) => {
        state.account = null;
        state.isAdmin = false;
      }
    );
    // accounts
    builder.addCase<string, ActionCase<IAccountSet>>(
      ACCOUNTS_LOAD_SET,
      (state: Draft<IStateAccount>, action: ActionCase<IAccountSet>) => {
        const set: IAccountSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.accounts.set[id] = set[id];
        });
      }
    );
    builder.addCase<string, ActionCase<IAccountSearchData>>(
      ACCOUNTS_SEARCH_LOAD,
      (state: Draft<IStateAccount>, action: ActionCase<IAccountSearchData>) => {
        const { search, accountIDs } = action.payload;
        state.accounts.IDsBySearchKey[search] = accountIDs;
      }
    );
    builder.addCase<string, ActionCase<DataBatchKey<string>>>(
      ACCOUNTS_LOAD_BATCH,
      (
        state: Draft<IStateAccount>,
        action: ActionCase<DataBatchKey<string>>
      ) => {
        const { cnt, items, batchKey } = action.payload;

        if (!state.accounts.byBatchKey[batchKey]) {
          state.accounts.byBatchKey[batchKey] = { IDs: [], cnt: 0, loaded: 0 };
        }

        state.accounts.byBatchKey[batchKey].cnt = cnt;
        state.accounts.byBatchKey[batchKey].IDs =
          state.accounts.byBatchKey[batchKey].IDs.concat(items);
        state.accounts.byBatchKey[batchKey].loaded =
          state.accounts.byBatchKey[batchKey].IDs.length;
      }
    );
    // notifications
    builder.addCase<string, ActionCase<string[]>>(
      NOTIFICATIONS_IDS_LOAD,
      (state: Draft<IStateAccount>, action: ActionCase<string[]>) => {
        state.notifications.IDs = action.payload;
        reCount(state);
      }
    );
    builder.addCase<string, ActionCase<IAccountNotification>>(
      NOTIFICATIONS_ONE_LOAD,
      (
        state: Draft<IStateAccount>,
        action: ActionCase<IAccountNotification>
      ) => {
        state.notifications.set[action.payload.id] = action.payload;
        reCount(state);
      }
    );
    builder.addCase<string, ActionCase<INotificationSet>>(
      NOTIFICATIONS_SET_LOAD,
      (state: Draft<IStateAccount>, action: ActionCase<INotificationSet>) => {
        const set: INotificationSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.notifications.set[id] = set[id];
        });

        reCount(state);
      }
    );
    builder.addCase<string, ActionCase<void>>(
      NOTIFICATION_VIEW,
      (state: Draft<IStateAccount>) => {
        state.notifications.isViewIncremented = true;
      }
    );
    builder.addCase<string, ActionCase<IAccountNotification[]>>(
      NOTIFICATIONS_FOR_MODERATION_LOAD,
      (
        state: Draft<IStateAccount>,
        action: ActionCase<IAccountNotification[]>
      ) => {
        state.notifications.moderation = action.payload;
      }
    );
  }
);
