import { Reducer } from 'redux';
import { createReducer, Draft } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { ActionCase, IStatePartners } from '../interfaces/system/IState';
import {
  PARTNER_AUCTION_ACTION,
  PARTNER_AUCTION_LOAD_IDS,
  PARTNER_AUCTION_LOAD_SET,
  PARTNER_FEEDBACKS_ACTION,
  PARTNER_FEEDBACKS_LOAD_IDS,
  PARTNER_FEEDBACKS_LOAD_SET,
  PARTNER_LOT_ACTION,
  PARTNER_LOT_LOAD_IDS,
  PARTNER_LOT_LOAD_SET,
} from '../actions/types';
import { IAppPartnerAuctionSet } from '../interfaces/partners/IAppPartnerAuction';
import { IAppPartnerFeedbacksSet } from '../interfaces/partners/IAppPartnerFeedbacks';
import { IAppPartnerLotSet } from '../interfaces/partners/IAppPartnerLot';

const initialStatePartners: IStatePartners = {
  Auction: {
    IDs: [],
    set: {},
    actions: [],
  },
  Feedbacks: {
    IDs: [],
    set: {},
    actions: [],
  },
  Lot: {
    IDs: [],
    set: {},
    actions: [],
  },
};

export const partnersReducer: Reducer = createReducer(
  initialStatePartners,
  (builder: ActionReducerMapBuilder<IStatePartners>) => {
    // Auction
    builder.addCase<string, ActionCase<string[]>>(
      PARTNER_AUCTION_LOAD_IDS,
      (state: Draft<IStatePartners>, action: ActionCase<string[]>) => {
        state.Auction.IDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IAppPartnerAuctionSet>>(
      PARTNER_AUCTION_LOAD_SET,
      (
        state: Draft<IStatePartners>,
        action: ActionCase<IAppPartnerAuctionSet>
      ) => {
        const set: IAppPartnerAuctionSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.Auction.set[id] = set[id];
        });
      }
    );
    builder.addCase<string, ActionCase<string>>(
      PARTNER_AUCTION_ACTION,
      (state: Draft<IStatePartners>, action: ActionCase<string>) => {
        state.Auction.actions.unshift(action.payload);
      }
    );
    // Feedbacks
    builder.addCase<string, ActionCase<string[]>>(
      PARTNER_FEEDBACKS_LOAD_IDS,
      (state: Draft<IStatePartners>, action: ActionCase<string[]>) => {
        state.Feedbacks.IDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IAppPartnerFeedbacksSet>>(
      PARTNER_FEEDBACKS_LOAD_SET,
      (
        state: Draft<IStatePartners>,
        action: ActionCase<IAppPartnerFeedbacksSet>
      ) => {
        const set: IAppPartnerFeedbacksSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.Feedbacks.set[id] = set[id];
        });
      }
    );
    builder.addCase<string, ActionCase<string>>(
      PARTNER_FEEDBACKS_ACTION,
      (state: Draft<IStatePartners>, action: ActionCase<string>) => {
        state.Feedbacks.actions.unshift(action.payload);
      }
    );
    // Lot
    builder.addCase<string, ActionCase<string[]>>(
      PARTNER_LOT_LOAD_IDS,
      (state: Draft<IStatePartners>, action: ActionCase<string[]>) => {
        state.Auction.IDs = action.payload;
      }
    );
    builder.addCase<string, ActionCase<IAppPartnerLotSet>>(
      PARTNER_LOT_LOAD_SET,
      (state: Draft<IStatePartners>, action: ActionCase<IAppPartnerLotSet>) => {
        const set: IAppPartnerLotSet = action.payload;
        Object.keys(set).forEach((id: string) => {
          state.Lot.set[id] = set[id];
        });
      }
    );
    builder.addCase<string, ActionCase<string>>(
      PARTNER_LOT_ACTION,
      (state: Draft<IStatePartners>, action: ActionCase<string>) => {
        state.Lot.actions.unshift(action.payload);
      }
    );
  }
);
