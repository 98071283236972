import React from 'react';
import { OrderBy, OrderRow } from './Order.styles';
import { Block } from '../layout';

export interface IOrderItem<IOrderType> {
  value: IOrderType;
  title?: string;
}

interface IOrderProps<IOrderType> {
  itemsSimple?: string[];
  items?: IOrderItem<IOrderType>[];
  groups?: IOrderItem<IOrderType>[][];
  order: IOrderType;
  cb: (order: IOrderType) => void;
  isSmall?: boolean;
}

export function Order<IOrderType>(props: IOrderProps<IOrderType>) {
  const renderGroups = (groups: IOrderItem<IOrderType>[][]): JSX.Element => {
    return (
      <>
        {groups.map((items: IOrderItem<IOrderType>[], index: number) => (
          <OrderRow key={index}>{renderItems(items)}</OrderRow>
        ))}
      </>
    );
  };

  const renderItems = (items: IOrderItem<IOrderType>[]): JSX.Element => {
    return (
      <>
        {items.map((item: IOrderItem<IOrderType>) => (
          <OrderBy
            onClick={() => props.cb(item.value)}
            className={getClassName(String(item.value))}
            key={String(item.value)}
          >
            {item.title || String(item.value)}
          </OrderBy>
        ))}
      </>
    );
  };

  const renderItemsSimple = (items: string[]): JSX.Element => {
    return (
      <>
        {items.map((item: string) => (
          <OrderBy
            onClick={() => props.cb(item as IOrderType)}
            className={getClassName(item)}
            key={item}
          >
            {item}
          </OrderBy>
        ))}
      </>
    );
  };

  const getClassName = (value: string): string => {
    return (
      (props.isSmall ? 'small ' : '') + (props.order === value ? 'active' : '')
    );
  };

  return (
    <Block>
      {props.groups && renderGroups(props.groups)}
      {props.items && renderItems(props.items)}
      {props.itemsSimple && renderItemsSimple(props.itemsSimple)}
    </Block>
  );
}
