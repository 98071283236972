import { DataBatchExtend, DataGroupItem } from '../system/data';

export interface IAppRecommendationPublic extends IAppRecommendation {
  id: string;
}

export type IAppRecommendationType = 'it-company';
export const IAppRecommendationTypeList: IAppRecommendationType[] = [
  'it-company',
];

export interface IAppRecommendationGroups {
  // name: string;
  type: DataGroupItem[];
  // emails: string[];
  // url: string;
  // social: IAppRecommendationSocial;
  // params?: null | Record<string, string>;
  geo: DataGroupItem[];
  // parsedID?: null | string;
  // categories?: null | string[];
  // createdAt: string;
  // engagedAt: null | string;
}

export interface IAppRecommendationSocial
  extends Record<string, undefined | string> {
  linkedin?: string;
  fb?: string;
  x?: string;
  inst?: string;
  t?: string;
}

export type IAppRecommendationSocialView =
  | boolean
  | IAppRecommendationSocialViewParams;

export interface IAppRecommendationSocialViewParams {
  linkedin?: boolean;
  fb?: boolean;
  x?: boolean;
  inst?: boolean;
  t?: boolean;
}

export interface IAppRecommendationSocialViewParamsFilter {
  linkedin: null | boolean;
  fb: null | boolean;
  x: null | boolean;
  inst: null | boolean;
  t: null | boolean;
}

export interface IAppRecommendationShortUrl {
  id: string;
  url: string;
}

export interface IAppRecommendationView extends Record<string, boolean> {
  name: boolean;
  type: boolean;
  emails: boolean;
  url: boolean;
  social: boolean;
  geo: boolean;
  parsedID: boolean;
  categories: boolean;
  createdAt: boolean;
  engagedAt: boolean;
}

export interface IAppRecommendation {
  name: string;
  type: IAppRecommendationType;
  emails: string[];
  url: string;
  social?: IAppRecommendationSocial;
  params?: null | Record<string, string>;
  geo: string;
  parsedID?: null | string;
  categories?: null | string[];
  createdAt: string;
  engagedAt: null | string;
}

export type IAppRecommendationSet = Record<string, IAppRecommendationPublic>;

export interface IAppRecommendationIDsResponse {
  recommendationIDs: string[];
}

export interface IAppRecommendationOneQuery extends Record<string, string> {
  recommendationID: string;
}

export interface IAppRecommendationOneResponse {
  recommendation: null | IAppRecommendationPublic;
}

export interface IAppRecommendationSetQuery extends Record<string, string> {
  recommendationIDs: string;
}

export interface IAppRecommendationSetResponse {
  set: IAppRecommendationSet;
}

export interface IAppRecommendationBatchBody
  extends Record<
    string,
    string | number | boolean | null | object | undefined
  > {
  limit: number;
  skip: number;
  emails: null | string[];
  categories?: null | string[];
  socialView?: IAppRecommendationSocialView;
  isEngaged: null | boolean;
  isParsed: null | boolean;
}

export type IAppRecommendationBatchResponse = DataBatchExtend<
  string,
  IAppRecommendationGroups
>;

export interface IAppRecommendationSearchQuery extends Record<string, string> {
  search: string;
}

export interface IAppReportLotSearchResponse {
  recommendationIDs: string[];
}

export interface IAppReportLotSearchData {
  search: string;
  recommendationIDs: string[];
}

export interface IAppRecommendationCreateBody
  extends Record<string, string | null | object | undefined> {
  name: string;
  type: IAppRecommendationType;
  emails: string[];
  url: string;
  social: IAppRecommendationSocial;
  params?: null | Record<string, string>;
  geo: string;
  parsedID?: null | string;
  categories?: null | string[];
}

export interface IAppRecommendationCreateListBody {
  items: IAppRecommendationCreateBody[];
}

export interface IAppRecommendationUpdateBody
  extends Partial<IAppRecommendationCreateBody> {
  recommendationID: string;
}

export interface IAppRecommendationDeleteQuery extends Record<string, string> {
  recommendationID: string;
}
