import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import PartnersLotForm from '../../../components/partners/PartnersLotForm';
import {
  IAppPartnerLotPublic,
  IAppPartnerLotSet,
} from '../../../interfaces/partners/IAppPartnerLot';
import {
  deletePartnerLot,
  loadPartnerLotIDs,
} from '../../../actions/partners/partnerLot.actions';
import { PartnersLotItemModeration } from '../../../components/partners/PartnersLotItemModeration';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import {
  RenderFormFunction,
  RenderItemPublicFunction,
} from '../../../common/common';

interface IPartnersLotProps {
  loadPartnerLotIDs: (hardReload?: boolean) => void;
  deletePartnerLot: (partnerID: string) => void;
  IDs: string[];
  set: IAppPartnerLotSet;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function PartnersLot(props: IPartnersLotProps) {
  const renderItemPublic: RenderItemPublicFunction<IAppPartnerLotPublic> = (
    item: IAppPartnerLotPublic
  ): JSX.Element => {
    return <PartnersLotItemModeration partner={item} />;
  };

  const renderForm: RenderFormFunction<IAppPartnerLotPublic> = (
    isDisabled: undefined | boolean,
    item: undefined | IAppPartnerLotPublic,
    cbCancel: () => void,
    cbDelete?: (partnerID: string) => void
  ) => {
    return (
      <PartnersLotForm
        isDisabled={isDisabled}
        partner={item}
        cbCancel={cbCancel}
        cbDelete={cbDelete}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'Partner Lot moderation'}
      itemName={'this lot partner'}
      itemNameCreate={'Create new Partner'}
      cb={{
        cbDelete: (removingID: string) => props.deletePartnerLot(removingID),
      }}
      render={{
        renderItemPublic,
        renderForm,
      }}
      isAdmin={props.isAdmin}
      data={{
        set: props.set,
        list: { load: props.loadPartnerLotIDs, IDs: props.IDs },
        actions: props.actions,
        errors: props.errors,
      }}
      action={{}}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    IDs: state.Partners.Lot.IDs,
    set: state.Partners.Lot.set,
    actions: state.Partners.Lot.actions,
    errors: state.System.errors,
  }),
  {
    loadPartnerLotIDs,
    deletePartnerLot,
  }
)(PartnersLot);
