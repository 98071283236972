import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
  BlockActions,
  BlockPage,
  BlockPageContent,
  ButtonMain,
} from '../styles';
import { ContentHead } from '../typos';
import { sendPageEvent } from '../../utils/analytics';
import { FormRemoveReAsk } from '../forms/FormRemoveReAsk';
import { ModerationItemsList } from './ModerationItemsList';
import ModerationMainHead from './ModerationMainHead';
import { Batch } from './Batch';
import { Search } from './Search';
import { ParamsWrapper } from './ParamsWrapper';
import {
  IModerationAction,
  IModerationCb,
  IModerationData,
  IModerationRender,
  scrollToForm,
} from './Moderation';
import { Block } from '../layout';
import { IParamsItemView } from '../common';

interface IModerationPageProps<ItemPublic> {
  pageTitle?: string;
  itemName?: string;
  itemNameCreate?: string;
  cb?: IModerationCb;
  render: IModerationRender<ItemPublic>;
  data: IModerationData<ItemPublic>;
  isAdmin: boolean;
  action: IModerationAction;
  ParamsItemView?: IParamsItemView;
}

export function ModerationPage<ItemPublic>(
  props: IModerationPageProps<ItemPublic>
) {
  const FormRef: RefObject<HTMLDivElement> = useRef<null | HTMLDivElement>(
    null
  );

  const [paramsView, setParamsView] = useState<IParamsItemView>(
    props.ParamsItemView || null
  );
  const [editedID, setEditedID] = useState<null | string>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [removingID, setRemovingID] = useState<null | string>(null);

  useEffect(() => {
    if (props.pageTitle) {
      sendPageEvent(props.pageTitle);
    }
  }, []);
  useEffect(() => {
    if (!props.data.actions || !props.data.actions[0] || !removingID) {
      return;
    }

    setRemovingID(null);
  }, [props.data.actions]);
  useEffect(() => {
    if (!props.data.errors || !props.data.errors[0] || !removingID) {
      return;
    }

    setRemovingID(null);
  }, [props.data.errors]);

  const create = () => {
    scrollToForm(FormRef);
    setEditedID(null);
    setIsCreating(true);
    setRemovingID(null);
  };
  const edit = (id: null | string) => {
    scrollToForm(FormRef);
    setEditedID(id);
  };
  const cancel = () => {
    setEditedID(null);
    setIsCreating(false);
  };

  const handleDelete = () => {
    if (!removingID || !props.cb || !props.cb.cbDelete) {
      return;
    }
    props.cb.cbDelete(removingID);
  };

  return (
    <BlockPage>
      <BlockPageContent>
        <ModerationMainHead />
        {props.pageTitle && <ContentHead>{props.pageTitle}</ContentHead>}

        {!removingID && !isCreating && (
          <>
            {props.render.renderItemView ? (
              <ParamsWrapper
                title={'view params'}
                renderParams={() =>
                  props.render.renderItemView ? (
                    props.render.renderItemView(setParamsView)
                  ) : (
                    <></>
                  )
                }
              />
            ) : undefined}

            {props.data.search && (
              <Search
                search={props.data.search.search}
                set={props.data.set}
                IDsBySearch={props.data.search.IDsBySearch}
                renderItemPublic={props.render.renderItemPublic}
                placeholderTitle={props.data.search.placeholderTitle}
                action={props.action}
                selection={{}}
              />
            )}

            {props.data.batch && (
              <Batch
                type={'moderation'}
                batch={props.data.batch}
                cbView={props.cb && props.cb.cbView}
                cbEdit={edit}
                cbDelete={setRemovingID}
                set={props.data.set}
                renderItemPublic={props.render.renderItemPublic}
                isAdmin={props.isAdmin}
                ParamsItemView={paramsView}
                action={props.action}
                selection={{}}
              />
            )}

            {props.data.list && (
              <ModerationItemsList
                isDisabled={!!removingID || !!editedID}
                cbView={props.cb && props.cb.cbView}
                cbEdit={edit}
                cbDelete={setRemovingID}
                load={props.data.list.load}
                renderItemPublic={props.render.renderItemPublic}
                IDs={props.data.list.IDs}
                set={props.data.set}
                action={props.action}
                selection={{}}
              />
            )}
          </>
        )}

        <Block ref={FormRef}>
          {isCreating &&
            props.render.renderForm &&
            props.render.renderForm(
              undefined,
              undefined,
              cancel,
              () => undefined
            )}

          {editedID &&
            props.render.renderForm &&
            props.render.renderForm(
              removingID !== null,
              props.data.set[editedID],
              cancel,
              props.cb && props.cb.cbDelete
                ? () => setRemovingID(editedID)
                : undefined
            )}
        </Block>

        {removingID &&
          props.render.renderFormRemove &&
          props.render.renderFormRemove(
            undefined,
            props.data.set[removingID],
            () => setRemovingID(null)
          )}
        {removingID && !props.render.renderFormRemove && (
          <BlockActions>
            <FormRemoveReAsk
              title={props.itemName}
              cbDelete={
                props.cb && props.cb.cbDelete ? handleDelete : undefined
              }
              cbCancel={() => setRemovingID(null)}
            />
          </BlockActions>
        )}

        {!props.action.hideCreate &&
          !removingID &&
          !isCreating &&
          props.render.renderForm && (
            <BlockActions>
              <ButtonMain
                className="main"
                onClick={create}
                disabled={isCreating}
              >
                {props.action.createActionTitle || 'Create'}
              </ButtonMain>
            </BlockActions>
          )}
      </BlockPageContent>
    </BlockPage>
  );
}
