import { IDispatch, IState } from '../../interfaces/system/IState';
import { EXAMPLE_USERS_LOAD } from './typesExample';
import {
  IAccountExamples,
  IAccountExamplesSet,
} from '../../interfaces/examples/IAccountExamples';
import {
  AccountIDExample,
  FirstNames,
  FirstNamesCnt,
  LastNames,
  LastNamesCnt,
} from '../../config/examples/static-accounts';

const AccountCnt: number = 10;
const AccountList: IAccountExamples[] = [];

function generateAccount(ind: number): IAccountExamples {
  const first: string = FirstNames[Math.floor(Math.random() * FirstNamesCnt)];
  const last: string = LastNames[Math.floor(Math.random() * LastNamesCnt)];

  return {
    id: `bot-${ind}`,
    name: `${first} ${last}`,
    mail: `${first}.${last}@gmail.com`,
  };
}

for (let i = 0; i < AccountCnt; i++) {
  AccountList.push(generateAccount(i));
}

export const loadUsers =
  () => (dispatch: IDispatch<IAccountExamplesSet>, getState: () => IState) => {
    const users: IAccountExamplesSet = {};
    AccountList.forEach((account: IAccountExamples) => {
      users[account.id] = account;
    });
    users[AccountIDExample] = {
      id: AccountIDExample,
      mail: 'your.post@gmail.com',
      name: 'Your Name',
    };

    dispatch({ type: EXAMPLE_USERS_LOAD, payload: users });
  };

export function getRandomAccount(): IAccountExamples {
  return AccountList[Math.floor(Math.random() * AccountCnt)];
}
