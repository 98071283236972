import { apiConfig } from '../../config/api.config';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import {
  ANALYTICS_RETENTION_LOAD,
  ANALYTICS_RETENTION_LOAD_BY_PLATFORM,
  ANALYTICS_RETENTION_LOAD_CACHE,
  ANALYTICS_RETENTION_LOAD_CACHE_ALL,
  ANALYTICS_RETENTION_ONLINE_NOW_LOAD,
  ANALYTICS_RETENTION_ONLINE_NOW_LOAD_BY_PLATFORM,
} from '../types';
import {
  IBaseAnalyticsAggregatedModelGetByPlatformResponse,
  IBaseAnalyticsAggregatedModelGetCacheAllResponse,
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  loadAnalyticsCacheAllWrapper,
  loadAnalyticsCacheWrapper,
  loadAnalyticsMainByPlatformWrapper,
  loadAnalyticsMainWrapper,
  saveAnalyticsCacheByPlatformWrapper,
  saveAnalyticsCacheWrapper,
} from './analyticsWrapper.actions';
import { IPlatformType } from '../../config/platform.config';
import {
  IBehavioralAnalyticsRetentionOnlineNowByPlatformData,
  IBehavioralAnalyticsRetentionOnlineNowByPlatformQuery,
  IBehavioralAnalyticsRetentionOnlineNowResponse,
} from '../../interfaces/analytics/IBehavioralAnalyticsRetention';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';

const { main, platform, cache, cacheAll, online, onlinePlatform } =
  apiConfig.endpoints.analytics.behavioralRetention;

export const loadAnalyticsRetentionMain =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      IBaseAnalyticsAggregatedModelGetResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainWrapper(
      main,
      ANALYTICS_RETENTION_LOAD,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsRetentionMainByPlatform =
  (platformType: IPlatformType, from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetByPlatformResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainByPlatformWrapper(
      platform,
      ANALYTICS_RETENTION_LOAD_BY_PLATFORM,
      platformType,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsRetentionCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheWrapper(
      cache,
      ANALYTICS_RETENTION_LOAD_CACHE,
      hardReload
    )(dispatch, getState);
  };

export const loadAnalyticsRetentionCacheAll =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheAllResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheAllWrapper(
      cacheAll,
      ANALYTICS_RETENTION_LOAD_CACHE_ALL,
      hardReload
    )(dispatch, getState);
  };

export const saveAnalyticsRetentionCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheWrapper(cache)(dispatch, getState);
  };

export const saveAnalyticsRetentionCacheByPlatform =
  (platformType: IPlatformType) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheByPlatformWrapper(cacheAll, platformType)(
      dispatch,
      getState
    );
  };

export const loadAnalyticsRetentionOnlineNow =
  () =>
  (
    dispatch: IDispatch<number | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    callApi<IBehavioralAnalyticsRetentionOnlineNowResponse>(online)
      .then((data: IBehavioralAnalyticsRetentionOnlineNowResponse) =>
        dispatch({
          type: ANALYTICS_RETENTION_ONLINE_NOW_LOAD,
          payload: data.cnt,
        })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadAnalyticsRetentionOnlineNowByPlatform =
  (platformType: IPlatformType) =>
  (
    dispatch: IDispatch<
      | IBehavioralAnalyticsRetentionOnlineNowByPlatformData
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const params: IBehavioralAnalyticsRetentionOnlineNowByPlatformQuery = {
      platformType,
    };

    callApi<IBehavioralAnalyticsRetentionOnlineNowResponse>(
      onlinePlatform,
      params
    )
      .then((data: IBehavioralAnalyticsRetentionOnlineNowResponse) => {
        const payload: IBehavioralAnalyticsRetentionOnlineNowByPlatformData = {
          platformType,
          cnt: data.cnt,
        };
        dispatch({
          type: ANALYTICS_RETENTION_ONLINE_NOW_LOAD_BY_PLATFORM,
          payload,
        });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
