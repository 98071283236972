import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BlockPage, BlockPageContent } from '../../common/styles';
import { ContentDesc, ContentDescInline, PageHead } from '../../common/typos';
import { sendPageEvent } from '../../utils/analytics';
import { IState } from '../../interfaces/system/IState';
import { loadAuctionIDs } from '../../actions/auctions/auction.actions';
import { IAppAuctionPublic } from '../../interfaces/auctions/IAppAuction';
import Auction from '../../components/auctions/Auction';
import { L } from '../../common/locale';

interface IAuctionListPageProps {
  loadAuctionIDs: () => any;
  completed: IAppAuctionPublic[];
  upcoming: IAppAuctionPublic[];
}

function AuctionListPage(props: IAuctionListPageProps) {
  // const navigate = useNavigate();
  // const [auctionsCompleted, setAuctionsCompleted] = useState<IAuction[]>([]);
  // const [auctionsUpcoming, setAuctionsUpcoming] = useState<IAuction[]>([]);

  useEffect(() => sendPageEvent('Auction List'), []);
  useEffect(() => props.loadAuctionIDs(), []);

  // const goToShop = () => {
  //   navigate(appConfig.routes.shopProducts.list);
  // };

  return (
    <BlockPage>
      <BlockPageContent>
        <PageHead>
          <L k={'AuctionsTitle'} />:
        </PageHead>
        {props.upcoming[0] && (
          <>
            <ContentDesc className="success">
              <ContentDescInline style={{ fontSize: '2rem' }}>
                •
              </ContentDescInline>
              <L k={'AuctionsTitleUpcomingShort'} />
            </ContentDesc>
            {props.upcoming.map((auction: IAppAuctionPublic) => (
              <Auction auction={auction} key={auction.id} />
            ))}
            {/*<ActionButton onClick={goToShop}>Go to shop</ActionButton>*/}
          </>
        )}

        {props.completed[0] && (
          <>
            <ContentDesc>
              <L k={'AuctionsTitleCompletedShort'} />
            </ContentDesc>
            {props.completed.map((auction: IAppAuctionPublic) => (
              <Auction auction={auction} key={auction.id} />
            ))}
          </>
        )}
      </BlockPageContent>
    </BlockPage>
  );
}

export default connect(
  (state: IState) => ({
    auctionIDs: state.Auctions.auctionIDs,
    upcoming: state.Auctions.auctions.upcoming,
    completed: state.Auctions.auctions.completed,
  }),
  { loadAuctionIDs }
)(AuctionListPage);
