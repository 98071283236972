import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { CacheRequests } from '../../service/cacheRequests';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { DataBatch } from '../../interfaces/system/data';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAppShopLotBatchData,
  IAppShopLotBatchQuery,
  IAppShopLotListData,
  IAppShopLotListQuery,
  IAppShopLotListResponse,
  IAppShopLotProductAnalyticsData,
  IAppShopLotProductAnalyticsQuery,
  IAppShopLotProductAnalyticsResponse,
} from '../../interfaces/shop/IAppShopLot';
import { IPlatformType } from '../../config/platform.config';
import {
  SHOP_PRODUCT_ANALYTICS_LOAD,
  SHOP_PRODUCT_BATCH_LOAD,
  SHOP_PRODUCT_LIST_LOAD,
} from '../types';
import { IProductPreviewOrder } from '../../interfaces/shop/IProduct';

const { shop } = apiConfig.endpoints;

const cacheBatchFirst: CacheRequests = new CacheRequests();
const cacheByIDs: CacheRequests = new CacheRequests();
const cacheDetailsByID: CacheRequests = new CacheRequests();
export const productOrder: IProductPreviewOrder = 'popular';

export const loadProductAnalytics =
  (platformType: IPlatformType, productID: string, hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      IAppShopLotProductAnalyticsData | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    const toLoad: string[] = cacheByIDs.getToLoad([productID]);
    if (!toLoad[0] && !hardReload) {
      return;
    }

    const query: IAppShopLotProductAnalyticsQuery = {
      productID: hardReload ? productID : toLoad[0],
      platformType,
    };
    callApi<IAppShopLotProductAnalyticsResponse>(shop.productAnalytics, query)
      .then((data: IAppShopLotProductAnalyticsResponse) => {
        const payload: IAppShopLotProductAnalyticsData = {
          platformType,
          productID,
          analytics: data.analytics,
        };
        dispatch({ type: SHOP_PRODUCT_ANALYTICS_LOAD, payload });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadProductBatchFirst =
  (platformType: IPlatformType) =>
  (
    dispatch: IDispatch<
      | IAppShopLotBatchData
      | IAppShopLotListData
      | IAppShopLotProductAnalyticsData
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const { limit } = getState().Shop.preview;
    if (cacheBatchFirst.checkOneIsLoaded(platformType)) {
      return;
    }

    const query: IAppShopLotBatchQuery = {
      productOrder,
      limit: limit.toString(),
      skip: '0',
      platformType,
    };

    callApi<DataBatch<string>>(shop.productBatch, query)
      .then((data: DataBatch<string>) => {
        const payload: IAppShopLotBatchData = {
          platformType,
          order: productOrder,
          ...data,
        };
        dispatch({ type: SHOP_PRODUCT_BATCH_LOAD, payload });
        loadProductList(platformType, data.items)(dispatch, getState);
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadProductBatchNext =
  (
    platformType: IPlatformType,
    productOrder: IProductPreviewOrder,
    hardReload?: boolean
  ) =>
  (
    dispatch: IDispatch<
      | IAppShopLotBatchData
      | IAppShopLotListData
      | IAppShopLotProductAnalyticsData
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const { orderByPlatform, limit } = getState().Shop.preview;

    const query: IAppShopLotBatchQuery = {
      productOrder,
      limit: limit.toString(),
      skip: hardReload
        ? '0'
        : (
            (orderByPlatform[platformType][productOrder] &&
              orderByPlatform[platformType][productOrder].loaded) ||
            0
          ).toString(),
      platformType,
    };

    const queryID: string = `Products-${platformType}-${query.productOrder}-${query.limit}-${query.skip}`;
    const toLoad: string[] = cacheByIDs.getToLoad([queryID]);
    if (!toLoad[0]) {
      return;
    }

    callApi<DataBatch<string>>(shop.productBatch, query)
      .then((data: DataBatch<string>) => {
        const payload: IAppShopLotBatchData = {
          platformType,
          order: productOrder,
          ...data,
        };
        dispatch({ type: SHOP_PRODUCT_BATCH_LOAD, payload });
        loadProductList(platformType, data.items)(dispatch, getState);
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadProductList =
  (platformType: IPlatformType, previewIDs: string[]) =>
  (
    dispatch: IDispatch<
      | IAppShopLotListData
      | IAppShopLotProductAnalyticsData
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    const productIDs: string[] = [];
    const { setByPlatform } = getState().Shop.preview;
    previewIDs.forEach((id: string) => {
      if (!setByPlatform[platformType][id]) {
        productIDs.push(id);
      }
    });

    const toLoad: string[] = cacheByIDs.getToLoad(productIDs);
    if (!toLoad[0]) {
      return;
    }

    const query: IAppShopLotListQuery = {
      productIDs: toLoad.join(','),
      platformType,
    };

    callApi<IAppShopLotListResponse>(shop.productMain, query)
      .then((data: IAppShopLotListResponse) => {
        const payload: IAppShopLotListData = {
          platformType,
          products: data.products,
        };
        dispatch({ type: SHOP_PRODUCT_LIST_LOAD, payload });
      })

      .then(() =>
        toLoad.map((productID: string) =>
          loadProductAnalytics(platformType, productID)(dispatch, getState)
        )
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

// export const reloadProductsByID =
//   (previewID: string) =>
//   (
//     dispatch: IDispatch<
//       IProductPreview[] | IProductAnalyticsByAccount[] | IServiceInfo | IServiceError
//     >,
//     getState: () => IState
//   ) => {
//     cacheByIDs.getToLoad([previewID]);
//
//     callApi<IProductPreviewResponse>(product.byIDs, {
//       productIDs: previewID,
//     })
//       .then((data: IProductPreviewResponse) =>
//         dispatch({ type: SHOP_PRODUCT_PREVIEW_LOAD, payload: data.products })
//       )
//       .then(() => getAnalyticsList([previewID])(dispatch, getState))
//       .catch((error: Error | IApiError) =>
//         catchError(dispatch, error, getState)
//       );
//   };

// export const loadDetailsByID =
//   (productID: string) =>
//   (dispatch: IDispatch<IProduct | IServiceInfo | IServiceError>, getState: () => IState) => {
//     const { byID } = getState().ShopProduct.products;
//     if (byID[productID]) {
//       return;
//     }
//     const toLoad: string[] = cacheDetailsByID.getToLoad([productID]);
//     if (!toLoad[0]) {
//       return;
//     }
//
//     callApi<IProductDetailsResponse>(product.details, { productID })
//       .then(
//         (data: IProductDetailsResponse) =>
//           data.product &&
//           dispatch({ type: SHOP_PRODUCT_DETAILS_LOAD, payload: data.product })
//       )
//       .catch((error: Error | IApiError) =>
//         catchError(dispatch, error, getState)
//       );
//   };

// export const reloadDetailsByID =
//   (productID: string) =>
//   (dispatch: IDispatch<IProduct | IServiceInfo | IServiceError>, getState: () => IState) => {
//     cacheDetailsByID.getToLoad([productID]);
//     callApi<IProductDetailsResponse>(product.details, { productID })
//       .then(
//         (data: IProductDetailsResponse) =>
//           data.product &&
//           dispatch({ type: SHOP_PRODUCT_DETAILS_LOAD, payload: data.product })
//       )
//       .catch((error: Error | IApiError) =>
//         catchError(dispatch, error, getState)
//       );
//   };

// export const loadProductsByPath =
//   (accountPath: string, productPath: string) =>
//   (
//     dispatch: IDispatch<IProductListSet | IAccountSet | IServiceInfo | IServiceError>,
//     getState: () => IState
//   ) => {
//     callApi<IProductListResponse>(product.path, { accountPath, productPath })
//       .then((data: IProductListResponse) => {
//         const payload: IProductListSet = {
//           productPath,
//           accountPath,
//           productList: data.productList,
//         };
//         dispatch({ type: SHOP_PRODUCT_LOAD, payload });
//         loadAccounts(
//           data.productList.map((product: IProduct) => product.sellerID)
//         )(dispatch, getState);
//       })
//       .catch((error: Error | IApiError) =>
//         catchError(dispatch, error, getState)
//       );
//   };

// export const loadProductsBySellers =
//   (accountPath: string) =>
//   (
//     dispatch: IDispatch<IProductListSet | IAccountSet | IServiceInfo | IServiceError>,
//     getState: () => IState
//   ) => {
//     callApi<IProductListResponse>(product.seller, { accountPath })
//       .then((data: IProductListResponse) => {
//         const payload: IProductListSet = {
//           productPath: null,
//           accountPath,
//           productList: data.productList,
//         };
//         dispatch({ type: SHOP_PRODUCT_LOAD, payload });
//
//         loadAccounts(
//           data.productList.map((product: IProduct) => product.sellerID)
//         )(dispatch, getState);
//       })
//       .catch((error: Error | IApiError) =>
//         catchError(dispatch, error, getState)
//       );
//   };

// export const searchProducts =
//   (search: string) =>
//   (
//     dispatch: IDispatch<
//       | DataSearchSetProduct
//       | IProductPreview[]
//       | IProductAnalyticsByAccount[]
//       | IServiceInfo | IServiceError
//     >,
//     getState: () => IState
//   ) => {
//     if (search.length < 3) {
//       return;
//     }
//     if (getState().ShopProduct.batchPreview.IDBySearch[search] !== undefined) {
//       return;
//     }
//
//     callApi<IProductSearchResponse>(product.search, { search })
//       .then((data: IProductSearchResponse) => {
//         const payload: DataSearchSetProduct = {
//           search,
//           items: data.productIDs,
//         };
//
//         dispatch({ type: SHOP_PRODUCT_SEARCH, payload });
//         loadProductsByIDs(data.productIDs)(dispatch, getState);
//       })
//       .catch((error: Error | IApiError) =>
//         catchError(dispatch, error, getState)
//       );
//   };
