import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import {
  IAccountExamples,
  IAccountExamplesSet,
} from '../../interfaces/examples/IAccountExamples';
import { ContentDescInline } from '../../common/typos';

interface ITopAccountProps {
  accountID: string;
  users: IAccountExamplesSet;
}

function TopAccount(props: ITopAccountProps) {
  const [account, setAccount] = useState<null | IAccountExamples>(null);
  useEffect(() => {
    setAccount(props.users[props.accountID]);
  }, [props.users, props.accountID]);

  return (
    <ContentDescInline>
      {account ? account.name : props.accountID.slice(0, 5)} -{' '}
    </ContentDescInline>
  );
}

export default connect(
  (state: IState) => ({
    users: state.Example.users,
  }),
  {}
)(TopAccount);
