import React, { useEffect, useRef, useState } from 'react';
import {
  Head,
  HeadBlock,
  HeadLinks,
  MenuBlock,
  MenuIcon,
  MenuIconBlock,
} from './Header.styles';
import { NavLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { IState } from '../interfaces/system/IState';
import { MainButton } from './landing/Button';
import { appConfig } from '../config/app.config';
import { savePathBeforeLogin, signOut } from '../actions/account/user.actions';
import { IAccount } from '../interfaces/account/IAccount';
import {
  ContentDescInline,
  ContentLi,
  ContentUl,
  PageHead,
} from '../common/typos';
import { ActionLink, BlockPage } from '../common/styles';
import { colors } from '../common/colors';
import Notifications from './account/Notifications';
import { margins } from '../common/sizes';
import { setContainerWidth } from '../actions/system/system.actions';
import i18next from 'i18next';
import { L } from '../common/locale';

interface IHeaderProps {
  scrollTo: (buttonName: string) => void;
  signOut: () => void;
  setContainerWidth: (width: number) => void;
  account: null | IAccount;
  isAdmin: boolean;
  cntNew: number;
  cntAll: number;
  notificationIDs: string[];
}

function Header(props: IHeaderProps) {
  const headerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [marginTop, setMarginTop] = useState<number>(0);
  const [marginRight, setMarginRight] = useState<number>(0);

  const [isActiveNotifications, setIsActiveNotifications] =
    useState<boolean>(false);
  const [isActiveLeft, setIsActiveLeft] = useState<boolean>(false);
  const [isActiveRight, setIsActiveRight] = useState<boolean>(false);
  const [isActiveLanguage, setIsActiveLanguage] = useState<boolean>(false);

  useEffect(() => {
    if (headerRef.current) {
      setMarginTop(-headerRef.current.clientHeight / 2 + 15);
      setMarginRight((window.innerWidth - headerRef.current.clientWidth) / 2);
      props.setContainerWidth(headerRef.current.clientWidth);
    }
  }, [headerRef.current]);
  const toggleLeft = () => {
    setIsActiveRight(false);
    setIsActiveNotifications(false);
    setIsActiveLeft(!isActiveLeft);
  };
  const toggleRight = () => {
    setIsActiveLeft(false);
    setIsActiveNotifications(false);
    setIsActiveRight(!isActiveRight);
  };
  const toggleNotifications = () => {
    setIsActiveLeft(false);
    setIsActiveRight(false);
    setIsActiveNotifications(!isActiveNotifications);
  };

  const goToLogin = () => {
    savePathBeforeLogin(window.location.pathname);
    navigate(appConfig.routes.main.login);
  };

  const goToHome = () => {
    navigate(appConfig.routes.main.home);
    window.scrollTo(0, 0);
  };

  const goToLeadForm = () => {
    navigate(appConfig.routes.main.leadForm);
    window.scrollTo(0, 0);
  };

  const changeLanguage = (lng: 'ua' | 'en') => {
    i18next.changeLanguage(lng);
    setIsActiveLanguage(false);
  };

  return (
    <Head>
      <BlockPage className={'relative'} ref={headerRef}>
        <HeadBlock>
          <MenuIconBlock>
            {!isActiveLeft && (
              <MenuIcon
                src="/icons/menu.png"
                alt="user"
                onClick={() => toggleLeft()}
              />
            )}
            {isActiveLeft && (
              <MenuIcon
                src="/icons/cancel.png"
                alt="user"
                onClick={() => toggleLeft()}
              />
            )}
          </MenuIconBlock>

          <PageHead
            onClick={goToHome}
            className="clickable"
            style={{ padding: 0 }}
          >
            Create Auction
          </PageHead>
          <MenuIconBlock style={{ display: 'flex' }}>
            <>
              {!isActiveLanguage && (
                <ActionLink onClick={() => setIsActiveLanguage(true)}>
                  {i18next.language}
                </ActionLink>
              )}

              {isActiveLanguage && (
                <ContentUl className={'noMargin'}>
                  <ContentLi>
                    <ActionLink
                      onClick={() => changeLanguage('ua')}
                      className={i18next.language === 'ua' ? 'main' : ''}
                    >
                      ua
                    </ActionLink>
                  </ContentLi>

                  <ContentLi>
                    <ActionLink
                      onClick={() => changeLanguage('en')}
                      className={i18next.language === 'en' ? 'main' : ''}
                    >
                      en
                    </ActionLink>
                  </ContentLi>
                </ContentUl>
              )}
            </>

            {/*{props.account && (
              <ContentDescInline style={{ textTransform: 'initial' }}>
                hi, {props.account.name.split(' ')[0]}
              </ContentDescInline>
            )}*/}
            {!props.account && (
              <ContentDescInline onClick={goToLogin}>
                <ActionLink>
                  <L k={'MenuLogIn'} />
                </ActionLink>
              </ContentDescInline>
            )}

            {props.cntAll !== 0 && (
              <div style={{ float: 'left', display: 'flex' }}>
                {props.cntNew === 0 && (
                  <MenuIcon
                    onClick={() => toggleNotifications()}
                    src="/icons/bell_empty.png"
                    alt="user"
                  />
                )}
                {props.cntNew !== 0 && (
                  <ContentDescInline style={{ color: colors.danger }}>
                    {props.cntNew}
                  </ContentDescInline>
                )}
                {props.cntNew !== 0 && (
                  <MenuIcon
                    onClick={() => toggleNotifications()}
                    src="/icons/bell_data.png"
                    alt="user"
                  />
                )}
              </div>
            )}
            {/*<MenuIcon
              src="/icons/user.png"
              alt="user"
              onClick={() => toggleRight()}
            />*/}

            <HeadLinks style={{ marginLeft: margins.item }}>
              <MainButton
                noShadow={true}
                label="Create"
                isDisabled={false}
                onClick={goToLeadForm}
              />
            </HeadLinks>
          </MenuIconBlock>
        </HeadBlock>

        <MenuBlock
          onClick={() => toggleLeft()}
          className={isActiveLeft ? 'active' : ''}
          style={{
            marginTop: marginTop + 'px',
          }}
        >
          <NavLink to={appConfig.routes.main.numbers}>
            <L k={'MenuCreate'} />
          </NavLink>
          <NavLink to={appConfig.routes.partners.list}>
            <L k={'MenuPartners'} />
          </NavLink>
          <NavLink to={appConfig.routes.auctions.list}>
            <L k={'MenuAuctions'} />
          </NavLink>
          <NavLink to={appConfig.routes.auctions.lotsAll}>
            <L k={'ButtonLotsFresh'} />
          </NavLink>
          {props.isAdmin && (
            <HeadLinks className="admin">
              <NavLink to={appConfig.routes.Moderation.main}>
                <L k={'MenuModeration'} />
              </NavLink>
            </HeadLinks>
          )}{' '}
          {props.isAdmin && (
            <HeadLinks className="admin">
              <NavLink to={appConfig.routes.Analytics.main}>
                <L k={'MenuAnalytics'} />
              </NavLink>
            </HeadLinks>
          )}
        </MenuBlock>

        <MenuBlock
          onClick={() => toggleRight()}
          className={isActiveRight ? 'active' : ''}
          style={{
            marginTop: marginTop + 'px',
            right: marginRight + 'px',
          }}
        >
          <NavLink to={appConfig.routes.account.lots}>My Lots</NavLink>
          <NavLink to={appConfig.routes.account.donations}>
            <L k={'MenuMyDonations'} />
          </NavLink>
          <NavLink to={appConfig.routes.account.settings}>Settings</NavLink>
          {props.account && (
            <NavLink to="" onClick={props.signOut}>
              <L k={'MenuLogout'} />
            </NavLink>
          )}
        </MenuBlock>

        <MenuBlock
          className={
            isActiveNotifications ? 'active notifications' : 'notifications'
          }
          style={{
            marginTop: marginTop + 'px',
            right: marginRight + 'px',
          }}
        >
          <Notifications />
        </MenuBlock>
      </BlockPage>
    </Head>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    isAdmin: state.Account.isAdmin,
    cntNew: state.Account.notifications.cntNew,
    cntAll: state.Account.notifications.cntAll,
    notificationIDs: state.Account.notifications.IDs,
  }),
  {
    setContainerWidth,
    signOut,
  }
)(Header);
