import { apiConfig } from '../../config/api.config';
import { getToken, removeToken, setToken } from './auth.actions';
import { IDispatch, IState } from '../../interfaces/system/IState';
// import {  SET_LOGIN_ACTION } from '../types';
import { ACCOUNT_LOADED, ACCOUNT_LOGOUT } from '../types';
import {
  IAccount,
  IAccountAuthBody,
  IAccountAuthResponse,
} from '../../interfaces/account/IAccount';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { callApi, catchError } from '../../service/api';
import { IApiError } from '../../interfaces/system/IApi';
import {
  getFromSession,
  KeySession,
  setToSession,
} from '../../service/sessionStorage';
import { INotificationSet } from '../../interfaces/account/IAccountNotification';
// import {
//   IAccount,
//   IAccountAuthBody,
//   IAccountAuthResponse,
//   IAccountBodyUpdate,
// } from '../../interfaces/account/IAccount';
// import { IDispatch, IState } from '../../interfaces/system/IState';
// import { IApiError } from '../../interfaces/system/IApi';
// import { getToken, removeToken, setToken } from './auth.actions';
// import { getFromSession, setToSession } from '../../service/sessionStorage';
// import { IServiceError } from '../../interfaces/system/IError';
// import {
//   getPlatformRole,
//   removePlatformRole,
//   setPlatformRole,
// } from './role.actions';
// import { IPlatformRole } from '../../interfaces/Example/IRole';
// import { INotificationSet } from '../../interfaces/account/IAccountNotification';

const { auth, main } = apiConfig.endpoints.account;

const keyLogin: KeySession = 'PathBeforeLogin';
const keyAction: KeySession = 'LoginAction';
export const savePathBeforeLogin = (path: string) => {
  setToSession(keyLogin, path);
};

export const getPathBeforeLogin = (): null | string => {
  return getFromSession(keyLogin);
};

export const getLoginAction = (): null | string => {
  const action: null | string = getFromSession(keyAction);
  if (!action) {
    return null;
  }
  return action.split('addToCalendar---')[1] || null;
};

export const checkIsLogged =
  () =>
  (
    dispatch: IDispatch<
      IAccount | string[] | INotificationSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    const token: null | string = getToken();
    if (token) {
      checkToken(token)(dispatch, getState);
    }
  };

export const checkToken =
  (token: string) =>
  (
    dispatch: IDispatch<IAccount | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    const body: IAccountAuthBody = {
      token,
    };

    setToken(token);
    callApi<IAccountAuthResponse>(auth.token, {}, body, 'POST')
      .then((data: IAccountAuthResponse) => {
        dispatch({ type: ACCOUNT_LOADED, payload: data.account });
        setToken(token);
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const checkTokenGoogle =
  (token: string) =>
  (
    dispatch: IDispatch<IAccount | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    const body: IAccountAuthBody = {
      token,
    };

    callApi<IAccountAuthResponse>(auth.tokenGoogle, {}, body, 'POST')
      .then((data: IAccountAuthResponse) => {
        dispatch({ type: ACCOUNT_LOADED, payload: data.account });
        setToken(data.token);
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const signOut =
  () => (dispatch: IDispatch<null>, getState: () => IState) => {
    removeToken();
    dispatch({ type: ACCOUNT_LOGOUT, payload: null });
  };

// export const updateAccount =
//   (image: null | string, name: null | string) =>
//   (
//     dispatch: IDispatch<
//       IAccount | IPlatformRole | string[] | INotificationSet | IServiceInfo | IServiceError
//     >,
//     getState: () => IState
//   ) => {
//     const body: IAccountBodyUpdate = {};
//     if (image) {
//       body.image = image;
//     }
//     if (name) {
//       body.name = name;
//     }
//
//     callApi<IAccountAuthResponse>(main, {}, body, 'POST')
//       .then(() =>
//         dispatchError(dispatch, {
//           message: 'Profile is updated',
//           params: {},
//           type: 'Info',
//         })
//       )
//       .then(() => checkIsLogged()(dispatch, getState))
//       .catch((error: Error | IApiError) =>
//         catchError(dispatch, error, getState)
//       );
//   };

// export const goToAddToCalendar =
//   (path: string) => (dispatch: IDispatch<string | IServiceInfo | IServiceError>) => {
//     const action: string = `addToCalendar---${path}`;
//     dispatch({ type: SET_LOGIN_ACTION, payload: action });
//     setToSession(keyAction, action);
//   };
