export const CREATE_AUCTION_LOAD = 'CREATE_AUCTION_LOAD';
export const CREATE_AUCTION_ACTION = 'CREATE_AUCTION_ACTION';
export const ITEM_CREATED = 'ITEM_CREATED';
export const ITEM_UPDATED = 'ITEM_UPDATED';
export const ITEM_REMOVED = 'ITEM_REMOVED';
export const DATA_LOADED = 'DATA_LOADED';
export const API_ERROR = 'API_ERROR';
export const API_ERROR_POP = 'API_ERROR_POP';
export const API_INFO_PUSH = 'API_INFO_PUSH';
export const API_INFO_POP = 'API_INFO_POP';
export const AUCTION_LOAD_IDS = 'AUCTION_LOAD_IDS';
export const AUCTION_LOAD_SET = 'AUCTION_LOAD_SET';
export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';

export const ACCOUNTS_LOAD_SET = 'ACCOUNTS_LOAD_SET';
export const ACCOUNTS_LOAD_BATCH = 'ACCOUNTS_LOAD_BATCH';
export const ACCOUNTS_SEARCH_LOAD = 'ACCOUNTS_SEARCH_LOAD';
export const ACCOUNTS_ACTION = 'ACCOUNTS_ACTION';

export const NOTIFICATIONS_IDS_LOAD = 'NOTIFICATIONS_IDS_LOAD';
export const NOTIFICATIONS_ONE_LOAD = 'NOTIFICATIONS_ONE_LOAD';
export const NOTIFICATIONS_SET_LOAD = 'NOTIFICATIONS_SET_LOAD';
export const NOTIFICATION_VIEW = 'NOTIFICATION_VIEW';
export const NOTIFICATIONS_FOR_MODERATION_LOAD =
  'NOTIFICATIONS_FOR_MODERATION_LOAD';
export const SETTINGS_LOAD = 'SETTINGS_LOAD';
export const REPORT_LOT_SET_LOAD = 'REPORT_LOT_SET_LOAD';
export const REPORT_LOT_BATCH_LOAD = 'REPORT_LOT_BATCH_LOAD';
export const REPORT_LOT_SEARCH_LOAD = 'REPORT_LOT_SEARCH_LOAD';
export const PARTNER_AUCTION_LOAD_IDS = 'PARTNER_AUCTION_LOAD_IDS';
export const PARTNER_AUCTION_LOAD_SET = 'PARTNER_AUCTION_LOAD_SET';
export const PARTNER_AUCTION_ACTION = 'PARTNER_AUCTION_ACTION';
export const PARTNER_FEEDBACKS_LOAD_IDS = 'PARTNER_FEEDBACKS_LOAD_IDS';
export const PARTNER_FEEDBACKS_LOAD_SET = 'PARTNER_FEEDBACKS_LOAD_SET';
export const PARTNER_FEEDBACKS_ACTION = 'PARTNER_FEEDBACKS_ACTION';
export const PARTNER_LOT_LOAD_IDS = 'PARTNER_LOT_LOAD_IDS';
export const PARTNER_LOT_LOAD_SET = 'PARTNER_LOT_LOAD_SET';
export const PARTNER_LOT_ACTION = 'PARTNER_LOT_ACTION';
export const SHOP_PRODUCT_BATCH_LOAD = 'SHOP_PRODUCT_BATCH_LOAD';
export const SHOP_PRODUCT_LIST_LOAD = 'SHOP_PRODUCT_LIST_LOAD';
export const SHOP_PRODUCT_ANALYTICS_LOAD = 'SHOP_PRODUCT_ANALYTICS_LOAD';
export const RECOMMENDATION_LOAD_IDS = 'RECOMMENDATION_LOAD_IDS';
export const RECOMMENDATION_LOAD_SET = 'RECOMMENDATION_LOAD_SET';
export const RECOMMENDATION_LOAD_BATCH = 'RECOMMENDATION_LOAD_BATCH';
export const RECOMMENDATION_SEARCH_LOAD = 'RECOMMENDATION_SEARCH_LOAD';
export const RECOMMENDATION_ACTION = 'RECOMMENDATION_ACTION';
export const TEMPLATE_PARAMS_LOAD_IDS = 'TEMPLATE_PARAMS_LOAD_IDS';
export const TEMPLATE_PARAMS_LOAD_SET = 'TEMPLATE_PARAMS_LOAD_SET';
export const TEMPLATE_PARAMS_LOAD_BATCH = 'TEMPLATE_PARAMS_LOAD_BATCH';
export const TEMPLATE_PARAMS_SEARCH_LOAD = 'TEMPLATE_PARAMS_SEARCH_LOAD';
export const TEMPLATE_PARAMS_ACTION = 'TEMPLATE_PARAMS_ACTION';
export const MAILING_LOAD_IDS = 'MAILING_LOAD_IDS';
export const MAILING_LOAD_SET = 'MAILING_LOAD_SET';
export const MAILING_LOAD_SETTINGS = 'MAILING_LOAD_SETTINGS';
export const MAILING_LOAD_BATCH = 'MAILING_LOAD_BATCH';
export const MAILING_LOAD_PREVIEW = 'MAILING_LOAD_PREVIEW';
export const MAILING_ACTION = 'MAILING_ACTION';
export const EMAIL_LOG_LOAD_BATCH = 'EMAIL_LOG_LOAD_BATCH';
export const EMAIL_LOG_LOAD_IDS = 'EMAIL_LOG_LOAD_IDS';
export const EMAIL_LOG_LOAD_SET = 'EMAIL_LOG_LOAD_SET';
export const ANALYTICS_ROUTE_LOAD = 'ANALYTICS_ROUTE_LOAD';
export const ANALYTICS_ROUTE_LOAD_BY_PLATFORM =
  'ANALYTICS_ROUTE_LOAD_BY_PLATFORM';
export const ANALYTICS_ROUTE_LOAD_EXTENDED = 'ANALYTICS_ROUTE_LOAD_EXTENDED';
export const ANALYTICS_ROUTE_LOAD_OPTIONS = 'ANALYTICS_ROUTE_LOAD_OPTIONS';
export const ANALYTICS_ROUTE_LOAD_CACHE = 'ANALYTICS_ROUTE_LOAD_CACHE';
export const ANALYTICS_ROUTE_LOAD_CACHE_ALL = 'ANALYTICS_ROUTE_LOAD_CACHE_ALL';
export const ANALYTICS_CRON_LOAD = 'ANALYTICS_CRON_LOAD';
export const ANALYTICS_CRON_LOAD_BY_PLATFORM =
  'ANALYTICS_CRON_LOAD_BY_PLATFORM';
export const ANALYTICS_CRON_LOAD_CACHE = 'ANALYTICS_CRON_LOAD_CACHE';
export const ANALYTICS_CRON_LOAD_CACHE_ALL = 'ANALYTICS_CRON_LOAD_CACHE_ALL';
export const ANALYTICS_DB_LOAD = 'ANALYTICS_DB_LOAD';
export const ANALYTICS_DB_LOAD_BY_PLATFORM = 'ANALYTICS_DB_LOAD_BY_PLATFORM';
export const ANALYTICS_DB_LOAD_EXTENDED = 'ANALYTICS_DB_LOAD_EXTENDED';
export const ANALYTICS_DB_LOAD_OPTIONS = 'ANALYTICS_DB_LOAD_OPTIONS';
export const ANALYTICS_DB_LOAD_CACHE = 'ANALYTICS_DB_LOAD_CACHE';
export const ANALYTICS_DB_LOAD_CACHE_ALL = 'ANALYTICS_DB_LOAD_CACHE_ALL';
export const ANALYTICS_RETENTION_LOAD = 'ANALYTICS_RETENTION_LOAD';
export const ANALYTICS_RETENTION_LOAD_BY_PLATFORM =
  'ANALYTICS_RETENTION_LOAD_BY_PLATFORM';
export const ANALYTICS_RETENTION_LOAD_CACHE = 'ANALYTICS_RETENTION_LOAD_CACHE';
export const ANALYTICS_RETENTION_LOAD_CACHE_ALL =
  'ANALYTICS_RETENTION_LOAD_CACHE_ALL';
export const ANALYTICS_RETENTION_ONLINE_NOW_LOAD =
  'ANALYTICS_RETENTION_ONLINE_NOW_LOAD';
export const ANALYTICS_RETENTION_ONLINE_NOW_LOAD_BY_PLATFORM =
  'ANALYTICS_RETENTION_ONLINE_NOW_LOAD_BY_PLATFORM';
export const SYSTEM_SET_WIDTH = 'SYSTEM_SET_WIDTH';
