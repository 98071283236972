import styled from 'styled-components';
import { colors, shadow, shadowS } from '../../common/colors';
import { device, margins } from '../../common/sizes';
import { transitions } from '../../common/styles';

export const LiveRow = styled.div`
  display: block;
  width: 100%;
  float: left;

  &.second {
    margin-top: ${margins.block};
    display: flex;
    flex-direction: column-reverse;
  }

  @media ${device.mobileL} {
    display: flex;
    justify-content: space-between;

    &.second {
      display: block;
      flex-direction: unset;
    }
  }
`;
export const LiveColumn = styled.div`
  display: block;
  width: 100%;

  @media ${device.mobileL} {
    display: inline-block;
    float: left;
    width: 49%;
    box-sizing: border-box;
  }
`;
export const LiveVideo = styled.video`
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  ${shadow};
`;
export const ExampleWrap = styled.section`
  white-space: nowrap;
  ${shadowS};
  background-color: ${colors.backgroundDark};
`;
export const ExampleBlock = styled.div`
  height: 0;
  overflow: hidden;

  &.active {
    height: auto;
    ${transitions.easy}
  }
`;
export const ExampleIconDown = styled.img`
  width: 30px;
  cursor: pointer;
  margin: auto;
  display: block;

  &.inline {
    margin: 0;
    display: inline-block;
  }
`;
export const ExampleIconBlock = styled.div`
  display: block;
  margin-top: -15px;
  z-index: 999;
  position: relative;
`;
export const SupportBlockItemFirst = styled.p`
  margin-bottom: ${margins.block} !important;
`;
export const SupportBlockItem = styled.p`
  margin: ${margins.item} 0 !important;
`;
export const SupportBlockIcon = styled.img`
  margin-right: 5px;
  width: 10px;
`;
