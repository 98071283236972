import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  BidAdditional,
  BidExtraSmall,
  BidMain,
  BidMedium,
  BidSmall,
  CustomBidInput,
  CustomBidSubmit,
  CustomBox,
} from './MakeBid.styles';
import { currencyConvert } from '../../utils/price';

import { ContentDesc } from '../../common/typos';
import { ILotShort, ILotStatus } from '../../interfaces/examples/ILot';
import { IState } from '../../interfaces/system/IState';
import { createBid } from '../../actions/examples/bid.actions';

interface IMakeBidProps {
  createBid: (lotID: string, price: number) => void;
  lotByID: Record<string, ILotShort>;
  currentLotID: null | string;
  statusByLotID: Record<string, ILotStatus>;
}

function MakeBid(props: IMakeBidProps) {
  const inputBidRef = useRef<HTMLInputElement>(null);
  const [isValidBid, setIsValidBid] = useState(false);
  const [lot, setLot] = useState<null | ILotShort>(null);
  const [status, setStatus] = useState<ILotStatus>(null);

  useEffect(() => {
    setLot(props.currentLotID ? props.lotByID[props.currentLotID] : null);
  }, [props.currentLotID, props.lotByID]);
  useEffect(() => {
    const statusLocal = props.currentLotID
      ? props.statusByLotID[props.currentLotID]
      : null;
    setStatus(statusLocal);
  }, [props.statusByLotID, props.currentLotID]);

  function makeBid(rate: null | number) {
    if (!lot || rate === null) {
      return;
    }

    const bid: number = rate + lot.price;
    const isFirstBid: boolean = !lot.rate.x08 && !lot.rate.x12;

    if (isFirstBid && rate === 0) {
      return props.createBid(lot.id, bid);
    }

    if (validateCustomBid(bid)) {
      props.createBid(lot.id, bid);
    }
  }

  function preValidateCustomBid() {
    const bid: number =
      (inputBidRef.current && Number(inputBidRef.current.value)) || 0;

    setIsValidBid(validateCustomBid(bid));
  }

  function makeCustomBid() {
    if (!lot) {
      return;
    }

    const bid: number =
      (inputBidRef.current && Number(inputBidRef.current.value)) || 0;

    if (validateCustomBid(bid)) {
      props.createBid(lot.id, bid);
      if (inputBidRef.current) {
        inputBidRef.current.value = '';
      }
    }
    // todo show error for customer that bad offer
  }

  function validateCustomBid(bid: number): boolean {
    return (lot && lot.price && bid > lot.price) || false;
  }

  function renderBid(isMain: boolean, rate: null | number) {
    if (!lot) {
      return <></>;
    }

    const isFirstBid: boolean = !lot.rate.x08 && !lot.rate.x12;
    const bid: number = (rate || 0) + lot.price;
    const converted: number = currencyConvert(bid);
    const label = `${bid} ($${converted})`;

    if (isMain) {
      return (
        <BidMain
          // className="bidMain"
          className={'bidMain ' + (isFirstBid ? 'firstBid' : '')}
          disabled={status === 'win'}
          onClick={() => makeBid(rate)}
        >
          bid {label}
        </BidMain>
      );
    } else {
      return (
        rate && (
          <BidAdditional
            className="bidAdditional"
            disabled={status === 'win'}
            onClick={() => makeBid(rate)}
          >
            {label}
          </BidAdditional>
        )
      );
    }
  }

  return (
    <>
      {/*todo check LOT_WAIT_BIDS || Lot.pause (from api admin actions -> socket)*/}

      {lot && (
        <>
          <ContentDesc>Make your offer:</ContentDesc>
          <BidExtraSmall>
            {renderBid(false, lot.rate.x08)}
            {renderBid(true, lot.rate.x10)}
            {renderBid(false, lot.rate.x12)}
          </BidExtraSmall>
          <BidSmall>
            <div>{renderBid(true, lot.rate.x10)}</div>
            <div className="additional">
              {renderBid(false, lot.rate.x08)}
              {renderBid(false, lot.rate.x12)}
            </div>
          </BidSmall>
          <BidMedium
            className={!lot.rate.x08 && !lot.rate.x12 ? 'firstBid' : ''}
          >
            <div>{renderBid(true, lot.rate.x10)}</div>
            <div>
              {renderBid(false, lot.rate.x08)}
              {renderBid(false, lot.rate.x12)}
            </div>
          </BidMedium>

          <CustomBox>
            <CustomBidInput
              ref={inputBidRef}
              onChange={preValidateCustomBid}
              type="number"
              min="0"
              // autoFocus
              placeholder={'or enter you offer'}
            />
            <CustomBidSubmit
              onClick={makeCustomBid}
              className={isValidBid ? 'valid' : ''}
            >
              <img src="/icons/makeBid.png" alt="make bid" />
            </CustomBidSubmit>
          </CustomBox>
        </>
      )}
    </>
  );
}

export default connect(
  (state: IState) => ({
    lotByID: state.Example.lotByID,
    currentLotID: state.Example.currentLotID,
    statusByLotID: state.Example.statusByLotID,
  }),
  {
    createBid,
  }
)(MakeBid);
