import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  IState,
  IStateShopProductByOrder,
} from '../../interfaces/system/IState';
import { IProductPreviewOrder } from '../../interfaces/shop/IProduct';
import {
  initPlatformSet,
  IPlatforms,
  IPlatformType,
} from '../../config/platform.config';
import { productOrder } from '../../actions/shop/product.actions';
import { ActionLink } from '../../common/styles';
import { ContentBold, ContentDesc, ContentHead } from '../../common/typos';
import PreviewCarousel from './PreviewCarousel';
import { Loading } from '../../common/animation/Loading';
import {
  IAppPartnerAuctionPublic,
  IAppPartnerAuctionSet,
} from '../../interfaces/partners/IAppPartnerAuction';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { IAppAuctionPublic } from '../../interfaces/auctions/IAppAuction';
import { Block } from '../../common/layout';
import { makePathFromName } from '../../utils/routes';
import { L } from '../../common/locale';

interface IPreviewListProps {
  platforms: IPlatformType[];
  orderByPlatform: IPlatforms<
    Record<IProductPreviewOrder, IStateShopProductByOrder>
  >;
  IDs: string[];
  set: IAppPartnerAuctionSet;
  upcoming: IAppAuctionPublic[];
}

function PreviewList(props: IPreviewListProps) {
  const navigate = useNavigate();
  const [platforms, setPlatforms] = useState<IPlatformType[]>([]);
  const [previewsByPlatform, setPreviewsByPlatform] = useState<
    IPlatforms<string[]>
  >(initPlatformSet<string[]>([]));
  const [partnerByPlatform, setPartnerByPlatform] = useState<
    IPlatforms<null | IAppPartnerAuctionPublic>
  >(initPlatformSet<null | IAppPartnerAuctionPublic>(null));

  useEffect(() => setPlatforms(props.platforms), [props.platforms]);
  useEffect(() => {
    const previewsByPlatformLocal: IPlatforms<string[]> = Object.assign(
      {},
      previewsByPlatform
    );

    platforms.forEach((platformType: IPlatformType) => {
      const set: null | IStateShopProductByOrder =
        props.orderByPlatform[platformType][productOrder] || null;
      if (!set) {
        return;
      }

      previewsByPlatformLocal[platformType] = set.previewIDs;
    });
    setPreviewsByPlatform(previewsByPlatformLocal);
  }, [platforms, props.orderByPlatform]);
  useEffect(() => {
    const partnerByPlatformLocal: IPlatforms<null | IAppPartnerAuctionPublic> =
      Object.assign({}, partnerByPlatform);
    props.IDs.forEach((partnerID: string) => {
      if (!props.set[partnerID]) {
        return;
      }

      const partner: IAppPartnerAuctionPublic = props.set[partnerID];
      if (!platforms.includes(partner.platformType)) {
        return;
      }

      partnerByPlatformLocal[partner.platformType] = partner;
    });

    setPartnerByPlatform(partnerByPlatformLocal);
  }, [platforms, props.IDs, props.set]);

  const navigateToPartner = (partner: IAppPartnerAuctionPublic) => {
    if (!partner) {
      return;
    }

    navigate(
      appConfig.routes.partners.one.replace(
        appConfig.params.platformPath,
        partner.platformType
      )
    );
  };

  const navigateToAuction = (auction: IAppAuctionPublic) => {
    if (!auction) {
      return;
    }
    const auctionPath = makePathFromName(auction.name);
    const path: string = appConfig.routes.auctions.one
      .replace(appConfig.params.auctionPath, auctionPath)
      .replace(appConfig.params.platformPath, auction.platformType);
    navigate(path);
  };

  const renderPartner = (
    partner: null | IAppPartnerAuctionPublic
  ): JSX.Element => {
    if (!partner) {
      return <></>;
    }

    const auctions: IAppAuctionPublic[] = [];
    const platformType: IPlatformType = partner.platformType;
    props.upcoming.forEach((auction: IAppAuctionPublic) => {
      if (auction.platformType === platformType) {
        auctions.push(auction);
      }
    });

    return (
      <>
        <ContentDesc>
          Our partner{' '}
          <ActionLink onClick={() => navigateToPartner(partner)}>
            <ContentBold>{partner.name}</ContentBold>
          </ActionLink>
        </ContentDesc>

        {auctions[0] && (
          <Block>
            {auctions.map((auction: IAppAuctionPublic) => (
              <ContentDesc>
                <ActionLink onClick={() => navigateToAuction(auction)}>
                  <ContentBold>{auction.name}</ContentBold>
                </ActionLink>
              </ContentDesc>
            ))}
          </Block>
        )}
      </>
    );
  };

  if (!props.platforms[0]) {
    return <></>;
  }

  return (
    <Block>
      <ContentHead>
        <L k={'LandingLotsPopular'} />
      </ContentHead>

      {platforms.map((platformType: IPlatformType) => (
        <>
          {partnerByPlatform[platformType] &&
            renderPartner(partnerByPlatform[platformType])}

          {previewsByPlatform[platformType] && (
            <PreviewCarousel
              platformType={platformType}
              noView={false}
              previewIDs={previewsByPlatform[platformType]}
            />
          )}
          {!previewsByPlatform[platformType] && <Loading size={'big'} />}
        </>
      ))}
    </Block>
  );
}

export default connect(
  (state: IState) => ({
    platforms: state.Shop.preview.platforms,
    orderByPlatform: state.Shop.preview.orderByPlatform,
    IDs: state.Partners.Auction.IDs,
    set: state.Partners.Auction.set,
    upcoming: state.Auctions.auctions.upcoming,
  }),
  {}
)(PreviewList);
