import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageHead } from '../typos';
import { IState } from '../../interfaces/system/IState';
import { appConfig } from '../../config/app.config';
import { useNavigate } from 'react-router-dom';
import { IAccount } from '../../interfaces/account/IAccount';
import { getToken } from '../../actions/account/auth.actions';
import { savePathBeforeLogin } from '../../actions/account/user.actions';

interface IModerationMainHeadProps {
  account: null | IAccount;
  isAdmin: boolean;
}

function ModerationMainHead(props: IModerationMainHeadProps) {
  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.account && getToken()) {
      return;
    }
    if (!props.account && !props.isAdmin) {
      goToLogin();
    }
  }, [props.account]);

  const goToLogin = () => {
    savePathBeforeLogin(window.location.pathname);
    navigation(appConfig.routes.main.login);
  };
  const goToModerationMain = () => {
    navigation(appConfig.routes.Moderation.main);
  };

  return (
    <PageHead className={'clickable'} onClick={goToModerationMain}>
      Admin Moderation
    </PageHead>
  );
}

export default connect(
  (state: IState) => ({
    account: state.Account.account,
    isAdmin: state.Account.isAdmin,
  }),
  {},
)(ModerationMainHead);
