import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { leadReducer } from './lead.reducer';
import { exampleReducer } from './example.reducer';
import { auctionsReducer } from './auctions.reducer';
import { accountReducer } from './account.reducer';
import { partnersReducer } from './partners.reducer';
import { systemReducer } from './system.reducer';
import { shopReducer } from './shop.reducer';
import { CreateAuctionReducer } from './CreateAuction.reducer';
import { communicationReducer } from './communication.reducer';
import { analyticsReducer } from './analytics.reducer';

export const store: EnhancedStore = configureStore({
  reducer: combineReducers({
    CreateAuction: CreateAuctionReducer,
    Lead: leadReducer,
    Example: exampleReducer,
    Auctions: auctionsReducer,
    Communication: communicationReducer,
    Account: accountReducer,
    Partners: partnersReducer,
    Shop: shopReducer,
    System: systemReducer,
    Analytics: analyticsReducer,
  }),
});
