import styled from 'styled-components';
import { colors, shadow } from '../colors';
import { margins, sizes, sizesBorder } from '../sizes';
import { borders, transitions } from '../styles';
import { fonts, typos } from '../typos';

const heightL: string = '15px';
const heightM: string = '19px';
const Weight: string = '34px';
const Padding: string = '2px';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  &.disabled {
    border-color: rgba(19, 1, 1, 0.3) !important;
    color: rgba(19, 1, 1, 0.3) !important;
    cursor: not-allowed;
  }
`;
export const Label = styled.label`
  margin-bottom: 5px;
  font-size: 16px;

  &.clickable {
    cursor: pointer;
  }
`;
export const LabelSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: ${Weight};
  height: ${heightM};
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;
export const InputSearch = styled.input`
  //text-align: center;
  font-size: ${fonts.sizeM};
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${colors.main};
  background-color: transparent;
  outline: none;
  //width: calc(100% - 25px);
  width: 100%;
  box-sizing: border-box;

  :focus {
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }
`;
export const InputSwitch = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  background-color: ${colors.main};

  &:checked + .slider {
    background-color: ${colors.main};
  }

  &:focus + .slider {
    box-shadow: 0 0 1px ${colors.main};
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(${heightL});
    -ms-transform: translateX(${heightL});
    transform: translateX(${heightL});
  }
`;
export const InputInline = styled.input`
  ${typos.desc};
  border-radius: ${sizesBorder.radius};
  border: ${borders.mainS};
  padding: ${margins.item} ${margins.block};
  outline: none;
  width: 100%;
  max-width: ${sizes.widthM};
  box-sizing: border-box;

  margin-left: ${margins.item};

  :focus {
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }
`;
export const InputDrag = styled.input`
  height: ${margins.block};
  width: ${margins.block};
  margin: 0;
  padding: ${margins.block};
`;

export const ErrorInfo = styled.span`
  color: ${colors.error};
`;
export const FormBlock = styled.div`
  display: block;
  float: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  margin: ${margins.item} 0 !important;

  &.sending {
    filter: opacity(0.5);
  }

  &.shadow {
    ${shadow};
  }
`;

export const TextAreaShort = styled.textarea`
  padding: ${margins.item};
  font-size: ${fonts.sizeM};
  border: ${borders.greyS};
  border-radius: ${sizesBorder.radius};
  width: ${sizes.full};
  resize: none;
  height: 4rem;
  box-sizing: border-box;
  ${fonts.main};
`;
export const TextAreaLong = styled.textarea`
  padding: ${margins.item};
  font-size: ${fonts.sizeM};
  border: ${borders.greyS};
  border-radius: ${sizesBorder.radius};
  width: ${sizes.full};
  //resize: none;
  height: 8rem;
  box-sizing: border-box;
  ${fonts.main};
`;

export const SliderSwitch = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.backgroundDark};

  border-radius: ${margins.block};
  transition: ${transitions.easy};

  &:before {
    position: absolute;
    content: '';
    height: ${heightL};
    width: ${heightL};
    left: ${Padding};
    bottom: ${Padding};
    background-color: ${colors.background};
    border-radius: ${sizes.half};
    transition: ${transitions.easy};
  }
`;
