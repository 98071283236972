import React, { useState } from 'react';
import { IStateAnalyticsGroupExtended } from '../../interfaces/system/IState';
import { ChartsBlock } from './analytics.styles';
import { IBaseAnalyticsAggregatedModelCreate } from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import { Block } from '../../common/layout';
import { Order } from '../../common/forms/Order';
import {
  elementOrderList,
  IElementOrder,
  IItemsOrder,
  itemsOrderList,
} from './AnalyticsDescriptionItemsElement';
import { AnalyticsDescriptionItems } from './AnalyticsDescriptionItems';
import { AnalyticsDescriptionExtended } from './AnalyticsDescriptionExtended';

interface IAnalyticsDescriptionProps {
  items: IBaseAnalyticsAggregatedModelCreate<
    string | undefined,
    string | undefined
  >[];
  Extended?: IStateAnalyticsGroupExtended;
  cbExtendedLoad?: (value: null | string, param: null | string) => void;
}

export function AnalyticsDescription(props: IAnalyticsDescriptionProps) {
  const [extendedView, setExtendedView] = useState<boolean>(false);
  const [itemOrder, setItemOrder] = useState<IItemsOrder>('date');
  const [elementOrder, setElementOrder] = useState<IElementOrder>('max');

  return (
    <ChartsBlock>
      <Block>
        <Order
          itemsSimple={itemsOrderList}
          order={itemOrder}
          cb={setItemOrder}
          isSmall={true}
        />
      </Block>
      <Block>
        <Order
          itemsSimple={elementOrderList}
          order={elementOrder}
          cb={setElementOrder}
          isSmall={true}
        />
      </Block>

      <AnalyticsDescriptionExtended
        Extended={props.Extended}
        cbExtendedLoad={props.cbExtendedLoad}
        cbIsShow={setExtendedView}
        itemOrder={itemOrder}
        elementOrder={elementOrder}
      />

      {!extendedView && (
        <AnalyticsDescriptionItems
          items={props.items}
          itemOrder={itemOrder}
          elementOrder={elementOrder}
        />
      )}
    </ChartsBlock>
  );
}
