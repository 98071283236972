import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../interfaces/system/IState';
import {
  prepareSearchKeyReportLot,
  searchReportLot,
} from '../../actions/auctions/reportLot.actions';
import { IAppReportLotPublic } from '../../interfaces/auctions/IAppReportLot';
import { IPlatformType } from '../../config/platform.config';
import { ReportLotInline } from './ReportLotInline';
import { Search } from '../../common/moderation/Search';

interface IReportLotSearchProps {
  platformType: null | IPlatformType;
  searchReportLot: (platformType: null | IPlatformType, search: string) => void;
  byLotID: Record<string, IAppReportLotPublic>;
  IDsBySearchKey: Record<string, string[]>;
}

function ReportLotSearch(props: IReportLotSearchProps) {
  const [searchKey, setSearchKey] = useState<string>('');

  const search = (value: string): void => {
    if (!value) {
      return;
    }
    setSearchKey(prepareSearchKeyReportLot(props.platformType, value));
    props.searchReportLot(props.platformType, value);
  };

  return (
    <Search
      searchKey={searchKey}
      search={search}
      set={props.byLotID}
      IDsBySearch={props.IDsBySearchKey}
      renderItemPublic={(reportLot: IAppReportLotPublic) => (
        <ReportLotInline reportLot={reportLot} key={reportLot.id} />
      )}
      action={{
        showInline: true,
      }}
      placeholderTitle={'lot name'}
    />
  );
}

export default connect(
  (state: IState) => ({
    byLotID: state.Auctions.reportLots.byLotID,
    IDsBySearchKey: state.Auctions.reportLots.IDsBySearchKey,
  }),
  {
    searchReportLot,
  }
)(ReportLotSearch);
