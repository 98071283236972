import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { DATA_LOADED } from '../types';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { ICreateLeadBody } from '../../interfaces/ILead';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';

const { main } = apiConfig.endpoints.leads;

export const setLead =
  (name: string, email: string, company: string) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    const body: ICreateLeadBody = {
      name,
      email,
      company,
    };
    callApi(main, {}, body)
      .then(() => dispatch({ type: DATA_LOADED, payload: 'success' }))
      .catch((error: Error) => catchError(dispatch, error));
  };
