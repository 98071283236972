import React, { useEffect } from 'react';
import {
  ScreenBlock,
  VictoryBlock,
  VictoryBlockName,
  VictoryWrap,
} from './ThankYouPage.styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { logEvent } from 'firebase/analytics';
import { analyticsApp } from '../../config/firebase.config';
import { ContentDesc, PageHead } from '../../common/typos';
import { BlockPage } from '../../common/styles';

export function ThankYouPage() {
  useEffect(() => logEvent(analyticsApp, 'OpenThankYouPage'), []);

  return (
    <>
      <ScreenBlock>
        <BlockPage className="mainScreen">
          <PageHead>
            Your submission <br />
            has been received!
          </PageHead>
          <ContentDesc>
            We appreciate your interest in our platform to create a successful
            auction event for your company's fundraising efforts. We will be
            contacting you shortly to discuss the next steps.
          </ContentDesc>
        </BlockPage>
      </ScreenBlock>
      <VictoryWrap>
        {[1, 2, 3, 4].map((i, index: number) => (
          <VictoryBlock key={index}>
            <VictoryBlockName>Support the Ukraine army!</VictoryBlockName>
          </VictoryBlock>
        ))}
      </VictoryWrap>
    </>
  );
}
