import { apiConfig } from '../config/api.config';
import { IDispatch } from '../interfaces/system/IState';
import { IApiError, IApiHeaders } from '../interfaces/system/IApi';
import { IServiceError, IServiceInfo } from '../interfaces/system/IError';
import { dispatchError } from './error';
import { getToken } from '../actions/account/auth.actions';
import { IPlatformType } from '../config/platform.config';

function makeURL(
  path: string,
  params?: Record<string, null | undefined | string>
): string {
  const query: Record<string, string> = {};
  if (params) {
    Object.keys(params).forEach((key: string) => {
      const value: null | string = params[key] || null;
      if (value) {
        query[key] = value;
      }
    });
  }

  return `${apiConfig.host}${path}?` + new URLSearchParams(query);
}

export function getHeadersWithPlatform(
  platformType: IPlatformType
): Record<string, string> {
  return { platformType };
}

export function callApi<Data>(
  path: string,
  params?: Record<string, null | undefined | string>,
  body?: Record<
    string,
    undefined | string | string[] | number | boolean | null | object
  >,
  method?: 'POST' | 'DELETE',
  headersAdd?: Record<string, string>
): Promise<Data> {
  const url: string = makeURL(path, params);
  const headers: IApiHeaders = {
    Authorization: 'Basic ' + getToken(),
    'Content-Type': 'application/json',
  };
  if (headersAdd) {
    Object.keys(headersAdd).forEach((key: string) => {
      headers[key] = headersAdd[key];
    });
  }
  const requestOptions: RequestInit = body
    ? {
        method: method || 'PUT',
        headers,
        body: JSON.stringify(body),
      }
    : {
        method: method || 'GET',
        headers,
      };

  let err: null | Error | IApiError = null;
  return new Promise<Data>(
    (resolve: (data: Data) => void, reject: (e: Error | IApiError) => void) => {
      fetch(url, requestOptions)
        .then((response: Response) =>
          response.json().then((json) => {
            if (!response.ok) {
              throw json;
            }

            return json;
          })
        )
        .then((data: Data) => resolve(data))
        .catch((e: Error) => {
          err = e;
          reject(e);
        })
        .finally(() => {
          // if (path !== 'system/log') {
          //   sendLog({
          //     path,
          //     method: method || 'GET',
          //     token: getToken() ? '+' : '-'
          //     error: err ? JSON.stringify(err) : '',
          //   });
          // }
        });
    }
  );
}

export function catchError(
  dispatch: IDispatch<IServiceInfo | IServiceInfo | IServiceError>,
  error: Error | IApiError
) {
  const err: IServiceError = {
    message: error.message,
    params: {},
  };
  if ('value' in error) {
    err.params.value = error.value;
  }
  if ('description' in error) {
    err.params.description = error.description;
  }
  dispatchError(dispatch, err);
}
