import React, { useEffect, useRef, useState } from 'react';
import { ImageInput } from './ImageInput';
import { ImageCropper } from './ImageCropper';
import { ImageBlock } from './Image.styles';
import { Area } from 'react-easy-crop/types';
import { ActionButton, BlockActions } from '../styles';

interface ImageLoaderProps {
  isDisabled?: boolean;
  image?: string;
  allowRemove?: boolean;
  cbImage: (imageBase64: null | string) => void;
  autoSelect?: boolean;
  noCrop?: boolean;
}

export function ImageLoader(props: ImageLoaderProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const [image, setImage] = useState<string>('');
  const [mode, setMode] = useState<null | 'choose' | 'crop'>('choose');
  const [isReset, setIsReset] = useState<boolean>(props.autoSelect || false);

  useEffect(() => {
    setImage(props.image || '');
    if (props.image && props.image !== '') {
      setMode(null);
    } else if (props.image === '') {
      setMode('choose');
    }
  }, [props.image]);

  const onImageSelected = (image: string) => {
    setImage(image);
    if (props.noCrop) {
      onCropDone(null, image);
    } else {
      setMode('crop');
    }
  };

  const onCropDone = (imgCroppedArea: null | Area, imageLocal?: string) => {
    if (!imgCroppedArea && !props.noCrop) {
      return;
    }

    let imageObj = new Image();
    imageObj.src = imageLocal || image;
    imageObj.onload = () => {
      const canvasEle = document.createElement('canvas');
      canvasEle.width = imgCroppedArea
        ? imgCroppedArea.width
        : (divRef.current && divRef.current.clientWidth) || 0;
      canvasEle.height = imgCroppedArea
        ? imgCroppedArea.height
        : (((divRef.current && divRef.current.clientWidth) || 0) *
            imageObj.height) /
          imageObj.width;

      const context: CanvasRenderingContext2D | null =
        canvasEle.getContext('2d');
      if (!context) {
        return;
      }
      if (imgCroppedArea) {
        context.drawImage(
          imageObj,
          imgCroppedArea.x,
          imgCroppedArea.y,
          imgCroppedArea.width,
          imgCroppedArea.height,
          0,
          0,
          imgCroppedArea.width,
          imgCroppedArea.height
        );
      } else {
        context.drawImage(imageObj, 0, 0, canvasEle.width, canvasEle.height);
      }

      const len: number = image.length;
      let quality: number = 1;
      if (len > 8000000) {
        quality = 0.3;
      } else if (len > 6000000) {
        quality = 0.4;
      } else if (len > 4000000) {
        quality = 0.5;
      } else if (len > 2000000) {
        quality = 0.6;
      } else if (len > 1500000) {
        quality = 0.7;
      } else if (len > 1000000) {
        quality = 0.8;
      } else if (len > 500000) {
        quality = 0.9;
      }
      const dataURL = canvasEle.toDataURL('image/jpeg', quality);
      onImageReady(dataURL);
    };
  };

  const onCropCancel = () => {
    props.cbImage(null);
    setMode('choose');
    setImage('');
  };

  const onImageReady = (imageBase64: string) => {
    props.cbImage(imageBase64);
    setMode(null);
  };

  const resetImage = () => {
    setMode('choose');
    setImage('');
    setIsReset(true);
  };

  const remove = () => {
    props.cbImage(null);
  };

  return (
    <>
      <ImageBlock ref={divRef}>
        {mode === 'choose' ? (
          <ImageInput
            isDisabled={props.isDisabled}
            onImageSelected={onImageSelected}
            autoSelect={props.autoSelect || isReset}
          />
        ) : mode === 'crop' ? (
          <ImageCropper
            image={image}
            onCropDone={onCropDone}
            onCropCancel={onCropCancel}
          />
        ) : (
          <BlockActions>
            <ActionButton
              onClick={resetImage}
              className="fail"
              disabled={props.isDisabled}
            >
              Select another image
            </ActionButton>
            {props.allowRemove && (
              <ActionButton
                onClick={remove}
                className="fail"
                disabled={props.isDisabled}
              >
                Remove image
              </ActionButton>
            )}
          </BlockActions>
        )}
      </ImageBlock>
    </>
  );
}
