import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { CacheRequests } from '../../service/cacheRequests';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAppPartnerFeedbacksCreateBody,
  IAppPartnerFeedbacksDeleteQuery,
  IAppPartnerFeedbacksIDsResponse,
  IAppPartnerFeedbacksSet,
  IAppPartnerFeedbacksSetQuery,
  IAppPartnerFeedbacksSetResponse,
  IAppPartnerFeedbacksUpdateBody,
} from '../../interfaces/partners/IAppPartnerFeedbacks';
import { dispatchInfo } from '../../service/error';
import {
  ITEM_CREATED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  PARTNER_FEEDBACKS_ACTION,
  PARTNER_FEEDBACKS_LOAD_IDS,
  PARTNER_FEEDBACKS_LOAD_SET,
} from '../types';
import { IPlatformType } from '../../config/platform.config';

const { partnerFeedbacks } = apiConfig.endpoints.partners;
const cacheByID: CacheRequests = new CacheRequests();

export const loadPartnerFeedbacksIDs =
  (hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      string | string[] | IAppPartnerFeedbacksSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    const { Partners } = getState();
    if (!hardReload && Partners.Feedbacks.IDs[0]) {
      return;
    }

    callApi<IAppPartnerFeedbacksIDsResponse>(partnerFeedbacks.ids)
      .then((data: IAppPartnerFeedbacksIDsResponse) => {
        loadPartnerFeedbacksSet(data.partnerIDs)(dispatch, getState);

        dispatch({
          type: PARTNER_FEEDBACKS_LOAD_IDS,
          payload: data.partnerIDs,
        });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadPartnerFeedbacksSet =
  (partnerFeedbacksIDs: string[], hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      string | IAppPartnerFeedbacksSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    if (!partnerFeedbacksIDs || !partnerFeedbacksIDs[0]) {
      return;
    }
    const set: IAppPartnerFeedbacksSet = getState().Partners.Feedbacks.set;
    let IDsToLoad: string[] = [];
    if (!hardReload) {
      const toLoad: string[] = [];
      partnerFeedbacksIDs.forEach((partnerFeedbacksID: string) => {
        if (!set[partnerFeedbacksID]) {
          toLoad.push(partnerFeedbacksID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      IDsToLoad = cacheByID.getToLoad(toLoad);
      if (!IDsToLoad[0]) {
        return;
      }
    }

    const query: IAppPartnerFeedbacksSetQuery = {
      partnerIDs: hardReload
        ? partnerFeedbacksIDs.join(',')
        : IDsToLoad.join(','),
    };
    callApi<IAppPartnerFeedbacksSetResponse>(partnerFeedbacks.main, query)
      .then((data: IAppPartnerFeedbacksSetResponse) => {
        dispatch({ type: PARTNER_FEEDBACKS_LOAD_SET, payload: data.set });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const updatePartnerFeedbacks =
  (
    partnerID: string,
    accountID: undefined | null | string,
    name: null | string,
    position: null | string,
    description: undefined | null | string,
    imageURL: null | string,
    feedback: null | string
  ) =>
  (
    dispatch: IDispatch<
      string | IAppPartnerFeedbacksSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IAppPartnerFeedbacksUpdateBody = {
      partnerID,
    };
    if (name) {
      body.name = name;
    }
    if (position) {
      body.position = position;
    }
    if (imageURL) {
      body.imageURL = imageURL;
    }
    if (feedback) {
      body.feedback = feedback;
    }
    if (accountID !== undefined) {
      body.accountID = accountID;
    }
    if (description !== undefined) {
      body.description = description;
    }

    callApi(partnerFeedbacks.main, {}, body, 'POST')
      .then(() =>
        dispatch({ type: PARTNER_FEEDBACKS_ACTION, payload: ITEM_UPDATED })
      )
      .then(() =>
        loadPartnerFeedbacksSet([partnerID], true)(dispatch, getState)
      )
      .then(() => dispatchInfo(dispatch, 'Feedback is updated'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

// export const updatePartnerFeedbacksSelf =

export const createPartnerFeedbacks =
  (
    platformType: IPlatformType,
    accountID: null | string,
    name: string,
    position: string,
    description: null | string,
    imageURL: string,
    feedback: string
  ) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IAppPartnerFeedbacksCreateBody = {
      platformType,
      accountID,
      name,
      position,
      description,
      imageURL,
      feedback,
    };
    callApi(partnerFeedbacks.main, {}, body)
      .then(() =>
        dispatch({ type: PARTNER_FEEDBACKS_ACTION, payload: ITEM_CREATED })
      )
      .then(() => dispatchInfo(dispatch, 'Feedback is created'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const deletePartnerFeedbacks =
  (partnerID: string) =>
  (
    dispatch: IDispatch<
      string | string[] | IAppPartnerFeedbacksSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const query: IAppPartnerFeedbacksDeleteQuery = {
      partnerID: partnerID,
    };
    callApi(partnerFeedbacks.main, query, undefined, 'DELETE')
      .then(() =>
        dispatch({ type: PARTNER_FEEDBACKS_ACTION, payload: ITEM_REMOVED })
      )
      .then(() => loadPartnerFeedbacksIDs(true)(dispatch, getState))
      .then(() => dispatchInfo(dispatch, 'Feedback is removed'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
