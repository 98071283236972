import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { appConfig } from '../config/app.config';
import { FooterBlock, FooterBlockItem } from './Footer.styles';
import { BlockPageContent } from '../common/styles';

export function Footer() {
  const location = useLocation();
  const [isShown, setIsShown] = useState<boolean>(true);
  useEffect(() => {
    setIsShown(location.pathname === appConfig.routes.main.home);
  }, [location.pathname]);
  return (
    <BlockPageContent className={'relative'}>
      {isShown && (
        <FooterBlock>
          <FooterBlockItem>
            <NavLink to={appConfig.routes.landing.terms}>Terms</NavLink>
          </FooterBlockItem>
          <FooterBlockItem>
            <NavLink to={appConfig.routes.landing.privacy}>Privacy</NavLink>
          </FooterBlockItem>
        </FooterBlock>
      )}
    </BlockPageContent>
  );
}
