import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendEvent } from '../../utils/analytics';
import { ContentHead } from '../../common/typos';
import { IState } from '../../interfaces/system/IState';
import { FormBlock } from '../../common/forms/Form.styles';
import {
  createPartnerLot,
  updatePartnerLot,
} from '../../actions/partners/partnerLot.actions';
import {
  IAppPartnerLotPublic,
  IAppPartnerLotSet,
} from '../../interfaces/partners/IAppPartnerLot';
import { Form, IFormItem } from '../../common/forms/Form';
import { FormsFields } from '../../common/forms/forms';

interface IPartnersLotFormProps {
  partner?: null | IAppPartnerLotPublic;
  isDisabled?: boolean;
  createPartnerLot: (
    name: string,
    description: string,
    imageURL: string
  ) => void;
  updatePartnerLot: (
    partnerID: string,
    name: null | string,
    description: null | string,
    imageURL: null | string
  ) => void;
  cbCancel: () => void;
  cbDelete?: (partnerID: string) => void;
  set: IAppPartnerLotSet;
  actions: string[];
  errors: string[];
}

function PartnersLotForm(props: IPartnersLotFormProps) {
  const [isSending, setIsSending] = useState<boolean>(false);

  const fields: Partial<
    Record<keyof IAppPartnerLotPublic, undefined | IFormItem>
  > = {
    name: {
      ...FormsFields.name,
      $value: props.partner?.name,
    },
    description: {
      ...FormsFields.description,
      $value: props.partner?.description,
    },
    imageURL: {
      ...FormsFields.imageURL,
      title: 'Company image',
      $valueList: (props.partner && [props.partner.imageURL]) || [],
    },
  };

  useEffect(() => sendEvent('Open-PartnersLotForm'), []);
  useEffect(() => {
    if (!props.actions[0] || !isSending) {
      return;
    }

    setIsSending(false);
    props.cbCancel();
  }, [props.actions]);
  useEffect(() => {
    if (!props.errors[0] || !isSending) {
      return;
    }

    setIsSending(false);
  }, [props.errors]);

  const handleCreate = () => {
    setIsSending(true);
    props.createPartnerLot(
      (fields.name && fields.name.$value) || '',
      (fields.description && fields.description.$value) || '',
      (fields.imageURL &&
        fields.imageURL.$valueList &&
        fields.imageURL.$valueList[0]) ||
        ''
    );
  };
  const handleUpdate = () => {
    if (!props.partner) {
      return;
    }
    setIsSending(true);
    props.updatePartnerLot(
      props.partner.id,
      (fields.name && fields.name.$value) || null,
      (fields.description && fields.description.$value) || null,
      (fields.imageURL &&
        fields.imageURL.$valueList &&
        fields.imageURL.$valueList[0]) ||
        null
    );
  };
  const handleDelete = () => {
    if (!props.partner || !props.cbDelete) {
      return;
    }
    props.cbDelete(props.partner.id);
  };

  return (
    <FormBlock>
      {!props.partner && <ContentHead>New Lot Partner creation</ContentHead>}
      {props.partner && <ContentHead>Lot Partner update</ContentHead>}

      <Form
        isUpdate={!!props.partner}
        isDisabled={props.isDisabled}
        placeholderTitle={'auction partner'}
        fields={fields}
        isSending={isSending}
        cbCancel={props.cbCancel}
        cbCreate={handleCreate}
        cbUpdate={handleUpdate}
        cbDelete={handleDelete}
      />
    </FormBlock>
  );
}

export default connect(
  (state: IState) => ({
    set: state.Partners.Lot.set,
    actions: state.Partners.Lot.actions,
    errors: state.System.errors,
  }),
  {
    createPartnerLot,
    updatePartnerLot,
  }
)(PartnersLotForm);
