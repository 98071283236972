import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import { CacheRequests } from '../../service/cacheRequests';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IApiError } from '../../interfaces/system/IApi';
import {
  IAppPartnerLotCreateBody,
  IAppPartnerLotDeleteQuery,
  IAppPartnerLotIDsResponse,
  IAppPartnerLotSet,
  IAppPartnerLotSetQuery,
  IAppPartnerLotSetResponse,
  IAppPartnerLotUpdateBody,
} from '../../interfaces/partners/IAppPartnerLot';
import { dispatchInfo } from '../../service/error';
import {
  ITEM_CREATED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  PARTNER_LOT_ACTION,
  PARTNER_LOT_LOAD_IDS,
  PARTNER_LOT_LOAD_SET,
} from '../types';

const { partnerLot } = apiConfig.endpoints.partners;
const cacheByID: CacheRequests = new CacheRequests();

export const loadPartnerLotIDs =
  (hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      string | string[] | IAppPartnerLotSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    const { Partners } = getState();
    if (!hardReload && Partners.Lot.IDs[0]) {
      return;
    }

    callApi<IAppPartnerLotIDsResponse>(partnerLot.ids)
      .then((data: IAppPartnerLotIDsResponse) => {
        loadPartnerLotSet(data.partnerIDs)(dispatch, getState);

        dispatch({
          type: PARTNER_LOT_LOAD_IDS,
          payload: data.partnerIDs,
        });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadPartnerLotSet =
  (partnerLotIDs: string[], hardReload?: boolean) =>
  (
    dispatch: IDispatch<
      string | IAppPartnerLotSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    if (!partnerLotIDs || !partnerLotIDs[0]) {
      return;
    }
    const set: IAppPartnerLotSet = getState().Partners.Lot.set;
    let IDsToLoad: string[] = [];
    if (!hardReload) {
      const toLoad: string[] = [];
      partnerLotIDs.forEach((partnerLotID: string) => {
        if (!set[partnerLotID]) {
          toLoad.push(partnerLotID);
        }
      });
      if (!toLoad[0]) {
        return;
      }

      IDsToLoad = cacheByID.getToLoad(toLoad);

      if (!IDsToLoad[0]) {
        return;
      }
    }

    const query: IAppPartnerLotSetQuery = {
      partnerIDs: hardReload ? partnerLotIDs.join(',') : IDsToLoad.join(','),
    };
    callApi<IAppPartnerLotSetResponse>(partnerLot.main, query)
      .then((data: IAppPartnerLotSetResponse) => {
        dispatch({ type: PARTNER_LOT_LOAD_SET, payload: data.set });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const updatePartnerLot =
  (
    partnerID: string,
    name: null | string,
    description: null | string,
    imageURL: null | string
  ) =>
  (
    dispatch: IDispatch<
      string | IAppPartnerLotSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IAppPartnerLotUpdateBody = {
      partnerID,
    };
    if (name) {
      body.name = name;
    }
    if (description) {
      body.description = description;
    }
    if (imageURL) {
      body.imageURL = imageURL;
    }

    callApi(partnerLot.main, {}, body, 'POST')
      .then(() => dispatch({ type: PARTNER_LOT_ACTION, payload: ITEM_UPDATED }))
      .then(() => loadPartnerLotSet([partnerID], true)(dispatch, getState))
      .then(() => dispatchInfo(dispatch, 'Partner is updated'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

// export const updatePartnerLotSelf =

export const createPartnerLot =
  (name: string, description: string, imageURL: string) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const body: IAppPartnerLotCreateBody = {
      name,
      description,
      imageURL,
    };
    callApi(partnerLot.main, {}, body)
      .then(() => dispatch({ type: PARTNER_LOT_ACTION, payload: ITEM_CREATED }))
      .then(() => dispatchInfo(dispatch, 'Partner is created'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const deletePartnerLot =
  (partnerID: string) =>
  (
    dispatch: IDispatch<
      string | string[] | IAppPartnerLotSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (!getState().Account.isAdmin) {
      return;
    }

    const query: IAppPartnerLotDeleteQuery = {
      partnerID: partnerID,
    };
    callApi(partnerLot.main, query, undefined, 'DELETE')
      .then(() => dispatch({ type: PARTNER_LOT_ACTION, payload: ITEM_REMOVED }))
      .then(() => loadPartnerLotIDs(true)(dispatch, getState))
      .then(() => dispatchInfo(dispatch, 'Partner is removed'))
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
