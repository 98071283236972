import { ITranslationAllTypes } from './_.types';

export const translationUA: ITranslationAllTypes = {
  // auctions
  AuctionsTitle: 'Аукціони',
  AuctionsTitleEmpty: 'Тут буде інформація про аукціони',
  AuctionsTitleUpcoming: 'Наступний аукціон',
  AuctionsTitleUpcomingList: 'Наступні аукціони:',
  AuctionsTitleUpcomingShort: 'Наступні:',
  AuctionsTitleCompleted: 'Завершені аукціони:',
  AuctionsTitleCompletedShort: 'Завершені:',
  AuctionsDonations: 'Донати:',
  AuctionsDonationsPlace:
    'Донати аукціонів будуть тут після завершення аукціону та оновлення інформації',
  AuctionLotsAll: 'Показати всі лоти',
  AuctionLotsLive: 'Лоти Лайв Аукціону',
  AuctionLotsLiveAll: 'Показати звіти по всім лотам Лайв Аукціону',
  AuctionLotsWeek: 'Лоти Тижневого Аукціону',
  AuctionLotsWeekAll: 'Показати звіти по всім лотам Тижневого Аукціону',
  AuctionLotsEmpty: 'Результати аукціону будуть тут після завершення аукціону',
  AuctionDonatorsAll: 'Показати всіх донаторів',
  // auction
  AuctionBid: 'ставка',
  AuctionBidCurrent: 'Поточна ставка:',
  AuctionBidCustom: 'Зробити свою ставку:',
  AuctionBidCustomPlaceholder: 'або введіть свою суму',
  AuctionBiddersMultiple: 'донаторів виграють цей лот',
  AuctionStatusWin: 'Виграєте',
  AuctionStatusLost: 'Вашу ставку перебито',
  AuctionLotCurrent: 'Поточний лот:',
  AuctionAdminNoLots: 'Немає лотів щоб почати аукціон',
  AuctionTitle: 'Ти виграєш в аукціоні -<line />Україна виграє війну!',
  // account
  AccountDonationTitle: 'Ваші донати з усіх аукціонів',
  AccountDonationTitleDetails: 'Інформація про донати:',
  AccountDonationItem: 'Донат:',
  AccountDonationLink: 'Посилання, щоб зробити платіж:',
  AccountDonationScreenshot: 'Скріншот платежу:',
  AccountDonationScreenshotMake: 'Додати скріншот',
  AccountLotCreateTitle: `* Лот потратпить на модерацію після створення.`,
  AccountLotCreateDescription: `Опис буде розширено та стандартизовано відповідно до загального стилю аукціону.<line />Ви можете додати лише короткий опис та основну інформацію.`,
  AccountLotCreateAction: `Додати лот`,
  AccountLots: 'Ваші лоти',
  AccountLotsLiveTitle: 'Ваші лоти з Лайв Аукціону:',
  AccountLotsWeekTitle: 'Ваші лоти з Тижневого Аукціону:',
  AccountLotsOrderLiked: 'Сподобалися лоти',
  AccountLotsOrderCreated: 'Створені лоти',
  AccountLotsOrderCreatedEmpty: 'Ви ще не додали жодного лота',
  AccountLotsOrderDeclined: 'Відхилені лоти',
  AccountLotsOrderDeclinedReason: 'Причина:',
  AccountLotsOrderDeclinedEmpty: 'Не має відхилених лотів',
  AccountLotsOrderSold: 'Продані лоти',
  AccountLotsOrderSoldEmpty: 'Ви ще не продали жодного лота',
  AccountLotsOrderDonated: 'Виграні лоти',
  AccountLotsOrderDonatedEmpty: 'Ви ще не задонатили за жоден лот',
  AccountSettings: `Налаштування вашого профіля`,
  AccountSettingsPartner: `Налаштування вашого партнерського профіля`,
  AccountSettingsSubscription: `Налаштування підписки`,
  AccountSettingsAuction: 'Отримувати оновлення аукціону',
  AccountSettingsLot: 'Отримати оновлення лота',
  AccountSettingsBid: 'Оновлення вашої ставки',
  AccountSettingsUnsubscribeTitle: 'Нам шкода, що ви йдете!',
  AccountSettingsUnsubscribeDesc:
    'Якщо ви хочете скасувати підписку на наші електронні листи та сповіщення,' +
    'оновіть свої налаштування або скористайтеся кнопкою «Відписатися». Ви' +
    'не отримуватиме жодних оновлень від нас.',
  AccountLoginCall: `<bold>Увійдіть</bold>, щоб отримати доступ до аукціону<line />і станьте частиною чогось особливого!`,
  AccountLoginCallAuction: `Аукціон вже почався.<line/> Вам потрібно <bold>увійти</bold>, щоб потрапити на аукціон!`,
  AccountLoginCallAction: `<bold>Увійдіть</bold> щоб `,
  // shop
  ShopTitleMain: 'Відкрийте для себе лоти',
  ShopTitleCatalog: 'Вітрина можливих лотів для аукціону',
  ShopTitlePartners: 'Відкрийте для себе наших партнерів',
  ShopTitlePartnersShort: 'Наші партнери:',
  ShopTitleSellers: 'Відкрийте для себе наших донаторів лотів',
  LotTitleNoLots: 'Ще не має лотів',
  // lot
  LotTitleFresh: '<bold>Найновіші</bold> лоти:',
  LotTitleLiked: 'Найбільш <bold>відмічені</bold> лоти:',
  LotTitlePopular: 'Найбільш <bold>популярні</bold> лоти:',
  LotTitlePartners: 'Лоти від наших <ContentBold>партнерів</ContentBold>:',
  // products
  ProductBy: 'Лот від',
  ProductByShort: '',
  ProductByPartners: 'Лоти від наших партнерів:',
  // items
  ItemsEmpty: 'Ще не має створених',
  // analytics
  AnalyticsTitleCreate: 'Create в цифрах:',
  AnalyticsLots: 'лотів',
  AnalyticsViews: 'переглядів',
  AnalyticsViewAdmin: '* тільки один перегляд від прродавця та модераторів',
  AnalyticsLikes: 'вподобайок',
  AnalyticsBids: 'ставки',
  AnalyticsFollowers: 'послідовників',
  AnalyticsAuctions: 'аукціонів',
  AnalyticsGMV: 'зібрано на зараз',
  AnalyticsTotalProducts: 'Всього за всі лоти:',
  // reports
  ReportsCurrentWeek: 'Поточні результати тижневого Аукціону:',
  ReportsCurrent: 'Поточні результати:',
  ReportsTotal: 'Загальні результати:',
  ReportsTotalShop: 'Результати вітрини лотів:',
  ReportsLots: 'Лоти:',
  ReportsBids: 'Ставки:',
  ReportsLikes: 'Вподобайки:',
  ReportsAttenders: 'Учасники:',
  ReportsSellers: 'Продавці:',
  ReportsFollowers: 'Учасники:',
  ReportsViewsAll: 'Всього переглядів:',
  ReportsViewsUnique: 'Унікальних переглядів:',
  ReportsTopLotPrice: 'Найдорожчий лот:',
  ReportsTopLotTotal: 'Найдорожчий лот:',
  ReportsTopLotPopular: 'Найбільш популярний лот:',
  ReportsDonatorsCurrent: 'Топ донатори (поточні):',
  ReportsTopBidders: 'Найактивніші учасники :',
  ReportsTopSellers: 'Топ продавці:',
  ReportsCurrencyUAH: 'грн',
  // archive
  ReportsUserTitle: 'Звіти про користувачів',
  ReportsLotTitleLive: 'Звіти лотів з Лайв Аукціону',
  ReportsLotTitleWeek: 'Звіти лотів з Тижневого Аукціону',
  ReportsLotOrderAuction: 'Порядок аукціону',
  ReportsLotOrderTotal: 'Найдорожчі',
  ReportsLotOrderBidCnt: 'Найпопуляніші',
  ReportsLotOrderBuyTotal: 'Топ донатори',
  ReportsLotOrderSellTotal: 'Топ селлери',
  ReportsLotAll: 'Подивитись всі звіти',
  HallTitle: 'Зала Слави',
  HallSortDonated: 'Загальна кількість донатів',
  HallSortSold: 'Продано всього',
  // orders
  OrderNew: 'Найновіші',
  OrderPopular: 'Найпопулярніші',
  OrderLiked: 'Залайкані',
  // moderation

  // buttons
  ButtonLotsAll: 'Побачити всі лоти',
  ButtonLotsFresh: 'Всі нові лоти',
  ButtonLotsPopular: 'Всі популярні лоти',
  ButtonLotAdd: 'Додати свій лот',
  ButtonLotAddFirst: 'Додати перший лот',
  ButtonLotEdit: 'Редагувати лот',
  ButtonLoadMore: 'Завантажити ще',
  ButtonSave: 'Зберегти',
  // actions
  ActionLogIn: 'Увійти',
  ActionLogInToLike: 'щоб вподобати',
  ActionYes: 'так',
  ActionNo: 'ні',
  ActionUnsubscribe: 'Відписатись від усього',
  ActionSubscribe: 'Підписатись на все',
  ActionGoToShop: 'Перейти до вітрини',
  ActionStart: 'Почати',
  ActionReport: 'Звіти',
  ActionAuctionEnter: 'Перейти до аукціону:',
  ActionLandingAuctions: 'Побачити лоти та аукціони',
  ActionLandingAuction: 'Перейти на сайт аукціону',
  ActionLandingPartners: 'Побачити всіх партнерів',
  ActionMoreAbout: 'Більше про',
  ActionAddCalendar: 'Додати в календар',
  // menu
  MenuAuctionLive: 'Лайв аукціон',
  MenuAuctionWeek: 'Тижневий аукціон',
  MenuHall: 'Зала Слави',
  MenuAuctions: 'Аукціони',
  MenuShop: 'Вітрина',
  MenuModeration: 'Модерація',
  MenuAnalytics: 'Аналітика',
  MenuMyLot: 'Мої лоти',
  MenuMyDonations: 'Мої донати',
  MenuSettings: 'Налаштування',
  MenuPartnerSettings: 'Партнерські налаштування',
  MenuCreate: 'Сервіс в цифрах',
  MenuPartners: 'Партнери',
  MenuLogIn: 'Увійти',
  MenuLogout: 'Вийти',
  // fields
  FieldsName: "ім'я",
  // landing
  LandingMainDescription:
    'Create Auction — платформа для створення та проведення благодійних аукціонів. ' +
    'Маючи лише один лот для початку, ви можете створити власну благодійну історію та зібрати гроші на важливі для вас справи.',
  LandingMainQuestionWhom: 'Для кого?',
  LandingMainQuestionWhy: 'Чому Create Auction?',
  LandingMainQuestionFormats: 'Який формат аукціону вибрати?',
  LandingMainQuestionSupport: 'Як ми можемо підтримати ваш аукціон?',
  LandingMainQuestionSupportAnswer:
    'Це не тільки про технології.<line />Ми тут, щоб допомогти вам організувати успішний благодійний аукціон:',
  LandingMainQuestionLive:
    'Чи можете ви виграти лот прямо зараз і тут (на лайв аукціоні)?',
  LandingMainSupportOptions: [
    `Допомога в організації благодійного вечора`,
    `Процес модерації партії`,
    `Створити перші партії та приклади наступних`,
    `Додайте магії до вашої події`,
  ],
  LandingMainMainFeatures: [
    `🎯 Універсальні формати аукціону`,
    `🛠️ Легке налаштування та налаштування`,
    `📈 Аналітика в реальному часі`,
    `💌 Залучення донорів`,
    `💼 White Label Solution`,
  ],
  LandingMainTargetGroups: [
    `💚Бізнес із соціальною відповідальністю`,
    `🎊Організатори заходів та спеціалісти зі збору коштів`,
    `🦋Некомерційні організації`,
  ],
  LandingMainLiveOptions: [
    `🎙️ Взаємодія в реальному часі`,
    `🕒 Своєчасне закриття лотів`,
    `📢 Конкурсні торги`,
  ],
  LandingMainWeekOptions: [
    `🗓️ Гнучкий період ставок`,
    `🔄 Закриття ділянки`,
    `💻📱 Безперебійний онлайн-досвід`,
  ],
  LandingLotsPopular: 'Найпопулярніші лоти з поточних аукціонів:',
};
