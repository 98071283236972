import styled from 'styled-components';
import { BlockPageContent } from '../../common/styles';
import { device, margins, sizes } from '../../common/sizes';

export const LoginBlock = styled.div`
  ${BlockPageContent};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: ${sizes.full};

  @media ${device.mobileL} {
    width: ${sizes.half};
  }
}
`;

export const LoginBlockButton = styled.div`
  padding: ${margins.item};
  width: auto;
  margin: auto;
  max-width: ${sizes.widthXL};
  box-sizing: border-box;

  @media ${device.mobileL} {
    padding: 0;
  }
}
`;
