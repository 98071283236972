import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../interfaces/system/IState';
import PartnersFeedbacksForm from '../../../components/partners/PartnersFeedbacksForm';
import {
  IAppPartnerFeedbacksPublic,
  IAppPartnerFeedbacksSet,
} from '../../../interfaces/partners/IAppPartnerFeedbacks';
import {
  deletePartnerFeedbacks,
  loadPartnerFeedbacksIDs,
} from '../../../actions/partners/partnerFeedbacks.actions';
import { PartnersFeedbacksItemModeration } from '../../../components/partners/PartnersFeedbacksItemModeration';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import {
  RenderFormFunction,
  RenderItemPublicFunction,
} from '../../../common/common';

interface IPartnersFeedbacksProps {
  loadPartnerFeedbacksIDs: (hardReload?: boolean) => void;
  deletePartnerFeedbacks: (partnerID: string) => void;
  IDs: string[];
  set: IAppPartnerFeedbacksSet;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function PartnersFeedbacks(props: IPartnersFeedbacksProps) {
  const renderItemPublic: RenderItemPublicFunction<
    IAppPartnerFeedbacksPublic
  > = (item: IAppPartnerFeedbacksPublic) => {
    return <PartnersFeedbacksItemModeration partner={item} />;
  };

  const renderForm: RenderFormFunction<IAppPartnerFeedbacksPublic> = (
    isDisabled: undefined | boolean,
    item: undefined | IAppPartnerFeedbacksPublic,
    cbCancel: () => void,
    cbDelete?: (partnerID: string) => void
  ) => {
    return (
      <PartnersFeedbacksForm
        isDisabled={isDisabled}
        partner={item}
        cbCancel={cbCancel}
        cbDelete={cbDelete}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'Partner Feedbacks moderation'}
      itemName={"this partner' feedback"}
      itemNameCreate={'Create new feedback'}
      render={{
        renderItemPublic,
        renderForm,
      }}
      data={{
        set: props.set,
        list: { load: props.loadPartnerFeedbacksIDs, IDs: props.IDs },
        actions: props.actions,
        errors: props.errors,
      }}
      cb={{
        cbDelete: (removingID: string) =>
          props.deletePartnerFeedbacks(removingID),
      }}
      action={{
        showInline: true,
      }}
      isAdmin={props.isAdmin}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    IDs: state.Partners.Feedbacks.IDs,
    set: state.Partners.Feedbacks.set,
    actions: state.Partners.Feedbacks.actions,
    errors: state.System.errors,
  }),
  {
    loadPartnerFeedbacksIDs,
    deletePartnerFeedbacks,
  }
)(PartnersFeedbacks);
