import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ContentBold } from './typos';
import {
  ITranslationListTypes,
  ITranslationSimpleTypes,
} from '../config/locales/_.types';

interface ILProps {
  k: keyof ITranslationSimpleTypes;
}

export function L(props: ILProps) {
  return (
    <Trans
      i18nKey={props.k}
      components={{ bold: <ContentBold />, line: <br /> }}
    ></Trans>
  );
}

export function LArray(k: keyof ITranslationListTypes): string[] {
  const { t } = useTranslation();
  return t(k, { returnObjects: true });
}
