import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../utils/analytics';
import { IState } from '../../interfaces/system/IState';
import ReportLots from '../../components/auctions/ReportLots';
import { BlockPage } from '../../common/styles';

interface ILotsAllPageProps {}

function LotsAllPage(props: ILotsAllPageProps) {
  useEffect(() => sendPageEvent('Auction Lots'), []);

  return (
    <BlockPage>
      <ReportLots
        auction={null}
        platformType={null}
        platformAuctionID={null}
        lotType={null}
        mode="rows"
      />
    </BlockPage>
  );
}

export default connect((state: IState) => ({}), {})(LotsAllPage);
