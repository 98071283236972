import React from 'react';
import { connect } from 'react-redux';
import TopAccount from './TopAccount';
import { IReportTopDonator } from '../../interfaces/examples/IReportAuction';
import { ContentDesc, ContentDescInline } from '../../common/typos';
import { Icon } from '../landing/Button.styles';

interface IAuctionResultProps {
  topDonators: IReportTopDonator[];
}

function TopDonators(props: IAuctionResultProps) {
  return (
    <>
      {props.topDonators.map((donator: IReportTopDonator, index: number) => (
        <ContentDesc key={index}>
          <TopAccount accountID={donator.accountID} />
          <ContentDescInline>{donator.donated}</ContentDescInline>
          <Icon src={'/icons/cup.png'} />
        </ContentDesc>
      ))}
    </>
  );
}

export default connect(() => ({}), {})(TopDonators);
