import styled, { css } from 'styled-components';
import { device, margins, sizes, sizesBorder } from './sizes';
import { colors } from './colors';
import { fonts, typos } from './typos';

export const shadows = {
  // main: `0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  //   0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
  add: `0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
  main: ` 0 0 5px 1px rgba(0,0,0,0.5)`,
};

export const transitions = {
  easy: `all 1s ease-out`,
  visibility: `visibility 0s, opacity 0.5s linear`,
};

export const borders = {
  greyS: `${sizesBorder.widthS} solid ${colors.greyBorder}`,
  mainS: `${sizesBorder.widthS} solid ${colors.main}`,
  mainM: `${sizesBorder.widthM} solid ${colors.main}`,
  mainL: `${sizesBorder.widthL} solid ${colors.main}`,
  win: css`
    border: ${sizesBorder.widthL} solid ${colors.win} !important;
    border-radius: ${sizesBorder.radius};
    transition: ${transitions.easy};
  `,
  lost: css`
    border: ${sizesBorder.widthL} solid ${colors.lost};
    border-radius: ${sizesBorder.radius};
    transition: ${transitions.easy};
  `,
  main_bottom: css`
    border-bottom: ${sizesBorder.widthL} solid ${colors.main};
    //border-radius: ${sizesBorder.radius};
    transition: ${transitions.easy};
  `,
};

const button = css`
  color: ${colors.main};
  border-radius: ${sizesBorder.radius};
  border: ${borders.mainS};
  cursor: pointer;
  margin-right: ${margins.block};
}
`;
const buttonSuccess = css`
  border-color: ${colors.win};
  border-width: ${sizesBorder.widthM};
}
`;
const buttonFail = css`
  border-color: ${colors.lost};
  border-width: ${sizesBorder.widthM};
}
`;

export const BlockPage = styled.div`
  width: 100%;
  display: block;
  //position: relative;
  position: initial;
  margin: auto;
  text-align: left;
  overflow: auto;
  padding: ${margins.block} ${margins.block};
  box-sizing: border-box;

  @media ${device.mobileL} {
    width: 87%;
  }
  @media ${device.tablet} {
    width: 75%;
  }
  @media ${device.laptop} {
    width: 62.5%;
  }
`;

export const BlockPageContent = styled.div`
  padding: 0 ${margins.block};
  float: left;
  width: ${sizes.full};
  box-sizing: border-box;
  min-height: ${sizes.widthM};

  &.relative {
    min-height: unset;
  }

  @media ${device.mobileL} {
    padding: 0;
  }
`;

export const BlockDates = styled.div`
  // margin: ${margins.block} 0;

  & label {
    width: 100%;
    max-width: ${sizes.widthL};
    display: block;
  }

  & input {
    ${typos.desc};
    border-radius: ${sizesBorder.radius};
    border: ${borders.mainS};
    padding: ${margins.item} ${margins.block};
    outline: none;
    width: 100%;
    max-width: ${sizes.widthM};
    box-sizing: border-box;

    :focus {
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }
  }
`;

export const BlockActions = styled.div`
  margin: ${margins.block} 0;

  & img {
    margin-left: ${margins.item};
  }

  &.inline {
    display: inline-block;
    float: left;
  }

  &.right {
    text-align: right;
  }
`;
export const ActionButton = styled.button`
  ${typos.name};
  min-height: 40px;
  padding: 0 ${margins.block};
  ${button};
  margin-bottom: ${margins.item};

  :hover {
    padding: 0 ${margins.blockX2};
    transition: ${transitions.easy};
  }

  &.success {
    ${buttonSuccess}
  }

  &.fail {
    ${buttonFail}
  }

  &[disabled] {
    border-color: rgba(19, 1, 1, 0.3) !important;
    color: rgba(19, 1, 1, 0.3) !important;
    cursor: not-allowed;
  }

  & .tooltip {
    position: absolute;
    width: 80%;
    background: ${colors.background};
    transform: translateY(-100%);
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: ${transitions.visibility};
  }

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;
export const ActionLink = styled.a`
  ${typos.name};
  margin-right: ${margins.item};
  font-size: inherit;
  //display: inline-block;
  text-decoration: underline;
  color: ${colors.main};
  cursor: pointer;

  &.block {
    display: block;
  }

  &.main {
    font-weight: ${fonts.weightL};
  }

  &.success {
    color: ${colors.win};
  }

  &.fail {
    color: ${colors.lost};
  }

  &.disabled {
    color: ${colors.greyBorder};
    cursor: inherit;
  }
`;
export const ButtonMain = styled.button`
  ${typos.name};
  height: 65px;
  padding-left: ${margins.block};
  padding-right: ${margins.block};
  ${button};

  :hover {
    padding: ${margins.block} ${margins.blockX2};
    transition: ${transitions.easy};
  }

  &[disabled] {
    border-color: rgba(19, 1, 1, 0.3) !important;
    color: rgba(19, 1, 1, 0.3) !important;
    cursor: not-allowed;
  }

  &.success {
    border-width: ${sizesBorder.widthL} !important;
    ${buttonSuccess};
  }

  &.fail {
    border-width: ${sizesBorder.widthL} !important;
    ${buttonFail};
  }
`;
export const Tooltip = styled.div`
  margin: ${margins.block} 5px;
  padding: ${margins.item};
  width: calc(100% - 10px);
  cursor: help;

  box-sizing: border-box;
  box-shadow: ${shadows.main};
  border-radius: ${sizesBorder.radius};
`;
