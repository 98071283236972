import { apiConfig } from '../../config/api.config';
import { IDispatch, IState } from '../../interfaces/system/IState';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import {
  ANALYTICS_CRON_LOAD,
  ANALYTICS_CRON_LOAD_BY_PLATFORM,
  ANALYTICS_CRON_LOAD_CACHE,
  ANALYTICS_CRON_LOAD_CACHE_ALL,
} from '../types';
import {
  IBaseAnalyticsAggregatedModelGetByPlatformResponse,
  IBaseAnalyticsAggregatedModelGetCacheAllResponse,
  IBaseAnalyticsAggregatedModelGetCacheResponse,
  IBaseAnalyticsAggregatedModelGetResponse,
} from '../../interfaces/analytics/IBaseAnalyticsAggregatedModel';
import {
  loadAnalyticsCacheAllWrapper,
  loadAnalyticsCacheWrapper,
  loadAnalyticsMainByPlatformWrapper,
  loadAnalyticsMainWrapper,
  saveAnalyticsCacheByPlatformWrapper,
  saveAnalyticsCacheWrapper,
} from './analyticsWrapper.actions';
import { IPlatformType } from '../../config/platform.config';

const { main, platform, cache, cacheAll } =
  apiConfig.endpoints.analytics.techCron;

export const loadAnalyticsCronMain =
  (from: string, to: string) =>
  (
    dispatch: IDispatch<
      IBaseAnalyticsAggregatedModelGetResponse | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainWrapper(
      main,
      ANALYTICS_CRON_LOAD,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsCronMainByPlatform =
  (platformType: IPlatformType, from: string, to: string) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetByPlatformResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsMainByPlatformWrapper(
      platform,
      ANALYTICS_CRON_LOAD_BY_PLATFORM,
      platformType,
      from,
      to
    )(dispatch, getState);
  };

export const loadAnalyticsCronCache =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheWrapper(
      cache,
      ANALYTICS_CRON_LOAD_CACHE,
      hardReload
    )(dispatch, getState);
  };

export const loadAnalyticsCronCacheAll =
  (hardReload: boolean) =>
  (
    dispatch: IDispatch<
      | IBaseAnalyticsAggregatedModelGetCacheAllResponse
      | IServiceInfo
      | IServiceError
    >,
    getState: () => IState
  ) => {
    loadAnalyticsCacheAllWrapper(
      cacheAll,
      ANALYTICS_CRON_LOAD_CACHE_ALL,
      hardReload
    )(dispatch, getState);
  };

export const saveTechCronCache =
  () =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheWrapper(cache)(dispatch, getState);
  };

export const saveTechCronCacheByPlatform =
  (platformType: IPlatformType) =>
  (
    dispatch: IDispatch<string | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    saveAnalyticsCacheByPlatformWrapper(cacheAll, platformType)(
      dispatch,
      getState
    );
  };
