import {
  ITemplateParamsPublic,
  ITemplateParamsView,
} from '../../../interfaces/communication/ITemplateParams';
import {
  IParamsItemView,
  RenderFormFunction,
  RenderItemPublicFunction,
  RenderItemViewFunction,
} from '../../../common/common';
import { TemplateParamsItemModeration } from './TemplateParamsItemModeration';
import TemplateParamsForm from './TemplateParamsForm';
import React from 'react';
import { ItemView } from '../../../common/moderation/ItemView';

export const renderTemplateParamsItemPublic: RenderItemPublicFunction<
  ITemplateParamsPublic
> = (
  item: ITemplateParamsPublic,
  inlineBlock?: JSX.Element,
  ParamsItemView?: IParamsItemView
) => {
  return (
    <TemplateParamsItemModeration
      templateParams={item}
      showInline={true}
      inlineBlock={inlineBlock}
      ParamsItemView={ParamsItemView}
    />
  );
};

export const renderTemplateParamsForm: RenderFormFunction<
  ITemplateParamsPublic
> = (
  isDisabled: undefined | boolean,
  item: undefined | ITemplateParamsPublic,
  cbCancel: () => void,
  cbDelete?: (partnerID: string) => void
) => {
  return (
    <TemplateParamsForm
      isDisabled={isDisabled}
      templateParams={item}
      cbCancel={cbCancel}
      cbDelete={cbDelete}
    />
  );
};

export const renderTemplateParamsItemView: RenderItemViewFunction = (
  cb: (params: ITemplateParamsView) => void
) => {
  const defaultParams: ITemplateParamsView = {
    mode: true,
    type: true,
    params: true,
    value: true,
    createdAt: true,
  };

  return (
    <ItemView
      cb={(params: Record<string, boolean>) =>
        cb(params as ITemplateParamsView)
      }
      defaultParam={defaultParams}
    />
  );
};
