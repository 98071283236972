import { apiConfig } from '../../config/api.config';
import { callApi, catchError } from '../../service/api';
import {
  IAppReportLotBatchData,
  IAppReportLotBatchQuery,
  IAppReportLotOrder,
  IAppReportLotSearchData,
  IAppReportLotSearchQuery,
  IAppReportLotSearchResponse,
  IAppReportLotSet,
  IAppReportLotSetQuery,
  IAppReportLotSetResponse,
} from '../../interfaces/auctions/IAppReportLot';
import { IApiError } from '../../interfaces/system/IApi';
import { IServiceError, IServiceInfo } from '../../interfaces/system/IError';
import { IDispatch, IState } from '../../interfaces/system/IState';
import {
  REPORT_LOT_BATCH_LOAD,
  REPORT_LOT_SEARCH_LOAD,
  REPORT_LOT_SET_LOAD,
} from '../types';
import { DataBatch } from '../../interfaces/system/data';
import { IPlatformType } from '../../config/platform.config';

const { reportLot } = apiConfig.endpoints;

export function prepareBatchKeyReportLot(
  lotType: null | string,
  platformType: null | string,
  platformAuctionID: null | string,
  order: null | string
): string {
  return JSON.stringify({
    lotType,
    platformType,
    platformAuctionID,
    order,
  });
}

export function prepareSearchKeyReportLot(
  platformType: null | IPlatformType,
  search: string
): string {
  return JSON.stringify({
    platformType,
    search,
  });
}

export const loadReportLotSet =
  (lotIDs: string[]) =>
  (
    dispatch: IDispatch<IAppReportLotSet | IServiceInfo | IServiceError>,
    getState: () => IState
  ) => {
    const IDs: string[] = [];
    const { byLotID } = getState().Auctions.reportLots;
    lotIDs.forEach((lotID: string) => {
      if (!byLotID[lotID]) {
        IDs.push(lotID);
      }
    });

    if (!IDs[0]) {
      return;
    }

    const query: IAppReportLotSetQuery = {
      reportLotIDs: IDs.join(','),
    };

    callApi<IAppReportLotSetResponse>(reportLot.set, query)
      .then((data: IAppReportLotSetResponse) =>
        dispatch({ type: REPORT_LOT_SET_LOAD, payload: data.set })
      )
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const loadReportLotBatchNext =
  (
    lotType: null | string,
    platformType: null | IPlatformType,
    platformAuctionID: null | string,
    order: null | IAppReportLotOrder
  ) =>
  (
    dispatch: IDispatch<
      IAppReportLotBatchData | IAppReportLotSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    const key: string = prepareBatchKeyReportLot(
      lotType,
      platformType,
      platformAuctionID,
      order
    );

    const { limit, IDsByBatchKey } = getState().Auctions.reportLots;
    const query: IAppReportLotBatchQuery = {
      lotType,
      platformType,
      platformAuctionID,
      order,
      limit: limit.toString(),
      skip: (IDsByBatchKey[key] || []).length.toString(),
    };

    callApi<DataBatch<string>>(reportLot.batch, query)
      .then((data: DataBatch<string>) => {
        const payload: IAppReportLotBatchData = {
          key,
          data,
        };
        loadReportLotSet(data.items)(dispatch, getState);
        dispatch({ type: REPORT_LOT_BATCH_LOAD, payload });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };

export const searchReportLot =
  (platformType: null | IPlatformType, search: string) =>
  (
    dispatch: IDispatch<
      IAppReportLotSearchData | IAppReportLotSet | IServiceInfo | IServiceError
    >,
    getState: () => IState
  ) => {
    if (search === '') {
      return;
    }

    const key: string = prepareSearchKeyReportLot(platformType, search);
    const { IDsBySearchKey } = getState().Auctions.reportLots;
    if (IDsBySearchKey[key]) {
      return;
    }

    const query: IAppReportLotSearchQuery = {
      search,
    };
    if (platformType) {
      query.platform = platformType;
    }

    callApi<IAppReportLotSearchResponse>(reportLot.search, query)
      .then((data: IAppReportLotSearchResponse) => {
        const payload: IAppReportLotSearchData = {
          key,
          reportIDs: data.reportIDs,
        };

        loadReportLotSet(data.reportIDs)(dispatch, getState);
        dispatch({ type: REPORT_LOT_SEARCH_LOAD, payload });
      })
      .catch((error: Error | IApiError) => catchError(dispatch, error));
  };
