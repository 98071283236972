import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sendPageEvent } from '../../../utils/analytics';
import { IState } from '../../../interfaces/system/IState';
import { ModerationPage } from '../../../common/moderation/ModerationPage';
import {
  deleteRecommendation,
  loadRecommendationBatch,
  prepareRecommendationBatchKey,
  searchRecommendation,
} from '../../../actions/communication/recommendation.actions';
import {
  IAppRecommendationSet,
  IAppRecommendationSocialView,
} from '../../../interfaces/communication/IAppRecommendation';
import { DataBatchWide } from '../../../interfaces/system/data';
import {
  IRecommendationBatchFiltersParams,
  RecommendationBatchFilters,
} from '../../../components/communication/Recommendation/RecommendationBatchFilters';
import { RenderBatchFilterFunction } from '../../../common/common';
import {
  renderRecommendationBatchActions,
  renderRecommendationForm,
  renderRecommendationItemPublic,
  renderRecommendationItemView,
} from '../../../components/communication/Recommendation/Recommendation';

interface ICommunicationRecommendationProps {
  loadRecommendationBatch: (
    emails: null | string[],
    categories: undefined | null | string[],
    isEngaged: null | boolean,
    isParsed: null | boolean,
    socialView: null | IAppRecommendationSocialView,
    extraLimit?: number
  ) => void;
  deleteRecommendation: (recommendationID: string) => void;
  searchRecommendation: (search: string) => void;
  IDs: string[];
  IDsBySearch: Record<string, string[]>;
  set: IAppRecommendationSet;
  byBatchKey: Record<string, DataBatchWide>;
  actions: string[];
  errors: string[];
  isAdmin: boolean;
}

function CommunicationRecommendation(props: ICommunicationRecommendationProps) {
  const [batchKey, setBatchKey] = useState<string>(
    prepareRecommendationBatchKey(null, undefined, null, null, null)
  );
  const [filters, setFilters] = useState<IRecommendationBatchFiltersParams>({
    emails: null,
    categories: undefined,
    isEngaged: null,
    isParsed: null,
    socialView: null,
  });

  useEffect(() => sendPageEvent('moderation communication recommendation'), []);

  const loadBatch = (extraLimit?: number) => {
    if (!filters) {
      return;
    }

    props.loadRecommendationBatch(
      filters.emails,
      filters.categories,
      filters.isEngaged,
      filters.isParsed,
      filters.socialView,
      extraLimit
    );
  };

  const renderBatchFilter: RenderBatchFilterFunction = () => {
    return (
      <RecommendationBatchFilters
        cb={(batchKey, params) => {
          setBatchKey(batchKey);
          setFilters(params);
        }}
      />
    );
  };

  return (
    <ModerationPage
      pageTitle={'Recommendation moderation'}
      itemName={'this recommendation'}
      itemNameCreate={'Create new recommendation'}
      render={{
        renderItemPublic: renderRecommendationItemPublic,
        renderForm: renderRecommendationForm,
        renderItemView: renderRecommendationItemView,
      }}
      data={{
        set: props.set,
        batch: {
          batchKey,
          byBatchKey: props.byBatchKey,
          loadBatch,
          renderBatchFilter,
          showExtraLoading: true,
          renderBatchActions: renderRecommendationBatchActions,
        },
        search: {
          search: props.searchRecommendation,
          IDsBySearch: props.IDsBySearch,
          placeholderTitle: 'emails, name or social',
        },
        actions: props.actions,
        errors: props.errors,
      }}
      cb={{
        cbDelete: (removingID: string) =>
          props.deleteRecommendation(removingID),
      }}
      action={{
        showInline: true,
      }}
      isAdmin={props.isAdmin}
    />
  );
}

export default connect(
  (state: IState) => ({
    isAdmin: state.Account.isAdmin,
    IDs: state.Communication.Recommendation.IDs,
    IDsBySearch: state.Communication.Recommendation.IDsBySearch,
    set: state.Communication.Recommendation.set,
    byBatchKey: state.Communication.Recommendation.byBatchKey,
    actions: state.Communication.Recommendation.actions,
    errors: state.System.errors,
  }),
  {
    loadRecommendationBatch,
    deleteRecommendation,
    searchRecommendation,
  }
)(CommunicationRecommendation);
