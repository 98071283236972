import styled from 'styled-components';
import { colors } from '../../common/colors';
import { fonts, typos } from '../../common/typos';
import { margins, sizes, sizesBorder } from '../../common/sizes';

export const AdminActionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${margins.item} 0;
  width: ${sizes.full};
  float: left;
`;

export const AdminAction = styled.button`
  ${typos.desc};
  //width: 40%;
  padding: ${margins.item};
  font-weight: ${fonts.weightL};
  text-align: left;

  font-family: Montserrat, -apple-system, sans-serif;
  color: white;
  border-radius: ${sizesBorder.radius};
  border: ${sizesBorder.widthS} solid ${colors.lost};
  cursor: pointer;
  background-color: ${colors.lost};

  &.restart {
    color: black;
    border-color: black;
  }

  &.continue {
    color: ${colors.lost};
    background-color: ${colors.background};
  }
`;
