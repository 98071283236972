export const AccountIDExample: string = 'example';
export const FirstNames: string[] = [
  'Emily',
  'Benjamin',
  'Sophia',
  'William',
  'Olivia',
  'Ethan',
  'Ava',
  'Michael',
  'Isabella',
  'Jacob',
];
export const FirstNamesCnt: number = FirstNames.length;
export const LastNames: string[] = [
  'Smith',
  'Johnson',
  'Williams',
  'Jones',
  'Brown',
  'Davis',
  'Miller',
  'Wilson',
  'Taylor',
  'Anderson',
];
export const LastNamesCnt: number = LastNames.length;
